var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import UserContactForm from '@declaration/components/appointment/UserContactForm.vue';
const claim = namespace('claim');
const user = namespace('user');
let ImmediatePayoutImpossible = class ImmediatePayoutImpossible extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/advisorCall.svg');
        this.isUpdatingClaim = false;
    }
    created() {
        this.initializeContact();
    }
    initializeContact() {
        if ((this.userContact.email && this.userContact.email.length) || !this.userEmail) {
            return;
        }
        this.updateUserContact({ email: this.userEmail });
    }
    async saveAndGoHome() {
        this.isUpdatingClaim = true;
        await this.updateAndSaveClaim({ step: Step.ImmediatePayoutImpossible, claimPayload: {} });
        this.isUpdatingClaim = false;
    }
};
__decorate([
    claim.Action('updateAndSaveClaim')
], ImmediatePayoutImpossible.prototype, "updateAndSaveClaim", void 0);
__decorate([
    claim.Getter('userContact')
], ImmediatePayoutImpossible.prototype, "userContact", void 0);
__decorate([
    claim.Action('updateUserContact')
], ImmediatePayoutImpossible.prototype, "updateUserContact", void 0);
__decorate([
    user.State('email')
], ImmediatePayoutImpossible.prototype, "userEmail", void 0);
ImmediatePayoutImpossible = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            UserContactForm,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], ImmediatePayoutImpossible);
export default ImmediatePayoutImpossible;
