var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QUESTIONS_PAGE_TRANSITION } from '@declaration/animations/questionsPageTransitions';
import { NO_OP_TRANSITION } from '@declaration/animations/utils';
import { goBackOneStep, quitClaimDeclarationCloseCallback, } from '@declaration/helpers/layoutMetaCallback';
import { claimDeclarationMiddlewares } from '@declaration/middleware/middlewares';
import { PageComponents } from '@declaration/pageComponents';
import { DeclarationPage } from '@declaration/pages';
import { applyLayoutColor } from '@declaration/services/applyLayoutColor';
import { changePageTitle } from '@declaration/services/changePageTitle';
import { setCloseCallback } from '@declaration/services/setCloseCallback';
import { setGoBackCallback } from '@declaration/services/setGoBackCallback';
import { Step } from '@shared/claim/steps';
// import { Step } from '@shared/claim/steps'
const claim = namespace('claim');
const pages = namespace('pages');
let Declaration = class Declaration extends Vue {
    created() {
        const translate = this.$tc.bind(this);
        const routerBack = () => {
            this.$router.back();
        };
        const helpersContext = {
            store: this.$store,
            translate,
            routerBack,
        };
        this.$watch('currentPage', (page) => {
            var _a;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const component = PageComponents[page];
            const { color, title, close, goBack } = (_a = component.options.meta) !== null && _a !== void 0 ? _a : {};
            applyLayoutColor(color, helpersContext);
            changePageTitle(title, helpersContext);
            setGoBackCallback(goBack === undefined ? goBackOneStep : goBack, helpersContext);
            setCloseCallback(close, helpersContext);
        }, { immediate: true });
        this.$watch('activeQuestion', (question) => {
            if (!question)
                return;
            setGoBackCallback(this.previousStep === Step.ClaimType ? null : goBackOneStep, helpersContext);
        }, { immediate: true });
    }
    get transition() {
        if (this.currentPage === DeclarationPage.Questions) {
            return QUESTIONS_PAGE_TRANSITION;
        }
        return NO_OP_TRANSITION;
    }
};
__decorate([
    claim.State('claim')
], Declaration.prototype, "claim", void 0);
__decorate([
    claim.Getter('currentStep')
], Declaration.prototype, "currentStep", void 0);
__decorate([
    pages.Getter('currentPage')
], Declaration.prototype, "currentPage", void 0);
__decorate([
    pages.Getter('previousStep')
], Declaration.prototype, "previousStep", void 0);
__decorate([
    pages.Getter('activeQuestion')
], Declaration.prototype, "activeQuestion", void 0);
__decorate([
    pages.Action('selectActiveStep')
], Declaration.prototype, "selectActiveStep", void 0);
Declaration = __decorate([
    Component({
        middleware: claimDeclarationMiddlewares(),
        components: {
            ...PageComponents,
        },
        meta: {
            close: quitClaimDeclarationCloseCallback,
            goBack: goBackOneStep,
        },
    })
], Declaration);
export default Declaration;
