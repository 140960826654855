var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { GLASS_BREAKAGE_PERSONAL_PROPERTY_COUNT_CHOICES, GLASS_BREAKAGE_TRAD_KEYS, GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_COUNT_ANSWERS, } from '@declaration/constants/glassBreakageDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
const settings = namespace('settings');
let GlassBreakagePersonalPropertyDamagesCountQuestion = class GlassBreakagePersonalPropertyDamagesCountQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get maxCount() {
        const value = this.settingGetter('bdgPersonalPropertiesCountThresholdBeforeExpertise');
        return value ? parseInt(value) : 1;
    }
    get questionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['personal-property-damages-count']);
    }
    get choices() {
        return GLASS_BREAKAGE_PERSONAL_PROPERTY_COUNT_CHOICES.map((choice) => {
            return {
                value: choice.value,
                label: this.$tc(choice.tradKey, this.maxCount, { count: this.maxCount }),
            };
        });
    }
    get selectedValue() {
        if (this.qualification.personalPropertiesCountAboveThreshold === null) {
            return null;
        }
        return this.qualification.personalPropertiesCountAboveThreshold;
    }
    get answer() {
        if (this.qualification.personalPropertiesCountAboveThreshold === null) {
            return null;
        }
        return this.qualification.personalPropertiesCountAboveThreshold
            ? this.$tc(GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_COUNT_ANSWERS['more-than-threshold'], this.maxCount, { count: this.maxCount })
            : this.$tc(GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_COUNT_ANSWERS['threshold-or-less'], this.maxCount, { count: this.maxCount });
    }
    async onUpdate(personalPropertiesCountAboveThreshold) {
        await this.updateAndSaveGlassBreakageDamageQualification({
            qualificationPayload: { personalPropertiesCountAboveThreshold },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakagePersonalPropertyDamagesCountQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakagePersonalPropertyDamagesCountQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakagePersonalPropertyDamagesCountQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakagePersonalPropertyDamagesCountQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
__decorate([
    settings.Getter('setting')
], GlassBreakagePersonalPropertyDamagesCountQuestion.prototype, "settingGetter", void 0);
GlassBreakagePersonalPropertyDamagesCountQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], GlassBreakagePersonalPropertyDamagesCountQuestion);
export default GlassBreakagePersonalPropertyDamagesCountQuestion;
