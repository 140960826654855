var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { formatDate } from '@shared/utils/date';
const claim = namespace('claim');
const qualification = namespace('qualification');
const layout = namespace('layout');
let ProfessionalInterventionInfoQuestion = class ProfessionalInterventionInfoQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.companyName = null;
        this.address = null;
        this.zipCodeCity = null;
        this.phoneNumber = null;
        this.interventionDate = null;
        this.maxDate = new Date();
        this.shouldDisplayValidationMessages = false;
    }
    created() {
        this.maxDate = this.claim.damageDate ? new Date(this.claim.damageDate) : new Date();
        this.companyName =
            this.electricalDamageQualification.professionalIntervention.professionalContact.companyName;
        this.address =
            this.electricalDamageQualification.professionalIntervention.professionalContact.address;
        this.zipCodeCity =
            this.electricalDamageQualification.professionalIntervention.professionalContact.zipCodeCity;
        this.phoneNumber =
            this.electricalDamageQualification.professionalIntervention.professionalContact.phoneNumber;
        this.interventionDate = this.electricalDamageQualification.professionalIntervention
            .interventionDate
            ? new Date(this.electricalDamageQualification.professionalIntervention.interventionDate)
            : this.maxDate;
    }
    get areInfoCompleted() {
        return this.companyName && this.interventionDate !== null;
    }
    revealValidation() {
        this.shouldDisplayValidationMessages = true;
    }
    get shouldDisplayCompanyNameErrorMessage() {
        return this.shouldDisplayValidationMessages && !this.companyName;
    }
    get shouldDisplayInterventionDateErrorMessage() {
        return this.shouldDisplayValidationMessages && !this.interventionDate;
    }
    updateClaimWithInfo() {
        this.updateAndSaveQualification({
            qualificationPayload: {
                professionalIntervention: {
                    professionalContact: {
                        companyName: this.companyName,
                        address: this.address,
                        zipCodeCity: this.zipCodeCity,
                        phoneNumber: this.phoneNumber,
                    },
                    interventionDate: this.interventionDate ? this.interventionDate.toISOString() : null,
                },
            },
            step: this.step,
        });
    }
    updateClaimWithNoInfo() {
        this.updateAndSaveQualification({
            qualificationPayload: {
                professionalIntervention: {
                    professionalContact: {
                        companyName: null,
                        address: null,
                        zipCodeCity: null,
                        phoneNumber: null,
                    },
                    interventionDate: null,
                },
            },
            step: this.step,
        });
    }
    get answer() {
        if (this.electricalDamageQualification.professionalIntervention.professionalContact.companyName &&
            this.electricalDamageQualification.professionalIntervention.interventionDate) {
            return this.$tc('claim.qualification.electrical.professional_intervention.sumup.info', 0, {
                companyName: this.electricalDamageQualification.professionalIntervention.professionalContact
                    .companyName,
                date: formatDate(this.electricalDamageQualification.professionalIntervention.interventionDate),
            });
        }
        return this.$tc('claim.qualification.electrical.professional_intervention.sumup.no_info');
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ProfessionalInterventionInfoQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.State('claim')
], ProfessionalInterventionInfoQuestion.prototype, "claim", void 0);
__decorate([
    layout.Getter('isDesktop')
], ProfessionalInterventionInfoQuestion.prototype, "isDesktop", void 0);
__decorate([
    qualification.State('qualification')
], ProfessionalInterventionInfoQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], ProfessionalInterventionInfoQuestion.prototype, "updateAndSaveQualification", void 0);
ProfessionalInterventionInfoQuestion = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton, DateAndTimePicker },
        props: QuestionProps,
    })
], ProfessionalInterventionInfoQuestion);
export default ProfessionalInterventionInfoQuestion;
