var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamagesComponent } from './GoodDamageComponent';
import NumberInput from '@declaration/components/NumberInput.vue';
import { getGoodDamageTypeDefinition, } from '@shared/roomDamage/goodDamage';
let GoodDamageTypesInput = class GoodDamageTypesInput extends GoodDamagesComponent {
    get definition() {
        return this.room && getGoodDamageTypeDefinition(this.room, this.category, this.type);
    }
    get label() {
        var _a, _b;
        const tradKey = (_b = (_a = this.definition) === null || _a === void 0 ? void 0 : _a.tradKey) !== null && _b !== void 0 ? _b : null;
        return tradKey && this.$tc(tradKey);
    }
    get illustration() {
        var _a, _b;
        return (_b = (_a = this.definition) === null || _a === void 0 ? void 0 : _a.illustration) !== null && _b !== void 0 ? _b : null;
    }
    get count() {
        return this.goodDamages.filter((goodDamage) => goodDamage.type === this.type &&
            goodDamage.category === this.category &&
            goodDamage.room === this.room).length;
    }
    onUpdate($event) {
        if (this.room) {
            let diff = $event - this.count;
            if (diff < 0) {
                while (diff < 0) {
                    diff++;
                    this.popGoodDamage(this.category, this.type);
                }
            }
            else if (diff > 0) {
                while (diff > 0) {
                    diff--;
                    this.addNewGoodDamage(this.category, this.type);
                }
            }
        }
    }
};
GoodDamageTypesInput = __decorate([
    Component({
        components: {
            NumberInput,
        },
        props: {
            category: { type: String, required: true },
            type: { type: String, required: true },
        },
    })
], GoodDamageTypesInput);
export default GoodDamageTypesInput;
