var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let DropDownCard = class DropDownCard extends Vue {
    constructor() {
        super(...arguments);
        this.isExpanded = this.isInitiallyExpanded;
    }
    toggleIsExpanded() {
        if (this.hasCheckedItem) {
            return;
        }
        this.isExpanded = !this.isExpanded;
    }
    get hasCheckedItem() {
        return !!this.items.find(({ slug }) => slug === this.itemCheckedSlug);
    }
};
DropDownCard = __decorate([
    Component({
        props: {
            title: {
                type: Object,
                required: true,
            },
            isInitiallyExpanded: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
                required: true,
            },
            itemCheckedSlug: {
                type: String,
                default: null,
            },
            image: {
                type: String,
                default: null,
            },
        },
    })
], DropDownCard);
export default DropDownCard;
