var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { downloadAppointmentsIcsEventFile, createGoogleCalendarLink, } from '@declaration/services/appointmentsEventCreator';
const layout = namespace('layout');
let CalendarDropdown = class CalendarDropdown extends Vue {
    downloadAppointments() {
        downloadAppointmentsIcsEventFile(this.$store.state);
    }
    openGoogleCalendarLinks() {
        const calendarLinks = createGoogleCalendarLink(this.$store.state);
        // on chrome it will only be able to open first link
        calendarLinks.forEach((link) => {
            window.open(link);
        });
    }
};
__decorate([
    layout.State('color')
], CalendarDropdown.prototype, "color", void 0);
CalendarDropdown = __decorate([
    Component({
        props: {
            boldTrigger: {
                type: Boolean,
                default: false,
            },
        },
    })
], CalendarDropdown);
export default CalendarDropdown;
