var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { RobberyEventType } from '@shared/types/api/claim';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { ROBBERY_EVENT_TYPE_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { objectToTranslatableChoices } from '@shared/choice';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
import { ClaimLanguage } from '@shared/types/api/user';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyEventTypeQuestion = class RobberyEventTypeQuestion extends QuestionComponent {
    mounted() {
        // check+setting done here to prevent test_e2e_generali_declaration from failing because of
        // server local language (english) used by default for translation keys
        if (INSURER_CONFIG.id === 'generali') {
            this.$i18n.locale = ClaimLanguage.French;
            this.$forceUpdate();
        }
    }
    updateClaim(eventType) {
        this.updateAndSaveQualification({
            qualificationPayload: { eventType },
            step: this.step,
        });
    }
    get choices() {
        const choices = {
            [RobberyEventType.Robbery]: ROBBERY_EVENT_TYPE_TRAD_KEYS[RobberyEventType.Robbery],
            [RobberyEventType.Vandalism]: ROBBERY_EVENT_TYPE_TRAD_KEYS[RobberyEventType.Vandalism],
        };
        if (insurerHasFeature(InsurerFeatureFlag.LostKeys)) {
            choices[RobberyEventType.LostKeys] = ROBBERY_EVENT_TYPE_TRAD_KEYS[RobberyEventType.LostKeys];
        }
        return objectToTranslatableChoices(choices);
    }
    get answer() {
        if (this.qualification.eventType === null) {
            return null;
        }
        const eventType = this.$tc(ROBBERY_EVENT_TYPE_TRAD_KEYS[this.qualification.eventType]).toLowerCase();
        return this.$tc('claim.qualification.robbery.event_type.answer', 1, { eventType });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyEventTypeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyEventTypeQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyEventTypeQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyEventTypeQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], RobberyEventTypeQuestion);
export default RobberyEventTypeQuestion;
