var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as Sentry from '@sentry/browser';
import { Watch } from 'vue-property-decorator';
import { uuidFromIri, optionalResourceIri } from '@shared/utils/iri';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const SEARCH_QUERY_MIN_LENGTH = 3;
const SEARCH_QUERY_DEBOUNCE_TIME = 500; // ms
const isLength = (input) => input.length >= SEARCH_QUERY_MIN_LENGTH;
let PersonalPropertyProductSearch = class PersonalPropertyProductSearch extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isLoaded = false;
        this.results = [];
        this.suggestions = [];
        this.inputSubject$ = new Subject();
        this.inputSubscription$ = null;
        this.querySubject$ = new Subject();
        this.querySubscription$ = null;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get designation() {
        var _a;
        return (_a = this.personalProperty.designation) !== null && _a !== void 0 ? _a : null;
    }
    set designation(designation) {
        this.update({ designation });
    }
    get description() {
        var _a;
        return (_a = this.personalProperty.description) !== null && _a !== void 0 ? _a : null;
    }
    set description(description) {
        this.update({ description });
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get category() {
        return this.personalProperty.category;
    }
    get categoryIRI() {
        return optionalResourceIri(this.category);
    }
    set categoryIRI(value) {
        const category = value ? this.categoriesByIri[value] : null;
        const payload = {
            category: category === null || category === void 0 ? void 0 : category.id,
            designation: this.designation,
            description: this.description,
        };
        if ((category === null || category === void 0 ? void 0 : category.isAmountOnly) || (category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.designation = category.label;
            payload.description = null;
            payload.userKnowPrice = null;
            payload.purchaseCertification = null;
        }
        else if (this.isAmountOnly || this.isIdentityDocument) {
            payload.designation = null;
            payload.receipt = null;
        }
        if (!(category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.documentType = null;
        }
        this.update(payload);
    }
    get searchQuery() {
        return this.value;
    }
    set searchQuery(query) {
        this.$emit('input', query);
    }
    get shouldDisplayMissingProduct() {
        return !this.isLoading && this.isLoaded && this.searchQuery.length >= SEARCH_QUERY_MIN_LENGTH;
    }
    mounted() {
        this.inputSubscription$ = this.inputSubject$
            .pipe(filter(isLength), distinctUntilChanged(), debounceTime(SEARCH_QUERY_DEBOUNCE_TIME))
            .subscribe((input) => this.querySubject$.next(input));
        if (this.$parent) {
            this.$parent.$on('categoryChange', this.onCategoryChange);
        }
        this.querySubscription$ = this.querySubject$
            .pipe(switchMap((input) => {
            const categoryIRI = this.categoryIRI !== null ? this.categoryIRI : '';
            return this.search(input, categoryIRI);
        }))
            .subscribe((result) => {
            var _a, _b;
            this.results = (_a = result === null || result === void 0 ? void 0 : result.results) !== null && _a !== void 0 ? _a : [];
            this.suggestions = (_b = result === null || result === void 0 ? void 0 : result.suggestions) !== null && _b !== void 0 ? _b : [];
        });
        if (isLength(this.searchQuery)) {
            this.querySubject$.next(this.searchQuery);
        }
    }
    beforeDestroy() {
        var _a, _b;
        (_a = this.inputSubscription$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.querySubscription$) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        if (this.$parent) {
            this.$parent.$off('categoryChange', this.onCategoryChange);
        }
    }
    onSearchQueryChange(newVal, _oldVal) {
        if (newVal.length >= SEARCH_QUERY_MIN_LENGTH) {
            this.performSearch();
        }
    }
    onCategoryIRIChange(newVal, _oldVal) {
        if (newVal !== _oldVal) {
            this.performSearch();
        }
    }
    async performSearch() {
        var _a, _b, _c;
        try {
            this.isLoading = true;
            const result = await this.search(this.searchQuery, (_a = this.categoryIRI) !== null && _a !== void 0 ? _a : '');
            this.results = (_b = result === null || result === void 0 ? void 0 : result.results) !== null && _b !== void 0 ? _b : [];
            this.suggestions = (_c = result === null || result === void 0 ? void 0 : result.suggestions) !== null && _c !== void 0 ? _c : [];
        }
        catch (error) {
        }
        finally {
            this.isLoading = false;
        }
    }
    async search(query, categoryIRI) {
        const params = { q: query };
        if (this.claim.id)
            params.c = uuidFromIri(this.claim.id);
        if (categoryIRI) {
            params.categoryIRI = categoryIRI;
        }
        try {
            this.isLoading = true;
            const result = await this.$axios.$get('/products/search', { params });
            this.isLoaded = true;
            return result;
        }
        catch (err) {
            Sentry.captureException(err);
            this.isLoaded = false;
            this.missingProduct();
            return null;
        }
        finally {
            this.isLoading = false;
        }
    }
    async select(product) {
        if (!this.claim.id) {
            return;
        }
        try {
            this.isLoading = true;
            const createdProduct = await this.$axios.$post(`/products/create-for-claim/${uuidFromIri(this.claim.id)}`, {
                productId: product.productId,
                groupId: product.groupId,
            });
            this.$emit('selected', { product, createdProduct });
        }
        catch (err) {
            Sentry.captureException(err);
            this.missingProduct();
        }
        finally {
            this.isLoading = false;
        }
    }
    onCategoryChange(categoryIRI) {
        if (categoryIRI !== null) {
            this.categoryIRI = categoryIRI;
        }
    }
    missingProduct() {
        this.$emit('missing', this.searchQuery);
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyProductSearch.prototype, "claim", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyProductSearch.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyProductSearch.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyProductSearch.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyProductSearch.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyProductSearch.prototype, "updatePersonalProperty", void 0);
__decorate([
    Watch('searchQuery')
], PersonalPropertyProductSearch.prototype, "onSearchQueryChange", null);
__decorate([
    Watch('categoryIRI')
], PersonalPropertyProductSearch.prototype, "onCategoryIRIChange", null);
PersonalPropertyProductSearch = __decorate([
    Component({
        components: {},
        props: {
            value: {
                type: String,
                required: true,
            },
            changeKey: {
                type: [Number, String],
                required: false,
                default: null,
            },
        },
    })
], PersonalPropertyProductSearch);
export default PersonalPropertyProductSearch;
