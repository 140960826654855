var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@declaration/components/UI/Modal.vue';
import { forcedAmount, forcedAmountWithDefault } from '@shared/utils/forcedAmount';
import { uuidFromIri } from '@shared/utils/iri';
import { getDamageName } from '@shared/damage/damage';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const counting = namespace('counting');
const roomDamage = namespace('roomDamage');
const externalDamage = namespace('externalDamage');
const breakInDamage = namespace('breakInDamage');
const glassDamage = namespace('glassDamage');
const electricalDamage = namespace('electricalDamage');
let RealEstateDamagesDetailModal = class RealEstateDamagesDetailModal extends Vue {
    get totalAmount() {
        return forcedAmountWithDefault(this.realEstateRefund.appliedAmounts.total, 0);
    }
    get compensationAmount() {
        return forcedAmountWithDefault(this.realEstateRefund.compensation.total, 0);
    }
    get ceilingAmount() {
        var _a;
        return (_a = this.realEstateRefund.parameters.ceiling.value) !== null && _a !== void 0 ? _a : null;
    }
    get deductibleAmount() {
        return forcedAmount(this.realEstateRefund.parameters.deductibleAmount);
    }
    get hasCeiling() {
        return this.ceilingAmount !== null;
    }
    get hasDeductible() {
        return this.deductibleAmount !== null;
    }
    get shouldDisplayCeiling() {
        return this.ceilingAmount !== null && this.compensationAmount > this.ceilingAmount;
    }
    get shouldDisplayDeductible() {
        return this.deductibleAmount !== null && this.deductibleAmount > 0;
    }
    get shouldDisplayDetails() {
        if (!insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition)) {
            return this.shouldDisplayCeiling || this.shouldDisplayDeductible;
        }
        else {
            return false;
        }
    }
    get shouldDisplayInfo() {
        return !insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition);
    }
    get damages() {
        return [
            ...this.roomDamages,
            ...this.externalDamages,
            ...this.breakInDamages,
            ...this.glassDamages,
            ...this.electricalDamages,
        ];
    }
    get realEstateDamagesWithAmounts() {
        return this.damages.map((damage) => {
            const warnings = this.warnings.filter(({ context: { refusedRealEstateDamages } }) => {
                if (!(refusedRealEstateDamages === null || refusedRealEstateDamages === void 0 ? void 0 : refusedRealEstateDamages.length))
                    return false;
                return refusedRealEstateDamages.includes(uuidFromIri(damage.id));
            });
            const title = getDamageName(damage, this.$i18n.tc.bind(this.$i18n));
            const recoverableAmount = forcedAmountWithDefault(damage.refund.appliedAmounts.total, 0);
            let exclusionMessages = [];
            if (warnings.length) {
                exclusionMessages = warnings.map(({ message, context: { tradKeyData } }) => {
                    if (this.$te(message))
                        return this.$tc(message, undefined, tradKeyData);
                    return message;
                });
            }
            else if (damage.refund.parameters.exclusionReason) {
                const key = `compensation.proposition.electrical_damages.exclusion_reason.${damage.refund.parameters.exclusionReason}`;
                if (this.$te(key))
                    exclusionMessages = [this.$tc(key)];
                else
                    exclusionMessages = [
                        this.$tc('compensation.proposition.electrical_damages.exclusion_reason'),
                    ];
            }
            return {
                ...damage,
                title,
                recoverableAmount,
                exclusionMessages,
            };
        });
    }
    get personalPropertyHeaderInfoTitle() {
        if (insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition)) {
            return this.$tc('compensation.proposition.personal_properties.modal.info.short');
        }
        return this.$tc('compensation.proposition.real_estate_damages.modal.info');
    }
    get warnings() {
        return this.claim.warnings;
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    claim.State('claim')
], RealEstateDamagesDetailModal.prototype, "claim", void 0);
__decorate([
    counting.Getter('realEstateRefund')
], RealEstateDamagesDetailModal.prototype, "realEstateRefund", void 0);
__decorate([
    roomDamage.State('damages')
], RealEstateDamagesDetailModal.prototype, "roomDamages", void 0);
__decorate([
    externalDamage.State('damages')
], RealEstateDamagesDetailModal.prototype, "externalDamages", void 0);
__decorate([
    breakInDamage.State('damages')
], RealEstateDamagesDetailModal.prototype, "breakInDamages", void 0);
__decorate([
    glassDamage.State('damages')
], RealEstateDamagesDetailModal.prototype, "glassDamages", void 0);
__decorate([
    electricalDamage.State('damages')
], RealEstateDamagesDetailModal.prototype, "electricalDamages", void 0);
RealEstateDamagesDetailModal = __decorate([
    Component({
        components: { Modal },
        props: {
            isDisplayed: { type: Boolean, required: true },
        },
    })
], RealEstateDamagesDetailModal);
export default RealEstateDamagesDetailModal;
