var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ROOM_DAMAGE_QUESTION_TRAD_KEYS, } from '@declaration/types/roomDamage';
const claim = namespace('claim');
const counting = namespace('counting');
const qualification = namespace('qualification');
let HasRoomDamagesQuestion = class HasRoomDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
    }
    updateClaim(hasRoomDamages) {
        this.updateAndSaveCounting({
            countingPayload: { hasRoomDamages },
            step: this.step,
        });
    }
    get answer() {
        if (this.counting.hasRoomDamages === null) {
            return null;
        }
        return this.counting.hasRoomDamages
            ? this.$tc('claim.damage.has_room_damages.sumup.yes')
            : this.$tc('claim.damage.has_room_damages.sumup.no');
    }
    get tradKeys() {
        return ROOM_DAMAGE_QUESTION_TRAD_KEYS[this.typeTrigram];
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], HasRoomDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    counting.State('counting')
], HasRoomDamagesQuestion.prototype, "counting", void 0);
__decorate([
    counting.Action('updateAndSaveCounting')
], HasRoomDamagesQuestion.prototype, "updateAndSaveCounting", void 0);
__decorate([
    qualification.State('typeTrigram')
], HasRoomDamagesQuestion.prototype, "typeTrigram", void 0);
HasRoomDamagesQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], HasRoomDamagesQuestion);
export default HasRoomDamagesQuestion;
