var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let SecondaryButton = class SecondaryButton extends Vue {
    onClick() {
        if (this.isDisabled) {
            this.$emit('on-disabled-button-click');
            return;
        }
        this.$emit('on-button-click');
    }
    get iconClass() {
        return this.icon ? `icon-${this.icon}` : null;
    }
};
SecondaryButton = __decorate([
    Component({
        props: {
            text: {
                type: String,
                required: true,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
            isHovered: {
                type: Boolean,
                default: false,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            isOutlined: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: null,
            },
        },
    })
], SecondaryButton);
export default SecondaryButton;
