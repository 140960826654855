var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { DEFAULT_LEAKAGE_CAUSE_LOCATIONS, LEAKAGE_CAUSE_LOCATIONS_BY_HOUSING_TYPE, } from '@shared/constants/waterDamageConstants';
import { LEAKAGE_CAUSE_LOCATION_OPTIONS } from '@declaration/constants/waterDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
let LeakageCauseLocationQuestion = class LeakageCauseLocationQuestion extends QuestionComponent {
    updateClaim(leakageCauseLocation) {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                leakageCauseLocation,
                leakageCauseLocationHouse: null,
                leakageCauseDetailSlug: null,
                leakageCauseEquipmentSlug: null,
                leakageCauseRoomSlug: null,
            },
            step: this.step,
        });
    }
    get choicesClass() {
        if (this.selectedContract === null) {
            return 'leakage-cause-location__choices';
        }
        else
            return `leakage-cause-location__choices leakage-cause-location__choices--${this.selectedContract.housingType.slug}`;
    }
    get answer() {
        if (!this.waterDamageQualification.leakageCauseLocation) {
            return null;
        }
        return LEAKAGE_CAUSE_LOCATION_OPTIONS[this.waterDamageQualification.leakageCauseLocation]
            ? this.$tc(LEAKAGE_CAUSE_LOCATION_OPTIONS[this.waterDamageQualification.leakageCauseLocation]
                .answerTradKey)
            : null;
    }
    get leakageCauseLocationOptions() {
        var _a, _b;
        if (this.selectedContract === null) {
            return DEFAULT_LEAKAGE_CAUSE_LOCATIONS.map((locationOption) => LEAKAGE_CAUSE_LOCATION_OPTIONS[locationOption]);
        }
        // get custom locations/housingCapacity if needed
        const causeLocations = LEAKAGE_CAUSE_LOCATIONS_BY_HOUSING_TYPE;
        const insurerCauseLocations = (_a = INSURER_CONFIG.leakageCauseLocationsByHousingType) !== null && _a !== void 0 ? _a : null;
        if (insurerCauseLocations) {
            for (const location in causeLocations) {
                if ((_b = insurerCauseLocations[location]) === null || _b === void 0 ? void 0 : _b.housingCapacity.includes(this.selectedContract.housingCapacity.code)) {
                    causeLocations[location] = insurerCauseLocations[location].leakageCauses;
                }
            }
        }
        if (!causeLocations[this.selectedContract.housingType.slug]) {
            return DEFAULT_LEAKAGE_CAUSE_LOCATIONS.map((locationOption) => LEAKAGE_CAUSE_LOCATION_OPTIONS[locationOption]);
        }
        return LEAKAGE_CAUSE_LOCATIONS_BY_HOUSING_TYPE[this.selectedContract.housingType.slug].map((location) => LEAKAGE_CAUSE_LOCATION_OPTIONS[location]);
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.leakageCauseLocation) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], LeakageCauseLocationQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], LeakageCauseLocationQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseLocationQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    contract.State('selectedContract')
], LeakageCauseLocationQuestion.prototype, "selectedContract", void 0);
LeakageCauseLocationQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], LeakageCauseLocationQuestion);
export default LeakageCauseLocationQuestion;
