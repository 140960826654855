var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { GLASS_DAMAGE_GLASS_TYPE_PLAIN_CHOICES, GLASS_DAMAGE_GLASS_TYPE_IMAGE_CHOICES, } from '@declaration/constants/glassDamageConstants';
import { ClaimFileType } from '@shared/types/file';
const glassDamage = namespace('glassDamage');
let GlassTypeComponent = class GlassTypeComponent extends Vue {
    constructor() {
        super(...arguments);
        this.fileType = ClaimFileType.GlassDamagePicture;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get glassDamage() {
        return this.change.data;
    }
    get glassTypeImageChoices() {
        return GLASS_DAMAGE_GLASS_TYPE_IMAGE_CHOICES;
    }
    get glassTypeButtonChoices() {
        return GLASS_DAMAGE_GLASS_TYPE_PLAIN_CHOICES;
    }
    onPictureCollectionUpdate(pictureCollection) {
        this.updateGlassDamage({
            changeKey: this.changeKey,
            data: { pictureCollection },
        });
    }
};
__decorate([
    glassDamage.Getter('change')
], GlassTypeComponent.prototype, "changeGetter", void 0);
__decorate([
    glassDamage.Getter('validation')
], GlassTypeComponent.prototype, "validationGetter", void 0);
__decorate([
    glassDamage.Action('updateDamage')
], GlassTypeComponent.prototype, "updateGlassDamage", void 0);
GlassTypeComponent = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            PictureCollectionUpload,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
        },
    })
], GlassTypeComponent);
export default GlassTypeComponent;
