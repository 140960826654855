var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FireElectricalCause } from '@shared/types/api/claim';
import { FIRE_ELECTRICAL_CAUSE_ANSWERS, FIRE_ELECTRICAL_CAUSE_IMAGE_CHOICES, } from '@declaration/constants/fireDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let FireElectricalCauseQuestion = class FireElectricalCauseQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get fireElectricalCauseChoices() {
        return FIRE_ELECTRICAL_CAUSE_IMAGE_CHOICES;
    }
    get selectedValue() {
        if (this.qualification.fireElectricalCause) {
            return this.qualification.fireElectricalCause;
        }
        return null;
    }
    get answer() {
        if (this.qualification.fireElectricalCause === null)
            return null;
        return this.$tc(FIRE_ELECTRICAL_CAUSE_ANSWERS[this.qualification.fireElectricalCause]);
    }
    get validateText() {
        if (this.qualification.fireElectricalCause === FireElectricalCause.dontKnow ||
            this.qualification.fireElectricalCause === null) {
            return this.$tc('claim.damage.fire_electrical_cause.button.dont_know');
        }
        return this.$tc('claim.damage.fire_electrical_cause.button.next_step');
    }
    onTypeOfDamagesClick(fireElectricalCause) {
        this.updateFireDamageQualification({
            qualificationPayload: {
                fireElectricalCause: fireElectricalCause !== this.qualification.fireElectricalCause
                    ? fireElectricalCause
                    : null,
            },
        });
    }
    async validate() {
        if (this.qualification.fireElectricalCause === null) {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireElectricalCause: FireElectricalCause.dontKnow,
                },
                step: this.step,
            });
        }
        else {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireElectricalCause: this.qualification.fireElectricalCause,
                },
                step: this.step,
            });
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireElectricalCauseQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireElectricalCauseQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireElectricalCauseQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireElectricalCauseQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireElectricalCauseQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], FireElectricalCauseQuestion);
export default FireElectricalCauseQuestion;
