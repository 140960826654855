var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamagesComponent } from './GoodDamageComponent';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import GoodDamageTypesInput from '@declaration/components/roomDamage/goodDamage/GoodDamageTypesInput.vue';
import { getGoodDamageCategoryDefinition, } from '@shared/roomDamage/goodDamage';
import { ClaimFileType } from '@shared/types/file';
let GoodDamageTypesForm = class GoodDamageTypesForm extends GoodDamagesComponent {
    constructor() {
        super(...arguments);
        this.fileType = ClaimFileType.RoomDamage;
    }
    get definitions() {
        return this.selectedCategories
            .map((category) => this.room && getGoodDamageCategoryDefinition(this.room, category))
            .filter((definition) => !!definition);
    }
    onGoodDamagesPictureCollectionUpdate(goodDamagesPictureCollection) {
        this.updateRoomDamage({ goodDamagesPictureCollection });
    }
};
GoodDamageTypesForm = __decorate([
    Component({
        components: { GoodDamageTypesInput, PictureCollectionUpload },
        props: {
            selectedCategories: { type: Array, required: true },
        },
    })
], GoodDamageTypesForm);
export default GoodDamageTypesForm;
