var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { OUTSIDER_INTERVENTION_CHOICES } from '@shared/constants/waterDamageConstants';
import { OutsiderIntervention, ClaimTypeTrigram, } from '@shared/types/api/claim';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QUALIFICATION_EQUIPMENT_DETAIL, QUALIFICATION_EQUIPMENT, } from '@shared/constants/equipmentConstants';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const qualification = namespace('qualification');
let OutsiderInterventionQuestion = class OutsiderInterventionQuestion extends QuestionComponent {
    get outsiderInterventionValuesAndTradKeys() {
        return OUTSIDER_INTERVENTION_CHOICES;
    }
    handleInput(choice) {
        this.updateClaim(choice);
    }
    updateClaim(outsiderInterventionSlug) {
        // 🛈 outsiderIntervention exist only on waterDamages for now
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                outsiderInterventionSlug,
            },
            step: this.step,
        });
    }
    get questionTitle() {
        if (this.typeTrigram === ClaimTypeTrigram.WaterDamage) {
            if (this.waterDamageQualification.leakageCauseDetailSlug !== null &&
                QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug] &&
                QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                    .outsiderInterventionAnswerEndTradKey !== null) {
                return this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                    .outsiderInterventionQuestionTradKey);
            }
            if (this.waterDamageQualification.leakageCauseEquipmentSlug !== null &&
                QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug] &&
                QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                    .outsiderInterventionAnswerEndTradKey !== null) {
                return this.$tc(QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                    .outsiderInterventionQuestionTradKey);
            }
            return this.$tc('claim.qualification.water_damage.outsider_intervention.title.default');
        }
        return this.$tc('claim.qualification.outsider_intervention.title.default');
    }
    get questionDescription() {
        if (this.typeTrigram === ClaimTypeTrigram.WaterDamage &&
            insurerHasFeature(InsurerFeatureFlag.ShowWaterDamageOutsiderInterventionHelper)) {
            return this.$tc('claim.qualification.water_damage.outsider_intervention.helper.default');
        }
        return null;
    }
    get answer() {
        if (this.waterDamageQualification.outsiderInterventionSlug === null) {
            return null;
        }
        let outsiderInterventionEquipment;
        if (this.waterDamageQualification.leakageCauseDetailSlug !== null &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug] &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .outsiderInterventionAnswerEndTradKey !== null) {
            outsiderInterventionEquipment = this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .outsiderInterventionAnswerEndTradKey);
        }
        else if (this.waterDamageQualification.leakageCauseEquipmentSlug !== null &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug] &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .outsiderInterventionAnswerEndTradKey !== null) {
            outsiderInterventionEquipment = this.$tc(QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .outsiderInterventionAnswerEndTradKey);
        }
        else {
            outsiderInterventionEquipment = this.$tc('claim.qualification.outsider_intervention.sumup.default');
        }
        switch (this.waterDamageQualification.outsiderInterventionSlug) {
            case OutsiderIntervention.Yes:
                return this.$tc('claim.qualification.outsider_intervention.sumup.professional', 0, {
                    equipment: outsiderInterventionEquipment,
                });
            case OutsiderIntervention.No:
                return this.$tc('claim.qualification.outsider_intervention.sumup.no', 0, {
                    equipment: outsiderInterventionEquipment,
                });
            case OutsiderIntervention.Unknown:
                return this.$tc('claim.qualification.outsider_intervention.sumup.unknown', 0, {
                    equipment: outsiderInterventionEquipment,
                });
            default:
                return null;
        }
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.outsiderIntervention) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], OutsiderInterventionQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], OutsiderInterventionQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], OutsiderInterventionQuestion.prototype, "typeTrigram", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], OutsiderInterventionQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
OutsiderInterventionQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], OutsiderInterventionQuestion);
export default OutsiderInterventionQuestion;
