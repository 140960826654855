var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as Sentry from '@sentry/browser';
import TermsAndConditionsModal from '@declaration/components/TermsAndConditions/TermsAndConditionsModal.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { ClaimCompensationOption, ClaimTypeTrigram, CompensationMode, } from '@shared/types/api/claim';
import { appointmentEndTime, appointmentLongDateDay, appointmentStartTime, } from '@declaration/services/appointmentLabels';
import formatAmount from '@shared/utils/formatAmount';
import ImmediateCompensationDetailModal from '@declaration/components/Compensation/ImmediateCompensationDetailModal.vue';
import PersonalPropertiesDetailModal from '@declaration/components/Compensation/PersonalPropertiesDetailModal.vue';
import CompensationOnProofDetailModal from '@declaration/components/Compensation/CompensationOnProofDetailModal.vue';
import RealEstateDamagesDetailModal from '@declaration/components/Compensation/RealEstateDamagesDetailModal.vue';
import { ExclusionReason } from '@shared/types/api/refundDetail';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { breakInDamagesToRepair } from '@declaration/store/breakInDamage';
import { externalDamageTitle } from '@shared/externalDamage/externalDamageFields';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { forcedAmount, forcedAmountWithDefault } from '@shared/utils/forcedAmount';
import { INSURER_CONFIG } from '@shared/insurer';
import ContactInformationModal from '@declaration/components/declaration/pages/indemnisation/artisan-partenaire/ContactInformationModal.vue';
const layout = namespace('layout');
const claim = namespace('claim');
const counting = namespace('counting');
const roomDamage = namespace('roomDamage');
const personalProperty = namespace('personalProperty');
const externalDamage = namespace('externalDamage');
const qualification = namespace('qualification');
const breakInDamage = namespace('breakInDamage');
const glassDamage = namespace('glassDamage');
const electricalDamage = namespace('electricalDamage');
let CompensationProposition = class CompensationProposition extends Vue {
    constructor() {
        var _a, _b, _c;
        super(...arguments);
        this.savingOption = false;
        this.ClaimCompensationOption = ClaimCompensationOption;
        this.isAccepting = false;
        this.isDeclining = false;
        this.displayTermsModal = false;
        this.displayContactInformationModal = false;
        this.selfRepairImage = require('@declaration/assets/images/compensation/selfRepair.svg');
        this.otherArtisanImage = require('@declaration/assets/images/compensation/otherArtisan.svg');
        this.partnerArtisanImage = require('@declaration/assets/images/compensation/partnerArtisan.svg');
        this.personalPropertiesImage = require('@declaration/assets/images/compensation/personalProperties.svg');
        this.paymentImage = require('@declaration/assets/images/payout/creditCard.svg');
        this.compensationOnProofImage = require('@declaration/assets/images/documents/document.svg');
        this.isRealEstateDetailDisplayed = false;
        this.isPersonalPropertiesDetailDisplayed = false;
        this.isCompensationOnProofDetailDisplayed = false;
        this.compensationPropositionSettings = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition;
        this.showTitle = (_c = (_b = this.compensationPropositionSettings) === null || _b === void 0 ? void 0 : _b.showTitle) !== null && _c !== void 0 ? _c : true;
        this.formatAmount = formatAmount;
        this.forcedAmount = forcedAmount;
        this.forcedAmountWithDefault = forcedAmountWithDefault;
        this.appointmentStartTime = appointmentStartTime;
        this.appointmentEndTime = appointmentEndTime;
        this.customContents = (customContents, appliedAmounts) => {
            var _a;
            if (customContents) {
                return ((_a = customContents.map((item) => {
                    return {
                        text: this.$tc(`compensation.proposition.${item.text}`, 0, {
                            immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(appliedAmounts, 0)),
                        }),
                        iconClass: item.iconClass,
                    };
                })) !== null && _a !== void 0 ? _a : null);
            }
            else
                return null;
        };
    }
    closeContactInformationModal() {
        this.displayContactInformationModal = false;
        this.isAccepting = false;
    }
    get shouldDisplayTitle() {
        return !this.isMobile && !this.showTitle;
    }
    get shouldDisplayRealEstateBlock() {
        return (!this.shouldDisplayImmediateAmountBlock &&
            (this.roomDamages.length > 0 ||
                this.externalDamages.length > 0 ||
                this.breakInDamages.length > 0 ||
                this.glassDamages.length > 0 ||
                this.electricalDamages.length > 0));
    }
    get shouldDisplayPropositionDetailInfo() {
        return !insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition);
    }
    get shouldDisplayPersonalPropertiesBlock() {
        return !this.shouldDisplayImmediateAmountBlock && this.personalProperties.length > 0;
    }
    get shouldDisplayPersonalPropertiesBigDetailsButton() {
        var _a, _b;
        return ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b.shouldShowPersonalPropertiesbigDetailsButton)
            ? 'compensation-proposition-section__detail-button--big'
            : '';
    }
    get shouldDisplayRealEstateDetails() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.RealEstateDetails);
    }
    get shouldDisplayImmediateAmountBlock() {
        return (claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.ImmediateAmountBlock) &&
            !insurerHasFeature(InsurerFeatureFlag.DontDisplayImmediateAmountBlock, this.claimTypeTrigram) &&
            this.hasCompensation);
    }
    get shouldDisplayCompensationOnProof() {
        return this.claim.isPendingRepairQuotePending;
    }
    get shouldDisplayTotalBlock() {
        return this.hasCompensation;
    }
    get delayedPartInfo() {
        const infoReceipt = this.$tc('compensation.proposition.amount.info.receipt', 0, {
            delayedRefund: this.formatAmount(this.realEstateCompensation / 100),
        });
        const onlyDelayed = this.$tc('compensation.proposition.amount.info.receipt.only_delayed');
        return forcedAmount(this.realEstateRefund.appliedAmounts.total) ? infoReceipt : onlyDelayed;
    }
    get shouldDisplayDeductibleBlock() {
        return !!forcedAmount(this.damagesRefund.parameters.deductibleAmount) && this.hasCompensation;
    }
    get isRealEstateDetailUseful() {
        var _a, _b;
        return ((this.realEstateRefund.parameters.ceiling.value !== null ||
            forcedAmount(this.realEstateRefund.parameters.deductibleAmount) !== null) &&
            !((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b.hideRealEstateDetails.includes(this.typeTrigram)));
    }
    get showIcons() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b.showCompensationTotalSectionInfoIcons) !== null && _c !== void 0 ? _c : true);
    }
    get isItemsListDisplayed() {
        return claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.DisplayListOfItemsToRepair);
    }
    get itemsToRepair() {
        return breakInDamagesToRepair(this.breakInDamages, this.$tc.bind(this));
    }
    get hasCompensation() {
        return !!forcedAmount(this.damagesRefund.appliedAmounts.total);
    }
    get hasRen() {
        return (this.breakInDamages.filter((breakInDamage) => breakInDamage.pendingCompensationMode ===
            CompensationMode.CompensationModeCertifiedArtisan).length > 0 ||
            this.roomDamages.filter((roomDamage) => roomDamage.compensationMode === CompensationMode.CompensationModeCertifiedArtisan).length > 0 ||
            this.externalDamages.filter((externalDamage) => externalDamage.compensationMode === CompensationMode.CompensationModeCertifiedArtisan).length > 0 ||
            this.glassDamages.filter((glassDamage) => glassDamage.compensationMode === CompensationMode.CompensationModeCertifiedArtisan).length > 0 ||
            this.electricalDamages.filter((electricalDamage) => electricalDamage.compensationMode === CompensationMode.CompensationModeCertifiedArtisan).length > 0);
    }
    toggleRealEstateDetailDisplay() {
        this.isRealEstateDetailDisplayed = !this.isRealEstateDetailDisplayed;
    }
    togglePersonalPropertiesDetailDisplay() {
        this.isPersonalPropertiesDetailDisplayed = !this.isPersonalPropertiesDetailDisplayed;
    }
    toggleCompensationOnProofDetailDisplay() {
        this.isCompensationOnProofDetailDisplayed = !this.isCompensationOnProofDetailDisplayed;
    }
    get externalDamagesExcludedLabels() {
        return this.externalDamages
            .filter((externalDamage) => {
            return externalDamage.exclusionReason === ExclusionReason.ContractGuarantees;
        })
            .map((externalDamage) => {
            return externalDamageTitle(externalDamage, this.stormSnowHailDamageQualification.causeType, this.$tc.bind(this));
        })
            .filter((label, index, self) => {
            // this filter function is used to remove duplicates
            return self.indexOf(label) === index;
        });
    }
    get shouldShowRealEstateReFundAppliedDelayedDescription() {
        return (!!forcedAmount(this.realEstateRefund.appliedAmounts.delayed) &&
            !insurerHasFeature(InsurerFeatureFlag.HideRealEstateReFundAppliedDelayedDescription));
    }
    get realEstateImage() {
        switch (this.claim.chosenCompensationOption) {
            case ClaimCompensationOption.SelfRepair:
                return this.selfRepairImage;
            case ClaimCompensationOption.OtherArtisan:
                return this.otherArtisanImage;
            case ClaimCompensationOption.PartnerArtisan:
                return this.partnerArtisanImage;
            default:
                return this.selfRepairImage;
        }
    }
    get realEstateSubtitle() {
        if (!this.realEstateCompensation || this.realEstateCompensation <= 0) {
            return this.$tc('compensation.proposition.room_damage.no_compensation');
        }
        return this.chosenOptionSubtitle;
    }
    get chosenOptionSubtitle() {
        switch (this.claim.chosenCompensationOption) {
            case ClaimCompensationOption.SelfRepair:
                return this.$tc('compensation.proposition.room_damage.subtitle.self_repair');
            case ClaimCompensationOption.OtherArtisan:
                return this.$tc('compensation.proposition.room_damage.subtitle.other_artisan');
            case ClaimCompensationOption.PartnerArtisan:
                return this.$tc('compensation.proposition.room_damage.subtitle.artisan_partner');
        }
    }
    get realEstateCompensation() {
        return forcedAmountWithDefault(this.realEstateRefund.appliedAmounts.total, 0);
    }
    get personalPropertiesCompensation() {
        return forcedAmountWithDefault(this.personalPropertiesRefund.appliedAmounts.total, 0);
    }
    get artisanPartnerAppointment() {
        return this.claim.artisanAppointment;
    }
    appointmentDate(appointment) {
        return appointmentLongDateDay(appointment, this.$tc.bind(this));
    }
    get compensationTotalInfo() {
        var _a, _b, _c, _d, _e, _f, _g;
        switch (this.claim.chosenCompensationOption) {
            case ClaimCompensationOption.SelfRepair:
                return ((_c = this.customContents((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b[ClaimCompensationOption.SelfRepair], this.damagesRefund.appliedAmounts.immediate)) !== null && _c !== void 0 ? _c : [
                    {
                        text: this.$tc('compensation.proposition.self_repair.info_1', 0, {
                            immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                        }),
                        iconClass: 'icon-timer',
                    },
                    {
                        text: this.$tc('compensation.proposition.self_repair.info_2'),
                        iconClass: 'icon-pad-check',
                    },
                    {
                        text: this.$tc('compensation.proposition.self_repair.info_3'),
                        iconClass: 'icon-beach',
                    },
                ]);
            case ClaimCompensationOption.OtherArtisan:
                if ((!!forcedAmount(this.damagesRefund.appliedAmounts.delayed) &&
                    this.personalProperties.length >= 1 &&
                    this.counting.roomDamages.length > 0) ||
                    (this.personalProperties.length === 0 &&
                        (this.counting.roomDamages.length > 0 ||
                            this.counting.breakInDamages.length > 0 ||
                            this.counting.externalDamages.length > 0 ||
                            this.counting.glassDamages.length > 0))) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.other_artisan.no_delayed.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.other_artisan.no_delayed.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                if (!!forcedAmount((_e = (_d = this.damagesRefund) === null || _d === void 0 ? void 0 : _d.appliedAmounts) === null || _e === void 0 ? void 0 : _e.immediate) &&
                    this.personalProperties.length >= 1 &&
                    this.counting.roomDamages.length === 0 &&
                    this.counting.breakInDamages.length === 0) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                if (this.counting.breakInDamages.filter((damage) => damage.damageSeverity === 'slight')
                    .length > 0) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                if (!this.damagesRefund.appliedAmounts.immediate) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.other_artisan.no_immediate.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.other_artisan.no_immediate.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                if (insurerHasFeature(InsurerFeatureFlag.DisplayCompensationProposalScreen)) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.other_artisan.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.other_artisan.info_3'),
                            iconClass: 'icon-euro',
                        },
                    ];
                }
                else {
                    return [
                        {
                            text: this.$tc('compensation.proposition.other_artisan.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.other_artisan.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                        {
                            text: this.$tc('compensation.proposition.other_artisan.info_3'),
                            iconClass: 'icon-euro',
                        },
                    ];
                }
            case ClaimCompensationOption.PartnerArtisan:
                return [
                    {
                        text: this.$tc('compensation.proposition.artisan_partner.info_1', 0, {
                            immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                        }),
                        iconClass: 'icon-timer',
                    },
                    {
                        text: this.$tc('compensation.proposition.artisan_partner.info_2'),
                        iconClass: 'icon-pad-check',
                    },
                ];
            case null:
                if (!!forcedAmount((_g = (_f = this.damagesRefund) === null || _f === void 0 ? void 0 : _f.appliedAmounts) === null || _g === void 0 ? void 0 : _g.immediate) &&
                    this.personalProperties.length >= 1 &&
                    this.counting.roomDamages.length === 0 &&
                    this.counting.breakInDamages.length === 0 &&
                    this.counting.glassDamages.length === 0 &&
                    this.counting.externalDamages.length === 0) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.artisan_partner.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                if (this.qualification.roomDamageReparations) {
                    return [
                        {
                            text: this.$tc('compensation.proposition.self_repair.info_1', 0, {
                                immediateRefund: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                            }),
                            iconClass: 'icon-timer',
                        },
                        {
                            text: this.$tc('compensation.proposition.self_repair.info_2'),
                            iconClass: 'icon-pad-check',
                        },
                    ];
                }
                break;
        }
        return [];
    }
    accept() {
        this.isAccepting = true;
        if (this.confirmationContactDetails && this.hasRen) {
            this.displayContactInformationModal = true;
        }
        else {
            this.displayTermsModal = true;
        }
    }
    acceptWithNoCompensation() {
        this.isAccepting = true;
        this.updateAndSaveClaim({
            step: Step.CompensationProposition,
            claimPayload: {
                isCompensationOptionAccepted: true,
                termsAndConditionsAccepted: true,
            },
        });
        this.isAccepting = false;
    }
    onModalClose() {
        this.isAccepting = false;
        this.displayTermsModal = false;
    }
    get declineText() {
        if (!this.hasCompensation) {
            return this.$tc('compensation.proposition.decline.no_compensation');
        }
        switch (this.claim.chosenCompensationOption) {
            case ClaimCompensationOption.SelfRepair:
                return this.$tc('compensation.proposition.decline.self_repair');
            case ClaimCompensationOption.OtherArtisan:
                return this.$tc('compensation.proposition.decline.other_artisan');
            case ClaimCompensationOption.PartnerArtisan:
                return this.$tc('compensation.proposition.decline.partner_artisan');
            default:
                return this.$tc('compensation.proposition.decline.be_called');
        }
    }
    decline() {
        this.isDeclining = true;
        this.updateAndSaveClaim({
            step: Step.CompensationProposition,
            claimPayload: {
                isCompensationOptionAccepted: false,
                isAdvisorCallWanted: this.claim.chosenCompensationOption === ClaimCompensationOption.OtherArtisan
                    ? true
                    : undefined,
            },
        });
        this.isDeclining = false;
    }
    get shouldShowRealEstateDeductibleAmountInfo() {
        var _a, _b, _c;
        return ((_c = (!!forcedAmount(this.damagesRefund.parameters.deductibleAmount) &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b.shouldShowRealEstateDeductibleAmountInfo))) !== null && _c !== void 0 ? _c : true);
    }
    get shouldShowPersonalPropertiesDeductibleAmountInfo() {
        var _a, _b, _c;
        return ((_c = (!!forcedAmount(this.damagesRefund.parameters.deductibleAmount) &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.compensationProposition) === null || _b === void 0 ? void 0 : _b.shouldShowPersonalPropertiesDeductibleAmountInfo))) !== null && _c !== void 0 ? _c : true);
    }
    get shouldDisplayImmediateCompensation() {
        return (claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.ImmediateAmountBlock) &&
            !insurerHasFeature(InsurerFeatureFlag.DontDisplayImmediateAmountBlock, this.claimTypeTrigram));
    }
    get shouldDisplayPersonalPropertiesDetails() {
        return insurerHasFeature(InsurerFeatureFlag.DisplayPersonalPropertiesDetails, this.claimTypeTrigram);
    }
    get insurerHasFeatureCompensationPropositionGenerali() {
        return insurerHasFeature(InsurerFeatureFlag.CompensationPropositionGenerali);
    }
    get isFire() {
        return this.typeTrigram === ClaimTypeTrigram.Fire;
    }
    get shouldDisplayRealEstateSubtitle() {
        return this.typeTrigram !== ClaimTypeTrigram.Electric;
    }
    get confirmationContactDetails() {
        return insurerHasFeature(InsurerFeatureFlag.ShowConfirmationContactDetailsForm);
    }
    async confirmContactInformation(renContactInformation) {
        if (this.confirmationContactDetails) {
            if (this.displayContactInformationModal && renContactInformation) {
                this.savingOption = ClaimCompensationOption.PartnerArtisan;
                try {
                    await this.updateAndSaveClaim({
                        claimPayload: {
                            chosenCompensationOption: ClaimCompensationOption.PartnerArtisan,
                            isCompensationOptionAccepted: true,
                            // DO NOT DELETE THIS LINE BELOW PLEASE
                            // It is necessary to confirm that the chosen option is final, other things for like IMH will not work anymore if you delete this
                            renContactInformation,
                        },
                        step: Step.CompensationOptions,
                    });
                    this.closeContactInformationModal();
                    this.displayTermsModal = true;
                }
                catch (err) {
                    Sentry.captureException(err);
                }
                finally {
                    this.savingOption = false;
                }
            }
            else {
                this.displayContactInformationModal = true;
            }
        }
        else {
            this.displayTermsModal = true;
        }
    }
};
__decorate([
    counting.State('counting')
], CompensationProposition.prototype, "counting", void 0);
__decorate([
    layout.State('isMobile')
], CompensationProposition.prototype, "isMobile", void 0);
__decorate([
    claim.State('claim')
], CompensationProposition.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], CompensationProposition.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationProposition.prototype, "updateAndSaveClaim", void 0);
__decorate([
    roomDamage.State('damages')
], CompensationProposition.prototype, "roomDamages", void 0);
__decorate([
    personalProperty.State('damages')
], CompensationProposition.prototype, "personalProperties", void 0);
__decorate([
    externalDamage.State('damages')
], CompensationProposition.prototype, "externalDamages", void 0);
__decorate([
    counting.Getter('realEstateRefund')
], CompensationProposition.prototype, "realEstateRefund", void 0);
__decorate([
    counting.Getter('personalPropertiesRefund')
], CompensationProposition.prototype, "personalPropertiesRefund", void 0);
__decorate([
    counting.Getter('damagesRefund')
], CompensationProposition.prototype, "damagesRefund", void 0);
__decorate([
    qualification.State('typeTrigram')
], CompensationProposition.prototype, "claimTypeTrigram", void 0);
__decorate([
    breakInDamage.State('damages')
], CompensationProposition.prototype, "breakInDamages", void 0);
__decorate([
    glassDamage.State('damages')
], CompensationProposition.prototype, "glassDamages", void 0);
__decorate([
    electricalDamage.State('damages')
], CompensationProposition.prototype, "electricalDamages", void 0);
__decorate([
    qualification.State('qualification')
], CompensationProposition.prototype, "stormSnowHailDamageQualification", void 0);
__decorate([
    qualification.State('qualification')
], CompensationProposition.prototype, "qualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], CompensationProposition.prototype, "typeTrigram", void 0);
CompensationProposition = __decorate([
    Component({
        components: {
            PersonalPropertiesDetailModal,
            ImmediateCompensationDetailModal,
            ClaimsButton,
            ContactInformationModal,
            TermsAndConditionsModal,
            Modal,
            CompensationOnProofDetailModal,
            RealEstateDamagesDetailModal,
        },
        meta: {
            title: 'page.title.proposal',
            close: quitClaimDeclarationCloseCallback,
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationProposition);
export default CompensationProposition;
