var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { GLASS_DAMAGE_GLAZING_TYPE_IMAGE_CHOICES, GLASS_DAMAGE_GLAZING_TYPE_PLAIN_CHOICES, } from '@declaration/constants/glassDamageConstants';
const glassDamage = namespace('glassDamage');
let GlazingTypeComponent = class GlazingTypeComponent extends Vue {
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get glassDamage() {
        return this.change.data;
    }
    get glazingTypeImageChoices() {
        return GLASS_DAMAGE_GLAZING_TYPE_IMAGE_CHOICES;
    }
    get glazingTypeButtonChoices() {
        return GLASS_DAMAGE_GLAZING_TYPE_PLAIN_CHOICES;
    }
    onChange(glazingType) {
        this.updateGlassDamage({
            changeKey: this.changeKey,
            data: {
                glazingType: this.glassDamage.glazingType === glazingType ? null : glazingType,
            },
        });
    }
};
__decorate([
    glassDamage.Getter('change')
], GlazingTypeComponent.prototype, "changeGetter", void 0);
__decorate([
    glassDamage.Getter('validation')
], GlazingTypeComponent.prototype, "validationGetter", void 0);
__decorate([
    glassDamage.Action('updateDamage')
], GlazingTypeComponent.prototype, "updateGlassDamage", void 0);
GlazingTypeComponent = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            RadioImageButton,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
        },
    })
], GlazingTypeComponent);
export default GlazingTypeComponent;
