import { render, staticRenderFns } from "./PictureCollectionUpload.vue?vue&type=template&id=3d30c228&scoped=true"
import script from "./PictureCollectionUpload.vue?vue&type=script&lang=ts"
export * from "./PictureCollectionUpload.vue?vue&type=script&lang=ts"
import style0 from "./PictureCollectionUpload.vue?vue&type=style&index=0&id=3d30c228&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d30c228",
  null
  
)

export default component.exports