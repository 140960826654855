var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
var Unit;
(function (Unit) {
    Unit["MILIMETER"] = "mm";
    Unit["CENTIMETER"] = "cm";
    Unit["METER"] = "m";
})(Unit || (Unit = {}));
let LengthInput = class LengthInput extends Vue {
    constructor() {
        super(...arguments);
        this.localUnit = this.unit;
    }
    get factor() {
        switch (this.inputUnit) {
            case Unit.MILIMETER:
                return 1;
            case Unit.CENTIMETER:
                return 10;
            case Unit.METER:
                return 1000;
        }
    }
    get inputValue() {
        return this.value ? Math.round(this.value / this.factor) : null;
    }
    set inputValue(value) {
        if (value === '' || value === null)
            this.$emit('input', null);
        else
            this.$emit('input', value * this.factor);
    }
    get inputUnit() {
        var _a;
        return (_a = this.localUnit) !== null && _a !== void 0 ? _a : this.unit;
    }
    set inputUnit(value) {
        this.localUnit = value;
        this.$emit('update:unit', value);
    }
};
LengthInput = __decorate([
    Component({
        props: {
            value: {
                type: Number,
                default: null,
            },
            unit: {
                type: String,
                default: Unit.MILIMETER,
            },
            canChoseUnit: {
                type: Boolean,
                default: false,
            },
            availableUnits: {
                type: Array,
                default: () => Object.values(Unit),
            },
        },
    })
], LengthInput);
export default LengthInput;
