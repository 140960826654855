var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { INSURER_CONFIG } from '@shared/insurer';
import { isElectricalDamageQualification } from '@shared/qualification/qualification';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const qualification = namespace('qualification');
const counting = namespace('counting');
let NeedManagement = class NeedManagement extends Vue {
    get expertNeeded() {
        var _a;
        if ((_a = this.counting) === null || _a === void 0 ? void 0 : _a.notEstimatedPersonalProperty) {
            return true;
        }
        if (isElectricalDamageQualification(this.qualification) &&
            (this.qualification.hasProfessionalIntervened || this.qualification.isAwareOfPublicWorks)) {
            return false;
        }
        return isElectricalDamageQualification(this.qualification) && this.qualification.possibleAppeal;
    }
    get managerNeeded() {
        var _a;
        if (!((_a = this.counting) === null || _a === void 0 ? void 0 : _a.notEstimatedPersonalProperty)) {
            return true;
        }
        if (isElectricalDamageQualification(this.qualification) &&
            (this.qualification.hasProfessionalIntervened || this.qualification.isAwareOfPublicWorks)) {
            return true;
        }
        return isElectricalDamageQualification(this.qualification) && !this.qualification.possibleAppeal;
    }
    get illustration() {
        if (this.expertNeeded) {
            return require('@declaration/assets/images/appointment/expertOnSite.svg');
        }
        if (this.managerNeeded) {
            return require('@declaration/assets/images/appointment/managerCall.svg');
        }
        return require('@declaration/assets/images/documents/pause-parcours.svg');
    }
    get title() {
        if (this.expertNeeded) {
            return this.$tc('need_management.title.expert_needed');
        }
        if (this.managerNeeded) {
            return this.$tc('need_management.title.manager_needed');
        }
        return this.$tc('need_management.title');
    }
    get description() {
        var _a, _b;
        if (this.expertNeeded) {
            return this.$tc('need_management.description.expert_needed');
        }
        if (this.managerNeeded) {
            return this.$tc('need_management.description.manager_needed');
        }
        return this.$tc('need_management.description', undefined, {
            phoneNumber: (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.france) !== null && _b !== void 0 ? _b : '01 XX XX XX XX',
        });
    }
    get displayCaseNumber() {
        return insurerHasFeature(InsurerFeatureFlag.DisplayCaseNumberInNeedManagementScreen, this.typeTrigram);
    }
    get continuation() {
        return insurerHasFeature(InsurerFeatureFlag.DisplayContinueButtonInNeedManagementScreen, this.typeTrigram)
            ? 'quit'
            : null;
    }
};
__decorate([
    counting.State('counting')
], NeedManagement.prototype, "counting", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], NeedManagement.prototype, "typeTrigram", void 0);
__decorate([
    qualification.State('qualification')
], NeedManagement.prototype, "qualification", void 0);
NeedManagement = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], NeedManagement);
export default NeedManagement;
