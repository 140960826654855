var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
const contract = namespace('contract');
let ContractModification = class ContractModification extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.revealDiffError = false;
    }
    mounted() {
        this.editContract();
    }
    get canSave() {
        return this.validation.isValid && this.contractInEditionHasDiff;
    }
    cancel() {
        this.cancelContractEdition();
        this.$emit('cancel');
    }
    validate() {
        this.revealDiffError = true;
        this.validateContractContact();
    }
    async saveClaimContract() {
        this.loading = true;
        await this.saveContractInEdition().catch(() => (this.loading = false));
        this.$emit('saved');
        this.$buefy.toast.open({
            message: this.$tc('contract.modification.toast.message'),
            type: 'is-info',
            position: 'is-bottom',
        });
    }
};
__decorate([
    contract.State('selectedContract')
], ContractModification.prototype, "contract", void 0);
__decorate([
    contract.State('contractInEditionHasDiff')
], ContractModification.prototype, "contractInEditionHasDiff", void 0);
__decorate([
    contract.Getter('validation')
], ContractModification.prototype, "validation", void 0);
__decorate([
    contract.Action('updateContractContact')
], ContractModification.prototype, "updateContact", void 0);
__decorate([
    contract.Action('validateContractContact')
], ContractModification.prototype, "validateContractContact", void 0);
__decorate([
    contract.Action('saveContractInEdition')
], ContractModification.prototype, "saveContractInEdition", void 0);
__decorate([
    contract.Action('editContract')
], ContractModification.prototype, "editContract", void 0);
__decorate([
    contract.Action('cancelContractEdition')
], ContractModification.prototype, "cancelContractEdition", void 0);
ContractModification = __decorate([
    Component({
        components: {
            ClaimsButton,
        },
    })
], ContractModification);
export default ContractModification;
