import { render, staticRenderFns } from "./RobberyComplaintFiledModal.vue?vue&type=template&id=664ceeb6"
import script from "./RobberyComplaintFiledModal.vue?vue&type=script&lang=ts"
export * from "./RobberyComplaintFiledModal.vue?vue&type=script&lang=ts"
import style0 from "./RobberyComplaintFiledModal.vue?vue&type=style&index=0&id=664ceeb6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports