var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import VerandaStructure from './VerandaStructure.vue';
import VerandaGutter from './VerandaGutter.vue';
import VerandaPanelMaterials from './VerandaPanelMaterials.vue';
import VerandaPanels from './VerandaPanels.vue';
import NumberInput from '@declaration/components/NumberInput.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { VerandaPanelMaterial, } from '@shared/types/api/externalDamage';
let Veranda = class Veranda extends ExternalDamageComponent {
    get structureDamaged() {
        return this.externalDamage.structureDamaged;
    }
    get panels() {
        var _a;
        return (_a = this.externalDamage.panels) !== null && _a !== void 0 ? _a : [];
    }
    get selectedMaterials() {
        return Object.values(VerandaPanelMaterial).filter((material) => this.panels.some((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === material));
    }
    set selectedMaterials(materials) {
        if (!materials.includes(VerandaPanelMaterial.Glass)) {
            this.glassPanelCount = 0;
        }
        else {
            this.glassPanelCount || (this.glassPanelCount = 1);
        }
        if (!materials.includes(VerandaPanelMaterial.Polycarbonate)) {
            this.polycarbonatePanelCount = 0;
        }
        else {
            this.polycarbonatePanelCount || (this.polycarbonatePanelCount = 1);
        }
        if (!materials.includes(VerandaPanelMaterial.SandwichPanel)) {
            this.sandwichPanelCount = 0;
        }
        else {
            this.sandwichPanelCount || (this.sandwichPanelCount = 1);
        }
        if (!materials.includes(VerandaPanelMaterial.Other)) {
            this.otherPanelCount = 0;
        }
        else {
            this.otherPanelCount || (this.otherPanelCount = 1);
        }
    }
    get hasGlassPanels() {
        return this.selectedMaterials.includes(VerandaPanelMaterial.Glass);
    }
    get hasPolycarbonatePanels() {
        return this.selectedMaterials.includes(VerandaPanelMaterial.Polycarbonate);
    }
    get hasSandwichPanels() {
        return this.selectedMaterials.includes(VerandaPanelMaterial.SandwichPanel);
    }
    get hasOtherPanels() {
        return this.selectedMaterials.includes(VerandaPanelMaterial.Other);
    }
    get glassPanels() {
        return this.panels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Glass);
    }
    get polycarbonatePanels() {
        return this.panels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Polycarbonate);
    }
    get sandwichPanels() {
        return this.panels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.SandwichPanel);
    }
    get otherPanels() {
        return this.panels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === VerandaPanelMaterial.Other);
    }
    get glassPanelCount() {
        return this.glassPanels.length;
    }
    set glassPanelCount(count) {
        var _a, _b;
        const diff = count - this.glassPanelCount;
        if (diff === 0)
            return;
        const panels = this.panels.slice();
        const glassPanels = this.glassPanels.slice();
        for (let i = 0; i < Math.abs(diff); i++) {
            if (diff > 0) {
                panels.push({
                    material: VerandaPanelMaterial.Glass,
                    glazingType: (_b = (_a = this.glassPanels.find((panel) => panel === null || panel === void 0 ? void 0 : panel.glazingType)) === null || _a === void 0 ? void 0 : _a.glazingType) !== null && _b !== void 0 ? _b : null,
                });
            }
            else {
                const panel = glassPanels.pop();
                const realIndex = panels.indexOf(panel);
                if (realIndex === -1)
                    continue;
                panels.splice(realIndex, 1);
            }
        }
        this.updateExternalDamage({ panels });
    }
    get polycarbonatePanelCount() {
        return this.polycarbonatePanels.length;
    }
    set polycarbonatePanelCount(count) {
        const diff = count - this.polycarbonatePanelCount;
        if (diff === 0)
            return;
        const panels = this.panels.slice();
        const polycarbonatePanels = this.polycarbonatePanels.slice();
        for (let i = 0; i < Math.abs(diff); i++) {
            if (diff > 0) {
                panels.push({
                    material: VerandaPanelMaterial.Polycarbonate,
                    glazingType: null,
                });
            }
            else {
                const panel = polycarbonatePanels.pop();
                const realIndex = panels.indexOf(panel);
                if (realIndex === -1)
                    continue;
                panels.splice(realIndex, 1);
            }
        }
        this.updateExternalDamage({ panels });
    }
    get sandwichPanelCount() {
        return this.sandwichPanels.length;
    }
    set sandwichPanelCount(count) {
        const diff = count - this.sandwichPanelCount;
        if (diff === 0)
            return;
        const panels = this.panels.slice();
        const sandwichPanels = this.sandwichPanels.slice();
        for (let i = 0; i < Math.abs(diff); i++) {
            if (diff > 0) {
                panels.push({
                    material: VerandaPanelMaterial.SandwichPanel,
                    glazingType: null,
                });
            }
            else {
                const panel = sandwichPanels.pop();
                const realIndex = panels.indexOf(panel);
                if (realIndex === -1)
                    continue;
                panels.splice(realIndex, 1);
            }
        }
        this.updateExternalDamage({ panels });
    }
    get otherPanelCount() {
        return this.otherPanels.length;
    }
    set otherPanelCount(count) {
        const diff = count - this.otherPanelCount;
        if (diff === 0)
            return;
        const panels = this.panels.slice();
        const otherPanels = this.otherPanels.slice();
        for (let i = 0; i < Math.abs(diff); i++) {
            if (diff > 0) {
                panels.push({
                    material: VerandaPanelMaterial.Other,
                    glazingType: null,
                });
            }
            else {
                const panel = otherPanels.pop();
                const realIndex = panels.indexOf(panel);
                if (realIndex === -1)
                    continue;
                panels.splice(realIndex, 1);
            }
        }
        this.updateExternalDamage({ panels });
    }
    getPanelsCount(material) {
        switch (material) {
            case VerandaPanelMaterial.Glass:
                return this.glassPanelCount;
            case VerandaPanelMaterial.Polycarbonate:
                return this.polycarbonatePanelCount;
            case VerandaPanelMaterial.SandwichPanel:
                return this.sandwichPanelCount;
            case VerandaPanelMaterial.Other:
                return this.otherPanelCount;
        }
    }
    setPanelsCount(material, count) {
        switch (material) {
            case VerandaPanelMaterial.Glass:
                this.glassPanelCount = count;
                break;
            case VerandaPanelMaterial.Polycarbonate:
                this.polycarbonatePanelCount = count;
                break;
            case VerandaPanelMaterial.SandwichPanel:
                this.sandwichPanelCount = count;
                break;
            case VerandaPanelMaterial.Other:
                this.otherPanelCount = count;
                break;
        }
    }
};
Veranda = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            RadioImageButton,
            NumberInput,
            LengthInput,
            VerandaStructure,
            VerandaGutter,
            VerandaPanelMaterials,
            VerandaPanels,
        },
        watch: {},
    })
], Veranda);
export default Veranda;
