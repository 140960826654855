var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
const claim = namespace('claim');
let DeclarationDocumentPending = class DeclarationDocumentPending extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/refus.svg');
    }
    created() {
        this.updateAndSaveClaimStep(Step.DeclarationDocumentPending);
    }
    trackClaim() {
        this.$goToLocalePath('/mes-declarations');
    }
};
__decorate([
    claim.Action('updateAndSaveClaimStep')
], DeclarationDocumentPending.prototype, "updateAndSaveClaimStep", void 0);
DeclarationDocumentPending = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
        },
    })
], DeclarationDocumentPending);
export default DeclarationDocumentPending;
