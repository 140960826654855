var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { FIRE_BREAK_IN_DAMAGES_QUESTION_OPTIONS } from '@declaration/constants/fireDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import InformationModal from '@declaration/components/InformationModal.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let FireHasRealEstateDamagesQuestion = class FireHasRealEstateDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isInformationModalOpen = false;
        this.FIRE_BREAK_IN_DAMAGES_QUESTION_OPTIONS = FIRE_BREAK_IN_DAMAGES_QUESTION_OPTIONS;
    }
    afterEnter() {
        this.openInformationModal();
    }
    updateClaim(hasRealEstateDamages) {
        this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                hasRealEstateDamages,
            },
            step: this.step,
        });
    }
    get answer() {
        var _a;
        if (this.fireDamageQualification.hasRealEstateDamages === null) {
            return null;
        }
        const tradKey = (_a = FIRE_BREAK_IN_DAMAGES_QUESTION_OPTIONS.find((o) => o.value === this.fireDamageQualification.hasRealEstateDamages)) === null || _a === void 0 ? void 0 : _a.answerTradKey;
        return tradKey ? this.$tc(tradKey) : null;
    }
    get modalText() {
        return this.$tc('claim.qualification.fire_has_real_estate_damages_question.information_modal.text');
    }
    get modalTitle() {
        return this.$tc('claim.qualification.fire_has_real_estate_damages_question.information_modal.title');
    }
    closeInformationModal() {
        this.isInformationModalOpen = false;
    }
    openInformationModal() {
        this.isInformationModalOpen = true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireHasRealEstateDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireHasRealEstateDamagesQuestion.prototype, "fireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireHasRealEstateDamagesQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireHasRealEstateDamagesQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, InformationModal },
        props: QuestionProps,
    })
], FireHasRealEstateDamagesQuestion);
export default FireHasRealEstateDamagesQuestion;
