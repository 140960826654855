var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { RoomDamageComponent } from '../RoomDamageComponent';
import { RoomSize } from '@shared/constants/roomDamageConstants';
import { ROOM_SURFACE_BIG_TO_MEAN_RATIO, ROOM_SURFACE_EQUIVALENCES_TRAD_KEYS, ROOM_SURFACE_MAX_TO_MEAN_RATIO, ROOM_SURFACE_SMALL_TO_MEAN_RATIO, } from '@declaration/constants/roomDamageConstants';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { ClaimTypeTrigram, QualificationRoomType } from '@shared/types/api/claim';
import SurfaceSlider from '@declaration/components/roomDamage/surfaceDamage/SurfaceSlider.vue';
import SurfaceDamagesInput from '@declaration/components/roomDamage/surfaceDamage/SurfaceDamagesInput.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { DEFAULT_HOUSING_CONFIGURATION, HOUSING_CONFIGURATION_BY_HOUSING_TYPE, INC_HOUSING_CONFIGURATION, } from '@declaration/constants/equipmentConstants';
import { INSURER_CONFIG } from '@shared/insurer';
const contract = namespace('contract');
const qualification = namespace('qualification');
let RoomForm = class RoomForm extends RoomDamageComponent {
    get housingConfiguration() {
        var _a, _b, _c;
        if (this.typeTrigram === ClaimTypeTrigram.Fire) {
            return INC_HOUSING_CONFIGURATION;
        }
        if (this.selectedContract === null) {
            return DEFAULT_HOUSING_CONFIGURATION;
        }
        const customConfig = ((_c = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.roomDamagesContents) === null || _b === void 0 ? void 0 : _b.housingConfigurationByHousingType) === null || _c === void 0 ? void 0 : _c[this.selectedContract.housingType.slug]) || null;
        return (customConfig ||
            HOUSING_CONFIGURATION_BY_HOUSING_TYPE[this.selectedContract.housingType.slug] ||
            DEFAULT_HOUSING_CONFIGURATION);
    }
    get roomOptions() {
        return [...this.housingConfiguration.rooms, ...this.housingConfiguration.otherRooms].reduce((acc, room) => {
            acc[room] = QUALIFICATION_ROOM[room];
            return acc;
        }, {});
    }
    get roomNameSlug() {
        var _a;
        return (_a = this.roomDamage.roomNameSlug) !== null && _a !== void 0 ? _a : null;
    }
    set roomNameSlug(roomNameSlug) {
        const surface = this.roomMeanSurface(roomNameSlug);
        this.updateRoomDamage({ roomNameSlug, surface });
    }
    get surface() {
        var _a;
        return (_a = this.roomDamage.surface) !== null && _a !== void 0 ? _a : null;
    }
    set surface(surface) {
        this.updateRoomDamage({ surface });
    }
    get roomLabel() {
        var _a;
        return (_a = this.roomDamage.roomLabel) !== null && _a !== void 0 ? _a : null;
    }
    set roomLabel(roomLabel) {
        this.updateRoomDamage({ roomLabel });
    }
    get roomDescription() {
        var _a;
        return (_a = this.roomDamage.roomDescription) !== null && _a !== void 0 ? _a : null;
    }
    set roomDescription(roomDescription) {
        this.updateRoomDamage({ roomDescription });
    }
    get meanSurface() {
        var _a;
        return this.roomMeanSurface((_a = this.roomNameSlug) !== null && _a !== void 0 ? _a : undefined);
    }
    get maxSurface() {
        return this.meanSurface * ROOM_SURFACE_MAX_TO_MEAN_RATIO;
    }
    get roomSize() {
        const { roomNameSlug, surface, meanSurface } = this;
        if (surface === null || roomNameSlug === null)
            return null;
        if (surface <= meanSurface * ROOM_SURFACE_SMALL_TO_MEAN_RATIO)
            return RoomSize.Small;
        if (surface <= meanSurface * ROOM_SURFACE_BIG_TO_MEAN_RATIO)
            return RoomSize.Mean;
        return RoomSize.Big;
    }
    get roomSizeDescription() {
        const { roomSize, roomNameSlug } = this;
        if (!roomSize || !roomNameSlug)
            return null;
        return this.$tc(ROOM_SURFACE_EQUIVALENCES_TRAD_KEYS[roomNameSlug][roomSize]);
    }
    get isOtherRoom() {
        return this.roomDamage.roomNameSlug === QualificationRoomType.Other;
    }
};
__decorate([
    contract.Getter('roomMeanSurface')
], RoomForm.prototype, "roomMeanSurface", void 0);
__decorate([
    contract.State('selectedContract')
], RoomForm.prototype, "selectedContract", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], RoomForm.prototype, "typeTrigram", void 0);
RoomForm = __decorate([
    Component({
        components: { SurfaceSlider, SurfaceDamagesInput, PictureCollectionUpload },
    })
], RoomForm);
export default RoomForm;
