var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import ClaimsCheck from '@declaration/components/UI/ClaimsCheck.vue';
import { RepairableStatus } from '@shared/types/api/personalProperty';
const claim = namespace('claim');
let RepairQuoteUpload = class RepairQuoteUpload extends Vue {
    get isRepairable() {
        return this.repairableStatus === RepairableStatus.Repairable;
    }
    get fileUploadLabel() {
        return this.repairableStatus === RepairableStatus.Repairable
            ? this.uploadLabel
            : this.$tc('claim.damage.personal_property.form.repair.upload.certificate.add');
    }
    get fileType() {
        return !this.isRepairable && this.irreparabilityFileType
            ? this.irreparabilityFileType
            : this.repairFileType;
    }
    get shouldDisplayRepairQuoteInfoForm() {
        return !this.showIsRepairable || this.repairableStatus === RepairableStatus.Repairable;
    }
    onRepairableUpdate(isRepairable) {
        var _a;
        if (isRepairable) {
            this.$emit('update', {
                repairableStatus: RepairableStatus.Repairable,
                repairQuoteInfo: this.repairQuoteInfo,
                repairFileCollection: (_a = this.repairFileCollection) !== null && _a !== void 0 ? _a : emptyUploadedFileCollection(),
            });
            return;
        }
        this.$emit('update', {
            repairableStatus: RepairableStatus.Irreparable,
            repairQuoteInfo: null,
            repairFileCollection: emptyUploadedFileCollection(),
        });
    }
    onQuoteInfoUpdate(repairQuoteInfo) {
        var _a;
        this.$emit('update', {
            repairableStatus: this.repairableStatus,
            repairQuoteInfo,
            repairFileCollection: (_a = this.repairFileCollection) !== null && _a !== void 0 ? _a : emptyUploadedFileCollection(),
        });
    }
    onFileCollectionUpdate(repairFileCollection) {
        var _a;
        this.$emit('update', {
            repairableStatus: (_a = this.repairableStatus) !== null && _a !== void 0 ? _a : RepairableStatus.Irreparable,
            repairQuoteInfo: repairFileCollection.uploadedAppFiles.length > 0 ? this.repairQuoteInfo : null,
            repairFileCollection,
        });
    }
};
__decorate([
    claim.State('claim')
], RepairQuoteUpload.prototype, "claim", void 0);
RepairQuoteUpload = __decorate([
    Component({
        components: {
            AmountInput,
            FileCollectionUpload,
            RadioPrimaryButton,
            ClaimsCheck,
        },
        props: {
            showIsRepairable: {
                type: Boolean,
                default: false,
            },
            isIrreparableCertificateRequired: {
                type: Boolean,
                default: false,
            },
            repairableStatus: {
                type: String,
                required: false,
            },
            repairFileCollection: {
                type: Object,
                required: false,
            },
            repairQuoteInfo: {
                type: Object,
                required: false,
            },
            repairableStatusValidationKey: {
                type: String,
                default: 'repairableStatus',
            },
            repairFileCollectionValidationKey: {
                type: String,
                default: 'repairFileCollection',
            },
            repairQuoteInfoValidationKey: {
                type: String,
                default: 'repairQuoteInfo',
            },
            repairFileType: {
                type: String,
                required: true,
            },
            irreparabilityFileType: {
                type: String,
                default: null,
            },
            validation: {
                type: Object,
                required: true,
            },
            uploadLabel: {
                type: String,
                required: false,
            },
            isMandatory: {
                type: Boolean,
                default: false,
            },
        },
    })
], RepairQuoteUpload);
export default RepairQuoteUpload;
