var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import ImmediateCompensationDetailCard from './ImmediateCompensationDetailCard.vue';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ClaimCompensationOption } from '@shared/types/api/claim';
import Modal from '@declaration/components/UI/Modal.vue';
import { BreakInDamageCompensationMode } from '@shared/types/api/breakInDamage';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { forcedAmountWithDefault } from '@shared/utils/forcedAmount';
const counting = namespace('counting');
const personalProperty = namespace('personalProperty');
const breakInDamage = namespace('breakInDamage');
const claim = namespace('claim');
let ImmediateCompensationDetailModal = class ImmediateCompensationDetailModal extends Vue {
    constructor() {
        super(...arguments);
        this.personalPropertyImage = require('@declaration/assets/images/compensation/objets-voles.svg');
        this.breakInImage = require('@declaration/assets/images/compensation/reparation-di.svg');
    }
    get headerAmount() {
        return (forcedAmountWithDefault(this.personalPropertiesRefund.appliedAmounts.total, 0) +
            forcedAmountWithDefault(this.breakInDamagesRefund.appliedAmounts.total, 0));
    }
    get certifiedArtisan() {
        if (this.claimInfo.chosenCompensationOption) {
            return this.claimInfo.chosenCompensationOption === ClaimCompensationOption.PartnerArtisan;
        }
        return true;
    }
    personalPropertyTitle(personalProperty) {
        return personalProperty.thirdPartyProductInfo
            ? personalProperty.thirdPartyProductInfo.name
            : personalProperty.designation;
    }
    breakInDamageTitle(breakInDamage) {
        return breakInDamageTitle(breakInDamage, this.$tc.bind(this));
    }
    personalPropertyIndemnisationReason(entity) {
        const hasReceipt = entity.receipt &&
            entity.receipt.fileCollection &&
            entity.receipt.fileCollection.uploadedAppFiles.length > 0;
        return this.indemnisationReason(entity, hasReceipt);
    }
    breakInDamageIndemnisationReason(entity) {
        const hasInitialReceipt = entity.repairFileCollection && entity.repairFileCollection.uploadedAppFiles.length > 0;
        const hasLateReceipt = entity.pendingRepairFileCollection &&
            entity.pendingRepairFileCollection.uploadedAppFiles.length > 0;
        if (hasInitialReceipt && hasLateReceipt) {
            return this.$tc('compensation.proposition.immediate_compensation.receipt.both');
        }
        else if (hasInitialReceipt) {
            return this.$tc('compensation.proposition.immediate_compensation.receipt.initial');
        }
        else if (hasLateReceipt) {
            return this.$tc('compensation.proposition.immediate_compensation.receipt.late');
        }
        return this.$tc('compensation.proposition.immediate_compensation.none');
    }
    breakInIndemnisationExplanation(entity) {
        switch (entity.compensationMode) {
            case BreakInDamageCompensationMode.CompensationModeTemporary:
            case BreakInDamageCompensationMode.CompensationModeDefinitive:
                return this.$tc('compensation.proposition.immediate_compensation.explanation.self_repair');
            case BreakInDamageCompensationMode.CompensationModeFixed:
                return this.$tc('compensation.proposition.immediate_compensation.explanation.fixed');
        }
        return null;
    }
    indemnisationReason(entity, hasReceipt) {
        const hasPictures = fileCollectionFilesCount(entity.pictureCollection) > 0;
        if (hasReceipt && hasPictures) {
            return this.$tc('compensation.proposition.immediate_compensation.receipt_and_picture');
        }
        else if (hasReceipt) {
            return this.$tc('compensation.proposition.immediate_compensation.receipt');
        }
        else if (hasPictures) {
            return this.$tc('compensation.proposition.immediate_compensation.picture');
        }
        return this.$tc('compensation.proposition.immediate_compensation.none');
    }
    close() {
        this.$emit('close');
    }
    get insurerHasFeatureCompensationPropositionGenerali() {
        return insurerHasFeature(InsurerFeatureFlag.CompensationPropositionGenerali);
    }
};
__decorate([
    counting.State('counting')
], ImmediateCompensationDetailModal.prototype, "counting", void 0);
__decorate([
    claim.State('claim')
], ImmediateCompensationDetailModal.prototype, "claimInfo", void 0);
__decorate([
    counting.Getter('personalPropertiesRefund')
], ImmediateCompensationDetailModal.prototype, "personalPropertiesRefund", void 0);
__decorate([
    counting.Getter('breakInDamagesRefund')
], ImmediateCompensationDetailModal.prototype, "breakInDamagesRefund", void 0);
__decorate([
    personalProperty.State('damages')
], ImmediateCompensationDetailModal.prototype, "personalProperties", void 0);
__decorate([
    breakInDamage.State('damages')
], ImmediateCompensationDetailModal.prototype, "breakInDamages", void 0);
ImmediateCompensationDetailModal = __decorate([
    Component({
        components: { Modal, ImmediateCompensationDetailCard },
        props: {
            isDisplayed: { type: Boolean, required: true },
        },
    })
], ImmediateCompensationDetailModal);
export default ImmediateCompensationDetailModal;
