var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Checkout from '@declaration/components/Payout/Checkout.vue';
import { WHITE_LAYOUT_COLOR } from '@declaration/layout';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { Step } from '@shared/claim/steps';
import { ClaimStatus } from '@shared/types/api/claim';
const claim = namespace('claim');
let PayoutIndex = class PayoutIndex extends Vue {
    constructor() {
        super(...arguments);
        this.isPayoutPending = false;
    }
    async created() {
        // if a payout exists, check status in created and not in middleware otherwise Checkout will try to render the form and, if a redirection is required, it will throw an error
        if ((this.claimInfo.status === ClaimStatus.ImmediatePayoutPending &&
            this.claimInfo.immediatePayout) ||
            (this.claimInfo.status === ClaimStatus.DelayedPayoutPending && this.claimInfo.delayedPayout)) {
            await this.updateAndSaveClaimStep(this.claimInfo.status === ClaimStatus.ImmediatePayoutPending
                ? Step.ImmediatePayoutIndex
                : Step.DelayedPayoutIndex);
        }
        else {
            this.isPayoutPending = true;
        }
    }
};
__decorate([
    claim.State('claim')
], PayoutIndex.prototype, "claimInfo", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], PayoutIndex.prototype, "updateAndSaveClaimStep", void 0);
PayoutIndex = __decorate([
    Component({
        components: {
            Checkout,
            ClaimsButton,
        },
        meta: {
            title: 'page.title.payout.index',
            goBack: null,
            color: WHITE_LAYOUT_COLOR,
        },
    })
], PayoutIndex);
export default PayoutIndex;
