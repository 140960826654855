var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { POSSIBLE_ROBBERY_METHODS_DURING_ABSENCE, ROBBERY_METHOD_CHOICES, ROBBERY_METHOD_DURING_ABSENCE_CHOICES, ROBBERY_METHOD_TRAD_KEYS, } from '@shared/constants/robberyConstants';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { RobberyMethod } from '@shared/types/api/claim';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyMethodQuestion = class RobberyMethodQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.clickedMethods = new Set();
    }
    get questionTitle() {
        return this.$tc('claim.qualification.robbery.method.title');
    }
    get methodChoices() {
        let methodChoices = this.qualification.isDuringAbsence
            ? ROBBERY_METHOD_DURING_ABSENCE_CHOICES
            : ROBBERY_METHOD_CHOICES;
        if (!insurerHasFeature(InsurerFeatureFlag.MagneticPassRobberyMethod)) {
            methodChoices = methodChoices.filter((method) => method.value !== RobberyMethod.MagneticPass);
        }
        return methodChoices;
    }
    get methodString() {
        if (!this.qualification.method) {
            return '';
        }
        if (this.qualification.method.length === 1) {
            return this.$tc(ROBBERY_METHOD_TRAD_KEYS[this.qualification.method[0]]).toLowerCase();
        }
        const lastMethodString = this.$tc(ROBBERY_METHOD_TRAD_KEYS[this.qualification.method[this.qualification.method.length - 1]]).toLowerCase();
        const firstMethodString = this.qualification.method
            .slice(0, this.qualification.method.length - 1)
            .map((method) => this.$tc(ROBBERY_METHOD_TRAD_KEYS[method]).toLowerCase())
            .join(', ');
        return `${firstMethodString} ${this.$tc('and')} ${lastMethodString}`;
    }
    get method() {
        var _a;
        return (_a = this.qualification.method) !== null && _a !== void 0 ? _a : [];
    }
    set method(method) {
        this.updateRobberyDamageQualification({ qualificationPayload: { method } });
    }
    get canValidate() {
        var _a, _b;
        return ((_b = (_a = this.qualification.method) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0;
    }
    get answer() {
        if (this.qualification.method === null) {
            return null;
        }
        if (this.qualification.method.length === 1) {
            return this.$tc('claim.qualification.robbery.method.answer', 1, {
                method: this.methodString,
            });
        }
        return this.$tc('claim.qualification.robbery.method.answer', 1, {
            method: this.methodString,
        });
    }
    filterPossibleMethods(methods) {
        let possibleMethods = methods;
        if (this.qualification.isDuringAbsence) {
            possibleMethods = methods.filter((method) => POSSIBLE_ROBBERY_METHODS_DURING_ABSENCE.has(method));
        }
        if (!insurerHasFeature(InsurerFeatureFlag.MagneticPassRobberyMethod)) {
            possibleMethods = possibleMethods.filter((method) => method !== RobberyMethod.MagneticPass);
        }
        return possibleMethods;
    }
    async validate() {
        await this.updateAndSaveRobberyDamageQualification({
            qualificationPayload: { method: this.filterPossibleMethods(this.method) },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyMethodQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyMethodQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyMethodQuestion.prototype, "updateRobberyDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyMethodQuestion.prototype, "updateAndSaveRobberyDamageQualification", void 0);
RobberyMethodQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], RobberyMethodQuestion);
export default RobberyMethodQuestion;
