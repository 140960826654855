var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { ExternalDamageComponent } from '../ExternalDamageComponent';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import { FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages';
import { FENCE_LENGHT_LABEL, FENCE_LENGHT_TITLE, FENCE_SUB_TITLE, FENCE_TITLE, FENCE_TYPE_FULL_HEIGHT_IMAGES, FENCE_TYPE_HALF_HEIGHT_IMAGES, FENCE_TYPE_OTHER_IMAGE, } from '@declaration/constants/externalDamages';
import { FenceType } from '@shared/types/api/externalDamage';
import { objectToBooleanImageChoices } from '@shared/choice';
let Fence = class Fence extends ExternalDamageComponent {
    get title() {
        return this.translateExternalDamageKey(FENCE_TITLE);
    }
    get subTitle() {
        return this.translateExternalDamageKey(FENCE_SUB_TITLE);
    }
    get lengthTitle() {
        return this.translateExternalDamageKey(FENCE_LENGHT_TITLE);
    }
    get lengthLabel() {
        return this.translateExternalDamageKey(FENCE_LENGHT_LABEL);
    }
    get fenceTypeChoices() {
        const damageOrigin = this.damageOrigin;
        if (!damageOrigin)
            return [];
        return Object.values(FenceType)
            .filter((val) => val !== FenceType.Other)
            .map((fenceType) => {
            return {
                fenceType,
                tradKey: FENCE_TYPE_TRAD_KEYS[damageOrigin][fenceType],
                choices: objectToBooleanImageChoices({
                    true: FENCE_TYPE_HALF_HEIGHT_IMAGES[fenceType],
                    false: FENCE_TYPE_FULL_HEIGHT_IMAGES[fenceType],
                }),
            };
        })
            .concat([
            {
                fenceType: FenceType.Other,
                tradKey: FENCE_TYPE_TRAD_KEYS[damageOrigin][FenceType.Other],
                choices: [{ value: true, image: FENCE_TYPE_OTHER_IMAGE }],
            },
        ]);
    }
    getIsFenceHalfHeight(fenceType) {
        var _a;
        if (this.externalDamage.fenceType === fenceType) {
            return (_a = this.externalDamage.isFenceHalfHeight) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }
    setIsFenceHalfHeight(fenceType, isFenceHalfHeight) {
        this.updateExternalDamage({ fenceType, isFenceHalfHeight });
    }
    get fenceLength() {
        if (this.externalDamage.fenceLength)
            return this.externalDamage.fenceLength;
        return null;
    }
    set fenceLength(fenceLength) {
        if (fenceLength)
            this.updateExternalDamage({ fenceLength });
        else
            this.updateExternalDamage({ fenceLength: null });
    }
};
Fence = __decorate([
    Component({ components: { RadioImageButton, LengthInput } })
], Fence);
export default Fence;
