var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamagesComponent } from './GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { getGoodDamageCategoryChoices, getGoodDamageCategoryDefinition, GOOD_DAMAGE_ROOM_ILLUSTRATIONS, } from '@shared/roomDamage/goodDamage';
let GoodDamageCategoriesForm = class GoodDamageCategoriesForm extends GoodDamagesComponent {
    get goodDamageCategoriesChoices() {
        var _a;
        const room = this.room;
        return (_a = (room && getGoodDamageCategoryChoices(room))) !== null && _a !== void 0 ? _a : [];
    }
    get roomImgFromRoomNameSlug() {
        var _a, _b;
        return ((_b = (this.roomDamage.roomNameSlug &&
            GOOD_DAMAGE_ROOM_ILLUSTRATIONS[(_a = this.roomDamage) === null || _a === void 0 ? void 0 : _a.roomNameSlug])) !== null && _b !== void 0 ? _b : null);
    }
    get categories() {
        return this.selectedCategories;
    }
    set categories(categories) {
        categories
            .filter((category) => !this.categories.includes(category))
            .forEach((category) => {
            const definition = this.room && getGoodDamageCategoryDefinition(this.room, category);
            if (!definition)
                this.addNewGoodDamage(category, null);
        });
        this.selectedCategories
            .filter((category) => !categories.includes(category))
            .forEach((category) => this.resetGoodDamage(category, null));
        this.$emit('update:selectedCategories', categories);
    }
    mounted() {
        const selectedCategories = this.goodDamages
            .filter((goodDamage) => goodDamage.room === this.roomDamage.roomNameSlug)
            .reduce((acc, { category }) => {
            if (category && !acc.includes(category))
                acc.push(category);
            return acc;
        }, []);
        this.$emit('update:selectedCategories', selectedCategories);
    }
};
GoodDamageCategoriesForm = __decorate([
    Component({
        components: { RadioPrimaryButton },
        props: {
            selectedCategories: { type: Array, default: () => [] },
        },
    })
], GoodDamageCategoriesForm);
export default GoodDamageCategoriesForm;
