var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS } from '@shared/claim/labels';
import { claimTypeDescriptionTradKey, claimTypeArgumentTradKey, claimTypeTitleTradKey, } from '@declaration/claim/labels';
import { CLAIM_TYPE_IMAGES, DEFAULT_SPLASHSCREEN_IMAGES, SPLASHSCREEN_IMAGES, } from '@shared/layout/layout';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import { INSURER_CONFIG } from '@shared/insurer';
const layout = namespace('layout');
const qualification = namespace('qualification');
const pages = namespace('pages');
let LargeSplashScreen = class LargeSplashScreen extends Vue {
    constructor() {
        super(...arguments);
        this.splashScreenPageNumber = 0;
    }
    stepImage(trigram) {
        var _a;
        const customImagesList = ((_a = INSURER_CONFIG.declaration.splashScreenImages) === null || _a === void 0 ? void 0 : _a[trigram]) ||
            DEFAULT_SPLASHSCREEN_IMAGES[trigram];
        const splashScreenImages = [];
        customImagesList.forEach((name) => {
            const imageFound = SPLASHSCREEN_IMAGES.find((image) => image.name === name);
            if (imageFound)
                splashScreenImages.push(imageFound);
        });
        return splashScreenImages;
    }
    get defaultSplashScreen() {
        const infos = {
            image: CLAIM_TYPE_IMAGES[this.typeTrigram],
            title: this.$tc(claimTypeTitleTradKey(this.typeTrigram)),
            buttonText: this.$tc('claim.declaration.qualification.splash.action'),
        };
        infos.steps = [];
        for (let i = 0; i < 3; i++) {
            infos.steps.push({
                image: this.stepImage(this.typeTrigram)[i].svg || CLAIM_TYPE_IMAGES[this.typeTrigram],
                title: this.$tc(claimTypeArgumentTradKey(this.typeTrigram) + i + '.title'),
                description: this.$tc(claimTypeArgumentTradKey(this.typeTrigram) + i + '.description'),
            });
        }
        return infos;
    }
    get unavailableSplashScreen() {
        return {
            image: this.defaultSplashScreen.image,
            title: this.defaultSplashScreen.title,
            description: this.$tc('claim.declaration.qualification.splash.unavailable.text', 0, {
                type: this.$tc(claimTypeDescriptionTradKey(this.typeTrigram)),
                helpTitle: this.$tc('help.default.sub_title'),
                helpPhoneNumber: this.$tc('help.default.phone_number'),
            }),
            buttonText: this.$tc('claim.declaration.qualification.splash.unavailable.action'),
        };
    }
    get splashScreenInfos() {
        if (this.isUnavailable) {
            return [this.unavailableSplashScreen];
        }
        return [this.defaultSplashScreen];
    }
    get splashScreenInfo() {
        if (this.splashScreenInfoData !== null) {
            return this.splashScreenInfoData;
        }
        // for the moment we keep the array syntax (in the future we will be able to render more than one page)
        return this.splashScreenInfos[this.splashScreenPageNumber];
    }
    get claimType() {
        return this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.typeTrigram]);
    }
    onSplashButtonClick() {
        this.$emit('close-splash-screen');
    }
};
__decorate([
    qualification.State('typeTrigram')
], LargeSplashScreen.prototype, "typeTrigram", void 0);
__decorate([
    layout.State('color')
], LargeSplashScreen.prototype, "color", void 0);
__decorate([
    pages.Getter('isUnavailable')
], LargeSplashScreen.prototype, "isUnavailable", void 0);
LargeSplashScreen = __decorate([
    Component({
        components: { ClaimsButton, SecondaryButton },
        props: {
            splashScreenInfoData: {
                type: Object,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    })
], LargeSplashScreen);
export default LargeSplashScreen;
