var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import NumberInput from '@declaration/components/NumberInput.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import { GUTTER_MATERIAL_CHOICES } from '@shared/constants/externalDamages';
import { GUTTER_LABEL, GUTTER_LENGTH_LABEL, GUTTER_MATERIAL_LABEL, GUTTER_TITLE, } from '@declaration/constants/externalDamages';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
let GutterQuestion = class GutterQuestion extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.isPartDamaged = false;
    }
    get title() {
        return this.translateExternalDamageKey(GUTTER_TITLE[this.fieldKey]);
    }
    get label() {
        return this.translateExternalDamageKey(GUTTER_LABEL[this.fieldKey]);
    }
    get materialLabel() {
        return this.translateExternalDamageKey(GUTTER_MATERIAL_LABEL);
    }
    get lengthLabel() {
        return this.translateExternalDamageKey(GUTTER_LENGTH_LABEL);
    }
    created() {
        this.isPartDamaged = typeof this.material === 'string' || typeof this.length === 'number';
    }
    get isDamaged() {
        return this.isPartDamaged;
    }
    set isDamaged(isDamaged) {
        this.isPartDamaged = isDamaged;
        if (isDamaged === false) {
            this.updateExternalDamage({
                [`${this.fieldKey}PartMaterial`]: null,
                [`${this.fieldKey}PartLength`]: null,
            });
        }
    }
    get materialChoices() {
        return GUTTER_MATERIAL_CHOICES;
    }
    get materialKey() {
        return `${this.fieldKey}PartMaterial`;
    }
    get lengthKey() {
        return `${this.fieldKey}PartLength`;
    }
    get material() {
        var _a;
        return (_a = this.externalDamage[this.materialKey]) !== null && _a !== void 0 ? _a : null;
    }
    set material(material) {
        this.updateExternalDamage({ [this.materialKey]: material });
    }
    get length() {
        var _a;
        return (_a = this.externalDamage[this.lengthKey]) !== null && _a !== void 0 ? _a : null;
    }
    set length(length) {
        this.updateExternalDamage({ [this.lengthKey]: length });
    }
};
GutterQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, LengthInput, NumberInput },
        props: { fieldKey: { type: String, required: true } },
    })
], GutterQuestion);
export default GutterQuestion;
