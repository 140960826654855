var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ThirdPartyForm from '@declaration/components/thirdParty/ThirdPartyForm.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
const thirdParty = namespace('thirdParty');
const pages = namespace('pages');
let ThirdPartyModal = class ThirdPartyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.isDeleting = false;
        this.hasInvoice = false;
    }
    created() {
        if (fileCollectionFilesCount(this.fileCollection) > 0) {
            this.hasInvoice = true;
        }
    }
    get headerTitle() {
        if (!this.thirdPartyInEdition.contact.lastName &&
            !this.thirdPartyInEdition.contact.firstName &&
            !this.thirdPartyInEdition.contact.companyName) {
            return this.$tc('claim.third_party.modal.title');
        }
        return `${this.thirdPartyInEdition.contact.lastName || ''}
      ${this.thirdPartyInEdition.contact.firstName || ''}
      ${this.thirdPartyInEdition.contact.companyName || ''}`;
    }
    get onFireStep() {
        return this.activeStep === 'declaration/tier-info-incendie';
    }
    revealValidation() {
        this.validateThirdPartyInEdition();
        this.$store.dispatch('error/add', 'Veuillez remplir tous les champs obligatoires');
    }
    get isNotValid() {
        if (this.onFireStep && this.hasInvoice) {
            return !this.validation.isValid || fileCollectionFilesCount(this.fileCollection) === 0;
        }
        return !this.validation.isValid;
    }
    get isWhiteContentBackground() {
        return true;
    }
    deleteConfirmation() {
        if (this.confirmDelete) {
            const deleteCallback = async () => {
                this.isDeleting = true;
                await this.deleteThirdPartyInEdition();
                this.closeModal();
                this.isDeleting = false;
            };
            this.confirmDelete(this.thirdPartyInEdition.iri, deleteCallback);
        }
    }
    closeModal() {
        this.$emit('close');
    }
    async postAndSaveThirdParty() {
        this.isSaving = true;
        try {
            this.closeModal();
            await this.saveThirdPartyInEdition(this.claimPayload);
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isSaving = false;
    }
};
__decorate([
    claim.State('claim')
], ThirdPartyModal.prototype, "claim", void 0);
__decorate([
    pages.State('activeStep')
], ThirdPartyModal.prototype, "activeStep", void 0);
__decorate([
    thirdParty.State('thirdPartyInEdition')
], ThirdPartyModal.prototype, "thirdPartyInEdition", void 0);
__decorate([
    thirdParty.Action('saveThirdPartyInEdition')
], ThirdPartyModal.prototype, "saveThirdPartyInEdition", void 0);
__decorate([
    thirdParty.Action('deleteThirdPartyInEdition')
], ThirdPartyModal.prototype, "deleteThirdPartyInEdition", void 0);
__decorate([
    thirdParty.Getter('validation')
], ThirdPartyModal.prototype, "validation", void 0);
__decorate([
    thirdParty.Action('validateThirdPartyInEdition')
], ThirdPartyModal.prototype, "validateThirdPartyInEdition", void 0);
ThirdPartyModal = __decorate([
    Component({
        components: {
            Modal,
            ThirdPartyForm,
            ClaimsButton,
        },
        watch: {
            hasInvoice(val) {
                if (!val) {
                    this.$emit('file-collection-update', null);
                }
            },
        },
        props: {
            title: {
                type: String,
            },
            formTitle: {
                type: String,
            },
            disableCompany: {
                type: Boolean,
            },
            isDisplayed: { type: Boolean, required: true },
            confirmDelete: {
                type: [Boolean, Function],
                default: false,
            },
            validationButtonText: {
                type: String,
                default() {
                    return this.$tc('claim.declaration.damages.validate.form');
                },
            },
            claimPayload: {
                type: Object,
                default: undefined,
            },
            fileCollection: {
                type: Object,
                default: null,
            },
            hasVehicle: {
                type: Boolean,
                default: false,
            },
            isTenantInfos: {
                type: Boolean,
                default: false,
            },
        },
    })
], ThirdPartyModal);
export default ThirdPartyModal;
