var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { AerationGrid, Antenna, Awning, Basin, Chimney, ElectricalSheath, Fence, FrontWall, GarageDoor, Gate, Gutter, VisiblePipe, LightSource, LittleGate, Pergola, Shutters, TileDisplacement, Veranda, Window, Door, } from '@declaration/components/externalDamage/DamageDescription';
let ExternalDamageDescription = class ExternalDamageDescription extends Vue {
};
ExternalDamageDescription = __decorate([
    Component({
        components: {
            AerationGrid,
            Antenna,
            Awning,
            Basin,
            Chimney,
            ElectricalSheath,
            Fence,
            FrontWall,
            GarageDoor,
            Gate,
            Gutter,
            VisiblePipe,
            LightSource,
            LittleGate,
            Pergola,
            Shutters,
            TileDisplacement,
            Veranda,
            Window,
            Door,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            descriptionComponent: { type: String, default: null },
        },
    })
], ExternalDamageDescription);
export default ExternalDamageDescription;
