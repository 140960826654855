var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS } from '@shared/claim/labels';
import { claimTypeDescriptionTradKey } from '@declaration/claim/labels';
import { CLAIM_TYPE_IMAGES } from '@shared/layout/layout';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { GLASS_BREAKAGE_TRAD_KEYS } from '@declaration/constants/glassBreakageDamageConstants';
import { INSURER } from '@shared/insurer';
const layout = namespace('layout');
const qualification = namespace('qualification');
const pages = namespace('pages');
let SplashScreen = class SplashScreen extends Vue {
    constructor() {
        super(...arguments);
        this.splashScreenPageNumber = 0;
    }
    get defaultSplashScreen() {
        return {
            image: CLAIM_TYPE_IMAGES[this.typeTrigram],
            title: this.typeTrigram === ClaimTypeTrigram.StormSnowHail
                ? this.$tc('claim.type.TNG.slashes')
                : this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.typeTrigram]),
            description: this.getDescription,
            buttonText: this.$tc('claim.declaration.qualification.splash.action'),
        };
    }
    get getDescription() {
        if (INSURER === 'generali' || INSURER === 'lamedicale') {
            if (this.typeTrigram === 'VOL') {
                return this.$tc('claim.declaration.qualification.splash.text.generali.vol');
            }
            else {
                return this.$tc('claim.declaration.qualification.splash.text', 0, {
                    type: this.$tc(claimTypeDescriptionTradKey(this.typeTrigram)),
                });
            }
        }
        else if (INSURER !== 'generali' && INSURER !== 'lamedicale') {
            if (this.typeTrigram !== 'BDG') {
                return this.$tc('claim.declaration.qualification.splash.text', 0, {
                    type: this.$tc(claimTypeDescriptionTradKey(this.typeTrigram)),
                });
            }
            else {
                return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['splashscreen-glass-message'], 0, {
                    type: this.$tc(claimTypeDescriptionTradKey(this.typeTrigram)),
                });
            }
        }
        return '';
    }
    get unavailableSplashScreen() {
        return {
            image: this.defaultSplashScreen.image,
            title: this.defaultSplashScreen.title,
            description: this.$tc('claim.declaration.qualification.splash.unavailable.text', 0, {
                type: this.$tc(claimTypeDescriptionTradKey(this.typeTrigram)),
                helpTitle: this.$tc('help.default.sub_title'),
                helpPhoneNumber: this.$tc('help.default.phone_number'),
            }),
            buttonText: this.$tc('claim.declaration.qualification.splash.unavailable.action'),
        };
    }
    get splashScreenInfoByClaimType() {
        if (INSURER === 'generali' || INSURER === 'lamedicale') {
            return {
                [ClaimTypeTrigram.Empty]: [],
                [ClaimTypeTrigram.Robbery]: [],
                [ClaimTypeTrigram.Drought]: [],
                [ClaimTypeTrigram.WaterDamage]: [],
                [ClaimTypeTrigram.Disaster]: [],
                [ClaimTypeTrigram.StormSnowHail]: [],
                [ClaimTypeTrigram.Glass]: [],
                [ClaimTypeTrigram.Fire]: [],
                [ClaimTypeTrigram.Electric]: [],
                [ClaimTypeTrigram.Vehicle]: [],
                [ClaimTypeTrigram.Other]: [],
            };
        }
        else {
            return {
                [ClaimTypeTrigram.Empty]: [],
                [ClaimTypeTrigram.Robbery]: [
                    {
                        image: require('@declaration/assets/images/documents/checklist.svg'),
                        title: this.$tc('claim.declaration.qualification.splash.robbery.title'),
                        description: this.$tc('claim.declaration.qualification.splash.robbery.text'),
                        buttonText: this.$tc('claim.declaration.qualification.splash.robbery.action'),
                    },
                ],
                [ClaimTypeTrigram.Drought]: [],
                [ClaimTypeTrigram.WaterDamage]: [],
                [ClaimTypeTrigram.Disaster]: [],
                [ClaimTypeTrigram.StormSnowHail]: [],
                [ClaimTypeTrigram.Glass]: [],
                [ClaimTypeTrigram.Fire]: [],
                [ClaimTypeTrigram.Electric]: [],
                [ClaimTypeTrigram.Vehicle]: [],
                [ClaimTypeTrigram.Other]: [],
            };
        }
    }
    get splashScreenInfos() {
        if (this.isUnavailable) {
            return [this.unavailableSplashScreen];
        }
        return [this.defaultSplashScreen].concat(this.splashScreenInfoByClaimType[this.typeTrigram]);
    }
    get splashScreenInfo() {
        if (this.splashScreenInfoData !== null) {
            return this.splashScreenInfoData;
        }
        return this.splashScreenInfos[this.splashScreenPageNumber];
    }
    get claimType() {
        return this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.typeTrigram]);
    }
    onSplashButtonClick() {
        if (this.splashScreenPageNumber + 1 >= this.splashScreenInfos.length) {
            this.$emit('close-splash-screen');
            return;
        }
        this.splashScreenPageNumber++;
        const container = this.$refs['splash-screen'];
        if (container) {
            container.scrollTop = 0;
        }
    }
};
__decorate([
    qualification.State('typeTrigram')
], SplashScreen.prototype, "typeTrigram", void 0);
__decorate([
    layout.State('color')
], SplashScreen.prototype, "color", void 0);
__decorate([
    pages.Getter('isUnavailable')
], SplashScreen.prototype, "isUnavailable", void 0);
SplashScreen = __decorate([
    Component({
        components: { ClaimsButton },
        props: {
            splashScreenInfoData: {
                type: Object,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    })
], SplashScreen);
export default SplashScreen;
