var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { RoomDamageComponent } from './RoomDamageComponent';
import RoomForm from '@declaration/components/roomDamage/surfaceDamage/RoomForm.vue';
import SurfaceDamagesForm from '@declaration/components/roomDamage/surfaceDamage/SurfaceDamagesForm.vue';
import GoodDamageCategoriesForm from '@declaration/components/roomDamage/goodDamage/GoodDamageCategoriesForm.vue';
import GoodDamageTypesForm from '@declaration/components/roomDamage/goodDamage/GoodDamageTypesForm.vue';
import GoodDamageDetailsForm from '@declaration/components/roomDamage/goodDamage/GoodDamageDetailsForm.vue';
import GoodDamageDescriptionForm from '@declaration/components/roomDamage/goodDamage/GoodDamageDescriptionForm.vue';
import { RoomDamageStep } from '@declaration/constants/roomDamageConstants';
let RoomDamageForm = class RoomDamageForm extends RoomDamageComponent {
    constructor() {
        super(...arguments);
        this.steps = RoomDamageStep;
    }
    get editing() {
        return this.goodDamageInEdition;
    }
    set editing(damageInEdition) {
        this.$emit('update:good-damage-in-edition', damageInEdition);
    }
    get localSelectedCategories() {
        return this.selectedCategories;
    }
    set localSelectedCategories(selectedCategories) {
        this.$emit('update:selectedCategories', selectedCategories);
    }
};
RoomDamageForm = __decorate([
    Component({
        components: {
            RoomForm,
            SurfaceDamagesForm,
            GoodDamageCategoriesForm,
            GoodDamageTypesForm,
            GoodDamageDetailsForm,
            GoodDamageDescriptionForm,
        },
        props: {
            step: { type: String, required: true },
            selectedCategories: { type: Array, required: true },
            goodDamageInEdition: { type: Number, required: true },
        },
    })
], RoomDamageForm);
export default RoomDamageForm;
