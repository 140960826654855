var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { WINDOW_PANE_GLASS_TYPE_CHOICES } from '@shared/constants/externalDamages';
import { WINDOW_PANE_GLASS_TYPE_TITLE } from '@declaration/constants/externalDamages';
import { WindowComponent } from '@declaration/components/externalDamage/DamageDescription/Window/WindowComponent';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
let WindowPaneGlassType = class WindowPaneGlassType extends WindowComponent {
    get windowPaneGlassTypeTitle() {
        return this.translateExternalDamageKey(WINDOW_PANE_GLASS_TYPE_TITLE);
    }
    get windowPaneGlassTypeChoices() {
        return WINDOW_PANE_GLASS_TYPE_CHOICES;
    }
};
WindowPaneGlassType = __decorate([
    Component({ components: { RadioImageButton } })
], WindowPaneGlassType);
export default WindowPaneGlassType;
