var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import CalendarDropdown from '@declaration/components/appointment/CalendarDropdown.vue';
const claim = namespace('claim');
const contract = namespace('contract');
let ExpertRecontactForAppointment = class ExpertRecontactForAppointment extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/expertByPhone.svg');
    }
    get contents() {
        var _a;
        return [
            {
                icon: 'mail',
                html: this.$tc('appointment.expert.confirmation.description.appointment'),
            },
            {
                html: this.claim.noSuitableExpertTimeSlotReason !== 'send_mission_with_contact_data_error'
                    ? this.$tc('appointment.expert.confirmation.description.expert', 0, {
                        expertCompanyName: (_a = this.claim.expert) === null || _a === void 0 ? void 0 : _a.name,
                    })
                    : this.$tc('appointment.expert.confirmation.description.expert.not_confirmed'),
            },
            {
                icon: 'file-chart',
                html: this.$tc('appointment.expert.confirmation.description.documents'),
            },
            {
                html: this.$tc('appointment.expert.confirmation.claim.case_number.summary', 0, {
                    caseNumber: this.claim.caseNumber,
                }),
            },
        ];
    }
};
__decorate([
    claim.State('claim')
], ExpertRecontactForAppointment.prototype, "claim", void 0);
__decorate([
    contract.State('selectedContract')
], ExpertRecontactForAppointment.prototype, "contract", void 0);
ExpertRecontactForAppointment = __decorate([
    Component({
        components: {
            CalendarDropdown,
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], ExpertRecontactForAppointment);
export default ExpertRecontactForAppointment;
