var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
const claim = namespace('claim');
const contract = namespace('contract');
let NoSuitableExpertiseSchedule = class NoSuitableExpertiseSchedule extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/checklist.svg');
    }
    get contents() {
        var _a, _b, _c, _d, _e;
        // No appointment api or country is outside of France
        const noApi = this.claim.noSuitableExpertTimeSlotReason === 'no_appointment_api' ||
            (((_b = (_a = this.contract) === null || _a === void 0 ? void 0 : _a.specialConditions) === null || _b === void 0 ? void 0 : _b.country) &&
                ((_d = (_c = this.contract) === null || _c === void 0 ? void 0 : _c.specialConditions) === null || _d === void 0 ? void 0 : _d.country) !== 'FR');
        return [
            {
                icon: 'mail',
                html: this.$tc('compensation.confirmation.description.mail'),
            },
            {
                icon: 'person',
                html: !noApi // Normal case : expert has api but got an error or has no time slot available
                    ? this.$tc('compensation.claim.no_suitable_expertise_schedule.description', 0, {
                        nomExpert: (_e = this.claim.expert) === null || _e === void 0 ? void 0 : _e.name,
                    }) // AGPM case : expert has no appointment api
                    : this.$tc('compensation.claim.no_suitable_expertise_schedule.description.no_api'),
            },
            {
                html: this.$tc('claim.case_number.summary', 0, { caseNumber: this.claim.caseNumber }),
            },
        ];
    }
};
__decorate([
    claim.State('claim')
], NoSuitableExpertiseSchedule.prototype, "claim", void 0);
__decorate([
    contract.State('selectedContract')
], NoSuitableExpertiseSchedule.prototype, "contract", void 0);
NoSuitableExpertiseSchedule = __decorate([
    Component({
        components: {
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], NoSuitableExpertiseSchedule);
export default NoSuitableExpertiseSchedule;
