var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { ELECTRICAL_DAMAGED_EQUIPMENT_NUMBER_CHOICES } from '@declaration/constants/electricalDamageConstants';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
const claim = namespace('claim');
const pages = namespace('pages');
const counting = namespace('counting');
const qualification = namespace('qualification');
const personalProperty = namespace('personalProperty');
let ElectricalNbrOfEquipmentsQuestion = class ElectricalNbrOfEquipmentsQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.numberOfDamagedEquipments = ELECTRICAL_DAMAGED_EQUIPMENT_NUMBER_CHOICES;
    }
    get numberOfEquipments() {
        var _a, _b;
        return (_b = (_a = this.qualification.numberOfDamagedEquipments) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : null;
    }
    set numberOfEquipments(value) {
        if (value === null) {
            return;
        }
        const numberOfDamagedEquipments = parseInt(value);
        const electricalDamageQualification = {
            ...this.qualification,
            numberOfDamagedEquipments,
        };
        const counting = {
            ...this.counting,
        };
        const step = this.step;
        if (numberOfDamagedEquipments === 0) {
            counting.electricalDamages = [];
            counting.personalProperties = counting.personalProperties.filter((pp) => optionalResourceIri(pp.category) ===
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        }
        this.saveClaimWithPayload({ electricalDamageQualification, counting, step });
    }
    get answer() {
        if (this.qualification.numberOfDamagedEquipments === null) {
            return null;
        }
        return this.$tc('claim.qualification.electrical.damaged_equipments_number.answer', this.qualification.numberOfDamagedEquipments);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ElectricalNbrOfEquipmentsQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], ElectricalNbrOfEquipmentsQuestion.prototype, "qualification", void 0);
__decorate([
    counting.State('counting')
], ElectricalNbrOfEquipmentsQuestion.prototype, "counting", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], ElectricalNbrOfEquipmentsQuestion.prototype, "categoriesByIri", void 0);
__decorate([
    claim.Action('saveClaimWithPayload')
], ElectricalNbrOfEquipmentsQuestion.prototype, "saveClaimWithPayload", void 0);
__decorate([
    pages.Getter('previousStep')
], ElectricalNbrOfEquipmentsQuestion.prototype, "previousStep", void 0);
ElectricalNbrOfEquipmentsQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], ElectricalNbrOfEquipmentsQuestion);
export default ElectricalNbrOfEquipmentsQuestion;
