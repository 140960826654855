var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let AgeInput = class AgeInput extends Vue {
    constructor() {
        super(...arguments);
        this.internalUnit = 'year';
    }
    created() {
        if (!this.unit && this.value) {
            this.internalUnit = this.value % 12 === 0 ? 'year' : 'month';
        }
    }
    get unitValue() {
        return this.internalUnit;
    }
    set unitValue(unit) {
        const age = this.ageValue;
        this.internalUnit = unit;
        this.ageValue = age;
        this.$emit('update:unit', unit);
    }
    get unitOutputValue() {
        var _a, _b;
        return (_b = (_a = this.unitOutput) !== null && _a !== void 0 ? _a : this.unit) !== null && _b !== void 0 ? _b : 'month';
    }
    get ageValue() {
        if (this.value === null)
            return '';
        else if (this.unitOutputValue === this.unitValue)
            return this.value;
        else if (this.unitOutputValue === 'year')
            return this.value * 12;
        else
            return this.value / 12;
    }
    set ageValue(age) {
        let value = age || 0;
        if (this.unitOutputValue !== this.unitValue) {
            if (this.unitOutputValue === 'year')
                value = value / 12;
            else
                value = value * 12;
        }
        this.$emit('input', value);
    }
};
AgeInput = __decorate([
    Component({
        props: {
            value: { type: Number, default: null },
            unit: { type: String, default: null },
            unitOutput: { type: String, default: null },
            unitEditable: { type: Boolean, default: false },
        },
    })
], AgeInput);
export default AgeInput;
