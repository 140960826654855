var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { computeProgressBarDotOffsets, computeProgressBarProgression, } from '@declaration/utils/progressBar';
const layout = namespace('layout');
const qualification = namespace('qualification');
let ProgressBar = class ProgressBar extends Vue {
    get completionWidth() {
        return `${this.progressBarCompletion}%`;
    }
    get progressBarCompletion() {
        return computeProgressBarProgression(this.claimTypeTrigram, this.sections.length, Object.values(this.sections).map(({ componentStack }) => componentStack.length));
    }
    get progressBarSectionsDotOffsets() {
        return computeProgressBarDotOffsets(this.claimTypeTrigram);
    }
    get backgroundColor() {
        if (this.isMobile) {
            // Same color as header
            return this.color.headerBackgroundColor;
        }
        // Same as queestion aside
        return this.color.questionsAsideBackgroundColor;
    }
    get progressBackgroundColor() {
        return this.isMobile
            ? this.color.progressBarBackgroundColorMobile
            : this.color.progressBarBackgroundColor;
    }
    get incompleteBackgroundColor() {
        return this.isMobile
            ? this.color.progressBarIncompleteBackgroundColorMobile
            : this.color.progressBarIncompleteBackgroundColor;
    }
    get dotBackgroundColor() {
        return this.isMobile
            ? this.color.progressBarDotBackgroundColorMobile
            : this.color.progressBarDotBackgroundColor;
    }
};
__decorate([
    qualification.State('typeTrigram')
], ProgressBar.prototype, "claimTypeTrigram", void 0);
__decorate([
    layout.State('color')
], ProgressBar.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], ProgressBar.prototype, "isMobile", void 0);
ProgressBar = __decorate([
    Component({
        props: {
            sections: {
                type: Array,
                required: true,
            },
        },
    })
], ProgressBar);
export default ProgressBar;
