var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { RoomDamageComponent } from '../RoomDamageComponent';
import SurfaceSlider from '@declaration/components/roomDamage/surfaceDamage/SurfaceSlider.vue';
import SurfaceDamagesInput from '@declaration/components/roomDamage/surfaceDamage/SurfaceDamagesInput.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { SurfaceType } from '@shared/types/api/roomDamage';
import { ClaimFileType } from '@shared/types/file';
let SurfaceDamagesForm = class SurfaceDamagesForm extends RoomDamageComponent {
    constructor() {
        super(...arguments);
        this.surfaceTypeLabels = SurfaceType;
        this.fileType = ClaimFileType.RoomDamage;
    }
    get pictureCollection() {
        return this.roomDamage.pictureCollection;
    }
    set pictureCollection(pictureCollection) {
        this.updateRoomDamage({ pictureCollection });
    }
};
SurfaceDamagesForm = __decorate([
    Component({
        components: { SurfaceSlider, SurfaceDamagesInput, PictureCollectionUpload },
    })
], SurfaceDamagesForm);
export default SurfaceDamagesForm;
