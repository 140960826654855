var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
let IsRehousingNeededQuestion = class IsRehousingNeededQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
    }
    updateClaim(isRehousingNeeded) {
        this.updateAndSaveQualification({
            qualificationPayload: { isRehousingNeeded },
            step: this.step,
        });
    }
    get answer() {
        if (this.qualification.isRehousingNeeded === null) {
            return null;
        }
        return this.qualification.isRehousingNeeded
            ? this.$tc('claim.qualification.rehousing.sumup.yes')
            : this.$tc('claim.qualification.rehousing.sumup.no');
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink, this.typeTrigram);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.isRehousingNeeded) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], IsRehousingNeededQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], IsRehousingNeededQuestion.prototype, "typeTrigram", void 0);
__decorate([
    qualification.State('qualification')
], IsRehousingNeededQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], IsRehousingNeededQuestion.prototype, "updateAndSaveQualification", void 0);
IsRehousingNeededQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], IsRehousingNeededQuestion);
export default IsRehousingNeededQuestion;
