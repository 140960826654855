var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Subject } from 'rxjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PersonalPropertyForm from '@declaration/components/personalProperty/PersonalPropertyForm.vue';
import PersonalPropertyReparationForm from '@declaration/components/personalProperty/PersonalPropertyReparationForm.vue';
import ProductSearch from '@declaration/components/personalProperty/ProductSearch.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { PERSONAL_PROPERTY_QUESTION_TRAD_KEYS, } from '@declaration/types/personalProperty';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES } from '@shared/constants/personalPropertyConstants';
import { BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY, BASE_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY, INC_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY, INITIAL_PERSONAL_PROPERTY_CATEGORIES_OTHER, } from '@declaration/constants/personalPropertyConstants';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { BDGPersonalPropertyInitialCategorySlug, RepairableStatus, } from '@shared/types/api/personalProperty';
import { isGlassBreakagePersonalProperty } from '@shared/personalProperty/personalPropertyFields';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
const layout = namespace('layout');
var Section;
(function (Section) {
    Section["InitialCategorySelection"] = "InitialCategorySelection";
    Section["ProductSearch"] = "ProductSearch";
    Section["FormFill"] = "FormFill";
    Section["ReparationFormFill"] = "ReparationFormFill";
})(Section || (Section = {}));
let PersonalPropertyModal = class PersonalPropertyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.searchQuery = '';
        this.isDeleting = false;
        this.fillReparations = false;
        this.SECTIONS = {
            initialCategorySelection: Section.InitialCategorySelection,
            productSearch: Section.ProductSearch,
            formFill: Section.FormFill,
            reparationFormFill: Section.ReparationFormFill,
        };
        this.scrollToTop$ = new Subject();
    }
    get initialPersonalPropertyCategories() {
        switch (this.typeTrigram) {
            case ClaimTypeTrigram.Glass:
                return BDG_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY;
            case ClaimTypeTrigram.Fire:
                return INC_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY;
            default:
                return BASE_INITIAL_PERSONAL_PROPERTY_CATEGORIES_ARRAY;
        }
    }
    get initialPersonalPropertyCategoryOtherAsButton() {
        if (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyOtherAsExtraButton)) {
            return INITIAL_PERSONAL_PROPERTY_CATEGORIES_OTHER;
        }
        return false;
    }
    created() {
        this.$watch('activeSection', () => {
            this.scrollToTop$.next(undefined);
        });
        this.$watch('isDisplayed', (isDisplayed) => {
            if (!isDisplayed) {
                this.searchQuery = '';
            }
            else if (isGlassBreakagePersonalProperty(this.personalProperty)) {
                this.updatePersonalProperty({
                    changeKey: this.changeKey,
                    data: {
                        windowReparation: typeof this.personalProperty.windowReparation === 'boolean'
                            ? this.personalProperty.windowReparation
                            : undefined,
                    },
                });
            }
        });
    }
    get isMultiStep() {
        return (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.InitialPersonalPropertyCategoryChoice) || this.isRepairable);
    }
    get hasGoBackButton() {
        return this.isMultiStep && this.activeSection !== Section.InitialCategorySelection;
    }
    get activeSection() {
        if (this.shouldSelectInitialCategory) {
            return Section.InitialCategorySelection;
        }
        else if (this.shouldSearchProduct) {
            return Section.ProductSearch;
        }
        else if (this.shouldFillReparations) {
            return Section.ReparationFormFill;
        }
        return Section.FormFill;
    }
    goBack() {
        switch (this.activeSection) {
            case Section.FormFill:
            case Section.ProductSearch:
                this.updatePersonalProperty({
                    changeKey: this.changeKey,
                    data: { initialCategorySlug: null },
                });
                break;
            case Section.ReparationFormFill:
                this.fillReparations = false;
                break;
        }
    }
    get contentBackground() {
        switch (this.activeSection) {
            case Section.FormFill:
                return this.color.personalPropertyModalFormBackgroundColor;
            case Section.ReparationFormFill:
                return this.color.personalPropertyModalFormBackgroundColor;
            case Section.InitialCategorySelection:
                return this.color.personalPropertyModalInitialCategorySelectionBackgroundColor;
            case Section.ProductSearch:
                return this.color.personalPropertyModalSearchBackgroundColor;
        }
    }
    get shouldSelectInitialCategory() {
        return (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.InitialPersonalPropertyCategoryChoice) && !this.personalProperty.initialCategorySlug);
    }
    get shouldFillReparations() {
        return this.isRepairable && this.fillReparations;
    }
    get isRepairable() {
        return (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyReparationForm) &&
            [
                BDGPersonalPropertyInitialCategorySlug.InsertDeCheminee,
                BDGPersonalPropertyInitialCategorySlug.Miroir,
                BDGPersonalPropertyInitialCategorySlug.Meuble,
            ].includes(this.personalProperty.initialCategorySlug));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get headerImageSource() {
        return this.personalProperty.initialCategorySlug
            ? INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.personalProperty.initialCategorySlug].imageUrl
            : null;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get initialCategoryChoiceTitle() {
        if (this.typeTrigram === ClaimTypeTrigram.Electric) {
            return this.$tc('claim.damage.personal_property.initial_category.title.electric');
        }
        else if (this.typeTrigram === ClaimTypeTrigram.Robbery) {
            return this.$tc('claim.damage.personal_property.initial_category.title.robbery');
        }
        return this.$tc('claim.damage.personal_property.initial_category.title');
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validatePersonalProperty(this.changeKey);
    }
    get tradKeys() {
        return PERSONAL_PROPERTY_QUESTION_TRAD_KEYS[this.typeTrigram];
    }
    get headerTitle() {
        if (this.shouldSelectInitialCategory) {
            return this.$tc('claim.damage.personal_property.modal.new');
        }
        if (this.personalProperty.initialCategorySlug) {
            return this.$tc(INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.personalProperty.initialCategorySlug]
                .labelTradKey);
        }
        return this.$tc(this.tradKeys.form.title, this.change.data.designation ? 1 : 2, {
            designation: this.change.data.designation,
            number: this.personalPropertyIndex + 1,
        });
    }
    get isNotValid() {
        return !this.validation.isValid;
    }
    get shouldSearchProduct() {
        return (!this.shouldSelectInitialCategory &&
            this.change.data.thirdPartyProductId === null &&
            this.change.data.designation === null &&
            (!this.change.data.initialCategorySlug ||
                INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.change.data.initialCategorySlug]
                    .isProductSearchAvailable));
    }
    get isCategoryModifiable() {
        return this.isCategoryModifiableGetter(this.changeKey);
    }
    closeModal() {
        this.fillReparations = false;
        this.$emit('close');
    }
    async save() {
        this.isSaving = true;
        try {
            await this.saveChanges();
            this.closeModal();
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isSaving = false;
    }
    confirmDeletion() {
        if (this.changeKey === 0) {
            // Creating new personal property
            this.closeModal();
        }
        if (typeof this.changeKey !== 'string') {
            return;
        }
        const deleteCallback = async () => {
            this.isDeleting = true;
            await this.deletePersonalProperty(this.changeKey);
            // Delete personal property from backend if it is persisted
            if (this.changeKey !== null) {
                await this.saveChanges();
            }
            this.closeModal();
            this.isDeleting = false;
        };
        this.confirmDelete(this.changeKey, deleteCallback);
    }
    onProductSelected({ product, createdProduct, }) {
        const repairableStatus = () => {
            if (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertiesIrreparableStatusDefault)) {
                return { repairableStatus: RepairableStatus.Irreparable };
            }
        };
        this.updatePersonalProperty({
            changeKey: this.changeKey,
            data: {
                description: product.name,
                designation: product.name,
                thirdPartyProductId: createdProduct.itemId,
                category: createdProduct.personalPropertyCategory,
                thirdPartyProductInfo: { ...product, ...createdProduct },
                userKnowPrice: null,
                purchaseCertification: false,
                ...repairableStatus(),
            },
        });
    }
    onMissingProduct(productName) {
        if (this.isCategoryModifiable) {
            this.updatePersonalProperty({
                changeKey: this.changeKey,
                data: {
                    designation: productName,
                    userKnowPrice: null,
                    category: null,
                },
            });
        }
        else {
            this.updatePersonalProperty({
                changeKey: this.changeKey,
                data: {
                    designation: productName,
                    userKnowPrice: null,
                },
            });
        }
    }
    selectInitialCategory(categorySlug) {
        this.selectWithInitialCategory({
            changeKey: this.changeKey,
            category: categorySlug,
        });
    }
    describeReparations() {
        if (isGlassBreakagePersonalProperty(this.personalProperty)) {
            this.updatePersonalProperty({
                changeKey: this.changeKey,
                data: {
                    windowReparation: this.personalProperty.windowReparation === undefined
                        ? null
                        : this.personalProperty.windowReparation,
                },
            });
            this.untouchField({ changeKey: this.changeKey, fieldName: 'windowReparation' });
        }
        this.fillReparations = true;
    }
};
__decorate([
    layout.State('color')
], PersonalPropertyModal.prototype, "color", void 0);
__decorate([
    claim.State('claim')
], PersonalPropertyModal.prototype, "claim", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyModal.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyModal.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('isCategoryModifiable')
], PersonalPropertyModal.prototype, "isCategoryModifiableGetter", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyModal.prototype, "typeTrigram", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], PersonalPropertyModal.prototype, "saveChanges", void 0);
__decorate([
    personalProperty.Action('validateAndRevealDamages')
], PersonalPropertyModal.prototype, "validatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyModal.prototype, "updatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('selectWithInitialCategory')
], PersonalPropertyModal.prototype, "selectWithInitialCategory", void 0);
__decorate([
    personalProperty.Action('deleteDamage')
], PersonalPropertyModal.prototype, "deletePersonalProperty", void 0);
__decorate([
    personalProperty.Mutation('UNTOUCH_FIELD')
], PersonalPropertyModal.prototype, "untouchField", void 0);
PersonalPropertyModal = __decorate([
    Component({
        components: {
            Modal,
            PersonalPropertyForm,
            PersonalPropertyReparationForm,
            ClaimsButton,
            ProductSearch,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
            isDisplayed: { type: Boolean, required: true },
            confirmDelete: { type: Function, required: true },
            personalPropertyIndex: {
                type: Number,
                required: false,
            },
        },
    })
], PersonalPropertyModal);
export default PersonalPropertyModal;
