var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { LostKeysLockType, } from '@shared/types/api/claim';
import { Step } from '@shared/claim/steps';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
import OutlinedNumberInput from '@declaration/components/OutlinedNumberInput.vue';
import { ROBBERY_LOST_KEYS_LOCK_TYPE_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { INSURER_CONFIG } from '@shared/insurer';
import { disabledLockTypesByInsurer } from '@shared/utils/disabledLockTypesByInsurer';
const qualification = namespace('qualification');
let RobberyLostKeysLockSelectionQuestion = class RobberyLostKeysLockSelectionQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.lostKeysLocks = {};
        this.otherLocksTypeDescription = '';
        this.displayOtherLocksTypeDescription = false;
        this.ClaimFileType = ClaimFileType;
        this.LOCKS_REPLACEMENT_QUOTE = ClaimFileType.LocksReplacementQuote;
        this.locksReplacementQuoteFileCollection = null;
    }
    created() {
        var _a, _b, _c, _d;
        this.lostKeysLocks = { ...((_a = this.qualification.lostKeysLocks) !== null && _a !== void 0 ? _a : null) };
        if (((_b = this.lostKeysLocks[LostKeysLockType.Other]) !== null && _b !== void 0 ? _b : 0) > 0) {
            this.displayOtherLocksTypeDescription = true;
        }
        this.otherLocksTypeDescription = (_c = this.qualification.lostKeysOtherLocksTypeDescription) !== null && _c !== void 0 ? _c : '';
        this.locksReplacementQuoteFileCollection =
            (_d = this.qualification.locksReplacementQuoteFileCollection) !== null && _d !== void 0 ? _d : null;
    }
    get answer() {
        if (this.storelocksKeysLocksCount === 1) {
            return this.$tc('claim.qualification.robbery.lost_keys.lock_selection.answer.one');
        }
        return this.$tc('claim.qualification.robbery.lost_keys.lock_selection.answer.many', 1, {
            lockCount: this.storelocksKeysLocksCount,
        });
    }
    get locksKeysLocksCount() {
        let count = 0;
        Object.values(this.lostKeysLocks).forEach((lockTypeCount) => {
            count += lockTypeCount !== null && lockTypeCount !== void 0 ? lockTypeCount : 0;
        });
        return count;
    }
    get storelocksKeysLocksCount() {
        var _a;
        let count = 0;
        Object.values((_a = this.qualification.lostKeysLocks) !== null && _a !== void 0 ? _a : {}).forEach((lockTypeCount) => {
            count += lockTypeCount !== null && lockTypeCount !== void 0 ? lockTypeCount : 0;
        });
        return count;
    }
    get canValidate() {
        return this.locksKeysLocksCount >= 1;
    }
    get lockTypeValuesAndTradKeys() {
        return Object.entries(ROBBERY_LOST_KEYS_LOCK_TYPE_TRAD_KEYS)
            .map(([slug, key]) => {
            var _a;
            return {
                value: slug,
                tradKey: key,
                count: (_a = this.lostKeysLocks[slug]) !== null && _a !== void 0 ? _a : 0,
            };
        })
            .filter((entry) => {
            const currentInsurerMapping = disabledLockTypesByInsurer.filter((map) => map.insurer === INSURER_CONFIG.id)[0];
            return !currentInsurerMapping.disabledLockTypes.includes(entry.value);
        });
    }
    updateLockCountForType({ count, type }) {
        // lostKeysLocks est pas réactif -> forcer vue à se mettre à jour
        Vue.set(this.lostKeysLocks, type, count);
        if (type === LostKeysLockType.Other) {
            this.displayOtherLocksTypeDescription = count > 0;
        }
    }
    onFileCollectionUpdate(fileCollection) {
        this.locksReplacementQuoteFileCollection = fileCollection;
    }
    updateClaim() {
        var _a;
        const payload = {
            lostKeysLocks: this.lostKeysLocks,
            locksReplacementQuoteFileCollection: this.locksReplacementQuoteFileCollection,
        };
        if (((_a = this.lostKeysLocks[LostKeysLockType.Other]) !== null && _a !== void 0 ? _a : 0) > 0) {
            payload.lostKeysOtherLocksTypeDescription = this.otherLocksTypeDescription;
        }
        this.updateAndSaveQualification({
            qualificationPayload: payload,
            step: Step.RobberyLostKeysLockSelection,
        });
    }
};
__decorate([
    qualification.State('qualification')
], RobberyLostKeysLockSelectionQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyLostKeysLockSelectionQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyLostKeysLockSelectionQuestion = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton, FileCollectionUpload, OutlinedNumberInput },
        props: QuestionProps,
    })
], RobberyLostKeysLockSelectionQuestion);
export default RobberyLostKeysLockSelectionQuestion;
