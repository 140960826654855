var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { getProtectionMean } from '@shared/contract/protectionMeans';
const claim = namespace('claim');
const contract = namespace('contract');
const qualification = namespace('qualification');
let RobberyProtectionMeansQuestion = class RobberyProtectionMeansQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.areProtectionMeansLoaded = false;
        this.clickedProtectionMeans = new Set();
    }
    get questionTitle() {
        return this.$tc('claim.qualification.robbery.protection_means.title');
    }
    get protectionMeanChoices() {
        return (this.allProtectionMeans ? this.allProtectionMeans : []).map((mean) => ({
            value: mean.id,
            label: mean.designation,
        }));
    }
    get protectionMeans() {
        var _a;
        return (_a = this.qualification.protectionMeans) !== null && _a !== void 0 ? _a : [];
    }
    set protectionMeans(protectionMeans) {
        this.updateRobberyDamageQualification({ qualificationPayload: { protectionMeans } });
    }
    get protectionMeanString() {
        var _a, _b, _c, _d;
        if (!this.protectionMeans || !this.allProtectionMeans) {
            return '';
        }
        if (this.protectionMeans.length === 1) {
            return ((_b = (_a = getProtectionMean(this.protectionMeans[0], this.allProtectionMeans)) === null || _a === void 0 ? void 0 : _a.shortDescription.toLowerCase()) !== null && _b !== void 0 ? _b : '');
        }
        const lastProtectionMeanString = this.$tc((_d = (_c = getProtectionMean(this.protectionMeans[this.protectionMeans.length - 1], this.allProtectionMeans)) === null || _c === void 0 ? void 0 : _c.shortDescription.toLowerCase()) !== null && _d !== void 0 ? _d : '').toLowerCase();
        const firstProtectionMeansString = this.protectionMeans
            .slice(0, this.protectionMeans.length - 1)
            .map((protectionMean) => {
            var _a, _b;
            return (_b = (_a = getProtectionMean(protectionMean, this.allProtectionMeans ? this.allProtectionMeans : [])) === null || _a === void 0 ? void 0 : _a.shortDescription) !== null && _b !== void 0 ? _b : '';
        })
            .join(', ')
            .toLowerCase();
        return `${firstProtectionMeansString} ${this.$tc('and')} ${lastProtectionMeanString}`;
    }
    get canValidate() {
        var _a, _b;
        return ((_b = (_a = this.protectionMeans) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0;
    }
    get answer() {
        if (this.protectionMeans === null) {
            return null;
        }
        if (this.protectionMeans.length === 0) {
            return this.$tc('claim.qualification.robbery.protection_means.answer', 1, {
                protectionMeans: '...',
            });
        }
        if (this.protectionMeans.length === 1) {
            return this.$tc('claim.qualification.robbery.protection_means.answer', 1, {
                protectionMeans: this.protectionMeanString,
            });
        }
        return this.$tc('claim.qualification.robbery.protection_means.answer', 1, {
            protectionMeans: this.protectionMeanString,
        });
    }
    async validate() {
        await this.updateAndSaveRobberyDamageQualification({
            qualificationPayload: { protectionMeans: this.protectionMeans },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyProtectionMeansQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    contract.State('protectionMeans')
], RobberyProtectionMeansQuestion.prototype, "allProtectionMeans", void 0);
__decorate([
    qualification.State('qualification')
], RobberyProtectionMeansQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyProtectionMeansQuestion.prototype, "updateRobberyDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyProtectionMeansQuestion.prototype, "updateAndSaveRobberyDamageQualification", void 0);
RobberyProtectionMeansQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], RobberyProtectionMeansQuestion);
export default RobberyProtectionMeansQuestion;
