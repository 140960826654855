var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { hasFeature } from '@shared/featureFlags/featureFlags';
import { InsurerFeatureFlag } from '@shared/featureFlags/insurerFeatureFlags';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
const qualification = namespace('qualification');
let WaintingForManagement = class WaintingForManagement extends Vue {
    get image() {
        return require('@declaration/assets/images/appointment/managerCall.svg');
    }
    get waitingForManagementWording2() {
        return hasFeature(InsurerFeatureFlag.DisplayWaitingForManagementWording2, this.typeTrigram);
    }
    get title() {
        return this.waitingForManagementWording2
            ? this.$tc('waiting_for_management.title.wording2')
            : this.$tc('waiting_for_management.title');
    }
    get message() {
        return this.waitingForManagementWording2
            ? this.$tc('waiting_for_management.message.wording2')
            : this.$tc('waiting_for_management.message');
    }
};
__decorate([
    qualification.State('typeTrigram')
], WaintingForManagement.prototype, "typeTrigram", void 0);
WaintingForManagement = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], WaintingForManagement);
export default WaintingForManagement;
