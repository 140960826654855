var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
let InformationModal = class InformationModal extends Vue {
    get title_() {
        return (this.title || this.$tc('claim.declaration.section.break_in_damages.information.modal.title'));
    }
    get buttonText() {
        return this.$tc('claim.declaration.section.break_in_damages.information.modal.button');
    }
    closeModal() {
        this.$emit('close');
    }
};
InformationModal = __decorate([
    Component({
        components: {
            Modal,
            ClaimsButton,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            text: { type: String, required: true },
            title: { type: String, required: true },
        },
    })
], InformationModal);
export default InformationModal;
