var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let SurfaceSlider = class SurfaceSlider extends Vue {
    constructor() {
        super(...arguments);
        this.dragging = false;
    }
    roundToHalfInteger(number) {
        return Math.round(number * 2) / 2;
    }
    get position() {
        if (this.value === null) {
            return 50;
        }
        return ((this.value - this.min) / (this.max - this.min)) * 100;
    }
    get tooltipText() {
        const displayedValue = this.value || this.roundToHalfInteger((this.max - this.min) / 2 + this.min);
        return `${displayedValue}m2`;
    }
    get positionStyle() {
        return { left: this.position + '%' };
    }
    setValueFromPosition(percent) {
        if (percent === null || isNaN(percent))
            return;
        if (percent < 0) {
            percent = 0;
        }
        else if (percent > 100) {
            percent = 100;
        }
        const value = this.roundToHalfInteger((percent / 100) * (this.max - this.min) + this.min);
        this.$emit('value-change', value);
    }
    updatePositionWithEvent(event) {
        if (event.type === 'touchstart') {
            event.clientX = event.touches[0].clientX;
        }
        const sliderBar = this.$refs.sliderBar;
        const percent = ((event.clientX - sliderBar.getBoundingClientRect().left) / (sliderBar.clientWidth || 1)) *
            100;
        this.setValueFromPosition(percent);
    }
    onSliderBarDown(event) {
        this.updatePositionWithEvent(event);
        this.onTickDown(event);
    }
    onTickDown(event) {
        event.preventDefault();
        this.dragging = true;
        document.addEventListener('mousemove', this.onDragging);
        document.addEventListener('touchmove', this.onDragging);
        document.addEventListener('mouseup', this.onDragEnd);
        document.addEventListener('touchend', this.onDragEnd);
        document.addEventListener('contextmenu', this.onDragEnd);
    }
    onDragging(event) {
        if (this.dragging) {
            if (event.type === 'touchmove') {
                event.clientX = event.touches[0].clientX;
            }
            const sliderBar = this.$refs.sliderBar;
            const percent = ((event.clientX - sliderBar.getBoundingClientRect().left) / (sliderBar.clientWidth || 1)) *
                100;
            this.setValueFromPosition(percent);
        }
    }
    onDragEnd() {
        this.dragging = false;
        document.removeEventListener('mousemove', this.onDragging);
        document.removeEventListener('touchmove', this.onDragging);
        document.removeEventListener('mouseup', this.onDragEnd);
        document.removeEventListener('touchend', this.onDragEnd);
        document.removeEventListener('contextmenu', this.onDragEnd);
    }
};
SurfaceSlider = __decorate([
    Component({
        props: {
            value: {
                required: true,
            },
            min: {
                type: Number,
                required: true,
            },
            max: {
                type: Number,
                required: true,
            },
        },
    })
], SurfaceSlider);
export default SurfaceSlider;
