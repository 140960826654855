var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from './GoodDamageComponent';
import WorkplanDescription from '@declaration/components/roomDamage/goodDamage/description/WorkplanDescription.vue';
import LockerDescription from '@declaration/components/roomDamage/goodDamage/description/LockerDescription.vue';
import LightSourceDescription from '@declaration/components/roomDamage/goodDamage/description/LightSourceDescription.vue';
import ExposedElectricalSheathDescription from '@declaration/components/roomDamage/goodDamage/description/ExposedElectricalSheathDescription.vue';
import ElectricMeterDescription from '@declaration/components/roomDamage/goodDamage/description/ElectricMeterDescription.vue';
import VisiblePipeDescription from '@declaration/components/roomDamage/goodDamage/description/VisiblePipesDescription.vue';
import WcDescription from '@declaration/components/roomDamage/goodDamage/description/WcDescription.vue';
import ElectricalHeaterDescription from '@declaration/components/roomDamage/goodDamage/description/ElectricalHeaterDescription.vue';
import TowelDryerDescription from '@declaration/components/roomDamage/goodDamage/description/TowelDryerDescription.vue';
import WaterRadiatorDescription from '@declaration/components/roomDamage/goodDamage/description/WaterRadiatorDescription.vue';
import SlidingBayDescription from '@declaration/components/roomDamage/goodDamage/description/SlidingBayDescription.vue';
import GarageDoorDescription from '@declaration/components/roomDamage/goodDamage/description/GarageDoorDescription.vue';
import DoorDescription from '@declaration/components/roomDamage/goodDamage/description/DoorDescription.vue';
import WindowDescription from '@declaration/components/roomDamage/goodDamage/description/WindowDescription.vue';
import FrenchWindowDescription from '@declaration/components/roomDamage/goodDamage/description/FrenchWindowDescription.vue';
import { GoodDamageType } from '@shared/types/api/roomDamage';
let GoodDamageDescriptionForm = class GoodDamageDescriptionForm extends GoodDamageComponent {
    get component() {
        var _a;
        switch ((_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.type) {
            case GoodDamageType.Workplan:
                return WorkplanDescription;
            case GoodDamageType.Locker:
                return LockerDescription;
            case GoodDamageType.LightSource:
                return LightSourceDescription;
            case GoodDamageType.ExposedElectricalSheath:
                return ExposedElectricalSheathDescription;
            case GoodDamageType.ElectricMeter:
                return ElectricMeterDescription;
            case GoodDamageType.VisiblePipes:
                return VisiblePipeDescription;
            case GoodDamageType.WC:
                return WcDescription;
            case GoodDamageType.ElectricHeater:
                return ElectricalHeaterDescription;
            case GoodDamageType.TowelDryer:
                return TowelDryerDescription;
            case GoodDamageType.WaterRadiator:
                return WaterRadiatorDescription;
            case GoodDamageType.SlidingBay:
                return SlidingBayDescription;
            case GoodDamageType.GarageDoor:
                return GarageDoorDescription;
            case GoodDamageType.Door:
                return DoorDescription;
            case GoodDamageType.Window:
                return WindowDescription;
            case GoodDamageType.FrenchWindow:
                return FrenchWindowDescription;
        }
        return null;
    }
};
GoodDamageDescriptionForm = __decorate([
    Component
], GoodDamageDescriptionForm);
export default GoodDamageDescriptionForm;
