var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, VModel } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { DOOR_GLASS_TYPE_CHOICES } from '@shared/constants/externalDamages/DoorConstants';
import { DOOR_GLASS_TYPE_TITLE } from '@declaration/constants/externalDamages/DoorConstants';
let DoorGlass = class DoorGlass extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.glassChoices = DOOR_GLASS_TYPE_CHOICES;
    }
    get title() {
        return this.translateExternalDamageKey(DOOR_GLASS_TYPE_TITLE);
    }
};
__decorate([
    VModel({ type: String, default: () => null })
], DoorGlass.prototype, "doorGlassType", void 0);
DoorGlass = __decorate([
    Component({ components: { RadioImageButton } })
], DoorGlass);
export default DoorGlass;
