var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FireCauseCommonPartsOrigin } from '@shared/types/api/claim';
import { FIRE_CAUSE_COMMON_PARTS_IMAGE_CHOICES, FIRE_CAUSE_COMMON_PARTS_ANSWER, } from '@declaration/constants/fireDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let FireCauseCommonPartsOriginQuestion = class FireCauseCommonPartsOriginQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get fireCauseCommonPartsOriginChoices() {
        return FIRE_CAUSE_COMMON_PARTS_IMAGE_CHOICES;
    }
    get selectedValue() {
        return this.qualification.fireCauseCommonPartsOrigin;
    }
    get answer() {
        return this.qualification.fireCauseCommonPartsOrigin
            ? this.$tc(FIRE_CAUSE_COMMON_PARTS_ANSWER[this.qualification.fireCauseCommonPartsOrigin])
            : null;
    }
    get validateText() {
        if (this.qualification.fireCauseCommonPartsOrigin === FireCauseCommonPartsOrigin.dontKnow ||
            this.qualification.fireCauseCommonPartsOrigin === null) {
            return this.$tc('claim.damage.fire.button.dont_know');
        }
        return this.$tc('claim.damage.fire.button.next_step');
    }
    onTypeOfDamagesClick(fireCauseCommonPartsOrigin) {
        this.updateFireDamageQualification({
            qualificationPayload: {
                fireCauseCommonPartsOrigin: fireCauseCommonPartsOrigin !== this.qualification.fireCauseCommonPartsOrigin
                    ? fireCauseCommonPartsOrigin
                    : null,
            },
        });
    }
    async validate() {
        if (this.qualification.fireCauseCommonPartsOrigin === null) {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireCauseCommonPartsOrigin: FireCauseCommonPartsOrigin.dontKnow,
                },
                step: this.step,
            });
        }
        await this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireCauseCommonPartsOrigin: this.qualification.fireCauseCommonPartsOrigin,
            },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireCauseCommonPartsOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireCauseCommonPartsOriginQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireCauseCommonPartsOriginQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireCauseCommonPartsOriginQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireCauseCommonPartsOriginQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], FireCauseCommonPartsOriginQuestion);
export default FireCauseCommonPartsOriginQuestion;
