var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PersonalPropertyProduct from '@declaration/components/personalProperty/v2/form/PersonalPropertyProduct.vue';
import PersonalPropertyPurchase from '@declaration/components/personalProperty/v2/form/PersonalPropertyPurchase.vue';
import PersonalPropertyInvoice from '@declaration/components/personalProperty/v2/form/PersonalPropertyInvoice.vue';
import PersonalPropertyPictures from '@declaration/components/personalProperty/v2/form/PersonalPropertyPictures.vue';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let PersonalPropertyInfo = class PersonalPropertyInfo extends Vue {
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get isPurchaseAvailable() {
        return !this.isIdentityDocument;
    }
    get isInvoiceAvailable() {
        if (this.isAmountOnly)
            return true;
        return this.isPurchaseAvailable && this.personalProperty.userKnowPrice;
    }
    get isPicturesAvailable() {
        if (this.isAmountOnly || this.isIdentityDocument)
            return false;
        return true;
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyInfo.prototype, "claim", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyInfo.prototype, "typeTrigram", void 0);
__decorate([
    personalProperty.State('categories')
], PersonalPropertyInfo.prototype, "categories", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyInfo.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyInfo.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('isCategoryModifiable')
], PersonalPropertyInfo.prototype, "isCategoryModifiableGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyInfo.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyInfo.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyInfo.prototype, "updatePersonalProperty", void 0);
PersonalPropertyInfo = __decorate([
    Component({
        components: {
            PersonalPropertyProduct,
            PersonalPropertyPurchase,
            PersonalPropertyInvoice,
            PersonalPropertyPictures,
        },
        props: { changeKey: { type: [String, Number], default: null } },
    })
], PersonalPropertyInfo);
export default PersonalPropertyInfo;
