var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ClaimFileType } from '@shared/types/file';
const receipt = namespace('receipt');
let ReceiptForm = class ReceiptForm extends Vue {
    constructor() {
        super(...arguments);
        this.ClaimFileType = ClaimFileType;
        this.maxDate = new Date();
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get receipt() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get canDeleteFile() {
        return fileCollectionFilesCount(this.receipt.receiptFile) > 1;
    }
    update(data) {
        this.updateReceipt({
            changeKey: this.changeKey,
            // Upload document with same title always, don't pass it through this.$tc to keep document title in french
            data: { title: 'Devis de réparation', ...data },
        });
    }
};
__decorate([
    receipt.Getter('change')
], ReceiptForm.prototype, "changeGetter", void 0);
__decorate([
    receipt.Getter('validation')
], ReceiptForm.prototype, "validationGetter", void 0);
__decorate([
    receipt.Action('updateDamage')
], ReceiptForm.prototype, "updateReceipt", void 0);
ReceiptForm = __decorate([
    Component({
        components: {
            FileCollectionUpload,
            AmountInput,
            DateAndTimePicker,
        },
    })
], ReceiptForm);
export default ReceiptForm;
