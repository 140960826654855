var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { uuidFromIri } from '@shared/utils/iri';
const claim = namespace('claim');
const SEARCH_QUERY_MIN_LENGTH = 3;
let ProductSearch = class ProductSearch extends Vue {
    constructor() {
        super(...arguments);
        this.searching = false;
        this.loadingProduct = false;
        this.queryInput$ = new Subject();
        this.query$ = new Subject();
        this.subscriptions = [];
        this.searchResults = [];
        this.suggestions = [];
        this.searchQueryDone = false;
    }
    mounted() {
        if (!this.searchQuery) {
            this.searchResults = [];
        }
        this.$watch('searchQuery', (searchQuery) => {
            this.onQueryInput(searchQuery);
        });
        this.subscriptions.push(this.queryInput$
            .pipe(filter((input) => input.length >= SEARCH_QUERY_MIN_LENGTH), distinctUntilChanged(), debounceTime(500))
            .subscribe((query) => this.query$.next(query)));
        this.subscriptions.push(this.query$.pipe(switchMap((query) => this.search(query))).subscribe((result) => {
            if (result === null) {
                this.suggestions = [];
                this.searchResults = [];
                return;
            }
            const { suggestions, results } = result;
            this.suggestions = suggestions;
            this.searchResults = results;
        }));
        if (this.searchQuery.length >= SEARCH_QUERY_MIN_LENGTH) {
            this.onQueryInput(this.searchQuery);
        }
    }
    destroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
    get displayMissingProduct() {
        return (this.searchQueryDone && this.searchQuery.length >= SEARCH_QUERY_MIN_LENGTH && !this.searching);
    }
    onQueryInput(query) {
        this.queryInput$.next(query);
    }
    searchSuggestion(suggestion) {
        this.query$.next(suggestion);
        this.$emit('suggestion-selected', suggestion);
    }
    async search(query) {
        this.searching = true;
        try {
            let params = { q: query };
            if (this.claim.id) {
                params = {
                    ...params,
                    c: uuidFromIri(this.claim.id),
                };
            }
            const result = await this.$axios.$get('/products/search', { params });
            this.searching = false;
            this.searchQueryDone = true;
            return result;
        }
        catch (err) {
            this.searching = false;
            Sentry.captureException(err);
            this.missingProduct();
            return null;
        }
    }
    async selectProduct(product) {
        if (!this.claim.id) {
            return;
        }
        this.loadingProduct = true;
        try {
            const createdProduct = await this.$axios.$post(`/products/create-for-claim/${uuidFromIri(this.claim.id)}`, {
                productId: product.productId,
                groupId: product.groupId,
            });
            this.$emit('product-selected', { product, createdProduct });
        }
        catch (err) {
            this.loadingProduct = false;
            Sentry.captureException(err);
            this.missingProduct();
        }
    }
    missingProduct() {
        this.$emit('missing-product', this.searchQuery);
    }
};
__decorate([
    claim.State('claim')
], ProductSearch.prototype, "claim", void 0);
ProductSearch = __decorate([
    Component({
        components: {},
        props: {
            searchQuery: {
                type: String,
                required: true,
            },
        },
    })
], ProductSearch);
export default ProductSearch;
