var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const personalProperty = namespace('personalProperty');
let PersonalPropertyCategorySelect = class PersonalPropertyCategorySelect extends Vue {
    get categoryIRI() {
        return this.value;
    }
    set categoryIRI(iri) {
        this.$emit('input', iri);
    }
    get filteredCategories() {
        return this.categories.filter((category) => !category.data.isHidden);
    }
    visibleSubcategories(subcategories) {
        return subcategories.filter((category) => !category.isHidden);
    }
};
__decorate([
    personalProperty.State('categories')
], PersonalPropertyCategorySelect.prototype, "categories", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyCategorySelect.prototype, "categoriesByIri", void 0);
PersonalPropertyCategorySelect = __decorate([
    Component({
        components: {},
        props: {
            value: { type: String, default: null },
        },
    })
], PersonalPropertyCategorySelect);
export default PersonalPropertyCategorySelect;
