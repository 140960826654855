var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { EQUIPMENT_AGE_CHOICES } from '@shared/constants/waterDamageConstants';
import { EquipmentAge } from '@shared/types/api/claim';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QUALIFICATION_EQUIPMENT, QUALIFICATION_EQUIPMENT_DETAIL, } from '@shared/constants/equipmentConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let EquipmentAgeQuestion = class EquipmentAgeQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.equipmentAgeSlugValuesAndTradKeys = EQUIPMENT_AGE_CHOICES;
    }
    updateClaim(equipmentAgeSlug) {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: { equipmentAgeSlug },
            step: this.step,
        });
    }
    get questionTitle() {
        if (this.waterDamageQualification.leakageCauseDetailSlug !== null &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug] &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .equipmentAgeQuestionTradKey !== null) {
            return this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .equipmentAgeQuestionTradKey);
        }
        if (this.waterDamageQualification.leakageCauseEquipmentSlug !== null &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug] &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .equipmentAgeQuestionTradKey !== null) {
            return this.$tc(QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .equipmentAgeQuestionTradKey);
        }
        return this.$tc('claim.qualification.equipment_age.title.default');
    }
    get answer() {
        if (this.waterDamageQualification.equipmentAgeSlug === null) {
            return null;
        }
        let ageAnswerSentenceStart;
        if (this.waterDamageQualification.leakageCauseDetailSlug !== null &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug] &&
            QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .equipmentAgeAnswerStartTradKey !== null) {
            ageAnswerSentenceStart = this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
                .equipmentAgeAnswerStartTradKey);
        }
        else if (this.waterDamageQualification.leakageCauseEquipmentSlug !== null &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug] &&
            QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .equipmentAgeAnswerStartTradKey !== null) {
            ageAnswerSentenceStart = this.$tc(QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
                .equipmentAgeAnswerStartTradKey);
        }
        else {
            ageAnswerSentenceStart = this.$tc('claim.qualification.equipment_age.sumup.default');
        }
        switch (this.waterDamageQualification.equipmentAgeSlug) {
            case EquipmentAge.LessThan2Years:
                return this.$tc('claim.qualification.equipment_age.sumup.below_2_years', 0, {
                    sentenceStart: ageAnswerSentenceStart,
                });
            case EquipmentAge.Between2And10Years:
                return this.$tc('claim.qualification.equipment_age.sumup.between_2_and_10_years', 0, {
                    sentenceStart: ageAnswerSentenceStart,
                });
            case EquipmentAge.MoreThan10Years:
                return this.$tc('claim.qualification.equipment_age.sumup.more_than_10_years', 0, {
                    sentenceStart: ageAnswerSentenceStart,
                });
            case EquipmentAge.Unknown:
                return this.$tc('claim.qualification.equipment_age.sumup.unknown', 0, {
                    sentenceStart: ageAnswerSentenceStart,
                });
            default:
                return null;
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], EquipmentAgeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], EquipmentAgeQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], EquipmentAgeQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
EquipmentAgeQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], EquipmentAgeQuestion);
export default EquipmentAgeQuestion;
