var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import FireComplaintFiledModal from '@declaration/components/qualification/FireDamage/criminalActDamage/FireComplaintFiledModal.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let FireComplaintFiledQuestion = class FireComplaintFiledQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalDisplayed = false;
        this.step = Step.FireComplaintFiledQuestion;
    }
    async update(isComplaintFiled) {
        const qualificationPayload = { isComplaintFiled };
        if (isComplaintFiled) {
            this.updateQualification({ qualificationPayload });
            this.isModalDisplayed = true;
        }
        else
            await this.updateAndSaveQualification({ step: this.step, qualificationPayload });
    }
    get answer() {
        if (this.qualification.isComplaintFiled === null)
            return null;
        return this.qualification.isComplaintFiled
            ? this.$tc('claim.qualification.fire.complaint_filed.answer.yes')
            : this.$tc('claim.qualification.fire.complaint_filed.answer.no');
    }
    onModalClose() {
        this.isModalDisplayed = false;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireComplaintFiledQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireComplaintFiledQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireComplaintFiledQuestion.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireComplaintFiledQuestion.prototype, "updateAndSaveQualification", void 0);
FireComplaintFiledQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, FireComplaintFiledModal },
        props: QuestionProps,
    })
], FireComplaintFiledQuestion);
export default FireComplaintFiledQuestion;
