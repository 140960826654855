import { render, staticRenderFns } from "./CommonArea.vue?vue&type=template&id=6e211650&scoped=true"
import script from "./CommonArea.vue?vue&type=script&lang=ts"
export * from "./CommonArea.vue?vue&type=script&lang=ts"
import style0 from "./CommonArea.vue?vue&type=style&index=0&id=6e211650&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e211650",
  null
  
)

export default component.exports