var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { formatDateTime, minimumDate, optionalValueToDate } from '@shared/utils/date';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyLeaveDateQuestion = class RobberyLeaveDateQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.now = new Date();
        this.leaveDate = null;
        this.damageDate = null;
    }
    created() {
        var _a, _b;
        // Initialize with qualification values
        this.leaveDate = optionalValueToDate((_b = (_a = this.qualification) === null || _a === void 0 ? void 0 : _a.absenceDateTimeInterval) === null || _b === void 0 ? void 0 : _b.start);
        this.damageDate = optionalValueToDate(this.claim.damageDate);
    }
    onChange(start) {
        this.leaveDate = new Date(start);
        if (!this.damageDate) {
            this.damageDate = new Date();
        }
    }
    onValidation() {
        const qualificationPayload = {
            absenceDateTimeInterval: {
                start: this.leaveDate && this.leaveDate.toISOString(),
                end: this.damageDate ? this.damageDate.toISOString() : null,
            },
        };
        if (!this.damageDate) {
            this.damageDate = new Date();
        }
        this.updateAndSaveQualification({
            step: this.step,
            qualificationPayload,
        });
    }
    get maxLeaveDate() {
        // We authorize the same day as damageDate no matter what time
        if (!this.damageDate) {
            return this.now;
        }
        return minimumDate(this.damageDate, this.now);
    }
    get minEndDate() {
        return this.leaveDate;
    }
    get canValidate() {
        return !!this.leaveDate;
    }
    get answer() {
        var _a;
        const start = (_a = this.qualification.absenceDateTimeInterval) === null || _a === void 0 ? void 0 : _a.start;
        if (!start) {
            return null;
        }
        return this.$tc('claim.qualification.robbery.leave_date.answer', 0, {
            date: formatDateTime(start),
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyLeaveDateQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.State('claim')
], RobberyLeaveDateQuestion.prototype, "claim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyLeaveDateQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyLeaveDateQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyLeaveDateQuestion = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton, DateAndTimePicker },
        props: QuestionProps,
    })
], RobberyLeaveDateQuestion);
export default RobberyLeaveDateQuestion;
