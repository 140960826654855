var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FireCauseLocalOrigin } from '@shared/types/api/claim';
import { FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS, FIRE_CAUSE_LOCAL_ORIGIN_IMAGE_CHOICES, } from '@declaration/constants/fireDamageConstants';
const qualification = namespace('qualification');
const claim = namespace('claim');
let FireCauseLocalOriginQuestion = class FireCauseLocalOriginQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get fireCauseLocalOriginChoices() {
        return FIRE_CAUSE_LOCAL_ORIGIN_IMAGE_CHOICES;
    }
    get selectedValue() {
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.Inattention) {
            return FireCauseLocalOrigin.Inattention;
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.ElectricalProblem) {
            return FireCauseLocalOrigin.ElectricalProblem;
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.CriminalAct) {
            return FireCauseLocalOrigin.CriminalAct;
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.HotWorks) {
            return FireCauseLocalOrigin.HotWorks;
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.Lightning) {
            return FireCauseLocalOrigin.Lightning;
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.FirePlace) {
            return FireCauseLocalOrigin.FirePlace;
        }
        return null;
    }
    get validateText() {
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.dontKnow ||
            this.qualification.fireCauseLocalOrigin === null) {
            return this.$tc('claim.damage.fire.button.dont_know');
        }
        return this.$tc('claim.damage.fire.button.next_step');
    }
    get answer() {
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.Inattention) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.Inattention]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.ElectricalProblem) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.ElectricalProblem]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.CriminalAct) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.CriminalAct]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.HotWorks) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.HotWorks]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.Lightning) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.Lightning]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.FirePlace) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.FirePlace]);
        }
        if (this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.dontKnow) {
            return this.$tc(FIRE_CAUSE_LOCAL_ORIGIN_ANSWERS[FireCauseLocalOrigin.dontKnow]);
        }
        return null;
    }
    onTypeOfDamagesClick(fireCauseLocalOrigin) {
        this.updateFireDamageQualification({
            qualificationPayload: {
                fireCauseLocalOrigin: fireCauseLocalOrigin !== this.qualification.fireCauseLocalOrigin
                    ? fireCauseLocalOrigin
                    : null,
            },
        });
    }
    async validate() {
        if (this.qualification.fireCauseLocalOrigin === null) {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireCauseLocalOrigin: FireCauseLocalOrigin.dontKnow,
                },
                step: this.step,
            });
        }
        await this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireCauseLocalOrigin: this.qualification.fireCauseLocalOrigin,
            },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireCauseLocalOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireCauseLocalOriginQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireCauseLocalOriginQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireCauseLocalOriginQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireCauseLocalOriginQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], FireCauseLocalOriginQuestion);
export default FireCauseLocalOriginQuestion;
