var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import MissingDocumentCard from '@declaration/components/receipt/MissingDocumentCard.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { Step } from '@shared/claim/steps';
const claim = namespace('claim');
let BreakInDamagesMissingFile = class BreakInDamagesMissingFile extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async skip() {
        this.loading = true;
        await this.saveClaimWithPayload({
            isMissingInitialFileStepValidated: true,
            step: Step.BreakInDamagesMissingFile,
        });
        this.loading = false;
    }
};
__decorate([
    claim.Action('saveClaimWithPayload')
], BreakInDamagesMissingFile.prototype, "saveClaimWithPayload", void 0);
BreakInDamagesMissingFile = __decorate([
    Component({
        components: {
            ClaimsButton,
            MissingDocumentCard,
        },
    })
], BreakInDamagesMissingFile);
export default BreakInDamagesMissingFile;
