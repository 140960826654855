var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
const layout = namespace('layout');
const qualification = namespace('qualification');
let FireComplaintFiledModal = class FireComplaintFiledModal extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/receipts/receipt.svg');
        this.COMPLAINT_RECEIPT_FILE = ClaimFileType.ComplaintReceipt;
        this.fileCollection = null;
    }
    created() {
        this.fileCollection = this.qualification.complaintReceiptFileCollection;
    }
    get validationLabel() {
        return this.$tc('claim.qualification.fire.complaint.modal.continue');
    }
    onCollectionUpdate(complaintReceiptFileCollection) {
        this.fileCollection = complaintReceiptFileCollection;
    }
    async onValidation() {
        this.$emit('close-modal');
        await this.updateAndSaveQualification({
            step: this.step,
            qualificationPayload: {
                isComplaintFiled: this.qualification.isComplaintFiled,
                complaintReceiptFileCollection: this.fileCollection,
            },
        });
    }
    get isLoading() {
        return this.isUpdatingClaim;
    }
    get isDisabled() {
        var _a;
        return ((_a = this.fileCollection) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles.length) === 0;
    }
};
__decorate([
    claim.State('claim')
], FireComplaintFiledModal.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], FireComplaintFiledModal.prototype, "isUpdatingClaim", void 0);
__decorate([
    layout.State('color')
], FireComplaintFiledModal.prototype, "color", void 0);
__decorate([
    qualification.State('qualification')
], FireComplaintFiledModal.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireComplaintFiledModal.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireComplaintFiledModal.prototype, "updateAndSaveQualification", void 0);
FireComplaintFiledModal = __decorate([
    Component({
        components: {
            Modal,
            TitleImageDescriptionLayout,
            ClaimsButton,
            FileCollectionUpload,
        },
        props: {
            isDisplayed: {
                type: Boolean,
                default: false,
            },
            step: {
                type: String,
                required: true,
            },
        },
    })
], FireComplaintFiledModal);
export default FireComplaintFiledModal;
