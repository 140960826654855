var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { TYPE_OF_ELECTRICAL_DAMAGE_CHOICES } from '@declaration/constants/electricalDamageConstants';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { TypeOfElectricalDamage } from '@shared/types/api/electricalDamage';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import { ContractHousingCapacityCode, HousingTypeSlug } from '@shared/types/api/contract';
import { ChoiceUsage } from '@shared/choice';
import CollapsibleHelp from '@declaration/components/UI/CollapsibleHelp.vue';
const claim = namespace('claim');
const contract = namespace('contract');
const qualification = namespace('qualification');
const counting = namespace('counting');
const personalProperty = namespace('personalProperty');
const payloadToChoices = (payload) => {
    const values = [];
    if (payload.hasRealEstateDamage)
        values.push(TypeOfElectricalDamage.RealsEstate);
    if (payload.hasPersonalPropertyDamage)
        values.push(TypeOfElectricalDamage.PersonalProperties);
    if (payload.hasFoodDamage)
        values.push(TypeOfElectricalDamage.Food);
    return values;
};
const choicesToPayload = (choices) => {
    return {
        hasRealEstateDamage: choices.includes(TypeOfElectricalDamage.RealsEstate),
        hasPersonalPropertyDamage: choices.includes(TypeOfElectricalDamage.PersonalProperties),
        hasFoodDamage: choices.includes(TypeOfElectricalDamage.Food),
    };
};
let ElectricalDamageTypeQuestion = class ElectricalDamageTypeQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.selectedValues = [];
    }
    mounted() {
        this.selectedValues = payloadToChoices(this.qualification);
    }
    updateClaim() {
        const electricalDamageQualification = {
            ...this.qualification,
            ...choicesToPayload(this.selectedValues),
        };
        const counting = {
            ...this.counting,
        };
        if (!this.selectedValues.includes(TypeOfElectricalDamage.PersonalProperties)) {
            counting.personalProperties = counting.personalProperties.filter((pp) => optionalResourceIri(pp.category) ===
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        }
        if (!this.selectedValues.includes(TypeOfElectricalDamage.Food)) {
            counting.personalProperties = counting.personalProperties.filter((pp) => optionalResourceIri(pp.category) !==
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        }
        if (!this.selectedValues.includes(TypeOfElectricalDamage.RealsEstate)) {
            counting.electricalDamages = [];
        }
        this.saveClaimWithPayload({ electricalDamageQualification, counting, step: this.step });
    }
    get damageTypeChoices() {
        const choices = TYPE_OF_ELECTRICAL_DAMAGE_CHOICES.filter((damage) => {
            return !(damage.value === TypeOfElectricalDamage.Food.toString() &&
                insurerHasFeature(InsurerFeatureFlag.DisableFoodDamage));
        });
        return choices.map((choice) => {
            delete choice.usage;
            if (choice.value === TypeOfElectricalDamage.RealsEstate && this.realEstateMessage) {
                choice.usage = ChoiceUsage.Alert;
            }
            if (choice.value === TypeOfElectricalDamage.PersonalProperties &&
                this.personalPropertiesMessage) {
                choice.usage = ChoiceUsage.Alert;
            }
            if (choice.value === TypeOfElectricalDamage.Food && this.foodDamageMessage) {
                choice.usage = ChoiceUsage.Alert;
            }
            return choice;
        });
    }
    get foodDamageMessage() {
        var _a;
        if (!((_a = this.coverageOptions) === null || _a === void 0 ? void 0 : _a.DENREES_ALIMENTAIRES)) {
            return this.$tc('claim.qualification.electrical.damage_type.food.warning.not_covered');
        }
        return null;
    }
    get realEstateMessage() {
        if (!this.contract)
            return null;
        if (this.contract.housingCapacity.tenant) {
            return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.loc');
        }
        if (this.contract.housingCapacity.coOwner) {
            return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.copro');
        }
        if (this.contract.housingCapacity.owner &&
            ![HousingTypeSlug.House, HousingTypeSlug.MobileResidence].includes(this.contract.housingType.slug)) {
            return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.apartment_owner');
        }
        switch (this.contract.housingCapacity.code) {
            case ContractHousingCapacityCode.NecessityServiceOccupier:
                return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.nser');
            case ContractHousingCapacityCode.FreeOfChargeOccupier:
                return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.otg');
            case ContractHousingCapacityCode.HousedInMilitaryCompound:
                return this.$tc('claim.qualification.electrical.damage_type.real_estate.warning.emil');
            default:
                return null;
        }
    }
    get personalPropertiesMessage() {
        var _a;
        if (((_a = this.contract) === null || _a === void 0 ? void 0 : _a.specialConditions.personalPropertyCapital) === 0) {
            return this.$tc('claim.qualification.electrical.damage_type.personal_properties.warning.no_capital');
        }
        return null;
    }
    get foodDamageWarning() {
        if (this.selectedValues.includes(TypeOfElectricalDamage.Food)) {
            return this.foodDamageMessage;
        }
        return null;
    }
    get realEstateWarning() {
        if (this.selectedValues.includes(TypeOfElectricalDamage.RealsEstate)) {
            return this.realEstateMessage;
        }
        return null;
    }
    get personalPropertiesWarning() {
        if (this.selectedValues.includes(TypeOfElectricalDamage.PersonalProperties)) {
            return this.personalPropertiesMessage;
        }
        return null;
    }
    get disabled() {
        return this.selectedValues.length === 0;
    }
    get answer() {
        const values = payloadToChoices(this.qualification);
        if (values.length === 0)
            return this.$tc('claim.qualification.electrical.damage_types.none');
        const labels = values.map((type) => {
            return this.$tc(`claim.qualification.electrical.damage_types.${type}`);
        });
        const last = labels.pop();
        const damageTypesLabel = [labels.join(', '), last].filter(Boolean).join(` ${this.$tc('and')} `);
        return this.$tc('claim.qualification.electrical.damage_types.answer', undefined, {
            types: damageTypesLabel,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ElectricalDamageTypeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('saveClaimWithPayload')
], ElectricalDamageTypeQuestion.prototype, "saveClaimWithPayload", void 0);
__decorate([
    counting.State('counting')
], ElectricalDamageTypeQuestion.prototype, "counting", void 0);
__decorate([
    contract.State('selectedContract')
], ElectricalDamageTypeQuestion.prototype, "contract", void 0);
__decorate([
    contract.State('coverageOptions')
], ElectricalDamageTypeQuestion.prototype, "coverageOptions", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], ElectricalDamageTypeQuestion.prototype, "categoriesByIri", void 0);
__decorate([
    qualification.State('qualification')
], ElectricalDamageTypeQuestion.prototype, "qualification", void 0);
ElectricalDamageTypeQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ClaimsButton, CollapsibleHelp },
        props: QuestionProps,
    })
], ElectricalDamageTypeQuestion);
export default ElectricalDamageTypeQuestion;
