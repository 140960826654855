var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
const qualification = namespace('qualification');
let RobberyLostKeysCircumstancesQuestion = class RobberyLostKeysCircumstancesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.circumstances = '';
    }
    created() {
        this.circumstances = this.qualification.lostKeysCircumstances
            ? this.qualification.lostKeysCircumstances
            : '';
    }
    get answer() {
        return this.$tc('claim.qualification.robbery.lost_keys.circumstances.answer', 1, {
            circumstances: this.qualification.lostKeysCircumstances,
        });
    }
    get canValidate() {
        return !!this.circumstances;
    }
    updateClaim() {
        this.updateAndSaveQualification({
            qualificationPayload: { lostKeysCircumstances: this.circumstances },
            step: this.step,
        });
    }
};
__decorate([
    qualification.State('qualification')
], RobberyLostKeysCircumstancesQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyLostKeysCircumstancesQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyLostKeysCircumstancesQuestion = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], RobberyLostKeysCircumstancesQuestion);
export default RobberyLostKeysCircumstancesQuestion;
