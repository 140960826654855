var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FirePersonalPropertyDamagesCount, FIRE_PERSONAL_PROPERTY_COUNT_CHOICES, FIRE_PERSONAL_PROPERTY_COUNT_ANSWERS, } from '@declaration/constants/fireDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
const settings = namespace('settings');
let FirePersonalPropertyDescriptionQuestion = class FirePersonalPropertyDescriptionQuestion extends QuestionComponent {
    get maxCount() {
        const value = this.settingGetter('incPersonalPropertiesCountThresholdBeforeExpertise');
        return value ? parseInt(value) : 1;
    }
    get questionTitle() {
        return this.$tc('claim.damage.fire.question.personal_property_damages_count.title');
    }
    get choices() {
        return FIRE_PERSONAL_PROPERTY_COUNT_CHOICES.map((choice) => {
            return {
                value: choice.value,
                label: this.$tc(choice.tradKey, this.maxCount, { count: this.maxCount }),
            };
        });
    }
    get selectedValue() {
        if (this.qualification.hasPersonalProperties === false)
            return FirePersonalPropertyDamagesCount.None;
        if (this.qualification.personalPropertiesCountAboveThreshold === true)
            return FirePersonalPropertyDamagesCount.MoreThanThreshold;
        if (this.qualification.personalPropertiesCountAboveThreshold === false)
            return FirePersonalPropertyDamagesCount.ThresholdOrLess;
        return null;
    }
    get answer() {
        if (this.qualification.hasPersonalProperties === true &&
            this.qualification.personalPropertiesCountAboveThreshold !== null) {
            return this.qualification.personalPropertiesCountAboveThreshold
                ? this.$tc(FIRE_PERSONAL_PROPERTY_COUNT_ANSWERS['more-than-threshold'], this.maxCount, {
                    count: this.maxCount,
                })
                : this.$tc(FIRE_PERSONAL_PROPERTY_COUNT_ANSWERS['threshold-or-less'], this.maxCount, {
                    count: this.maxCount,
                });
        }
        else if (this.qualification.hasPersonalProperties === false) {
            return this.$tc(FIRE_PERSONAL_PROPERTY_COUNT_ANSWERS.none);
        }
        return null;
    }
    async onUpdate(value) {
        const hasPersonalProperties = !value ? null : value !== FirePersonalPropertyDamagesCount.None;
        await this.updateAndSaveFireDamageQualification({
            step: this.step,
            qualificationPayload: {
                hasPersonalProperties,
                personalPropertiesCountAboveThreshold: !hasPersonalProperties
                    ? null
                    : value === FirePersonalPropertyDamagesCount.MoreThanThreshold,
            },
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FirePersonalPropertyDescriptionQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FirePersonalPropertyDescriptionQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FirePersonalPropertyDescriptionQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FirePersonalPropertyDescriptionQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
__decorate([
    settings.Getter('setting')
], FirePersonalPropertyDescriptionQuestion.prototype, "settingGetter", void 0);
FirePersonalPropertyDescriptionQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], FirePersonalPropertyDescriptionQuestion);
export default FirePersonalPropertyDescriptionQuestion;
