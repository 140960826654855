var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Header from '@declaration/components/Header.vue';
import HeaderBackground from '@declaration/components/HeaderBackground.vue';
import Help from '@declaration/components/Help.vue';
import { bodyHeight100Percent } from '@declaration/services/bodyHeight100Percent';
import { changeHtmlBackground, changeHtmlBackgroundOnColorChange, } from '@declaration/services/changeHtmlBackgroundOnColorChange';
import { displayToastOnNewError } from '@declaration/services/displayToastOnNewError';
import { updateLayoutOnScreenSizeChange } from '@declaration/services/updateLayoutOnScreenSizeChange';
const layout = namespace('layout');
let DefaultLayout = class DefaultLayout extends Vue {
    constructor() {
        super(...arguments);
        this.watchers = [];
    }
    destroyed() {
        this.watchers.forEach((unwatch) => unwatch());
    }
    created() {
        changeHtmlBackground(this.color.layoutBackgroundColor);
        this.watchers.push(displayToastOnNewError(this));
        this.watchers.push(updateLayoutOnScreenSizeChange(this));
        this.watchers.push(changeHtmlBackgroundOnColorChange(this));
        this.watchers.push(bodyHeight100Percent());
    }
};
__decorate([
    layout.State('isMobile')
], DefaultLayout.prototype, "isMobile", void 0);
__decorate([
    layout.State('color')
], DefaultLayout.prototype, "color", void 0);
DefaultLayout = __decorate([
    Component({
        components: {
            Help,
            Header,
            HeaderBackground,
        },
        head() {
            return this.$nuxtI18nHead({ addDirAttribute: false, addSeoAttributes: true });
        },
    })
], DefaultLayout);
export default DefaultLayout;
