var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import NumberInput from '@declaration/components/NumberInput.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { AERATION_GRID_TITLE } from '@declaration/constants/externalDamages/AerationGridConstants';
let AerationGrid = class AerationGrid extends ExternalDamageComponent {
    get title() {
        return this.translateExternalDamageKey(AERATION_GRID_TITLE);
    }
    get gridCount() {
        var _a;
        return (_a = this.externalDamage.gridCount) !== null && _a !== void 0 ? _a : 0;
    }
    set gridCount(gridCount) {
        this.updateExternalDamage({ gridCount });
    }
};
AerationGrid = __decorate([
    Component({
        components: {
            NumberInput,
        },
    })
], AerationGrid);
export default AerationGrid;
