var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { GARAGE_DOOR_DAMAGE_TYPE_CHOICES, GARAGE_DOOR_MATERIAL_CHOICES, GARAGE_DOOR_OPENING_MODE_CHOICES, GARAGE_DOOR_TYPE_CHOICES, GARAGE_DOOR_WIDTH_CHOICES, } from '@shared/roomDamage/goodDamage';
import { GarageDoorMaterial, GarageDoorDamageType, GarageDoorWidth, GarageDoorOpeningMode, } from '@shared/types/api/roomDamage';
let GarageDoorDescription = class GarageDoorDescription extends GoodDamageComponent {
    get doorTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_type');
    }
    get materialLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.material');
    }
    get damageTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.damage_type');
    }
    get doorWidthLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.door_width');
    }
    get openingModeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.opening_mode');
    }
    get isMotorWorkingLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.garage_door.is_motor_working');
    }
    get doorTypeChoices() {
        return GARAGE_DOOR_TYPE_CHOICES;
    }
    get materialChoices() {
        return GARAGE_DOOR_MATERIAL_CHOICES;
    }
    get damageTypeChoices() {
        return GARAGE_DOOR_DAMAGE_TYPE_CHOICES;
    }
    get doorWidthChoices() {
        return GARAGE_DOOR_WIDTH_CHOICES;
    }
    get openingModeChoices() {
        return GARAGE_DOOR_OPENING_MODE_CHOICES;
    }
    get isMotorWorkingChoices() {
        return YES_OR_NO_CHOICES;
    }
    get doorType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.doorType) !== null && _b !== void 0 ? _b : null;
    }
    set doorType(doorType) {
        this.updateGoodDamage({ doorType });
    }
    get material() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : null;
    }
    set material(material) {
        this.updateGoodDamage({
            material,
            damageType: material === GarageDoorMaterial.WOOD ? this.damageType : null,
        });
    }
    get damageType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.damageType) !== null && _b !== void 0 ? _b : null;
    }
    set damageType(damageType) {
        this.updateGoodDamage({
            damageType,
            doorWidth: damageType === GarageDoorDamageType.DAMAGED ? this.doorWidth : null,
        });
    }
    get doorWidth() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.doorWidth) !== null && _b !== void 0 ? _b : null;
    }
    set doorWidth(doorWidth) {
        this.updateGoodDamage({
            doorWidth,
            openingMode: doorWidth === GarageDoorWidth.STANDARD ? this.openingMode : null,
            isMotorWorking: doorWidth === GarageDoorWidth.STANDARD ? this.isMotorWorking : null,
        });
    }
    get openingMode() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.openingMode) !== null && _b !== void 0 ? _b : null;
    }
    set openingMode(openingMode) {
        this.updateGoodDamage({
            openingMode,
            isMotorWorking: openingMode === GarageDoorOpeningMode.MOTORIZED ? this.isMotorWorking : null,
        });
    }
    get isMotorWorking() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.isMotorWorking) !== null && _b !== void 0 ? _b : null;
    }
    set isMotorWorking(isMotorWorking) {
        this.updateGoodDamage({ isMotorWorking });
    }
    get shouldProvideDamageType() {
        var _a;
        return ((_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.material) === GarageDoorMaterial.WOOD;
    }
    get shouldProvideDoorWidth() {
        var _a, _b, _c;
        return (((_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.material) === GarageDoorMaterial.PVC ||
            ((_b = this.goodDamage) === null || _b === void 0 ? void 0 : _b.material) === GarageDoorMaterial.METAL ||
            ((_c = this.goodDamage) === null || _c === void 0 ? void 0 : _c.damageType) === GarageDoorDamageType.DAMAGED);
    }
    get shouldProvideOpeningMode() {
        var _a, _b;
        return (((_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.damageType) === GarageDoorDamageType.AESTHETIC ||
            ((_b = this.goodDamage) === null || _b === void 0 ? void 0 : _b.doorWidth) === GarageDoorWidth.STANDARD);
    }
    get shouldProvideIsMotorWorking() {
        var _a;
        return ((_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.openingMode) === GarageDoorOpeningMode.MOTORIZED;
    }
};
GarageDoorDescription = __decorate([
    Component({ components: { RadioPrimaryButton } })
], GarageDoorDescription);
export default GarageDoorDescription;
