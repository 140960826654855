var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import CalendarDropdown from '@declaration/components/appointment/CalendarDropdown.vue';
const claim = namespace('claim');
const artisanAppointment = namespace('artisanAppointment');
let ArtisanAppointmentConfirmation = class ArtisanAppointmentConfirmation extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/checklist.svg');
    }
    get appointmentInfo() {
        return {
            day: this.selectedTimeSlotDay.label.toLowerCase(),
            startHour: this.selectedTimeSlot.startHour,
            endHour: this.selectedTimeSlot.endHour,
            artisanName: this.artisanName,
        };
    }
    async nextStep() {
        await this.updateAndSaveClaimStep(this.step);
    }
};
__decorate([
    artisanAppointment.Getter('selectedTimeSlotDay')
], ArtisanAppointmentConfirmation.prototype, "selectedTimeSlotDay", void 0);
__decorate([
    artisanAppointment.Getter('selectedTimeSlot')
], ArtisanAppointmentConfirmation.prototype, "selectedTimeSlot", void 0);
__decorate([
    artisanAppointment.State('artisanName')
], ArtisanAppointmentConfirmation.prototype, "artisanName", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], ArtisanAppointmentConfirmation.prototype, "updateAndSaveClaimStep", void 0);
ArtisanAppointmentConfirmation = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            ParagraphsWithIcon,
            CalendarDropdown,
        },
        props: {
            step: {
                type: String,
                required: true,
            },
        },
    })
], ArtisanAppointmentConfirmation);
export default ArtisanAppointmentConfirmation;
