var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { formatDate } from '@shared/utils/date';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import DateWithValidation from '@declaration/components/qualification/DateWithValidation.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let StormDateQuestion = class StormDateQuestion extends QuestionComponent {
    get stormDate() {
        return this.electricalDamageQualification.stormDate
            ? new Date(this.electricalDamageQualification.stormDate)
            : this.currentDamageDate;
    }
    get currentDamageDate() {
        return this.claim.damageDate ? new Date(this.claim.damageDate) : new Date();
    }
    get answer() {
        return this.electricalDamageQualification.stormDate
            ? this.$tc('claim.qualification.electrical.storm_date.sumup', 0, {
                date: formatDate(this.electricalDamageQualification.stormDate),
            })
            : null;
    }
    updateQualification(stormDate) {
        this.updateAndSaveQualification({
            qualificationPayload: { stormDate: stormDate.toISOString() },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('claim')
], StormDateQuestion.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], StormDateQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], StormDateQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], StormDateQuestion.prototype, "updateAndSaveQualification", void 0);
StormDateQuestion = __decorate([
    Component({
        components: { QuestionPanel, DateWithValidation },
        props: QuestionProps,
    })
], StormDateQuestion);
export default StormDateQuestion;
