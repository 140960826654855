var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { render } from '@shared/services/pdfRenderer';
let PdfPreview = class PdfPreview extends Vue {
    constructor() {
        super(...arguments);
        this.canvas = null;
    }
    mounted() {
        this.$watch('objectUrl', this.renderPdf, { immediate: true });
    }
    async renderPdf() {
        var _a;
        const container = this.$refs.container;
        if (!container) {
            return;
        }
        (_a = this.canvas) === null || _a === void 0 ? void 0 : _a.remove();
        this.canvas = document.createElement('canvas');
        container.appendChild(this.canvas);
        await render(this.objectUrl, this.canvas, {
            maxHeight: container.clientHeight,
            maxWidth: container.clientWidth,
        });
    }
};
PdfPreview = __decorate([
    Component({
        props: {
            objectUrl: {
                type: String,
                required: true,
            },
        },
    })
], PdfPreview);
export default PdfPreview;
