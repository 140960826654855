var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
const breakInDamage = namespace('breakInDamage');
let BreakInDamagePictures = class BreakInDamagePictures extends Vue {
    constructor() {
        super(...arguments);
        this.ClaimFileType = ClaimFileType;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    update(data) {
        this.updateDamage({ changeKey: this.changeKey, data });
    }
};
__decorate([
    breakInDamage.Getter('change')
], BreakInDamagePictures.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], BreakInDamagePictures.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], BreakInDamagePictures.prototype, "updateDamage", void 0);
BreakInDamagePictures = __decorate([
    Component({
        components: {
            PictureCollectionUpload,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], BreakInDamagePictures);
export default BreakInDamagePictures;
