var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
let RobberyPNOExit = class RobberyPNOExit extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/checklist.svg');
        this.isUpdatingClaim = false;
    }
    async save() {
        this.isUpdatingClaim = true;
        await this.updateAndSaveClaim({ step: Step.RobberyPNOExit, claimPayload: {} });
        this.isUpdatingClaim = false;
    }
    get description() {
        var _a, _b;
        return this.$tc('robbery_PNO.description', undefined, {
            phoneNumber: (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.france) !== null && _b !== void 0 ? _b : '01 XX XX XX XX',
        });
    }
};
__decorate([
    claim.Action('updateAndSaveClaim')
], RobberyPNOExit.prototype, "updateAndSaveClaim", void 0);
RobberyPNOExit = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], RobberyPNOExit);
export default RobberyPNOExit;
