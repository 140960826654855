var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, PropSync } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { parseLength } from '@declaration/helpers/typeFormatters';
import { DOOR_BIGEST_BROKEN_TILE_TITLE } from '@declaration/constants/externalDamages/DoorConstants';
let DoorBrokenTileSize = class DoorBrokenTileSize extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.parseLength = parseLength;
    }
    get title() {
        return this.translateExternalDamageKey(DOOR_BIGEST_BROKEN_TILE_TITLE);
    }
};
__decorate([
    PropSync('window-height', { type: Number, required: false })
], DoorBrokenTileSize.prototype, "height", void 0);
__decorate([
    PropSync('window-width', { type: Number, required: false })
], DoorBrokenTileSize.prototype, "width", void 0);
DoorBrokenTileSize = __decorate([
    Component({
        components: {},
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], DoorBrokenTileSize);
export default DoorBrokenTileSize;
