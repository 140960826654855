var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import NumberInput from '@declaration/components/NumberInput.vue';
import Select from '@declaration/components/Select.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { BASIN_MATERIAL_CHOICES } from '@shared/constants/externalDamages/BasinConstants';
import { BASIN_MATERIAL_PLACEHOLDER, BASIN_TITLE, } from '@declaration/constants/externalDamages/BasinConstants';
let Basin = class Basin extends ExternalDamageComponent {
    get title() {
        return this.translateExternalDamageKey(BASIN_TITLE);
    }
    get materialPlaceholder() {
        return this.translateExternalDamageKey(BASIN_MATERIAL_PLACEHOLDER);
    }
    get materialChoices() {
        return this.getExternalDamageChoices(BASIN_MATERIAL_CHOICES);
    }
    get material() {
        var _a;
        return (_a = this.externalDamage.material) !== null && _a !== void 0 ? _a : null;
    }
    set material(material) {
        this.updateExternalDamage({ material });
    }
};
Basin = __decorate([
    Component({
        components: {
            Select,
            NumberInput,
        },
    })
], Basin);
export default Basin;
