var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CAUSE_TYPE_CHOICES } from '@shared/constants/stormSnowHailDamageConstants';
import { CAUSE_TYPE_ANSWER_TRAD_KEYS } from '@declaration/constants/stormSnowHailDamageConstants';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { isExternalDamageEnabled } from '@shared/constants/externalDamages';
const claim = namespace('claim');
const qualification = namespace('qualification');
const externalDamage = namespace('externalDamage');
const counting = namespace('counting');
let StormCauseTypeQuestion = class StormCauseTypeQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.causeTypeValuesAndTradKeys = CAUSE_TYPE_CHOICES;
    }
    updateClaim(causeType) {
        // Filter ExternalDamages to only keep the ones that are enable for the choosen causeType
        const externalDamagesIdsToKeep = [];
        for (const externalDamage of this.externalDamages) {
            if (externalDamage.id &&
                isExternalDamageEnabled(externalDamage.slug, externalDamage.localizationSlug, { causeType })) {
                externalDamagesIdsToKeep.push(externalDamage.id);
            }
        }
        this.updateAndSaveQualification({
            qualificationPayload: { causeType },
            step: this.step,
            claimPayload: {
                counting: {
                    id: this.counting.id,
                    externalDamages: externalDamagesIdsToKeep,
                },
            },
        });
    }
    get answer() {
        if (!this.stormSnowHailDamageQualification.causeType) {
            return null;
        }
        return this.$tc(CAUSE_TYPE_ANSWER_TRAD_KEYS[this.stormSnowHailDamageQualification.causeType]);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], StormCauseTypeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    counting.State('counting')
], StormCauseTypeQuestion.prototype, "counting", void 0);
__decorate([
    qualification.State('qualification')
], StormCauseTypeQuestion.prototype, "stormSnowHailDamageQualification", void 0);
__decorate([
    externalDamage.State('damages')
], StormCauseTypeQuestion.prototype, "externalDamages", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], StormCauseTypeQuestion.prototype, "updateAndSaveQualification", void 0);
StormCauseTypeQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], StormCauseTypeQuestion);
export default StormCauseTypeQuestion;
