import { render, staticRenderFns } from "./IsPremiseVacantQuestion.vue?vue&type=template&id=3f91a852&scoped=true"
import script from "./IsPremiseVacantQuestion.vue?vue&type=script&lang=ts"
export * from "./IsPremiseVacantQuestion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f91a852",
  null
  
)

export default component.exports