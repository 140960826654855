var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import { ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { minimalize } from '@shared/utils/strings';
let BreakInDamageNoDamageWarningModal = class BreakInDamageNoDamageWarningModal extends Vue {
    get displayed() {
        return this.isDisplayed;
    }
    get title() {
        return this.$tc('claim.declaration.section.break_in_damages.no-damage.modal.title');
    }
    get content() {
        return this.damageType
            ? this.$tc('claim.declaration.section.break_in_damages.no-damage.modal.text', 0, {
                type: minimalize(this.$tc(ROBBERY_BREAKABLE_ELEMENT_TRAD_KEYS[this.damageType])),
            })
            : this.$tc('claim.declaration.section.break_in_damages.no-damage.modal.default_type');
    }
    get deleteText() {
        return this.$tc('claim.declaration.section.break_in_damages.no-damage.modal.button.delete-damage');
    }
    get updateText() {
        return this.$tc('claim.declaration.section.break_in_damages.no-damage.modal.button.update-damage');
    }
    onDeleteButtonClick() {
        this.$emit('delete-damage-and-close');
    }
    onUpdateButtonClick() {
        this.closeModal();
        this.$emit('reset-modal-steps');
    }
    closeModal() {
        this.$emit('close-warning-modal');
    }
};
BreakInDamageNoDamageWarningModal = __decorate([
    Component({
        components: { Modal, ClaimsButton, SecondaryButton },
        props: {
            isDisplayed: {
                type: Boolean,
                required: true,
            },
            damageType: {
                type: String,
                default: null,
            },
        },
    })
], BreakInDamageNoDamageWarningModal);
export default BreakInDamageNoDamageWarningModal;
