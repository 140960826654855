import { render, staticRenderFns } from "./envoi-facture.vue?vue&type=template&id=59316ccc&scoped=true"
import script from "./envoi-facture.vue?vue&type=script&lang=ts"
export * from "./envoi-facture.vue?vue&type=script&lang=ts"
import style0 from "./envoi-facture.vue?vue&type=style&index=0&id=59316ccc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59316ccc",
  null
  
)

export default component.exports