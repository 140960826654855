var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ExternalDamageComponent } from '../ExternalDamageComponent';
import { damageSizeChoiceValue } from '@declaration/types/externalDamage';
import Select from '@declaration/components/Select.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { AWNING_DIMENSIONS_LABEL, AWNING_OVERHANG_LENGTH_LABEL, AWNING_OVERHANG_LENGTH_PLACEHOLDER, AWNING_MOTORIZATION_CHOICES, AWNING_MOTORIZATION_LABEL, AWNING_WIDTH_LABEL, AWNING_WIDTH_PLACEHOLDER, } from '@declaration/constants/externalDamages';
const externalDamage = namespace('externalDamage');
let Awning = class Awning extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.awningImage = require('@declaration/assets/images/externalDamage/awning/store_banne.svg');
    }
    get motorizationLabel() {
        return this.translateExternalDamageKey(AWNING_MOTORIZATION_LABEL);
    }
    get dimensionsLabel() {
        return this.translateExternalDamageKey(AWNING_DIMENSIONS_LABEL);
    }
    get widthLabel() {
        return this.translateExternalDamageKey(AWNING_WIDTH_LABEL);
    }
    get widthPlaceholder() {
        return this.translateExternalDamageKey(AWNING_WIDTH_PLACEHOLDER);
    }
    get overhangLengthLabel() {
        return this.translateExternalDamageKey(AWNING_OVERHANG_LENGTH_LABEL);
    }
    get overhangLengthPlaceholder() {
        return this.translateExternalDamageKey(AWNING_OVERHANG_LENGTH_PLACEHOLDER);
    }
    get motorizationChoices() {
        return this.getExternalDamageChoices(AWNING_MOTORIZATION_CHOICES);
    }
    get widthChoices() {
        const widths = [...new Set(this.awningTypes.map((awningType) => awningType.standardWidth))];
        const awningWidths = [];
        let previousWidth = 0;
        for (const i in widths) {
            const width = widths[i];
            awningWidths.push(damageSizeChoiceValue(this.$tc.bind(this), width, previousWidth));
            previousWidth = width;
        }
        awningWidths.push(damageSizeChoiceValue(this.$tc.bind(this), previousWidth));
        return awningWidths;
    }
    get overhangLengthChoices() {
        const overhangLengths = [
            ...new Set(this.awningTypes
                .filter((awningType) => {
                if (this.externalDamage.width) {
                    return awningType.standardWidth === this.externalDamage.width;
                }
                return true;
            })
                .map((awningType) => awningType.overhangStandardLength)),
        ];
        const awningOverhangLengths = [];
        let previousOverhangLength;
        for (const i in overhangLengths) {
            const overhangLength = overhangLengths[i];
            awningOverhangLengths.push(damageSizeChoiceValue(this.$tc.bind(this), overhangLength, previousOverhangLength !== null && previousOverhangLength !== void 0 ? previousOverhangLength : 0));
            previousOverhangLength = overhangLength;
        }
        if (previousOverhangLength) {
            awningOverhangLengths.push(damageSizeChoiceValue(this.$tc.bind(this), previousOverhangLength));
        }
        else {
            awningOverhangLengths.push(damageSizeChoiceValue(this.$tc.bind(this), 1, 0));
            awningOverhangLengths.push(damageSizeChoiceValue(this.$tc.bind(this), 1));
        }
        return awningOverhangLengths;
    }
    get isOpeningMotorized() {
        var _a;
        return (_a = this.externalDamage.isOpeningMotorized) !== null && _a !== void 0 ? _a : null;
    }
    set isOpeningMotorized(isOpeningMotorized) {
        this.updateExternalDamage({ isOpeningMotorized });
    }
    get width() {
        var _a;
        return (_a = this.externalDamage.width) !== null && _a !== void 0 ? _a : null;
    }
    set width(width) {
        this.updateExternalDamage({ width });
    }
    get overhangLength() {
        var _a;
        return (_a = this.externalDamage.overhangLength) !== null && _a !== void 0 ? _a : null;
    }
    set overhangLength(overhangLength) {
        this.updateExternalDamage({ overhangLength });
    }
};
__decorate([
    externalDamage.State('awningTypes')
], Awning.prototype, "awningTypes", void 0);
Awning = __decorate([
    Component({ components: { Select, RadioPrimaryButton } })
], Awning);
export default Awning;
