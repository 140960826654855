var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CAUSE_ORIGIN_CHOICES } from '@shared/constants/electricalDamageConstants';
import { CAUSE_ORIGIN_ANSWER_TRAD_KEYS } from '@declaration/constants/electricalDamageConstants';
import { ElectricalDamageCauseOrigin } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
const qualification = namespace('qualification');
let ElectricalCauseOriginQuestion = class ElectricalCauseOriginQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.causeOriginLocationValuesAndTradKeys = CAUSE_ORIGIN_CHOICES;
    }
    updateClaim(causeOrigin) {
        const atHome = causeOrigin === ElectricalDamageCauseOrigin.AtHome;
        const publicWorks = causeOrigin === ElectricalDamageCauseOrigin.Network;
        this.updateAndSaveQualification({
            qualificationPayload: {
                causeOrigin,
                professionalIntervention: atHome ? undefined : null,
                professionalInterventionInvoice: atHome ? undefined : emptyUploadedFileCollection(),
                causeEquipment: atHome ? undefined : null,
                publicWorksDocument: publicWorks ? undefined : emptyUploadedFileCollection(),
                publicWorksInterventionDate: publicWorks ? undefined : null,
                publicWorksCompanyName: publicWorks ? undefined : null,
                stormDate: causeOrigin === ElectricalDamageCauseOrigin.Thunder ? undefined : null,
            },
            step: this.step,
        });
    }
    get answer() {
        if (!this.electricalDamageQualification.causeOrigin) {
            return null;
        }
        return this.$tc(CAUSE_ORIGIN_ANSWER_TRAD_KEYS[this.electricalDamageQualification.causeOrigin]);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ElectricalCauseOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], ElectricalCauseOriginQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], ElectricalCauseOriginQuestion.prototype, "updateAndSaveQualification", void 0);
ElectricalCauseOriginQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], ElectricalCauseOriginQuestion);
export default ElectricalCauseOriginQuestion;
