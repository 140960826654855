var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BDGPersonalPropertyReparationBy, PersonalPropertyType, } from '@shared/types/api/personalProperty';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { objectToTranslatableChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY } from '@shared/constants/personalPropertyConstants';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let PersonalPropertyForm = class PersonalPropertyForm extends Vue {
    get reparationTitle() {
        return this.$tc('claim.damage.personal_property.BDG.form.reparation.title');
    }
    get reparationByTitle() {
        return this.$tc('claim.damage.personal_property.BDG.form.reparation-by.title');
    }
    get reparationInvoiceTitle() {
        return this.personalProperty.windowReparationBy === BDGPersonalPropertyReparationBy.Professional
            ? this.$tc('claim.damage.personal_property.BDG.form.reparation-invoice.title.mandatory')
            : this.$tc('claim.damage.personal_property.BDG.form.reparation-invoice.title.optional');
    }
    get reparationInvoiceDescription() {
        return this.$tc('claim.damage.personal_property.BDG.form.reparation-invoice.description');
    }
    get reparationInvoiceUploadLabel() {
        return this.$tc('claim.damage.personal_property.BDG.form.reparation-invoice.upload');
    }
    get reparationChoices() {
        return YES_OR_NO_CHOICES;
    }
    get reparationByChoices() {
        return objectToTranslatableChoices(BDG_PERSONAL_PROPERTY_REPARATION_BY_TRAD_KEY);
    }
    get invoiceFileType() {
        return ClaimFileType.CraftsmanInvoice;
    }
    get selectedReparationValue() {
        var _a;
        return (_a = this.personalProperty.windowReparation) !== null && _a !== void 0 ? _a : null;
    }
    get selectedReparationByValue() {
        var _a;
        return (_a = this.personalProperty.windowReparationBy) !== null && _a !== void 0 ? _a : null;
    }
    get selectedReparationInvoice() {
        return this.personalProperty.windowReparationInvoice;
    }
    get selectedReparationInvoiceAmount() {
        var _a;
        return (_a = this.personalProperty.windowReparationInvoiceAmount) !== null && _a !== void 0 ? _a : null;
    }
    get shouldDisplayReparationBy() {
        return this.personalProperty.windowReparation === true;
    }
    get shouldDisplayReparationInvoice() {
        return [
            BDGPersonalPropertyReparationBy.Myself,
            BDGPersonalPropertyReparationBy.Professional,
        ].includes(this.personalProperty.windowReparationBy);
    }
    get shouldDisplayReparationInvoiceAmount() {
        var _a;
        return !!((_a = this.selectedReparationInvoice) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles.length);
    }
    onReparationUpdate(windowReparation) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data: { windowReparation } });
    }
    onReparationByUpdate(windowReparationBy) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data: { windowReparationBy } });
    }
    onReparationInvoiceUpdate(windowReparationInvoice) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data: { windowReparationInvoice } });
    }
    onReparationInvoiceAmountUpdate(windowReparationInvoiceAmount) {
        this.updatePersonalProperty({
            changeKey: this.changeKey,
            data: { windowReparationInvoiceAmount },
        });
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get isGlassBreakagePersonalProperty() {
        return this.personalProperty.type === PersonalPropertyType.GlassBreakage;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyForm.prototype, "claim", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyForm.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyForm.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Action('validateAndRevealDamages')
], PersonalPropertyForm.prototype, "validatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyForm.prototype, "updatePersonalProperty", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyForm.prototype, "typeTrigram", void 0);
PersonalPropertyForm = __decorate([
    Component({
        components: { RadioPrimaryButton, FileCollectionUpload, AmountInput },
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], PersonalPropertyForm);
export default PersonalPropertyForm;
