var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import Modal from '@declaration/components/UI/Modal.vue';
import { BreakInDamageRepairQuotePendingStatus } from '@shared/types/api/claim';
import { BreakInDamageCompensationMode } from '@shared/types/api/breakInDamage';
const breakInDamage = namespace('breakInDamage');
const claim = namespace('claim');
let CompensationOnProofDetailModal = class CompensationOnProofDetailModal extends Vue {
    constructor() {
        super(...arguments);
        this.waitingIcon = require('@declaration/assets/images/compensation/waiting.svg');
    }
    displayName(breakInDamage) {
        return breakInDamageTitle(breakInDamage, this.$tc.bind(this));
    }
    proofMissingMessage(breakInDamage) {
        switch (breakInDamage.compensationMode) {
            case BreakInDamageCompensationMode.CompensationModeTemporary:
                return 'compensation.proposition.compensation_on_proof.modal.card.waiting.temporary';
            case BreakInDamageCompensationMode.CompensationModeDefinitive:
                return 'compensation.proposition.compensation_on_proof.modal.card.waiting.definitive';
            case BreakInDamageCompensationMode.CompensationModeReplacementNecessary:
                return 'compensation.proposition.compensation_on_proof.modal.card.waiting.replacement';
        }
        return '';
    }
    get pendingDamages() {
        return this.breakInDamagesList.filter((breakInDamage) => breakInDamage.repairQuotePendingStatus !== BreakInDamageRepairQuotePendingStatus.None);
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    breakInDamage.State('damages')
], CompensationOnProofDetailModal.prototype, "breakInDamagesList", void 0);
__decorate([
    claim.State('claim')
], CompensationOnProofDetailModal.prototype, "claim", void 0);
CompensationOnProofDetailModal = __decorate([
    Component({
        components: { Modal },
        props: {
            isDisplayed: { type: Boolean, required: true },
        },
    })
], CompensationOnProofDetailModal);
export default CompensationOnProofDetailModal;
