var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { GLASS_BREAKAGE_ROOM_DAMAGES_INSTALLATION_DATE_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
import { GLASS_BREAKAGE_ROOM_DAMAGES_INSTALLATION_DATE_CHOICES } from '@shared/constants/glassBreakageDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
const settings = namespace('settings');
let GlassBreakageRoomDamagesInstallationDateQuestion = class GlassBreakageRoomDamagesInstallationDateQuestion extends QuestionComponent {
    get dateThreshold() {
        return this.setting('bdgRoomDamagesInstallationDateLimit');
    }
    get questionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damages-installation-date-question'], this.dateThreshold, {
            count: this.dateThreshold,
        });
    }
    get choices() {
        return GLASS_BREAKAGE_ROOM_DAMAGES_INSTALLATION_DATE_CHOICES;
    }
    get selectedValue() {
        return this.qualification.roomDamagesInstallationDate;
    }
    get answer() {
        if (this.selectedValue === null) {
            return null;
        }
        return this.$tc(GLASS_BREAKAGE_ROOM_DAMAGES_INSTALLATION_DATE_ANSWERS[this.selectedValue], this.dateThreshold, { count: this.dateThreshold });
    }
    async onUpdate(roomDamagesInstallationDate) {
        await this.updateAndSaveGlassBreakageDamageQualification({
            qualificationPayload: { roomDamagesInstallationDate },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakageRoomDamagesInstallationDateQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakageRoomDamagesInstallationDateQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakageRoomDamagesInstallationDateQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakageRoomDamagesInstallationDateQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
__decorate([
    settings.Getter('setting')
], GlassBreakageRoomDamagesInstallationDateQuestion.prototype, "setting", void 0);
GlassBreakageRoomDamagesInstallationDateQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], GlassBreakageRoomDamagesInstallationDateQuestion);
export default GlassBreakageRoomDamagesInstallationDateQuestion;
