var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { PersonalPropertyCountValues } from '@shared/personalProperty/personalPropertyCountValues';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
const counting = namespace('counting');
const settings = namespace('settings');
let RobberyPersonalPropertyCountQuestion = class RobberyPersonalPropertyCountQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.selectedValue = null;
    }
    get maxDamages() {
        return this.settingGetter('volPersonalPropertyMaxCountBeforeExpertise');
    }
    mounted() {
        this.selectedValue = this.qualification.morePersonalPropertiesThanLimit
            ? PersonalPropertyCountValues.MoreThanLimit
            : this.qualification.noStolenPersonalProperty
                ? PersonalPropertyCountValues.None
                : PersonalPropertyCountValues.LessThanLimit;
    }
    updateClaim(numberOfPersonalProperty) {
        this.selectedValue = numberOfPersonalProperty;
        const robberyDamageQualification = {
            ...this.qualification,
        };
        const counting = {
            ...this.counting,
        };
        if (numberOfPersonalProperty === PersonalPropertyCountValues.None) {
            robberyDamageQualification.noStolenPersonalProperty = true;
            robberyDamageQualification.morePersonalPropertiesThanLimit = null;
            counting.personalProperties = [];
        }
        else {
            robberyDamageQualification.morePersonalPropertiesThanLimit =
                numberOfPersonalProperty === PersonalPropertyCountValues.MoreThanLimit;
            robberyDamageQualification.noStolenPersonalProperty = null;
        }
        this.saveClaimWithPayload({ robberyDamageQualification, counting, step: this.step });
    }
    get answer() {
        if (this.qualification.morePersonalPropertiesThanLimit === null &&
            this.qualification.noStolenPersonalProperty === null) {
            return null;
        }
        if (this.qualification.noStolenPersonalProperty) {
            return this.$tc('claim.qualification.robbery.personal-property-count.answser.none');
        }
        if (this.qualification.morePersonalPropertiesThanLimit) {
            return this.$tc('claim.qualification.robbery.personal-property-count.answser.more', 1, {
                count: this.maxDamages,
            });
        }
        return this.$tc('claim.qualification.robbery.personal-property-count.answser.less', 1, {
            count: this.maxDamages,
        });
    }
    get choices() {
        const choices = [
            {
                value: PersonalPropertyCountValues.LessThanLimit,
                label: this.$tc('claim.qualification.robbery.personal-property-count.choice.less', 1, {
                    count: this.maxDamages,
                }),
            },
            {
                value: PersonalPropertyCountValues.MoreThanLimit,
                label: this.$tc('claim.qualification.robbery.personal-property-count.choice.more', 1, {
                    count: this.maxDamages,
                }),
            },
        ];
        if (insurerHasFeature(InsurerFeatureFlag.AllowNoStolenPersonalProperty)) {
            return [
                {
                    value: PersonalPropertyCountValues.None,
                    label: this.$tc('claim.qualification.robbery.personal-property-count.choice.none'),
                },
            ].concat(choices);
        }
        return choices;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyPersonalPropertyCountQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('saveClaimWithPayload')
], RobberyPersonalPropertyCountQuestion.prototype, "saveClaimWithPayload", void 0);
__decorate([
    qualification.State('qualification')
], RobberyPersonalPropertyCountQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyPersonalPropertyCountQuestion.prototype, "updateAndSaveQualification", void 0);
__decorate([
    counting.State('counting')
], RobberyPersonalPropertyCountQuestion.prototype, "counting", void 0);
__decorate([
    settings.Getter('setting')
], RobberyPersonalPropertyCountQuestion.prototype, "settingGetter", void 0);
RobberyPersonalPropertyCountQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], RobberyPersonalPropertyCountQuestion);
export default RobberyPersonalPropertyCountQuestion;
