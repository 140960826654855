var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FireCauseExternOrigin } from '@shared/types/api/claim';
import { FIRE_CAUSE_EXTERN_ORIGIN_IMAGE_CHOICES, FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS, } from '@declaration/constants/fireDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let FireCauseExternOriginQuestion = class FireCauseExternOriginQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get fireCauseExternOriginChoices() {
        return FIRE_CAUSE_EXTERN_ORIGIN_IMAGE_CHOICES;
    }
    get selectedValue() {
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Barbecue) {
            return FireCauseExternOrigin.Barbecue;
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Lightning) {
            return FireCauseExternOrigin.Lightning;
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Tinkering) {
            return FireCauseExternOrigin.Tinkering;
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.ForestFire) {
            return FireCauseExternOrigin.ForestFire;
        }
        return null;
    }
    get answer() {
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Barbecue) {
            return this.$tc(FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS[FireCauseExternOrigin.Barbecue]);
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Lightning) {
            return this.$tc(FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS[FireCauseExternOrigin.Lightning]);
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.Tinkering) {
            return this.$tc(FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS[FireCauseExternOrigin.Tinkering]);
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.ForestFire) {
            return this.$tc(FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS[FireCauseExternOrigin.ForestFire]);
        }
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.dontKnow) {
            return this.$tc(FIRE_CAUSE_EXTERN_ORIGIN_ANSWERS[FireCauseExternOrigin.dontKnow]);
        }
        return null;
    }
    get validateText() {
        if (this.qualification.fireCauseExternOrigin === FireCauseExternOrigin.dontKnow ||
            this.qualification.fireCauseExternOrigin === null) {
            return this.$tc('claim.damage.fire.button.dont_know');
        }
        return this.$tc('claim.damage.fire.button.next_step');
    }
    onTypeOfDamagesClick(fireCauseExternOrigin) {
        this.updateFireDamageQualification({
            qualificationPayload: {
                fireCauseExternOrigin: fireCauseExternOrigin !== this.qualification.fireCauseExternOrigin
                    ? fireCauseExternOrigin
                    : null,
            },
        });
    }
    async validate() {
        if (this.qualification.fireCauseExternOrigin === null) {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireCauseExternOrigin: FireCauseExternOrigin.dontKnow,
                },
                step: this.step,
            });
        }
        await this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireCauseExternOrigin: this.qualification.fireCauseExternOrigin,
            },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireCauseExternOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireCauseExternOriginQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireCauseExternOriginQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireCauseExternOriginQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireCauseExternOriginQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], FireCauseExternOriginQuestion);
export default FireCauseExternOriginQuestion;
