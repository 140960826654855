var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { NUMERIC_CHOICES, YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { LOCKER_DOOR_TYPE_CHOICES } from '@shared/roomDamage/goodDamage';
let LockerDescription = class LockerDescription extends GoodDamageComponent {
    get doorTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_type');
    }
    get doorCountLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.door_count');
    }
    get isLayoutDamagedLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.furniture.locker.is_layout_damaged');
    }
    get doorTypeChoices() {
        return LOCKER_DOOR_TYPE_CHOICES;
    }
    get doorCountChoices() {
        return NUMERIC_CHOICES(3);
    }
    get isLayoutDamagedChoices() {
        return YES_OR_NO_CHOICES;
    }
    get doorType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.doorType) !== null && _b !== void 0 ? _b : null;
    }
    set doorType(doorType) {
        this.updateGoodDamage({ doorType });
    }
    get doorCount() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.doorCount) !== null && _b !== void 0 ? _b : null;
    }
    set doorCount(doorCount) {
        this.updateGoodDamage({ doorCount });
    }
    get isLayoutDamaged() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.isLayoutDamaged) !== null && _b !== void 0 ? _b : null;
    }
    set isLayoutDamaged(isLayoutDamaged) {
        this.updateGoodDamage({ isLayoutDamaged });
    }
};
LockerDescription = __decorate([
    Component({ components: { RadioPrimaryButton } })
], LockerDescription);
export default LockerDescription;
