var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import BreakInDamageChoices from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageChoices.vue';
import { SLIDING_BAY_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/SlidingBayConstants';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let SlidingBayDescription = class SlidingBayDescription extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.showValidation = false;
        this.leavesType = Object.entries(SLIDING_BAY_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES).map(([leavesType, description]) => description
            ? {
                value: leavesType,
                tradKey: description.tradKey,
                image: description.image,
            }
            : null);
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return !this.validation.fieldHasViolations('slidingBayLeavesType');
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
};
__decorate([
    layout.State('color')
], SlidingBayDescription.prototype, "color", void 0);
__decorate([
    breakInDamage.Getter('change')
], SlidingBayDescription.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], SlidingBayDescription.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], SlidingBayDescription.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], SlidingBayDescription.prototype, "validateBreakInDamage", void 0);
SlidingBayDescription = __decorate([
    Component({
        components: {
            BreakInDamageChoices,
            RadioPrimaryButton,
            ClaimsButton,
            BreakInDamageDescriptionSectionCard,
            BreakInDamageDescriptionStep,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
        },
    })
], SlidingBayDescription);
export default SlidingBayDescription;
