var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { FIRE_ELECTRICAL_BOARD_AGE_OPTIONS } from '@shared/constants/fireDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
let FireElectricalBoardAgeQuestion = class FireElectricalBoardAgeQuestion extends QuestionComponent {
    updateClaim(fireElectricalBoardAge) {
        this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireElectricalBoardAge,
            },
            step: this.step,
        });
    }
    get answer() {
        if (!this.fireDamageQualification.fireElectricalBoardAge) {
            return null;
        }
        return FIRE_ELECTRICAL_BOARD_AGE_OPTIONS[this.fireDamageQualification.fireElectricalBoardAge]
            ? this.$tc(FIRE_ELECTRICAL_BOARD_AGE_OPTIONS[this.fireDamageQualification.fireElectricalBoardAge]
                .answerTradKey, 0)
            : null;
    }
    get fireElectricalBoardAgeOptions() {
        return Object.values(FIRE_ELECTRICAL_BOARD_AGE_OPTIONS);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireElectricalBoardAgeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireElectricalBoardAgeQuestion.prototype, "fireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireElectricalBoardAgeQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
__decorate([
    contract.State('selectedContract')
], FireElectricalBoardAgeQuestion.prototype, "selectedContract", void 0);
FireElectricalBoardAgeQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], FireElectricalBoardAgeQuestion);
export default FireElectricalBoardAgeQuestion;
