var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import NumberInput from '@declaration/components/NumberInput.vue';
import { SURFACE_DAMAGE_VETUSTY_CHOICES } from '@shared/constants/roomDamageConstants';
import { COVERING_PLACEHOLDER_TRADKEYS } from '@declaration/constants/roomDamageConstants';
import { surfaceDamageOfType } from '@shared/roomDamage/surfaceDamage';
import { optionalResourceIri } from '@shared/utils/iri';
const roomDamage = namespace('roomDamage');
let SurfaceDamagesInput = class SurfaceDamagesInput extends Vue {
    constructor() {
        super(...arguments);
        this.vetustyChoices = SURFACE_DAMAGE_VETUSTY_CHOICES;
        this.optionalResourceIri = optionalResourceIri;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get roomDamage() {
        return this.change.data;
    }
    get surfaceDamages() {
        if (!this.roomDamage.surfaceDamages) {
            return [];
        }
        return this.roomDamage.surfaceDamages.filter(surfaceDamageOfType(this.surfaceType));
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    surfaceDamageFieldName(surfaceIndex, field) {
        var _a;
        const allSurfaces = ((_a = this.roomDamage.surfaceDamages) !== null && _a !== void 0 ? _a : []);
        const damageIndex = allSurfaces.indexOf(this.surfaceDamages[surfaceIndex]);
        return `surfaceDamages.${damageIndex}.${field}`;
    }
    getSurfaceCoveringPlaceholder(surfaceIndex) {
        return this.$tc(COVERING_PLACEHOLDER_TRADKEYS[this.surfaceType], 0, {
            number: surfaceIndex + 1,
        });
    }
    surfaceDamageValidationErrors(index, field) {
        return this.validation.fieldErrors(this.surfaceDamageFieldName(index, field), this);
    }
    changeNumberOfSurfaceDamages(number) {
        if (number < this.surfaceDamages.length) {
            this.popSurfaceDamage({
                surfaceType: this.surfaceType,
                changeKey: this.changeKey,
            });
        }
        else {
            this.addNewSurfaceDamage({
                surfaceType: this.surfaceType,
                changeKey: this.changeKey,
            });
        }
    }
    switchHasSurfaceDamages(hasSurfaceDamages) {
        if (hasSurfaceDamages) {
            this.addNewSurfaceDamage({
                surfaceType: this.surfaceType,
                changeKey: this.changeKey,
            });
        }
        else {
            this.resetSurfaceDamage({
                surfaceType: this.surfaceType,
                changeKey: this.changeKey,
            });
        }
    }
};
__decorate([
    roomDamage.State('coveringCategoriesBySurfaceType')
], SurfaceDamagesInput.prototype, "coveringCategoriesBySurfaceType", void 0);
__decorate([
    roomDamage.Getter('change')
], SurfaceDamagesInput.prototype, "changeGetter", void 0);
__decorate([
    roomDamage.Getter('validation')
], SurfaceDamagesInput.prototype, "validationGetter", void 0);
__decorate([
    roomDamage.Action('resetSurfaceDamage')
], SurfaceDamagesInput.prototype, "resetSurfaceDamage", void 0);
__decorate([
    roomDamage.Action('addNewSurfaceDamage')
], SurfaceDamagesInput.prototype, "addNewSurfaceDamage", void 0);
__decorate([
    roomDamage.Action('popSurfaceDamage')
], SurfaceDamagesInput.prototype, "popSurfaceDamage", void 0);
__decorate([
    roomDamage.Action('setSurfaceDamageCovering')
], SurfaceDamagesInput.prototype, "setSurfaceDamageCovering", void 0);
__decorate([
    roomDamage.Action('setSurfaceDamageAges')
], SurfaceDamagesInput.prototype, "setSurfaceDamageAges", void 0);
SurfaceDamagesInput = __decorate([
    Component({
        components: {
            NumberInput,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            surfaceType: {
                type: String,
                required: true,
            },
            canHaveMultipleSurface: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                required: true,
            },
        },
    })
], SurfaceDamagesInput);
export default SurfaceDamagesInput;
