var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable no-console */
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PersonalPropertyCategorySelect from '@declaration/components/personalProperty/PersonalPropertyCategorySelect.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { BDGPersonalPropertyInitialCategorySlug, RepairableStatus, } from '@shared/types/api/personalProperty';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import PersonalPropertyProductSearch from '@declaration/components/personalProperty/v2/steps/PersonalPropertyProductSearch.vue';
import PersonalPropertyInfo from '@declaration/components/personalProperty/v2/steps/PersonalPropertyInfo.vue';
import PersonalPropertyReparations from '@declaration/components/personalProperty/v2/steps/PersonalPropertyReparations.vue';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES } from '@shared/constants/personalPropertyConstants';
import { PERSONAL_PROPERTY_QUESTION_TRAD_KEYS } from '@declaration/types/personalProperty';
import { idFromIri, optionalResourceIri } from '@shared/utils/iri';
const claim = namespace('claim');
const qualification = namespace('qualification');
const personalProperty = namespace('personalProperty');
var PersonalPropertyModalStep;
(function (PersonalPropertyModalStep) {
    PersonalPropertyModalStep[PersonalPropertyModalStep["CategoryChoice"] = 0] = "CategoryChoice";
    PersonalPropertyModalStep[PersonalPropertyModalStep["ProductSearch"] = 1] = "ProductSearch";
    PersonalPropertyModalStep[PersonalPropertyModalStep["Info"] = 2] = "Info";
    PersonalPropertyModalStep[PersonalPropertyModalStep["Reparations"] = 3] = "Reparations";
})(PersonalPropertyModalStep || (PersonalPropertyModalStep = {}));
let PersonalPropertyModal = class PersonalPropertyModal extends Vue {
    constructor() {
        super(...arguments);
        this.currentStep = PersonalPropertyModalStep.CategoryChoice;
        this.searchQuery = '';
        this.isLoading = false;
        this.showSearchForm = true;
        this.categoryIri = null;
        this.filteredCategoryProducts = [];
        this.products = [];
    }
    get searchCategoriesTitle() {
        return this.$tc('claim.damage.personal_property.select.category.title');
    }
    get searchProductsTitle() {
        return this.$tc('claim.damage.personal_property.search.products.title');
    }
    get category() {
        return this.personalProperty.category;
    }
    get categoryIRI() {
        return optionalResourceIri(this.category);
    }
    set categoryIRI(value) {
        if (!value) {
            return;
        }
        const category = value ? this.categoriesByIri[value] : null;
        const payload = {
            category: category === null || category === void 0 ? void 0 : category.id,
            designation: this.designation,
            description: this.description,
        };
        if ((category === null || category === void 0 ? void 0 : category.isAmountOnly) || (category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.designation = category.label;
            payload.description = null;
            payload.userKnowPrice = null;
            payload.purchaseCertification = null;
        }
        else if (this.isAmountOnly || this.isIdentityDocument) {
            payload.designation = null;
            payload.receipt = null;
        }
        if (!(category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.documentType = null;
        }
        this.update(payload);
    }
    get designation() {
        var _a;
        return (_a = this.personalProperty.designation) !== null && _a !== void 0 ? _a : null;
    }
    set designation(designation) {
        this.update({ designation });
    }
    get description() {
        var _a;
        return (_a = this.personalProperty.description) !== null && _a !== void 0 ? _a : null;
    }
    set description(description) {
        this.update({ description });
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get headerTitle() {
        if (this.shouldSelectInitialCategory) {
            return this.$tc('claim.damage.personal_property.modal.new');
        }
        if (this.personalProperty.initialCategorySlug) {
            return this.$tc(INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.personalProperty.initialCategorySlug]
                .labelTradKey);
        }
        return this.$tc(PERSONAL_PROPERTY_QUESTION_TRAD_KEYS[this.typeTrigram].form.title, this.change.data.designation ? 1 : 2, {
            designation: this.change.data.designation,
            number: this.personalPropertyIndex + 1,
        });
    }
    get nextStep() {
        switch (this.currentStep) {
            case PersonalPropertyModalStep.CategoryChoice: {
                return this.shouldSearchProduct
                    ? PersonalPropertyModalStep.ProductSearch
                    : PersonalPropertyModalStep.Info;
            }
            case PersonalPropertyModalStep.ProductSearch: {
                return PersonalPropertyModalStep.Info;
            }
            case PersonalPropertyModalStep.Info: {
                return PersonalPropertyModalStep.Reparations;
            }
            case PersonalPropertyModalStep.Reparations: {
                break;
            }
        }
    }
    get prevStep() {
        switch (this.currentStep) {
            case PersonalPropertyModalStep.CategoryChoice: {
                break;
            }
            case PersonalPropertyModalStep.ProductSearch: {
                if (this.hasInitialCategory)
                    return PersonalPropertyModalStep.CategoryChoice;
                break;
            }
            case PersonalPropertyModalStep.Info: {
                if (this.hasProductSearch)
                    return PersonalPropertyModalStep.ProductSearch;
                else if (this.hasInitialCategory)
                    return PersonalPropertyModalStep.CategoryChoice;
                break;
            }
            case PersonalPropertyModalStep.Reparations: {
                return PersonalPropertyModalStep.Info;
            }
        }
    }
    get currentComponent() {
        switch (this.currentStep) {
            case PersonalPropertyModalStep.CategoryChoice:
                // TODO implémenter le composant pour les cas de figure où une catégorie
                // de départ est disponible (Ex: VOL, BDG)
                return { component: 'div' };
            case PersonalPropertyModalStep.ProductSearch:
                return {
                    component: PersonalPropertyProductSearch,
                    props: { value: this.searchQuery, changeKey: this.changeKey },
                    events: {
                        input: (input) => (this.searchQuery = input),
                        selected: this.onProductSelected,
                        missing: this.onProductMissing,
                    },
                };
            case PersonalPropertyModalStep.Info:
                return {
                    component: PersonalPropertyInfo,
                    props: { changeKey: this.changeKey },
                };
            case PersonalPropertyModalStep.Reparations:
                return {
                    component: PersonalPropertyReparations,
                    props: { changeKey: this.changeKey },
                };
        }
    }
    get canValidate() {
        switch (this.currentStep) {
            case PersonalPropertyModalStep.CategoryChoice:
            case PersonalPropertyModalStep.ProductSearch:
                return true;
            case PersonalPropertyModalStep.Info: {
                return ![
                    'category',
                    'documentType',
                    'designation',
                    'description',
                    'userKnowPrice',
                    'age',
                    'amount',
                    'purchaseCertification',
                    'receipt',
                ].some((fieldName) => this.validation.fieldHasViolations(fieldName));
            }
            case PersonalPropertyModalStep.Reparations: {
                return !['repairFileCollection', 'repairQuoteInfo.amount'].some((fieldName) => this.validation.fieldHasViolations(fieldName));
            }
        }
    }
    get nextLabel() {
        if (this.nextStep)
            return 'Suivant';
        return 'Valider';
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get hasInitialCategory() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.InitialPersonalPropertyCategoryChoice);
    }
    get hasProductSearch() {
        return (!this.personalProperty.initialCategorySlug ||
            INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.personalProperty.initialCategorySlug]
                .isProductSearchAvailable);
    }
    get hasReparations() {
        return (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyReparationForm) &&
            ![
                BDGPersonalPropertyInitialCategorySlug.ElectromenagerOuMultimedia,
                BDGPersonalPropertyInitialCategorySlug.Autres,
            ].includes(this.personalProperty.initialCategorySlug));
    }
    get shouldSelectInitialCategory() {
        return this.hasInitialCategory && !this.personalProperty.initialCategorySlug;
    }
    get shouldSearchProduct() {
        return (this.hasProductSearch &&
            this.personalProperty.thirdPartyProductId === null &&
            this.change.data.designation === null);
    }
    get isCategoryModifiable() {
        return this.isCategoryModifiableGetter(this.changeKey);
    }
    async fetchRepairQuoteMandatory() {
        var _a, _b, _c;
        try {
            this.isLoading = true;
            const age = (_a = this.personalProperty.age) !== null && _a !== void 0 ? _a : null;
            const valuation = ((_b = this.personalProperty.thirdPartyProductInfo) === null || _b === void 0 ? void 0 : _b.amount)
                ? this.personalProperty.thirdPartyProductInfo.amount * 100
                : this.personalProperty.amount;
            const discountApplied = !!((_c = this.personalProperty.thirdPartyProductInfo) === null || _c === void 0 ? void 0 : _c.amount);
            const personalPropertyCategoryIri = optionalResourceIri(this.personalProperty.category);
            const personalPropertyCategoryId = personalPropertyCategoryIri
                ? idFromIri(personalPropertyCategoryIri)
                : null;
            const isRepairQuoteMandatory = await this.$axios.$post('/personal-property/is-repair-quote-mandatory', {
                age,
                valuation,
                personalPropertyCategoryId,
                discountApplied,
            });
            this.updatePersonalProperty({ changeKey: this.changeKey, data: { isRepairQuoteMandatory } });
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.isLoading = false;
        }
    }
    async save() {
        try {
            this.isLoading = true;
            await this.savePersonalProperty();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.isLoading = false;
        }
    }
    async next() {
        if (!this.nextStep) {
            await this.save();
            return this.close();
        }
        switch (this.nextStep) {
            case PersonalPropertyModalStep.Reparations:
                await this.fetchRepairQuoteMandatory();
                break;
        }
        this.currentStep = this.nextStep;
    }
    fieldType(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations) && !this.validation.fieldIsValid(key)
            ? 'is-danger'
            : null;
    }
    fieldMessage(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations)
            ? this.validation.fieldErrors(key, this)[0]
            : null;
    }
    goBack() {
        if (!this.prevStep)
            return;
        if (this.prevStep === PersonalPropertyModalStep.ProductSearch) {
            this.updatePersonalProperty({
                changeKey: this.changeKey,
                data: { thirdPartyProductId: null, thirdPartyProductInfo: null, designation: null },
            });
        }
        this.currentStep = this.prevStep;
    }
    cancel() {
        this.resetPersonalProperty();
        this.$emit('close');
        this.searchQuery = '';
        this.showSearchForm = true;
    }
    close() {
        this.$emit('close');
    }
    resetFilteredProducts() {
        this.filteredCategoryProducts = [];
    }
    onProductSelected({ product, createdProduct, }) {
        this.updatePersonalProperty({
            changeKey: this.changeKey,
            data: {
                description: product.brand,
                designation: product.name,
                category: createdProduct.personalPropertyCategory,
                thirdPartyProductId: createdProduct.itemId,
                thirdPartyProductInfo: { ...product, ...createdProduct },
                userKnowPrice: true,
                age: null,
                amount: product.amount * 100,
                purchaseCertification: false,
                repairableStatus: RepairableStatus.Repairable,
                repairQuoteInfo: { amount: null, companyName: null, companyPhoneNumber: null },
            },
        });
        this.next();
    }
    onProductMissing(productName) {
        const payload = {
            designation: productName,
            description: null,
            userKnowPrice: true,
            age: null,
            amount: null,
            purchaseCertification: false,
            repairableStatus: RepairableStatus.Repairable,
            repairQuoteInfo: { amount: null, companyName: null, companyPhoneNumber: null },
        };
        if (this.categoryIRI) {
            const category = this.categoriesByIri[this.categoryIRI];
            if ((category === null || category === void 0 ? void 0 : category.isAmountOnly) || (category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
                payload.designation = category.label;
                payload.description = null;
                payload.userKnowPrice = null;
                payload.purchaseCertification = null;
            }
        }
        if (this.isCategoryModifiable) {
            payload.category = this.categoryIRI;
        }
        this.updatePersonalProperty({
            changeKey: this.changeKey,
            data: payload,
        });
        this.showSearchForm = false;
        this.next();
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validatePersonalProperty(this.changeKey);
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyModal.prototype, "claim", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyModal.prototype, "typeTrigram", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyModal.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyModal.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyModal.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('isCategoryModifiable')
], PersonalPropertyModal.prototype, "isCategoryModifiableGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyModal.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyModal.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyModal.prototype, "updatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('validateAndRevealDamages')
], PersonalPropertyModal.prototype, "validatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('resetChanges')
], PersonalPropertyModal.prototype, "resetPersonalProperty", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], PersonalPropertyModal.prototype, "savePersonalProperty", void 0);
PersonalPropertyModal = __decorate([
    Component({
        components: {
            Modal,
            ClaimsButton,
            PersonalPropertyCategorySelect,
            PersonalPropertyProductSearch,
            PersonalPropertyInfo,
            PersonalPropertyReparations,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
            personalPropertyIndex: { type: Number, default: 0 },
            isDisplayed: { type: Boolean, required: true },
            confirmDelete: { type: Function, required: true },
        },
        watch: {
            isDisplayed(isDisplayed) {
                if (!isDisplayed) {
                    this.searchQuery = '';
                    this.categoryIRI = '';
                }
                else if (this.shouldSelectInitialCategory) {
                    this.currentStep = PersonalPropertyModalStep.CategoryChoice;
                    this.showSearchForm = true;
                }
                else if (this.shouldSearchProduct) {
                    this.currentStep = PersonalPropertyModalStep.ProductSearch;
                    this.showSearchForm = true;
                }
                else {
                    this.currentStep = PersonalPropertyModalStep.Info;
                }
            },
        },
    })
], PersonalPropertyModal);
export default PersonalPropertyModal;
