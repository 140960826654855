var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import CustomRadio from '@declaration/components/Buefy/CustomRadio.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ClaimTypeTrigram, FireCauseLocalOrigin, FireFireplaceAge, FireFireplaceWhoMakesWorks, FireWhoMakesWorks, } from '@shared/types/api/claim';
import { ClaimFileType } from '@shared/types/file';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST, NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST_CHOICES, } from '@shared/constants/thirdParty';
const thirdParty = namespace('thirdParty');
const pages = namespace('pages');
const qualification = namespace('qualification');
let ThirdPartyForm = class ThirdPartyForm extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isReceiptUploading = false;
        this.step = this.activeStep;
    }
    created() {
        if (this.shouldSelectSocietyByDefault) {
            this.updateIsCompany(true);
        }
    }
    get isFire() {
        return this.typeTrigram === ClaimTypeTrigram.Fire;
    }
    get isCriminalAct() {
        return this.qualification.fireCauseLocalOrigin === FireCauseLocalOrigin.CriminalAct;
    }
    get onFireStep() {
        return this.activeStep === 'declaration/tier-info-incendie';
    }
    get invoiceInput() {
        return (this.qualification.fireFireplaceWhoMakesWorks === FireFireplaceWhoMakesWorks.professional ||
            this.qualification.fireWhoMakesWorks === FireWhoMakesWorks.professional);
    }
    get isFileValid() {
        return fileCollectionFilesCount(this.fileCollection) > 0;
    }
    get defaultTitle() {
        return this.$tc('claim.third_party.form.contact.title');
    }
    get invoiceFileType() {
        return ClaimFileType.CraftsmanInvoice;
    }
    get leaseFileType() {
        return ClaimFileType.TenantLease;
    }
    get workInvoiceLabel() {
        return this.$tc('claim.third_party.form.invoice_select.label');
    }
    get tenantLeaseLabel() {
        return this.$tc('tenant_info.lease_upload_label');
    }
    get insuranceCompanyNames() {
        const companyNames = this.rawInsuranceCompanyNames.map((item) => {
            return { value: item, label: item };
        });
        const notListedChoices = NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST_CHOICES.map((item) => {
            return { value: item.value, label: this.$tc(item.tradKey) };
        });
        const separator = { value: 'separator', label: '---', disabled: true };
        return [notListedChoices, separator, companyNames].flat();
    }
    get isCompanyValuesAndTradKeys() {
        if (this.disableCompany) {
            return [
                {
                    value: false,
                    tradKey: 'claim.third_party.form.contact.is_company.false',
                },
            ];
        }
        return [
            {
                value: false,
                tradKey: 'claim.third_party.form.contact.is_company.false',
            },
            {
                value: true,
                tradKey: 'claim.third_party.form.contact.is_company.true',
            },
        ];
    }
    get labelCheckbox() {
        return this.$tc('claim.third_party.form.invoice.title');
    }
    get shouldSelectSocietyByDefault() {
        return ((this.qualification.fireFireplaceAge === FireFireplaceAge.yes &&
            this.qualification.fireFireplaceWhoMakesWorks ===
                FireFireplaceWhoMakesWorks.professional) ||
            this.qualification.fireWhoMakesWorks === FireWhoMakesWorks.professional ||
            this.qualification.fireFireplaceWhoMakesWorks === FireFireplaceWhoMakesWorks.professional);
    }
    get isListedCompany() {
        if (this.thirdParty.insuranceCompany.name) {
            return this.insuranceCompanyNames
                .map((item) => {
                return item.value;
            })
                .includes(this.thirdParty.insuranceCompany.name);
        }
        else
            return false;
    }
    get insuranceCompanyNameFieldDisplay() {
        if (this.insuranceCompanyNamesErrors.length === 0) {
            return '';
        }
        else
            return this.validation.fieldIsValid('insuranceCompany.name') ? '' : 'is-danger';
    }
    get insuranceCompanyNamesErrors() {
        if (this.thirdParty.insuranceCompany.name && this.isListedCompany) {
            return [];
        }
        else
            return this.validation.fieldErrors('insuranceCompany.name', this);
    }
    updateIsCompany(isCompany) {
        this.updateContactOfThirdPartyInEdition({ isCompany });
        if (isCompany) {
            this.updateContactOfThirdPartyInEdition({ lastName: null });
            this.updateContactOfThirdPartyInEdition({ firstName: null });
        }
        else {
            this.updateContactOfThirdPartyInEdition({ companyName: null });
        }
    }
    onFileCollectionUpdate(event) {
        this.$emit('file-collection-update', event);
    }
};
__decorate([
    qualification.State('qualification')
], ThirdPartyForm.prototype, "qualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], ThirdPartyForm.prototype, "typeTrigram", void 0);
__decorate([
    pages.State('activeStep')
], ThirdPartyForm.prototype, "activeStep", void 0);
__decorate([
    thirdParty.State('thirdPartyInEdition')
], ThirdPartyForm.prototype, "thirdParty", void 0);
__decorate([
    thirdParty.Getter('rawInsuranceCompanyNames')
], ThirdPartyForm.prototype, "rawInsuranceCompanyNames", void 0);
__decorate([
    thirdParty.Getter('hasSelectedInsuranceCompany')
], ThirdPartyForm.prototype, "hasSelectedInsuranceCompany", void 0);
__decorate([
    thirdParty.Action('updateThirdPartyInEdition')
], ThirdPartyForm.prototype, "updateThirdPartyInEdition", void 0);
__decorate([
    thirdParty.Action('updateContactOfThirdPartyInEdition')
], ThirdPartyForm.prototype, "updateContactOfThirdPartyInEdition", void 0);
__decorate([
    thirdParty.Action('updateInsuranceCompanyOfThirdPartyInEdition')
], ThirdPartyForm.prototype, "updateInsuranceCompanyOfThirdPartyInEdition", void 0);
__decorate([
    thirdParty.Getter('validation')
], ThirdPartyForm.prototype, "validation", void 0);
ThirdPartyForm = __decorate([
    Component({
        computed: {
            NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST() {
                return NOT_LISTED_IN_INSURANCE_COMPANY_NAMES_LIST;
            },
        },
        components: {
            CustomRadio,
            FileCollectionUpload,
        },
        props: {
            title: {
                type: String,
            },
            disableCompany: {
                type: Boolean,
            },
            isTenantInfos: {
                type: Boolean,
                default: false,
            },
            fileCollection: {
                type: Object,
                default: null,
            },
            hasInvoice: {
                type: Boolean,
                default: false,
            },
            hasVehicle: {
                type: Boolean,
                default: false,
            },
        },
    })
], ThirdPartyForm);
export default ThirdPartyForm;
