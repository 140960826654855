var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import RepairQuoteUpload from '@declaration/components/upload/RepairQuoteUpload.vue';
import { ClaimFileType } from '@shared/types/file';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
let PersonalPropertyReparations = class PersonalPropertyReparations extends Vue {
    get title() {
        return this.isRepairQuoteMandatory
            ? this.$tc('claim.damage.personal_property.form.repair.mandatory.title')
            : this.$tc('claim.damage.personal_property.form.repair.title');
    }
    get message() {
        return this.isRepairQuoteMandatory
            ? this.$tc('claim.damage.personal_property.form.repair.mandatory.text')
            : this.$tc('claim.damage.personal_property.form.repair.text');
    }
    get repairFileType() {
        return ClaimFileType.PersonalPropertyRepairQuote;
    }
    get irreparabilityFileType() {
        return ClaimFileType.PersonalPropertyIrreparabilityCertificate;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get isRepairQuoteMandatory() {
        var _a;
        return (_a = this.personalProperty.isRepairQuoteMandatory) !== null && _a !== void 0 ? _a : false;
    }
    get repairableStatus() {
        var _a;
        return (_a = this.personalProperty.repairableStatus) !== null && _a !== void 0 ? _a : null;
    }
    get repairQuoteUploadData() {
        var _a, _b, _c;
        return {
            repairQuoteInfo: (_a = this.personalProperty.repairQuoteInfo) !== null && _a !== void 0 ? _a : null,
            repairableStatus: (_b = this.personalProperty.repairableStatus) !== null && _b !== void 0 ? _b : null,
            repairFileCollection: ((_c = this.personalProperty.repairFileCollection) !== null && _c !== void 0 ? _c : emptyUploadedFileCollection()),
        };
    }
    set repairQuoteUploadData(repairQuoteUploadData) {
        this.update(repairQuoteUploadData);
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyReparations.prototype, "claim", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyReparations.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyReparations.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyReparations.prototype, "updatePersonalProperty", void 0);
PersonalPropertyReparations = __decorate([
    Component({
        components: { RepairQuoteUpload },
        props: { changeKey: { type: [String, Number], default: null } },
    })
], PersonalPropertyReparations);
export default PersonalPropertyReparations;
