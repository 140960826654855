import { render, staticRenderFns } from "./PersonalPropertyList.vue?vue&type=template&id=71804f79&scoped=true"
import script from "./PersonalPropertyList.vue?vue&type=script&lang=ts"
export * from "./PersonalPropertyList.vue?vue&type=script&lang=ts"
import style0 from "./PersonalPropertyList.vue?vue&type=style&index=0&id=71804f79&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71804f79",
  null
  
)

export default component.exports