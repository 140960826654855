var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import formatAmount from '@shared/utils/formatAmount';
import { Step } from '@shared/claim/steps';
import { ClaimCompensationOption } from '@shared/types/api/claim';
import { forcedAmount, forcedAmountWithDefault } from '@shared/utils/forcedAmount';
const claim = namespace('claim');
let TermsAndConditionsModal = class TermsAndConditionsModal extends Vue {
    constructor() {
        super(...arguments);
        this.checked = false;
        this.formatAmount = formatAmount;
    }
    get conditionItems() {
        const terms = [];
        switch (this.claim.chosenCompensationOption) {
            case ClaimCompensationOption.SelfRepair:
                return [
                    this.$tc('compensation.term_modal.self_repair.check_1'),
                    this.$tc('compensation.term_modal.self_repair.check_2', 0, {
                        compensation: formatAmount(forcedAmountWithDefault(this.compensationTotal, 0) / 100),
                    }),
                    this.$tc('compensation.term_modal.self_repair.check_3'),
                    this.$tc('compensation.term_modal.self_repair.check_4'),
                ];
            case ClaimCompensationOption.OtherArtisan:
                if (forcedAmount(this.compensationDelayed) && forcedAmount(this.compensationImmediate)) {
                    return [
                        this.$tc('compensation.term_modal.other_artisan.check_1'),
                        this.$tc('compensation.term_modal.other_artisan.check_2', 0, {
                            compensationImmediate: formatAmount(forcedAmountWithDefault(this.compensationImmediate, 0) / 100),
                            compensationDelayed: formatAmount(forcedAmountWithDefault(this.compensationDelayed, 0) / 100),
                        }),
                        this.$tc('compensation.term_modal.other_artisan.check_3'),
                        this.$tc('compensation.term_modal.other_artisan.check_4'),
                    ];
                }
                else if (forcedAmount(this.compensationDelayed)) {
                    return [
                        this.$tc('compensation.term_modal.other_artisan.check_1'),
                        this.$tc('compensation.term_modal.other_artisan.check_2.no_immediate', 0, {
                            compensation: formatAmount(forcedAmountWithDefault(this.compensationTotal, 0) / 100),
                        }),
                        this.$tc('compensation.term_modal.other_artisan.check_3'),
                        this.$tc('compensation.term_modal.other_artisan.check_4'),
                    ];
                }
                else {
                    return [
                        this.$tc('compensation.term_modal.other_artisan.check_1'),
                        this.$tc('compensation.term_modal.other_artisan.check_2.no_delayed', 0, {
                            compensation: formatAmount(forcedAmountWithDefault(this.compensationTotal, 0) / 100),
                        }),
                        this.$tc('compensation.term_modal.other_artisan.check_3'),
                        this.$tc('compensation.term_modal.other_artisan.check_4'),
                    ];
                }
            case ClaimCompensationOption.PartnerArtisan:
                terms.push(this.$tc('compensation.term_modal.artisan_partner.check_1'));
                if (forcedAmountWithDefault(this.compensationTotal, 0)) {
                    terms.push(this.$tc('compensation.term_modal.artisan_partner.check_2', 0, {
                        compensation: formatAmount(forcedAmountWithDefault(this.compensationTotal, 0) / 100),
                    }));
                }
                terms.push(this.$tc('compensation.term_modal.artisan_partner.check_3'), this.$tc('compensation.term_modal.artisan_partner.check_4'), this.$tc('compensation.term_modal.artisan_partner.check_5'));
                return terms;
            default:
                return [
                    this.$tc('compensation.term_modal.default.check_1'),
                    this.$tc('compensation.term_modal.default.check_2', 0, {
                        compensation: formatAmount(forcedAmountWithDefault(this.compensationTotal, 0) / 100),
                    }),
                    this.$tc('compensation.term_modal.default.check_3'),
                    this.$tc('compensation.term_modal.default.check_4'),
                ];
        }
    }
    close() {
        this.$emit('close-terms-and-conditions-modal');
    }
    saveConfirmation() {
        this.updateAndSaveClaim({
            step: Step.CompensationProposition,
            claimPayload: {
                isCompensationOptionAccepted: true,
                termsAndConditionsAccepted: true,
            },
        });
    }
};
__decorate([
    claim.State('claim')
], TermsAndConditionsModal.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], TermsAndConditionsModal.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], TermsAndConditionsModal.prototype, "updateAndSaveClaim", void 0);
TermsAndConditionsModal = __decorate([
    Component({
        components: { Modal, ClaimsButton },
        props: {
            isDisplayed: { type: Boolean, required: true },
            compensationTotal: { type: Object, required: true },
            compensationImmediate: { type: Object, required: true },
            compensationDelayed: { type: Object, required: true },
        },
    })
], TermsAndConditionsModal);
export default TermsAndConditionsModal;
