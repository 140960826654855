var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import * as Sentry from '@sentry/browser';
import { CONFIG } from '@shared/environment/environment';
import { INSURER } from '@shared/insurer';
let Exception = class Exception extends Vue {
    triggerError() {
        throw new Error('Test exception');
    }
    async triggerAsyncComponentError() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        await new Promise((resolve, reject) => {
            reject(new Error('Test async component exception'));
        });
    }
    triggerAsyncError() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-new
        new Promise((resolve, reject) => {
            reject(new Error('Test async exception'));
        });
    }
    get sentryInfo() {
        var _a, _b;
        const client = Sentry.getCurrentHub().getClient();
        return [
            ['Activé', client ? 'Oui' : 'Non'],
            ['Release', (_a = CONFIG.release) !== null && _a !== void 0 ? _a : '-'],
            ['Insurer', INSURER !== null && INSURER !== void 0 ? INSURER : '-'],
            ['Environment', (_b = CONFIG.nodeEnv) !== null && _b !== void 0 ? _b : '-'],
        ];
    }
};
Exception = __decorate([
    Component({
        components: {},
        meta: {
            close: 'index',
        },
    })
], Exception);
export default Exception;
