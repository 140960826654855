var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import RoomDamageCard from '@declaration/components/roomDamage/RoomDamageCard.vue';
import RoomDamageModal from '@declaration/components/roomDamage/RoomDamageModal.vue';
import AddButton from '@declaration/components/UI/AddButton.vue';
import { roomDamageTitle } from '@shared/roomDamage/roomDamageFields';
import { EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE, } from '@shared/roomDamage/surfaceDamage';
const roomDamage = namespace('roomDamage');
const layout = namespace('layout');
let RoomDamagesList = class RoomDamagesList extends Vue {
    constructor() {
        super(...arguments);
        this.isFetchingCoveringCategories = false;
        this.isRoomDamagesModalOpen = false;
        this.selectedchangeKey = null;
        this.coveringCategories = null;
        this.coveringCategoriesBySurfaceType = EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE;
    }
    async created() {
        this.isFetchingCoveringCategories = true;
        await this.fetchCoveringCategoriesIfNotSet();
        this.isFetchingCoveringCategories = false;
    }
    closeModal() {
        this.isRoomDamagesModalOpen = false;
    }
    selectRoomDamageAndOpenModal(roomDamage) {
        this.selectedchangeKey = roomDamage.id;
        this.isRoomDamagesModalOpen = true;
    }
    addNewRoomDamage() {
        this.selectedchangeKey = null;
        this.isRoomDamagesModalOpen = true;
    }
    title(damage, index) {
        const positionSuffix = this.getRoomDamagePositionSuffix(damage.roomNameSlug, index);
        return (roomDamageTitle(damage, this.$tc.bind(this)) + (positionSuffix ? ` ${positionSuffix}` : ''));
    }
};
__decorate([
    roomDamage.Getter('changedDamages')
], RoomDamagesList.prototype, "roomDamages", void 0);
__decorate([
    roomDamage.Action('fetchCoveringCategoriesIfNotSet')
], RoomDamagesList.prototype, "fetchCoveringCategoriesIfNotSet", void 0);
__decorate([
    roomDamage.Getter('getRoomDamagePositionSuffix')
], RoomDamagesList.prototype, "getRoomDamagePositionSuffix", void 0);
__decorate([
    layout.Action('setIsHeaderHiddenOnMobile')
], RoomDamagesList.prototype, "setIsHeaderHiddenOnMobile", void 0);
RoomDamagesList = __decorate([
    Component({
        components: {
            RoomDamageCard,
            RoomDamageModal,
            AddButton,
        },
        props: {
            confirmDelete: { type: Function, required: true },
        },
    })
], RoomDamagesList);
export default RoomDamagesList;
