var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ContractStatus, HousingTypeSlug } from '@shared/types/api/contract';
import { CONTRACT_STATUSES_TRAD_KEYS } from '@shared/contract/labels';
let ContractCard = class ContractCard extends Vue {
    get image() {
        return this.contract.housingType.slug === HousingTypeSlug.Apartment
            ? require('@declaration/assets/images/housing-type/appartment.svg')
            : require('@declaration/assets/images/housing-type/house.svg');
    }
    get title() {
        return `${this.contract.specialConditions.zipcode} ${this.contract.specialConditions.city}`;
    }
    get address() {
        return this.contract.specialConditions.address;
    }
    get info() {
        return `${this.contract.formula} - n°${this.contract.number}`;
    }
    get statusText() {
        return this.$tc(CONTRACT_STATUSES_TRAD_KEYS[this.contract.status]);
    }
    get isSuspended() {
        return this.contract.status === ContractStatus.Suspended;
    }
    get isCanceled() {
        return this.contract.status === ContractStatus.Cancelled;
    }
    get isDisabled() {
        return this.isSuspended || this.isCanceled;
    }
};
ContractCard = __decorate([
    Component({
        props: {
            contract: {
                type: Object,
                required: true,
            },
        },
    })
], ContractCard);
export default ContractCard;
