var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, VModel } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { DOOR_WINDOWS_BROKEN_TITLE } from '@declaration/constants/externalDamages/DoorConstants';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
let DoorWindowsIsBroken = class DoorWindowsIsBroken extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.doorHasWindowsBrokenChoices = YES_OR_NO_CHOICES;
    }
    get title() {
        return this.translateExternalDamageKey(DOOR_WINDOWS_BROKEN_TITLE);
    }
};
__decorate([
    VModel({ type: Boolean, default: () => null })
], DoorWindowsIsBroken.prototype, "doorHasBrokenTiles", void 0);
DoorWindowsIsBroken = __decorate([
    Component({ components: { RadioPrimaryButton } })
], DoorWindowsIsBroken);
export default DoorWindowsIsBroken;
