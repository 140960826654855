var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let OwnerHasDamagesOnTenantPropertiesQuestion = class OwnerHasDamagesOnTenantPropertiesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalOpen = false;
        this.leaseFileCollection = null;
    }
    updateClaim(ownerHasDamagesOnTenantProperties) {
        this.updateAndSaveQualification({
            qualificationPayload: { ownerHasDamagesOnTenantProperties },
            step: this.step,
        });
    }
    get title() {
        return this.$tc('claim.qualification.default.damages-on-tenant-personal-properties.title');
    }
    get answer() {
        if (this.qualification.ownerHasDamagesOnTenantProperties === null) {
            return null;
        }
        return this.qualification.isLeaveBeforeSinister
            ? this.$tc('claim.qualification.leave-before-sinister.owner.sumup.yes')
            : this.$tc('claim.qualification.leave-before-sinister.owner.sumup.no');
    }
    handleButtonClick(ownerHasDamagesOnTenantProperties) {
        this.updateClaim(ownerHasDamagesOnTenantProperties);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], OwnerHasDamagesOnTenantPropertiesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], OwnerHasDamagesOnTenantPropertiesQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], OwnerHasDamagesOnTenantPropertiesQuestion.prototype, "updateAndSaveQualification", void 0);
OwnerHasDamagesOnTenantPropertiesQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ThirdPartyModal },
        props: QuestionProps,
    })
], OwnerHasDamagesOnTenantPropertiesQuestion);
export default OwnerHasDamagesOnTenantPropertiesQuestion;
