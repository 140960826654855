var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ExternalDamageComponent } from '../ExternalDamageComponent';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import Select from '@declaration/components/Select.vue';
import { GATE_IS_MOTORIZED_CHOICES } from '@shared/constants/externalDamages';
import { GATE_FILL_STYLE_CHOICES, GATE_HEIGHT_CHOICES, GATE_HEIGHT_TITLE, GATE_MATERIAL_CHOICES, GATE_MATERIAL_TITLE, GATE_OPENING_TITLE, GATE_PANEL_FILL_STYLE_TITLE, GATE_PANEL_FILL_STYLE_SUBTITLE, GATE_TYPE_CHOICES, GATE_TYPE_TITLE, GATE_TYPE_SUBTITLE, GATE_WIDTH_CHOICES, GATE_WIDTH_TITLE, GATE_MATERIAL_PLACEHOLDER, GATE_HEIGHT_PLACEHOLDER, GATE_WIDTH_PLACEHOLDER, } from '@declaration/constants/externalDamages';
const externalDamage = namespace('externalDamage');
let Gate = class Gate extends ExternalDamageComponent {
    get typeTitle() {
        return this.translateExternalDamageKey(GATE_TYPE_TITLE);
    }
    get typeSubtitle() {
        return this.translateExternalDamageKey(GATE_TYPE_SUBTITLE);
    }
    get panelFillStyleTitle() {
        return this.translateExternalDamageKey(GATE_PANEL_FILL_STYLE_TITLE);
    }
    get panelFillStyleSubtitle() {
        return this.translateExternalDamageKey(GATE_PANEL_FILL_STYLE_SUBTITLE);
    }
    get materialTitle() {
        return this.translateExternalDamageKey(GATE_MATERIAL_TITLE);
    }
    get materialPlaceholder() {
        return this.translateExternalDamageKey(GATE_MATERIAL_PLACEHOLDER);
    }
    get openingTitle() {
        return this.translateExternalDamageKey(GATE_OPENING_TITLE);
    }
    get heightTitle() {
        return this.translateExternalDamageKey(GATE_HEIGHT_TITLE);
    }
    get heightPlaceholder() {
        return this.translateExternalDamageKey(GATE_HEIGHT_PLACEHOLDER);
    }
    get widthTitle() {
        return this.translateExternalDamageKey(GATE_WIDTH_TITLE);
    }
    get widthPlaceholder() {
        return this.translateExternalDamageKey(GATE_WIDTH_PLACEHOLDER);
    }
    get typeChoices() {
        return GATE_TYPE_CHOICES;
    }
    get panelFillStyleChoices() {
        return GATE_FILL_STYLE_CHOICES;
    }
    get materialChoices() {
        return GATE_MATERIAL_CHOICES;
    }
    get widthChoices() {
        return GATE_WIDTH_CHOICES;
    }
    get heightChoices() {
        return GATE_HEIGHT_CHOICES;
    }
    get isMotorizedChoices() {
        return GATE_IS_MOTORIZED_CHOICES;
    }
    get type() {
        var _a;
        return (_a = this.externalDamage.type) !== null && _a !== void 0 ? _a : null;
    }
    set type(type) {
        this.updateExternalDamage({ type });
    }
    get panelFillStyle() {
        var _a;
        return (_a = this.externalDamage.panelFillStyle) !== null && _a !== void 0 ? _a : null;
    }
    set panelFillStyle(panelFillStyle) {
        this.updateExternalDamage({ panelFillStyle });
    }
    get material() {
        var _a;
        return (_a = this.externalDamage.material) !== null && _a !== void 0 ? _a : null;
    }
    set material(material) {
        this.updateExternalDamage({ material });
    }
    get isOpeningMotorized() {
        var _a;
        return (_a = this.externalDamage.isOpeningMotorized) !== null && _a !== void 0 ? _a : null;
    }
    set isOpeningMotorized(isOpeningMotorized) {
        this.updateExternalDamage({ isOpeningMotorized });
    }
    get heightSlug() {
        var _a;
        return (_a = this.externalDamage.heightSlug) !== null && _a !== void 0 ? _a : null;
    }
    set heightSlug(heightSlug) {
        this.updateExternalDamage({ heightSlug });
    }
    get widthSlug() {
        var _a;
        return (_a = this.externalDamage.widthSlug) !== null && _a !== void 0 ? _a : null;
    }
    set widthSlug(widthSlug) {
        this.updateExternalDamage({ widthSlug });
    }
};
__decorate([
    externalDamage.State('gateTypes')
], Gate.prototype, "gateAllowanceTypes", void 0);
Gate = __decorate([
    Component({ components: { RadioImageButton, RadioPrimaryButton, Select } })
], Gate);
export default Gate;
