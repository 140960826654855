var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
const receipt = namespace('receipt');
let ReceiptCard = class ReceiptCard extends Vue {
    constructor() {
        super(...arguments);
        this.isDeleting = false;
    }
    get title() {
        if (typeof this.receipt.receiptFile.uploadedAppFiles[0] !== 'string') {
            return this.receipt.receiptFile.uploadedAppFiles[0].originalName.concat(this.receipt.receiptFile.uploadedAppFiles.length > 1
                ? this.$tc('claim.damage.real_estate.quote.card.additional_files', 0, {
                    number: this.receipt.receiptFile.uploadedAppFiles.length - 1,
                })
                : '');
        }
        else {
            return this.$tc('claim.declaration.room_damages.receipts.list.placeholder');
        }
    }
    onDelete() {
        this.confirmDelete(this.receipt.id, this.deleteReceipt);
    }
    async deleteReceipt() {
        this.isDeleting = true;
        await this.delete(this.receipt.id);
        await this.saveChanges();
        this.isDeleting = false;
    }
};
__decorate([
    receipt.Action('deleteDamage')
], ReceiptCard.prototype, "delete", void 0);
__decorate([
    receipt.Action('saveChanges')
], ReceiptCard.prototype, "saveChanges", void 0);
ReceiptCard = __decorate([
    Component({
        props: {
            receipt: {
                type: Object,
                required: true,
            },
            confirmDelete: {
                type: Function,
                required: true,
            },
            receiptIndex: {
                type: Number,
                required: true,
            },
        },
    })
], ReceiptCard);
export default ReceiptCard;
