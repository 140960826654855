var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PictureUpload from '@declaration/components/upload/PictureUpload.vue';
import { FILE_SIZE_LIMIT10_MBYTES } from '@shared/constants/fileConstants';
import { fetchFile } from '@shared/files/fetchFile';
import { isImage, isPdf } from '@shared/files/mimeTypes';
import { uploadFile } from '@shared/files/uploadFile';
import { resourceIri } from '@shared/utils/iri';
import { hasValue } from '@shared/utils/isNotNull';
import { padArray } from '@shared/utils/padArray';
const claim = namespace('claim');
const EMPTY_PICTURE = {
    isFetching: false,
    isUploading: false,
    uploaded: null,
};
const UPLOADING_PICTURE = {
    isFetching: false,
    isUploading: true,
    uploaded: null,
};
const PICTURES_COUNT = 4;
const EMPTY_PICTURES_ARRAY = Array.from({ length: PICTURES_COUNT }, () => EMPTY_PICTURE);
let PictureCollectionUpload = class PictureCollectionUpload extends Vue {
    constructor() {
        super(...arguments);
        this.pictures = EMPTY_PICTURES_ARRAY;
    }
    mounted() {
        this.$watch('fileCollection', (fileCollection) => {
            if (!fileCollection) {
                this.pictures = EMPTY_PICTURES_ARRAY;
                return;
            }
            const files = padArray(fileCollection.uploadedAppFiles, PICTURES_COUNT, null);
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (file === null) {
                    this.setPictureUpload(index, EMPTY_PICTURE);
                    continue;
                }
                if (typeof file === 'string') {
                    // Lazily load files
                    fetchFile(file, this.$axios).then((uploaded) => {
                        this.setPictureUpload(index, { isFetching: false, isUploading: false, uploaded });
                    });
                    continue;
                }
                this.setPictureUpload(index, { isFetching: false, isUploading: false, uploaded: file });
            }
        }, { immediate: true });
    }
    get picturesIri() {
        return this.pictures
            .map((picture) => { var _a; return (_a = picture === null || picture === void 0 ? void 0 : picture.uploaded) === null || _a === void 0 ? void 0 : _a.id; })
            .filter(hasValue)
            .map(resourceIri);
    }
    async onUpload(file, index) {
        const claimId = this.claim.id;
        if (!claimId) {
            return;
        }
        if (!file) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
            return;
        }
        if (file.size >= FILE_SIZE_LIMIT10_MBYTES) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.upload.file_size_greater_than_10_mo'));
            return;
        }
        if (!isImage(file.type) && !isPdf(file.type)) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.upload.file_image_or_pdf'));
            return;
        }
        this.setPictureUpload(index, UPLOADING_PICTURE);
        try {
            const uploaded = await uploadFile(file, claimId, this.claimFileType, this.$axios);
            this.update([...this.picturesIri, uploaded.id]);
        }
        catch (err) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
    }
    // Delete should not actually remove files as file collection can be
    // attached to an entity or form for which edition can be canceled
    onDelete(index) {
        this.update(this.picturesIri.filter((_, pictureIndex) => index !== pictureIndex));
    }
    update(uploadedAppFiles) {
        var _a;
        const payload = { ...((_a = this.fileCollection) !== null && _a !== void 0 ? _a : { id: null }), uploadedAppFiles };
        this.$emit('update', payload);
    }
    setPictureUpload(index, picture) {
        Vue.set(this.pictures, index, picture);
    }
};
__decorate([
    claim.State('claim')
], PictureCollectionUpload.prototype, "claim", void 0);
PictureCollectionUpload = __decorate([
    Component({
        components: { PictureUpload },
        props: {
            fileCollection: {
                type: Object,
                required: false,
            },
            claimFileType: {
                type: String,
                required: true,
            },
        },
        model: {
            prop: 'fileCollection',
            event: 'update',
        },
    })
], PictureCollectionUpload);
export default PictureCollectionUpload;
