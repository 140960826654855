var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import FlatCrossSection from '@declaration/components/svg/FlatCrossSection.vue';
import HouseCrossSection from '@declaration/components/svg/HouseCrossSection.vue';
import { DEFAULT_HOUSING_CONFIGURATION, HOUSING_CONFIGURATION_BY_HOUSING_TYPE, } from '@declaration/constants/equipmentConstants';
import { ROOM_CONFIGURATION_BY_ROOM_TYPE, } from '@shared/constants/equipmentConstants';
import RoomKitchen from '@declaration/components/svg/RoomKitchen.vue';
import RoomWC from '@declaration/components/svg/RoomWC.vue';
import RoomBedroomOffice from '@declaration/components/svg/RoomBedroomOffice.vue';
import RoomCorridorEntrance from '@declaration/components/svg/RoomCorridorEntrance.vue';
import RoomBathroom from '@declaration/components/svg/RoomBathroom.vue';
import RoomLounge from '@declaration/components/svg/RoomLounge.vue';
import RoomGarage from '@declaration/components/svg/RoomGarage.vue';
import RoomBasement from '@declaration/components/svg/RoomBasement.vue';
import RoomLaundry from '@declaration/components/svg/RoomLaundry.vue';
import { INSURER_CONFIG } from '@shared/insurer';
const layout = namespace('layout');
const qualification = namespace('qualification');
const contract = namespace('contract');
let LeakageCauseRoomQuestion = class LeakageCauseRoomQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.hoveredRoom = null;
    }
    get housingConfiguration() {
        var _a, _b;
        if (this.selectedContract === null) {
            return DEFAULT_HOUSING_CONFIGURATION;
        }
        const housingType = this.selectedContract.housingType.slug;
        const customConfig = ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.roomDamagesContents) === null || _b === void 0 ? void 0 : _b.housingConfigurationByHousingType) ||
            null;
        return ((customConfig === null || customConfig === void 0 ? void 0 : customConfig[housingType]) ||
            HOUSING_CONFIGURATION_BY_HOUSING_TYPE[housingType] ||
            DEFAULT_HOUSING_CONFIGURATION);
    }
    get rooms() {
        return this.housingConfiguration.rooms;
    }
    get otherRooms() {
        return this.housingConfiguration.otherRooms;
    }
    get roomValuesAndTradKeys() {
        return Object.values(this.rooms).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_ROOM[slug].tradKey,
            };
        });
    }
    get roomValuesAndTradKeysWithSvgComponent() {
        return this.roomValuesAndTradKeys.filter(({ value }) => {
            return !!this.componentForRoom(value);
        });
    }
    get otherRoomValuesAndTradKeys() {
        return Object.values(this.otherRooms).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_ROOM[slug].tradKey,
            };
        });
    }
    get otherRoomAndRoomWithoutSvgComponentValuesAndTradKeys() {
        return this.roomValuesAndTradKeys
            .filter(({ value }) => {
            return !this.componentForRoom(value);
        })
            .concat(this.otherRoomValuesAndTradKeys);
    }
    componentForRoom(roomValue) {
        if (!ROOM_CONFIGURATION_BY_ROOM_TYPE[roomValue]) {
            return '';
        }
        return ROOM_CONFIGURATION_BY_ROOM_TYPE[roomValue].svgComponentName;
    }
    updateClaim(leakageCauseRoomSlug) {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                leakageCauseDetailSlug: null,
                leakageCauseEquipmentSlug: null,
                leakageCauseRoomSlug,
            },
            step: this.step,
        });
    }
    updateHover(leakageCauseRoomSlug) {
        this.hoveredRoom = leakageCauseRoomSlug;
    }
    get answer() {
        if (this.waterDamageQualification.leakageCauseRoomSlug === null) {
            return null;
        }
        if (!QUALIFICATION_ROOM[this.waterDamageQualification.leakageCauseRoomSlug]) {
            return this.$tc('claim.qualification.leakage_cause_room.sumup.default');
        }
        return this.$tc(QUALIFICATION_ROOM[this.waterDamageQualification.leakageCauseRoomSlug]
            .leakageCauseRoomAnswerTradKey);
    }
};
__decorate([
    contract.State('selectedContract')
], LeakageCauseRoomQuestion.prototype, "selectedContract", void 0);
__decorate([
    qualification.State('qualification')
], LeakageCauseRoomQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseRoomQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    layout.Getter('isDesktop')
], LeakageCauseRoomQuestion.prototype, "isDesktop", void 0);
LeakageCauseRoomQuestion = __decorate([
    Component({
        components: {
            RoomKitchen,
            RoomWC,
            RoomBedroomOffice,
            RoomCorridorEntrance,
            RoomBathroom,
            RoomLounge,
            RoomGarage,
            RoomBasement,
            RoomLaundry,
            HouseCrossSection,
            FlatCrossSection,
            RadioPrimaryButton,
            QuestionPanel,
        },
        props: QuestionProps,
    })
], LeakageCauseRoomQuestion);
export default LeakageCauseRoomQuestion;
