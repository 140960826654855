var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import RoomOutbuilding from '@declaration/components/svg/RoomOutbuilding.vue';
import RoomBasement from '@declaration/components/svg/RoomBasement.vue';
import RoomBathroom from '@declaration/components/svg/RoomBathroom.vue';
import RoomBedroomOffice from '@declaration/components/svg/RoomBedroomOffice.vue';
import RoomCorridorEntrance from '@declaration/components/svg/RoomCorridorEntrance.vue';
import RoomGarage from '@declaration/components/svg/RoomGarage.vue';
import RoomKitchen from '@declaration/components/svg/RoomKitchen.vue';
import RoomLaundry from '@declaration/components/svg/RoomLaundry.vue';
import RoomLounge from '@declaration/components/svg/RoomLounge.vue';
import RoomWC from '@declaration/components/svg/RoomWC.vue';
import RoomOther from '@declaration/components/svg/RoomOther.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import OutlinedNumberInput from '@declaration/components/OutlinedNumberInput.vue';
import { DEFAULT_HOUSING_CONFIGURATION, HOUSING_CONFIGURATION_BY_HOUSING_TYPE, INC_HOUSING_CONFIGURATION, } from '@declaration/constants/equipmentConstants';
import { ROOM_CONFIGURATION_BY_ROOM_TYPE, } from '@shared/constants/equipmentConstants';
import { QUALIFICATION_ROOM, roomMaxCount } from '@shared/roomDamage/constants';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const counting = namespace('counting');
const roomDamage = namespace('roomDamage');
const contract = namespace('contract');
const layout = namespace('layout');
const qualification = namespace('qualification');
let NumberOfRoomDamagesQuestion = class NumberOfRoomDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.roomSlugsWithNumberInput = [];
    }
    get housingConfiguration() {
        var _a, _b, _c;
        if (this.typeTrigram === ClaimTypeTrigram.Fire) {
            return INC_HOUSING_CONFIGURATION;
        }
        if (this.selectedContract === null) {
            return DEFAULT_HOUSING_CONFIGURATION;
        }
        const customConfig = ((_c = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.roomDamagesContents) === null || _b === void 0 ? void 0 : _b.housingConfigurationByHousingType) === null || _c === void 0 ? void 0 : _c[this.selectedContract.housingType.slug]) || null;
        return (customConfig ||
            HOUSING_CONFIGURATION_BY_HOUSING_TYPE[this.selectedContract.housingType.slug] ||
            DEFAULT_HOUSING_CONFIGURATION);
    }
    get rooms() {
        return this.housingConfiguration.rooms;
    }
    get otherRooms() {
        return this.housingConfiguration.otherRooms;
    }
    get roomValuesAndTradKeys() {
        return Object.values(this.rooms).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_ROOM[slug].tradKey,
                maxCount: roomMaxCount(slug),
                areDamages: this.areDamagesInCategory(slug),
                damagesCount: this.damagesCountInCategory(slug),
                component: this.componentForRoom(slug),
            };
        });
    }
    get roomValuesAndTradKeysWithSvgComponent() {
        return this.roomValuesAndTradKeys.filter(({ value }) => {
            return !!this.componentForRoom(value);
        });
    }
    get otherRoomValuesAndTradKeys() {
        return Object.values(this.otherRooms).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_ROOM[slug].tradKey,
                maxCount: roomMaxCount(slug),
                areDamages: this.areDamagesInCategory(slug),
                damagesCount: this.damagesCountInCategory(slug),
                component: this.componentForRoom(slug),
            };
        });
    }
    get otherRoomAndRoomWithoutSvgComponentValuesAndTradKeys() {
        return this.roomValuesAndTradKeys
            .filter(({ value }) => {
            return !this.componentForRoom(value);
        })
            .concat(this.otherRoomValuesAndTradKeys);
    }
    componentForRoom(roomValue) {
        if (!ROOM_CONFIGURATION_BY_ROOM_TYPE[roomValue]) {
            return '';
        }
        return ROOM_CONFIGURATION_BY_ROOM_TYPE[roomValue].svgComponentName;
    }
    onValidationButtonClick() {
        if (this.hasDeclaredRoom) {
            this.saveChanges({ step: this.step });
        }
        else {
            this.updateAndSaveCounting({
                countingPayload: { hasRoomDamages: false, roomDamages: [] },
                step: this.step,
            });
        }
    }
    get hasDeclaredRoom() {
        return this.damagesCount !== null && this.damagesCount > 0;
    }
    get answer() {
        if (this.damagesCount === null) {
            return null;
        }
        return this.$tc('claim.damage.real_estate.number_of_room.sumup', this.damagesCount, {
            roomCount: this.damagesCount,
        });
    }
    get validateButtonText() {
        return this.$tc('claim.qualification.number_of_room.validate', this.hasDeclaredRoom ? 2 : 1);
    }
    addCategory(roomSlug, damagesCount, maxCount) {
        if (damagesCount < maxCount) {
            this.addWithInitialCategory(roomSlug);
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], NumberOfRoomDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    roomDamage.Action('saveChanges')
], NumberOfRoomDamagesQuestion.prototype, "saveChanges", void 0);
__decorate([
    counting.Action('updateAndSaveCounting')
], NumberOfRoomDamagesQuestion.prototype, "updateAndSaveCounting", void 0);
__decorate([
    roomDamage.Getter('damagesCountInCategory')
], NumberOfRoomDamagesQuestion.prototype, "damagesCountInCategory", void 0);
__decorate([
    roomDamage.Getter('areDamagesInCategory')
], NumberOfRoomDamagesQuestion.prototype, "areDamagesInCategory", void 0);
__decorate([
    roomDamage.Getter('damagesCount')
], NumberOfRoomDamagesQuestion.prototype, "damagesCount", void 0);
__decorate([
    contract.State('selectedContract')
], NumberOfRoomDamagesQuestion.prototype, "selectedContract", void 0);
__decorate([
    layout.State('isMobile')
], NumberOfRoomDamagesQuestion.prototype, "isMobile", void 0);
__decorate([
    roomDamage.Action('deleteWithInitialCategory')
], NumberOfRoomDamagesQuestion.prototype, "deleteWithInitialCategory", void 0);
__decorate([
    roomDamage.Action('addWithInitialCategory')
], NumberOfRoomDamagesQuestion.prototype, "addWithInitialCategory", void 0);
__decorate([
    roomDamage.Action('updateDamagesCountInCategory')
], NumberOfRoomDamagesQuestion.prototype, "updateDamagesCountInCategory", void 0);
__decorate([
    qualification.State('qualification')
], NumberOfRoomDamagesQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], NumberOfRoomDamagesQuestion.prototype, "typeTrigram", void 0);
NumberOfRoomDamagesQuestion = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            QuestionPanel,
            RoomKitchen,
            RoomWC,
            RoomBedroomOffice,
            RoomCorridorEntrance,
            RoomBathroom,
            RoomLounge,
            RoomGarage,
            RoomBasement,
            RoomLaundry,
            RoomOther,
            RoomOutbuilding,
            OutlinedNumberInput,
            ClaimsButton,
        },
        props: QuestionProps,
    })
], NumberOfRoomDamagesQuestion);
export default NumberOfRoomDamagesQuestion;
