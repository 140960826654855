var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Modal from '@declaration/components/UI/Modal.vue';
import ElectricalDamageTypeSection from '@declaration/components/electricalDamage/sections/ElectricalDamageTypeSection.vue';
import ElectricalDamageDetailsSection from '@declaration/components/electricalDamage/sections/ElectricalDamageDetailsSection.vue';
import { electricalDamageTitle } from '@shared/electricalDamage/electricalDamageFields';
const electricalDamage = namespace('electricalDamage');
var Sections;
(function (Sections) {
    Sections[Sections["DamageType"] = 0] = "DamageType";
    Sections[Sections["DamageDetails"] = 1] = "DamageDetails";
})(Sections || (Sections = {}));
let ElectricalDamageModal = class ElectricalDamageModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.section = Sections.DamageType;
        this.errors = [];
    }
    get shouldDisplayGoBackButton() {
        return this.section > 0 && this.changeKey === null;
    }
    get electricalDamages() {
        const damages = [];
        return damages.concat(this.damages, this.changes.create.map((e) => e.data));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get electricalDamage() {
        return this.change.data;
    }
    get title() {
        if (this.section === Sections.DamageType || !this.electricalDamage.type) {
            const index = this.electricalDamages.length;
            return this.$tc('claim.damage.electrical_damage.form.title', undefined, { index });
        }
        const type = electricalDamageTitle(this.electricalDamage, this.$i18n.tc.bind(this.$i18n));
        const ofType = this.electricalDamages.filter((d) => d.type === this.electricalDamage.type);
        const index = ofType.findIndex((e) => e.clientIdentifier === this.electricalDamage.clientIdentifier) + 1;
        return this.$tc('claim.damage.electrical_damage.form.title.typed', ofType.length || 1, {
            type,
            index,
        });
    }
    get isValidStep() {
        switch (this.section) {
            case Sections.DamageDetails:
                return this.validation.isValid;
            default:
                return true;
        }
    }
    get sectionComponent() {
        switch (this.section) {
            case Sections.DamageType:
                return ElectricalDamageTypeSection;
            case Sections.DamageDetails:
                return ElectricalDamageDetailsSection;
        }
    }
    async save() {
        var _a, _b;
        try {
            this.isSaving = true;
            await this.saveChanges({ throwError: true });
            this.closeModal();
        }
        catch (e) {
            if (axios.isAxiosError(e) && ((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 422) {
                this.errors = e.response.data.violations;
                this.$buefy.toast.open({
                    message: (_b = e.response.data.violations[0]) === null || _b === void 0 ? void 0 : _b.message,
                    type: 'is-danger',
                    duration: 10 * 1000, // ms
                });
            }
            else {
                this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
            }
        }
        finally {
            this.isSaving = false;
        }
    }
    initStep() {
        var _a;
        this.updateType({ changeKey: this.changeKey, type: (_a = this.electricalDamage.type) !== null && _a !== void 0 ? _a : null });
        this.section = this.electricalDamage.type ? Sections.DamageDetails : Sections.DamageType;
    }
    nextStep() {
        if (this.isValidStep) {
            switch (this.section) {
                case Sections.DamageType:
                    this.section = Sections.DamageDetails;
                    break;
                case Sections.DamageDetails:
                    this.save();
                    break;
            }
        }
        else
            this.validateElectricalDamage(this.changeKey);
    }
    prevStep() {
        switch (this.section) {
            case Sections.DamageDetails:
                this.section = Sections.DamageType;
                break;
        }
    }
    closeModal() {
        this.section = Sections.DamageType;
        this.$emit('close');
    }
};
__decorate([
    electricalDamage.State('damages')
], ElectricalDamageModal.prototype, "damages", void 0);
__decorate([
    electricalDamage.State('changes')
], ElectricalDamageModal.prototype, "changes", void 0);
__decorate([
    electricalDamage.Getter('change')
], ElectricalDamageModal.prototype, "changeGetter", void 0);
__decorate([
    electricalDamage.Action('updateType')
], ElectricalDamageModal.prototype, "updateType", void 0);
__decorate([
    electricalDamage.Getter('validation')
], ElectricalDamageModal.prototype, "validationGetter", void 0);
__decorate([
    electricalDamage.Action('validateAndRevealDamages')
], ElectricalDamageModal.prototype, "validateElectricalDamage", void 0);
__decorate([
    electricalDamage.Action('saveChanges')
], ElectricalDamageModal.prototype, "saveChanges", void 0);
ElectricalDamageModal = __decorate([
    Component({
        components: { Modal },
        props: {
            isDisplayed: { type: Boolean, required: true },
            changeKey: { type: [Number, String], default: null },
        },
    })
], ElectricalDamageModal);
export default ElectricalDamageModal;
