var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import DamageCard from '../UI/DamageCard.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import ElectricalDamageModal from '@declaration/components/electricalDamage/ElectricalDamageModal.vue';
import { electricalDamageFields, electricalDamageTitle, } from '@shared/electricalDamage/electricalDamageFields';
import { DEFAULT_IMMOVABLE_DAMAGE_QUESTION_TRAD_KEYS, } from '@declaration/types/immovableDamages';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { ExclusionReason } from '@shared/types/api/refundDetail';
const electricalDamage = namespace('electricalDamage');
const qualification = namespace('qualification');
const claim = namespace('claim');
let ElectricalDamageList = class ElectricalDamageList extends Vue {
    constructor() {
        super(...arguments);
        this.isModalOpen = false;
        this.changeKey = null;
    }
    selectItemAndOpenHandler(damage) {
        if (this.selectItemAndOpenModal !== null) {
            this.selectItemAndOpenModal(damage);
        }
    }
    deleteItemHandler(damage) {
        this.$emit('on-delete-damage', damage);
    }
    addNewDamageHandler() {
        this.$emit('modale-opened');
    }
    get isAddButtonOutlined() {
        return this.electricalDamages.length > 0;
    }
    get tradKeys() {
        return DEFAULT_IMMOVABLE_DAMAGE_QUESTION_TRAD_KEYS;
    }
    editDamage(damage) {
        this.changeKey = damage.id;
        this.isModalOpen = true;
    }
    addDamage() {
        this.changeKey = null;
        this.isModalOpen = true;
    }
    closeModal() {
        this.resetChange(this.changeKey);
        this.isModalOpen = false;
    }
    modelFields(damage) {
        return electricalDamageFields(damage, {
            edition: false,
            type: ClaimTypeTrigram.Electric,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            damageDate: this.claim.damageDate,
            damageType: this.damageType,
        });
    }
    isExcludedByGuarantees(damage) {
        if ('exclusionReason' in damage) {
            return (damage.exclusionReason === ExclusionReason.ContractGuarantees ||
                damage.exclusionReason === ExclusionReason.NotIncludedInOutsideRobbery);
        }
        else
            return false;
    }
    isComplete(damage) {
        return !!damage.id;
    }
    canEdit() {
        return false;
    }
    title(damage, index) {
        if (this.damageType === 'immovableProperties') {
            const positionSuffix = this.getElectricalDamagePositionSuffix(damage.type, index);
            return (electricalDamageTitle(damage, this.$tc.bind(this)) +
                (positionSuffix ? ` ${positionSuffix}` : ''));
        }
        else {
            return this.modelFields(damage).title ? this.$tc(this.modelFields(damage).title) : '';
        }
    }
};
__decorate([
    electricalDamage.State('damages')
], ElectricalDamageList.prototype, "electricalDamages", void 0);
__decorate([
    electricalDamage.Action('resetChanges')
], ElectricalDamageList.prototype, "resetChange", void 0);
__decorate([
    electricalDamage.Getter('getElectricalDamagePositionSuffix')
], ElectricalDamageList.prototype, "getElectricalDamagePositionSuffix", void 0);
__decorate([
    claim.State('claim')
], ElectricalDamageList.prototype, "claim", void 0);
__decorate([
    qualification.State('typeTrigram')
], ElectricalDamageList.prototype, "typeTrigram", void 0);
ElectricalDamageList = __decorate([
    Component({
        components: { ClaimsButton, DamageCard, ElectricalDamageModal },
        props: {
            addButtonOutlined: {
                type: Boolean,
                default: false,
            },
            damageType: {
                type: String,
                default: null,
            },
            damages: {
                type: Array,
                default: () => [],
            },
            selectItemAndOpenModal: {
                type: Function,
                default: null,
            },
            confirmDamageDelete: {
                type: Function,
                default: null,
            },
        },
    })
], ElectricalDamageList);
export default ElectricalDamageList;
