var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { ShuttersComponent } from './ShuttersComponent';
import NumberInput from '@declaration/components/NumberInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { SHUTTER_MATERIAL_CHOICES, SHUTTERS_MOTORIZATION_CHOICES, } from '@shared/constants/externalDamages';
import { SHUTTER_DETAILS_TITLE, SHUTTER_COUNT_TITLE, SHUTTER_MATERIAL_TITLE, SHUTTER_WIDTH_TITLE, SHUTTER_OPENING_TITLE, SHUTTERS_WIDTH_CHOICES, } from '@declaration/constants/externalDamages';
import { ShutterType } from '@shared/types/api/externalDamage';
let ShuttersDetails = class ShuttersDetails extends ShuttersComponent {
    get detailsTitle() {
        return this.translateExternalDamageKey(SHUTTER_DETAILS_TITLE);
    }
    get countTitle() {
        return this.translateExternalDamageKey(SHUTTER_COUNT_TITLE);
    }
    get materialTitle() {
        return this.translateExternalDamageKey(SHUTTER_MATERIAL_TITLE);
    }
    get widthTitle() {
        return this.translateExternalDamageKey(SHUTTER_WIDTH_TITLE);
    }
    get motorizationTitle() {
        return this.translateExternalDamageKey(SHUTTER_OPENING_TITLE);
    }
    get materialChoices() {
        return SHUTTER_MATERIAL_CHOICES;
    }
    get shuttersWidthChoices() {
        return SHUTTERS_WIDTH_CHOICES;
    }
    get shuttersMotorizationChoices() {
        return SHUTTERS_MOTORIZATION_CHOICES;
    }
    get hasMotorization() {
        return this.shutterType === ShutterType.Rolling;
    }
};
ShuttersDetails = __decorate([
    Component({ components: { RadioImageButton, RadioPrimaryButton, NumberInput } })
], ShuttersDetails);
export default ShuttersDetails;
