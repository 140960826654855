var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { FireFireplaceCause } from '@shared/types/api/claim';
import { FIRE_FIREPLACE_CAUSE_ANSWERS, FIRE_FIREPLACE_CAUSE_IMAGE_CHOICES, } from '@declaration/constants/fireDamageConstants';
const qualification = namespace('qualification');
const claim = namespace('claim');
let FireFireplaceCauseQuestion = class FireFireplaceCauseQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get fireFireplaceCauseChoices() {
        return FIRE_FIREPLACE_CAUSE_IMAGE_CHOICES;
    }
    get selectedValue() {
        return this.qualification.fireFireplaceCause;
    }
    get validateText() {
        if (this.qualification.fireFireplaceCause === FireFireplaceCause.dontKnow ||
            this.qualification.fireFireplaceCause === null) {
            return this.$tc('claim.damage.fire.button.dont_know');
        }
        return this.$tc('claim.damage.fire.button.next_step');
    }
    get answer() {
        if (this.qualification.fireFireplaceCause === FireFireplaceCause.equipment) {
            return this.$tc(FIRE_FIREPLACE_CAUSE_ANSWERS[FireFireplaceCause.equipment]);
        }
        if (this.qualification.fireFireplaceCause === FireFireplaceCause.sparks) {
            return this.$tc(FIRE_FIREPLACE_CAUSE_ANSWERS[FireFireplaceCause.sparks]);
        }
        if (this.qualification.fireFireplaceCause === FireFireplaceCause.dontKnow) {
            return this.$tc(FIRE_FIREPLACE_CAUSE_ANSWERS[FireFireplaceCause.dontKnow]);
        }
        return null;
    }
    onTypeOfDamagesClick(fireFireplaceCause) {
        this.updateFireDamageQualification({
            qualificationPayload: {
                fireFireplaceCause: fireFireplaceCause !== this.qualification.fireFireplaceCause ? fireFireplaceCause : null,
            },
        });
    }
    async validate() {
        if (this.qualification.fireFireplaceCause === null) {
            await this.updateAndSaveFireDamageQualification({
                qualificationPayload: {
                    fireFireplaceCause: FireFireplaceCause.dontKnow,
                },
                step: this.step,
            });
        }
        await this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireFireplaceCause: this.qualification.fireFireplaceCause,
            },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireFireplaceCauseQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireFireplaceCauseQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireFireplaceCauseQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireFireplaceCauseQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
FireFireplaceCauseQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], FireFireplaceCauseQuestion);
export default FireFireplaceCauseQuestion;
