var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import AgeInput from '@declaration/components/UI/AgeInput.vue';
const personalProperty = namespace('personalProperty');
let PersonalPropertyPurchase = class PersonalPropertyPurchase extends Vue {
    get amountLabel() {
        if (this.isAmountOnly)
            return 'Montant';
        return "Prix d'achat";
    }
    get userKnowPriceLabel() {
        var _a;
        if ((_a = this.thirdPartyProductInfo) === null || _a === void 0 ? void 0 : _a.amount)
            return "Je connais la date d'achat du bien";
        return "Je connais le prix et la date d'achat du bien";
    }
    get purchaseCertificationLabel() {
        var _a;
        if ((_a = this.thirdPartyProductInfo) === null || _a === void 0 ? void 0 : _a.amount)
            return this.$tc('claim.damage.personal_property.form.question.user_purchase_certification.without_price');
        return this.$tc('claim.damage.personal_property.form.question.user_purchase_certification');
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get showErrors() {
        var _a;
        return (_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations;
    }
    get personalProperty() {
        return this.change.data;
    }
    get thirdPartyProductInfo() {
        var _a;
        return (_a = this.personalProperty.thirdPartyProductInfo) !== null && _a !== void 0 ? _a : null;
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get isUserKnowPriceModifiable() {
        return !this.isAmountOnly && !this.isIdentityDocument;
    }
    get isAmountModifiable() {
        var _a;
        if (this.isAmountOnly)
            return true;
        if (this.isIdentityDocument)
            return false;
        if ((_a = this.thirdPartyProductInfo) === null || _a === void 0 ? void 0 : _a.amount)
            return false;
        if (this.userKnowPrice !== true)
            return false;
        return true;
    }
    get isAgeModifiable() {
        if (this.isAmountOnly || this.isIdentityDocument)
            return false;
        if (this.personalProperty.userKnowPurchaseDate === false)
            return false;
        if (this.userKnowPrice !== true)
            return false;
        return true;
    }
    get isCertificationModifiable() {
        return !this.userKnowPrice && !this.isAmountOnly;
    }
    get userKnowPrice() {
        var _a;
        return (_a = this.personalProperty.userKnowPrice) !== null && _a !== void 0 ? _a : null;
    }
    set userKnowPrice(userKnowPrice) {
        this.update({
            userKnowPrice,
            amount: userKnowPrice ? this.amount : null,
            age: userKnowPrice ? this.age : null,
            purchaseCertification: userKnowPrice ? null : false,
            receipt: userKnowPrice ? this.personalProperty.receipt : null,
        });
    }
    get age() {
        var _a;
        return (_a = this.personalProperty.age) !== null && _a !== void 0 ? _a : null;
    }
    set age(age) {
        this.update({ age });
    }
    get amount() {
        return typeof this.personalProperty.amount === 'number' ? this.personalProperty.amount : null;
    }
    set amount(amount) {
        this.update({ amount: typeof amount === 'number' ? amount : null });
    }
    get purchaseCertification() {
        var _a;
        return (_a = this.personalProperty.purchaseCertification) !== null && _a !== void 0 ? _a : null;
    }
    set purchaseCertification(purchaseCertification) {
        this.update({ purchaseCertification });
    }
    fieldType(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations) && !this.validation.fieldIsValid(key)
            ? 'is-danger'
            : null;
    }
    fieldMessage(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations)
            ? this.validation.fieldErrors(key, this)[0]
            : null;
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyPurchase.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyPurchase.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyPurchase.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyPurchase.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyPurchase.prototype, "updatePersonalProperty", void 0);
PersonalPropertyPurchase = __decorate([
    Component({
        components: { AmountInput, AgeInput },
        props: { changeKey: { type: [String, Number], default: null } },
    })
], PersonalPropertyPurchase);
export default PersonalPropertyPurchase;
