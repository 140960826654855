var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { MandatoryDocumentStatus } from '@shared/types/documents';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import DocumentSynthesisModal from '@declaration/components/documents/DocumentSynthesisModal.vue';
import { getMandatoryDocumentTitle } from '@shared/documents/document';
const documents = namespace('documents');
const personalProperty = namespace('personalProperty');
let DocumentSynthesisListItem = class DocumentSynthesisListItem extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.isLoading = false;
    }
    get fileType() {
        return this.document.fileType;
    }
    get parent() {
        return this.document.parent;
    }
    get fileCollection() {
        return this.document.uploadedAppFileCollection;
    }
    get title() {
        return getMandatoryDocumentTitle(this.document, this.categoriesByIri, this.$i18n.tc.bind(this.$i18n));
    }
    get status() {
        return this.document.status;
    }
    get statusIcon() {
        switch (this.status) {
            case MandatoryDocumentStatus.Uploaded:
            case MandatoryDocumentStatus.NotVerified:
            case MandatoryDocumentStatus.Valid:
                return 'check';
            case MandatoryDocumentStatus.Pending:
                return 'pending';
            case MandatoryDocumentStatus.Missing:
            case MandatoryDocumentStatus.Invalid:
                return 'cancel';
        }
    }
    get statusLabel() {
        return this.$tc(`claim.document_synthesis.status.${this.status}`);
    }
    get actionLabel() {
        let key;
        if (this.status === MandatoryDocumentStatus.Missing)
            key = 'add';
        else if (this.status === MandatoryDocumentStatus.Invalid)
            key = 'replace';
        else
            key = 'edit';
        return this.$tc(`claim.document_synthesis.action.${key}`);
    }
    async upload(payload) {
        try {
            this.isLoading = true;
            await this.uploadDocument({
                document: this.document,
                ...payload,
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$buefy.toast.open({
                    message: err.message,
                    type: 'is-danger',
                });
            }
        }
        finally {
            this.isLoading = false;
        }
    }
};
__decorate([
    documents.Action('uploadDocument')
], DocumentSynthesisListItem.prototype, "uploadDocument", void 0);
__decorate([
    documents.Getter('isAnalysisEnabled')
], DocumentSynthesisListItem.prototype, "isAnalysisEnabled", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], DocumentSynthesisListItem.prototype, "categoriesByIri", void 0);
DocumentSynthesisListItem = __decorate([
    Component({
        components: { DocumentSynthesisModal, SecondaryButton },
        props: {
            document: { type: Object, required: true },
            disabled: { type: Boolean, default: false },
        },
    })
], DocumentSynthesisListItem);
export default DocumentSynthesisListItem;
