var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { Step } from '@shared/claim/steps';
import { uuidFromIri } from '@shared/utils/iri';
import { PayoutStatus } from '@declaration/types/payout';
import { CONFIG } from '@shared/environment/environment';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { ClaimStatus } from '@shared/types/api/claim';
import { forcedAmount } from '@shared/utils/forcedAmount';
const claim = namespace('claim');
const counting = namespace('counting');
const layout = namespace('layout');
/**
 * Test cards:
 * Visa / 4242 4242 4242 4242 / 100
 * Mastercard / 5436 0310 3060 6378 / 257
 */
let Checkout = class Checkout extends Vue {
    constructor() {
        super(...arguments);
        this.framesScriptUrl = 'https://cdn.checkout.com/js/framesv2.min.js';
        this.frameReady = false;
        this.frames = null;
        this.formatOptionalCentsAmount = formatOptionalCentsAmount;
        this.tokenObject = null;
        this.cardToken = null;
        this.isCardValid = false;
        this.cardHolderName = '';
        this.tokenSubmitTimeout = null;
        this.timeout = null;
        this.isTokenizingCard = false;
        this.isRequestingPayout = false;
        this.isPollingStatus = false;
        this.showCardHolderNameErrorMessage = false;
        this.showCardNumberErrorMessage = false;
        this.claimId = null;
        this.cbLogo = require('@declaration/assets/images/payout/cb_logo.svg');
        this.mastercardLogo = require('@declaration/assets/images/payout/mastercard_logo.svg');
        this.visaLogo = require('@declaration/assets/images/payout/visa_logo.svg');
        this.anytimeLogo = require('@declaration/assets/images/payout/anytime_logo.svg');
    }
    async mounted() {
        const form = this.$refs.form;
        this.claimId = this.claim.id;
        // Only load script for this component to minimize client js load
        this.frames = await this.loadFramesScript();
        this.frames.init({
            publicKey: CONFIG.checkoutPublicKey,
            localization: {
                cardNumberPlaceholder: this.$tc('payout.form.placeholder.card_number'),
                expiryMonthPlaceholder: this.$tc('payout.form.placeholder.expiry_month'),
                expiryYearPlaceholder: this.$tc('payout.form.placeholder.expiry_year'),
                cvvPlaceholder: this.$tc('payout.form.placeholder.cvv'),
            },
            style: {
                base: {
                    color: this.color.checkoutColor,
                    fontFamily: "'quicksand', sans-serif",
                    fontSize: '16px',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    fontWeight: 'normal',
                },
                placeholder: {
                    base: {
                        color: this.color.checkoutPlaceholder,
                    },
                    focus: {
                        color: this.color.checkoutPlaceholder,
                    },
                },
            },
        });
        this.frames.addEventHandler(this.frames.Events.READY, () => {
            this.frameReady = true;
        });
        this.frames.addEventHandler(this.frames.Events.CARD_VALIDATION_CHANGED, () => {
            this.isCardValid = this.frames.isCardValid();
        });
        this.frames.addEventHandler(this.frames.Events.CARD_TOKENIZED, (event) => {
            this.isTokenizingCard = false;
            this.cardToken = event.token;
            this.tokenObject = event;
            this.requestPayout();
            this.clearTokenSubmitTimeout();
        });
        this.frames.addEventHandler(this.frames.Events.CARD_TOKENIZATION_FAILED, () => {
            this.isTokenizingCard = false;
            this.showCardNumberErrorMessage = true;
            this.clearTokenSubmitTimeout();
        });
        form.addEventListener('submit', (event) => {
            event.preventDefault();
            if (!this.cardHolderName.length) {
                this.showCardHolderNameErrorMessage = true;
                return;
            }
            this.frames.cardholder = {
                name: this.cardHolderName,
            };
            if (!this.isCardValid) {
                this.showCardNumberErrorMessage = true;
                return;
            }
            this.showCardNumberErrorMessage = false;
            this.isTokenizingCard = true;
            this.frames.submitCard();
            this.tokenSubmitTimeout = setTimeout(() => {
                // Claim change edge case
                if (this.claim.id !== this.claimId) {
                    return;
                }
                // use brms to be redirected to error page
                this.updateAndSaveClaimStep(this.step);
            }, 20000);
        });
    }
    clearTokenSubmitTimeout() {
        if (this.tokenSubmitTimeout) {
            clearTimeout(this.tokenSubmitTimeout);
            this.tokenSubmitTimeout = null;
        }
    }
    destroyed() {
        if (this.frames) {
            this.frames.removeAllEventHandlers(this.frames.Events.CARD_TOKENIZED);
            this.frames.removeAllEventHandlers(this.frames.Events.CARD_TOKENIZATION_FAILED);
            this.frames.removeAllEventHandlers(this.frames.Events.CARD_VALIDATION_CHANGED);
        }
    }
    async requestPayout() {
        if (!this.cardToken || !this.tokenObject || !this.claim.id) {
            this.showCardNumberErrorMessage = true;
            return;
        }
        this.isRequestingPayout = true;
        try {
            const response = await this.$axios.$post(`/checkout/create-payout/${uuidFromIri(this.claim.id)}`, {
                cardToken: this.cardToken,
                tokenObject: this.tokenObject,
                cardHolderName: this.cardHolderName,
                cardLastFour: this.tokenObject.last4,
            });
            if (this.claim.id !== this.claimId) {
                return;
            }
            if (response.result.status === PayoutStatus.Pending) {
                this.pollPayoutStatus();
                this.isPollingStatus = true;
                this.isRequestingPayout = false;
            }
            else {
                this.updateAndSaveClaimStep(this.step);
            }
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
    }
    pollPayoutStatus() {
        const payoutStatusPoll = setInterval(async () => {
            // Claim change/exit edge case
            if (!this.claim.id || this.claim.id !== this.claimId) {
                clearInterval(payoutStatusPoll);
                return;
            }
            try {
                const response = await this.$axios.$get(`/checkout/check-payout-status/${uuidFromIri(this.claim.id)}`);
                if (response.status === PayoutStatus.Succeeded) {
                    clearInterval(payoutStatusPoll);
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    this.updateAndSaveClaimStep(this.step);
                }
                else if (response.status === PayoutStatus.Failed) {
                    clearInterval(payoutStatusPoll);
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    this.updateAndSaveClaimStep(this.step);
                }
            }
            catch (err) {
                clearInterval(payoutStatusPoll);
                this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
                this.isPollingStatus = false;
            }
        }, 3000);
        this.timeout = setTimeout(async () => {
            clearInterval(payoutStatusPoll);
            if (!this.claim.id || this.claim.id !== this.claimId) {
                return;
            }
            await this.updateAndSaveClaimStep(this.step);
            this.isPollingStatus = false;
        }, 15000);
    }
    loadFramesScript() {
        // Do not load script if already defined
        // Use a ref for typescript ease
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowAsAny = window;
        if (typeof windowAsAny.Frames !== 'undefined') {
            return windowAsAny.Frames;
        }
        return new Promise((resolve, reject) => {
            const component = this.$refs.checkout;
            const script = this.$refs.script;
            script.onload = function () {
                resolve(windowAsAny.Frames);
            };
            script.onerror = function (err) {
                reject(err);
            };
            script.src = this.framesScriptUrl;
            component.prepend(script);
        });
    }
    get description() {
        return this.$tc('payout.description', 0, { amount: formatOptionalCentsAmount(this.amount) });
    }
    get amount() {
        return this.claim.status === ClaimStatus.ImmediatePayoutPending
            ? forcedAmount(this.claim.immediateCompensationAmount)
            : forcedAmount(this.claim.delayedCompensationAmount);
    }
    updateCardHolderName(name) {
        this.cardHolderName = name;
        this.showCardHolderNameErrorMessage = !this.cardHolderName.length;
    }
    get step() {
        return this.claim.status === ClaimStatus.ImmediatePayoutPending
            ? Step.ImmediatePayoutIndex
            : Step.DelayedPayoutIndex;
    }
};
__decorate([
    claim.State('claim')
], Checkout.prototype, "claim", void 0);
__decorate([
    counting.State('counting')
], Checkout.prototype, "counting", void 0);
__decorate([
    counting.Getter('damagesRefund')
], Checkout.prototype, "damagesRefund", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], Checkout.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    layout.State('color')
], Checkout.prototype, "color", void 0);
Checkout = __decorate([
    Component({
        components: { ClaimsButton },
    })
], Checkout);
export default Checkout;
