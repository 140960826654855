var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_ORIGIN_CHOICES } from '@shared/constants/glassBreakageDamageConstants';
import { GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_ORIGIN_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let GlassBreakagePersonalPropertyDamagesOriginQuestion = class GlassBreakagePersonalPropertyDamagesOriginQuestion extends QuestionComponent {
    get title() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['personal-property-damages-origin-question']);
    }
    get choices() {
        return GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_ORIGIN_CHOICES;
    }
    updateClaim(personalPropertyDamagesOrigin) {
        this.updateAndSaveQualification({
            qualificationPayload: { personalPropertyDamagesOrigin },
            step: this.step,
        });
    }
    get answer() {
        if (this.qualification.personalPropertyDamagesOrigin === null) {
            return null;
        }
        return this.$tc(GLASS_BREAKAGE_PERSONAL_PROPERTY_DAMAGES_ORIGIN_ANSWERS[this.qualification.personalPropertyDamagesOrigin]);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "updateAndSaveQualification", void 0);
GlassBreakagePersonalPropertyDamagesOriginQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], GlassBreakagePersonalPropertyDamagesOriginQuestion);
export default GlassBreakagePersonalPropertyDamagesOriginQuestion;
