var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
let ClaimsCheck = class ClaimsCheck extends Vue {
    get mode() {
        return insurerHasFeature(InsurerFeatureFlag.ClaimsCheckModeIsSwitch)
            ? 'switch'
            : this.displayMode;
    }
    get cssClasses() {
        return this.classes.join(' ');
    }
    get checkedState() {
        return this.isChecked;
    }
    set checkedState(checked) {
        this.$emit('update', checked);
    }
};
ClaimsCheck = __decorate([
    Component({
        name: 'ClaimsCheck',
        props: {
            displayMode: {
                type: String,
                default: 'checkbox',
            },
            tradKey: {
                type: String,
                required: true,
            },
            isChecked: {
                type: Boolean,
                default: false,
            },
        },
        model: {
            prop: 'isChecked',
            event: 'update',
        },
    })
], ClaimsCheck);
export default ClaimsCheck;
