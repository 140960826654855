var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { INSURER, INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const helpers = namespace('helpers');
const user = namespace('user');
let Help = class Help extends Vue {
    constructor() {
        super(...arguments);
        this.overflowingContents = [];
        this.expendedContents = [];
        this.shouldDisplayDropup = false;
    }
    updated() {
        var _a;
        const refs = this.$refs;
        const overflowingContents = (_a = this.steps) === null || _a === void 0 ? void 0 : _a.filter((step) => {
            const element = refs[step.slug];
            return element && element[0] && element[0].scrollHeight > 100;
        }).map((step) => step.slug);
        if (!overflowingContents && this.overflowingContents) {
            this.overflowingContents = [];
        }
        else if (overflowingContents.length !== this.overflowingContents.length) {
            this.overflowingContents = overflowingContents;
        }
        else {
            for (const overflowingContent in overflowingContents) {
                if (!this.overflowingContents.includes(overflowingContents[overflowingContent])) {
                    this.overflowingContents = overflowingContents;
                    break;
                }
            }
        }
    }
    get insurerHasFeatureDisplayDirectlyContactOnHelp() {
        return insurerHasFeature(InsurerFeatureFlag.DisplayDirectlyContactOnHelp);
    }
    get steps() {
        var _a;
        if (!this.shownStep || !this.help) {
            return [];
        }
        const integratedLangSlug = this.stateLanguage;
        return this.help[this.shownStep] !== undefined
            ? (_a = this.help[this.shownStep].filter((content) => content.language === integratedLangSlug)) !== null && _a !== void 0 ? _a : []
            : [];
    }
    get insurer() {
        return INSURER;
    }
    get insurerHasAdvisorPhoneNumbers() {
        return INSURER_CONFIG.declaration.advisorPhoneNumbers !== undefined;
    }
    get phoneNumberFrance() {
        var _a, _b;
        return (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.france) !== null && _b !== void 0 ? _b : '';
    }
    get phoneNumberAbroad() {
        var _a, _b;
        return (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.abroad) !== null && _b !== void 0 ? _b : '';
    }
    hideHelp() {
        this.expendedContents = [];
        this.hide();
        this.shouldDisplayDropup = false;
    }
    expendContent(slug) {
        this.expendedContents.push(slug);
    }
    collapseContent(slug) {
        this.expendedContents = this.expendedContents.filter((s) => s !== slug);
    }
    displayDropup() {
        this.shouldDisplayDropup = true;
    }
};
__decorate([
    helpers.Action('hide')
], Help.prototype, "hide", void 0);
__decorate([
    helpers.State('shownStep')
], Help.prototype, "shownStep", void 0);
__decorate([
    helpers.State('stepHelps')
], Help.prototype, "help", void 0);
__decorate([
    user.State('language')
], Help.prototype, "stateLanguage", void 0);
Help = __decorate([
    Component({
        components: { ClaimsButton },
    })
], Help);
export default Help;
