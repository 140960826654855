var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { LeakageCauseFixed } from '@shared/types/api/claim';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
const qualification = namespace('qualification');
const contract = namespace('contract');
const helpers = namespace('helpers');
let ShouldRepairLeakage = class ShouldRepairLeakage extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/pause-parcours.svg');
    }
    showGlobalHelp() {
        this.showHelp(Step.ShouldRepairLeakage);
    }
    leakageIsRepaired() {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                isWaterLeakageCauseFixed: LeakageCauseFixed.YES,
            },
            step: Step.ShouldRepairLeakage,
        });
    }
    get isDescription() {
        return insurerHasFeature(InsurerFeatureFlag.HideProfessionalInterventionDescription);
    }
    get showCaseNumber() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.shouldRepairLeakage) === null || _b === void 0 ? void 0 : _b.displayRememberContractNumber) !== null && _c !== void 0 ? _c : true);
    }
};
__decorate([
    qualification.Action('updateAndSaveQualification')
], ShouldRepairLeakage.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    contract.Getter('isTenant')
], ShouldRepairLeakage.prototype, "isTenant", void 0);
__decorate([
    helpers.Action('show')
], ShouldRepairLeakage.prototype, "showHelp", void 0);
ShouldRepairLeakage = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], ShouldRepairLeakage);
export default ShouldRepairLeakage;
