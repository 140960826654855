var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import formatAmount from '@shared/utils/formatAmount';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
let ElectricalDamageFoodAmount = class ElectricalDamageFoodAmount extends QuestionComponent {
    get currentFoodDamage() {
        var _a, _b;
        return this.change((_b = (_a = this.personalProperties.find((personalProperty) => {
            return (optionalResourceIri(personalProperty.category) ===
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null);
    }
    get foodAmountValue() {
        var _a;
        return (_a = this.currentFoodDamage.data.amount) !== null && _a !== void 0 ? _a : 0;
    }
    set foodAmountValue(value) {
        var _a;
        this.updateDamage({
            changeKey: (_a = this.currentFoodDamage.data.id) !== null && _a !== void 0 ? _a : null,
            data: {
                category: optionalCategoryIri('denrees-alimentaires', this.categoriesByIri),
                amount: value,
            },
        });
    }
    get isDisabled() {
        return !!this.foodAmountValue;
    }
    async updateClaim() {
        await this.saveChanges({ step: this.step });
    }
    get answer() {
        if (typeof this.currentFoodDamage.data.amount !== 'number') {
            return null;
        }
        return this.$tc('claim.qualification.electrical.food_damage_amount.answer', undefined, {
            amount: formatAmount(this.foodAmountValue / 100),
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ElectricalDamageFoodAmount.prototype, "isUpdatingClaim", void 0);
__decorate([
    personalProperty.State('damages')
], ElectricalDamageFoodAmount.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], ElectricalDamageFoodAmount.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], ElectricalDamageFoodAmount.prototype, "change", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], ElectricalDamageFoodAmount.prototype, "updateDamage", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], ElectricalDamageFoodAmount.prototype, "saveChanges", void 0);
ElectricalDamageFoodAmount = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton, AmountInput },
        props: QuestionProps,
    })
], ElectricalDamageFoodAmount);
export default ElectricalDamageFoodAmount;
