var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, PropSync } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { DOOR_NUMBER_WINDOWS_BROKEN_TITLE } from '@declaration/constants/externalDamages/DoorConstants';
import NumberInput from '@declaration/components/NumberInput.vue';
let DoorNumberOfBrokenTiles = class DoorNumberOfBrokenTiles extends ExternalDamageComponent {
    get title() {
        return this.translateExternalDamageKey(DOOR_NUMBER_WINDOWS_BROKEN_TITLE);
    }
};
__decorate([
    PropSync('count', { type: Number, required: false })
], DoorNumberOfBrokenTiles.prototype, "tilesCount", void 0);
DoorNumberOfBrokenTiles = __decorate([
    Component({
        components: { NumberInput },
        props: {},
    })
], DoorNumberOfBrokenTiles);
export default DoorNumberOfBrokenTiles;
