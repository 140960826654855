var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
const breakInDamage = namespace('breakInDamage');
let BreakInDamagePicturesSection = class BreakInDamagePicturesSection extends Vue {
    constructor() {
        super(...arguments);
        this.ClaimFileType = ClaimFileType;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    update(data) {
        this.updateDamage({ changeKey: this.changeKey, data });
    }
    get titleText() {
        return this.title !== null
            ? this.title
            : this.$tc('claim.declaration.break_in_damages.form.photos.title');
    }
    get subtitleText() {
        return this.subtitle !== null
            ? this.subtitle
            : this.$tc('claim.declaration.break_in_damages.form.pictures.info');
    }
};
__decorate([
    breakInDamage.Getter('change')
], BreakInDamagePicturesSection.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], BreakInDamagePicturesSection.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], BreakInDamagePicturesSection.prototype, "updateDamage", void 0);
BreakInDamagePicturesSection = __decorate([
    Component({
        components: {
            BreakInDamageDescriptionSectionCard,
            PictureCollectionUpload,
        },
        props: {
            changeKey: {
                type: [String, Number],
                default: null,
            },
            title: {
                default: null,
            },
            subtitle: {
                default: null,
            },
        },
    })
], BreakInDamagePicturesSection);
export default BreakInDamagePicturesSection;
