var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { ExitTypes } from '@shared/types/api/claim';
const claim = namespace('claim');
const qualification = namespace('qualification');
let ExpertAppointmentByPhoneIndex = class ExpertAppointmentByPhoneIndex extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/expertByPhone.svg');
    }
    get description() {
        if (this.qualification.irsiApplication === 'non') {
            return this.$tc('appointment.expert.start_screen.description.on_site.irsi');
        }
        if (this.claim.exit === ExitTypes.PhoneDistanceAppointment ||
            this.claim.exit === ExitTypes.VisioDistanceAppointment) {
            return this.$tc('appointment.expert.start_screen.description.phone.counting');
        }
        return this.$tc('appointment.expert.start_screen.description.phone.complex');
    }
    async acceptAppointment() {
        await this.updateAndSaveClaimStep(Step.ExpertAppointmentByPhoneIndex);
    }
};
__decorate([
    claim.Action('updateAndSaveClaimStep')
], ExpertAppointmentByPhoneIndex.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    claim.State('claim')
], ExpertAppointmentByPhoneIndex.prototype, "claim", void 0);
__decorate([
    qualification.State('qualification')
], ExpertAppointmentByPhoneIndex.prototype, "qualification", void 0);
ExpertAppointmentByPhoneIndex = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], ExpertAppointmentByPhoneIndex);
export default ExpertAppointmentByPhoneIndex;
