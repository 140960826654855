var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { VERANDA_STRUCTURE_TITLE, VERANDA_STRUCTURE_MESSAGE, } from '@declaration/constants/externalDamages/VerandaConstants';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { translateChoices } from '@shared/choice';
let VerandaStructure = class VerandaStructure extends ExternalDamageComponent {
    get structureTitle() {
        return this.translateExternalDamageKey(VERANDA_STRUCTURE_TITLE);
    }
    get structureMessage() {
        return this.translateExternalDamageKey(VERANDA_STRUCTURE_MESSAGE);
    }
    get structureDamagedField() {
        return {
            type: this.fieldIsValid('structureDamaged') ? '' : 'is-danger',
            message: this.fieldErrors('structureDamaged')[0],
        };
    }
    get structureDamagedChoices() {
        return translateChoices(YES_OR_NO_CHOICES, this.translateExternalDamageKey.bind(this));
    }
    get structureDamaged() {
        return this.externalDamage.structureDamaged;
    }
    set structureDamaged(structureDamaged) {
        if (structureDamaged) {
            this.updateExternalDamage({
                structureDamaged,
                gutterDamaged: null,
                gutterMaterial: null,
                gutterLength: null,
                panels: [],
            });
        }
        else {
            this.updateExternalDamage({ structureDamaged });
        }
    }
};
VerandaStructure = __decorate([
    Component({ components: { RadioPrimaryButton } })
], VerandaStructure);
export default VerandaStructure;
