var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PersonalPropertyCategorySelect from '@declaration/components/personalProperty/PersonalPropertyCategorySelect.vue';
import { optionalResourceIri } from '@shared/utils/iri';
import { translateChoices } from '@shared/choice';
import { PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES } from '@shared/constants/personalPropertyConstants';
const personalProperty = namespace('personalProperty');
let PersonalPropertyProduct = class PersonalPropertyProduct extends Vue {
    get identityDocumentTypeChoices() {
        return translateChoices(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES, this.$tc.bind(this));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get showErrors() {
        var _a;
        return (_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations;
    }
    get personalProperty() {
        return this.change.data;
    }
    get thirdPartyProductId() {
        var _a;
        return (_a = this.personalProperty.thirdPartyProductId) !== null && _a !== void 0 ? _a : null;
    }
    get thirdPartyProductInfo() {
        var _a;
        return (_a = this.personalProperty.thirdPartyProductInfo) !== null && _a !== void 0 ? _a : null;
    }
    get category() {
        return this.personalProperty.category;
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get isCategoryModifiable() {
        return this.isCategoryModifiableGetter(this.changeKey);
    }
    get isDesignationModifiable() {
        return !this.isAmountOnly && !this.isIdentityDocument;
    }
    get isDescriptionModifiable() {
        return !this.isAmountOnly && !this.isIdentityDocument;
    }
    get categoryIRI() {
        return optionalResourceIri(this.category);
    }
    set categoryIRI(value) {
        const category = value ? this.categoriesByIri[value] : null;
        const payload = {
            category: category === null || category === void 0 ? void 0 : category.id,
            designation: this.designation,
            description: this.description,
        };
        if ((category === null || category === void 0 ? void 0 : category.isAmountOnly) || (category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.designation = category.label;
            payload.description = null;
            payload.userKnowPrice = null;
            payload.purchaseCertification = null;
        }
        else if (this.isAmountOnly || this.isIdentityDocument) {
            payload.designation = null;
            payload.receipt = null;
        }
        if (!(category === null || category === void 0 ? void 0 : category.isIdentityDocument)) {
            payload.documentType = null;
        }
        this.update(payload);
    }
    get designation() {
        var _a;
        return (_a = this.personalProperty.designation) !== null && _a !== void 0 ? _a : null;
    }
    set designation(designation) {
        this.update({ designation });
    }
    get description() {
        var _a;
        return (_a = this.personalProperty.description) !== null && _a !== void 0 ? _a : null;
    }
    set description(description) {
        this.update({ description });
    }
    get documentType() {
        var _a;
        return (_a = this.personalProperty.documentType) !== null && _a !== void 0 ? _a : null;
    }
    set documentType(documentType) {
        this.update({ documentType });
    }
    fieldType(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations) && !this.validation.fieldIsValid(key)
            ? 'is-danger'
            : null;
    }
    fieldMessage(key) {
        var _a;
        return ((_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.revealViolations)
            ? this.validation.fieldErrors(key, this)[0]
            : null;
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyProduct.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyProduct.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyProduct.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('isCategoryModifiable')
], PersonalPropertyProduct.prototype, "isCategoryModifiableGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyProduct.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyProduct.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyProduct.prototype, "updatePersonalProperty", void 0);
PersonalPropertyProduct = __decorate([
    Component({
        components: { PersonalPropertyCategorySelect },
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], PersonalPropertyProduct);
export default PersonalPropertyProduct;
