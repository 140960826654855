var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import EquipmentWashBasin from '@declaration/components/svg/EquipmentWashBasin.vue';
import EquipmentSink from '@declaration/components/svg/EquipmentSink.vue';
import EquipmentShower from '@declaration/components/svg/EquipmentShower.vue';
import EquipmentBathtube from '@declaration/components/svg/EquipmentBathtube.vue';
import EquipmentWC from '@declaration/components/svg/EquipmentWC.vue';
import EquipmentDishwasher from '@declaration/components/svg/EquipmentDishwasher.vue';
import EquipmentRefrigerator from '@declaration/components/svg/EquipmentRefrigerator.vue';
import EquipmentWashingMachine from '@declaration/components/svg/EquipmentWashingMachine.vue';
import EquipmentRadiator from '@declaration/components/svg/EquipmentRadiator.vue';
import EquipmentBoiler from '@declaration/components/svg/EquipmentBoiler.vue';
import EquipmentHotWaterTank from '@declaration/components/svg/EquipmentHotWaterTank.vue';
import EquipmentAquarium from '@declaration/components/svg/EquipmentAquarium.vue';
import { EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE, DEFAULT_EQUIPMENT_CONFIGURATION, QUALIFICATION_EQUIPMENT_DETAIL, } from '@shared/constants/equipmentConstants';
const qualification = namespace('qualification');
let LeakageCauseEquipmentDetailQuestion = class LeakageCauseEquipmentDetailQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.hoveredEquipmentDetail = null;
        this.selectedEquipmentDetail = null;
        this.svgWidth = 0;
        this.svgHeight = 0;
        // Listener de window resize
        this.windowResizeListener = () => {
            this.recomputeSvgWidth();
            this.recomputeSvgHeight();
        };
        this.equipmentSvg = null;
        this.equipmentForHoverAndSelectionDiv = null;
        this.equipmentForHoverAndSelectionSvg = null;
    }
    created() {
        this.selectedEquipmentDetail = this.waterDamageQualification.leakageCauseDetailSlug;
        this.updateHoverAndSelectionInSvg();
        this.$watch('equipmentSvg', () => {
            // Recompute
            this.recomputeSvgWidth();
            this.recomputeSvgHeight();
        }, { immediate: true });
        window.addEventListener('resize', this.windowResizeListener);
    }
    destroy() {
        window.removeEventListener('resize', this.windowResizeListener);
    }
    recomputeSvgWidth() {
        this.svgWidth = this.equipmentSvg ? this.equipmentSvg.clientWidth : 0;
    }
    recomputeSvgHeight() {
        this.svgHeight = this.equipmentSvg ? this.equipmentSvg.clientHeight : 0;
    }
    get equipmentConfiguration() {
        if (this.waterDamageQualification.leakageCauseEquipmentSlug === null) {
            return DEFAULT_EQUIPMENT_CONFIGURATION;
        }
        if (!EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE[this.waterDamageQualification.leakageCauseEquipmentSlug]) {
            return DEFAULT_EQUIPMENT_CONFIGURATION;
        }
        return EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE[this.waterDamageQualification.leakageCauseEquipmentSlug];
    }
    get equipmentDetails() {
        return this.equipmentConfiguration.equipmentDetails;
    }
    get equipmentDetailsValuesAndTradKeys() {
        return Object.values(this.equipmentDetails).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_EQUIPMENT_DETAIL[slug].tradKey,
            };
        });
    }
    selectEquipmentDetailFromHoveredEquipmentDetail() {
        if (this.hoveredEquipmentDetail) {
            this.selectEquipmentDetail(this.hoveredEquipmentDetail);
        }
    }
    selectEquipmentDetail(leakageCauseDetailSlug) {
        this.selectedEquipmentDetail = leakageCauseDetailSlug;
        this.updateClaim();
    }
    updateClaim() {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: { leakageCauseDetailSlug: this.selectedEquipmentDetail },
            step: this.step,
        });
    }
    updateHover(leakageCauseDetailSlug) {
        if (this.hoveredEquipmentDetail !== leakageCauseDetailSlug) {
            this.hoveredEquipmentDetail = leakageCauseDetailSlug;
            this.updateHoverAndSelectionInSvg();
        }
    }
    get answer() {
        if (this.waterDamageQualification.leakageCauseDetailSlug === null) {
            return null;
        }
        if (!QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]) {
            return this.$tc('claim.qualification.leakage_cause_equipment_detail.sumup.default');
        }
        return this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
            .leakageCauseEquipmentDetailAnswerTradKey);
    }
    setEquipmentSvg() {
        this.equipmentSvg = document.getElementById('equipment-fade-svg');
    }
    setEquipmentSvgForHoverAndSelection() {
        this.equipmentForHoverAndSelectionDiv = document.getElementById('equipment-for-hover-and-selection');
        this.equipmentForHoverAndSelectionSvg = document.getElementById('equipment-for-hover-and-selection-svg');
        this.updateHoverAndSelectionInSvg();
    }
    get svgViewBoxWidth() {
        return this.equipmentSvg ? this.equipmentSvg.viewBox.baseVal.width : 0;
    }
    get svgViewBoxHeight() {
        return this.equipmentSvg ? this.equipmentSvg.viewBox.baseVal.height : 0;
    }
    get isSvgTakingFullHeight() {
        return this.svgViewBoxHeight
            ? (this.svgHeight * this.svgViewBoxWidth) / this.svgViewBoxHeight < this.svgWidth
            : true;
    }
    get svgWidthWithoutBlankSpace() {
        if (this.isSvgTakingFullHeight) {
            return this.svgViewBoxHeight
                ? (this.svgHeight * this.svgViewBoxWidth) / this.svgViewBoxHeight
                : 0;
        }
        return this.svgWidth;
    }
    get svgHeightWithoutBlankSpace() {
        if (!this.isSvgTakingFullHeight) {
            return this.svgViewBoxWidth
                ? (this.svgWidth * this.svgViewBoxHeight) / this.svgViewBoxWidth
                : 0;
        }
        return this.svgHeight;
    }
    onMouseOverOrMouseClick(event) {
        // todo choose this const with some calculus
        const hoverCircleRadius = 150;
        const mousePositionInDivX = event.offsetX;
        const mousePositionInDivY = event.offsetY;
        let mousePositionInSvgX, mousePositionInSvgY, mousePositionInViewBoxX, mousePositionInViewBoxY;
        if (this.isSvgTakingFullHeight) {
            mousePositionInSvgX =
                mousePositionInDivX - (this.svgWidth - this.svgWidthWithoutBlankSpace) / 2; // svg is centered by default
            mousePositionInSvgY = mousePositionInDivY;
            mousePositionInViewBoxX = this.svgWidthWithoutBlankSpace
                ? (mousePositionInSvgX * this.svgViewBoxWidth) / this.svgWidthWithoutBlankSpace
                : 0;
            mousePositionInViewBoxY = this.svgHeightWithoutBlankSpace
                ? (mousePositionInSvgY * this.svgViewBoxHeight) / this.svgHeightWithoutBlankSpace
                : 0;
        }
        else {
            mousePositionInSvgX = mousePositionInDivX;
            mousePositionInSvgY =
                mousePositionInDivY - (this.svgHeight - this.svgHeightWithoutBlankSpace) / 2; // svg is centered by default
            mousePositionInViewBoxX = this.svgWidthWithoutBlankSpace
                ? (mousePositionInSvgX * this.svgViewBoxWidth) / this.svgWidthWithoutBlankSpace
                : 0;
            mousePositionInViewBoxY = this.svgHeightWithoutBlankSpace
                ? (mousePositionInSvgY * this.svgViewBoxHeight) / this.svgHeightWithoutBlankSpace
                : 0;
        }
        const isMouseInSvg = mousePositionInSvgX > 0 &&
            mousePositionInSvgY > 0 &&
            mousePositionInSvgX < this.svgWidthWithoutBlankSpace &&
            mousePositionInSvgY < this.svgHeightWithoutBlankSpace;
        if (!isMouseInSvg) {
            this.updateHover(null);
            return;
        }
        const positionOfElementInViewBox = this.equipmentConfiguration.positionOfElementsInViewBox.find((positionOfElement) => {
            const { viewBoxX, viewBoxY } = positionOfElement;
            return (Math.abs(viewBoxX - mousePositionInViewBoxX) < hoverCircleRadius &&
                Math.abs(viewBoxY - mousePositionInViewBoxY) < hoverCircleRadius);
        });
        if (positionOfElementInViewBox) {
            this.updateHover(positionOfElementInViewBox.equipment);
        }
        else {
            this.updateHover(null);
        }
    }
    updateHoverAndSelectionInSvg() {
        if (!this.equipmentForHoverAndSelectionSvg || !this.equipmentForHoverAndSelectionDiv) {
            return;
        }
        const positionOfHoveredElementInViewBox = this.equipmentConfiguration.positionOfElementsInViewBox.find((positionOfElement) => {
            return positionOfElement.equipment === this.hoveredEquipmentDetail;
        });
        const positionOfSelectedElementInViewBox = this.equipmentConfiguration.positionOfElementsInViewBox.find((positionOfElement) => {
            return positionOfElement.equipment === this.selectedEquipmentDetail;
        });
        let positionOfElementInViewBox = positionOfHoveredElementInViewBox;
        if (!positionOfElementInViewBox) {
            if (!positionOfSelectedElementInViewBox) {
                this.equipmentForHoverAndSelectionDiv.style.opacity = '0';
                this.equipmentForHoverAndSelectionDiv.style.top = `calc(${this.equipmentForHoverAndSelectionDiv.style.top} + ${this.equipmentForHoverAndSelectionDiv.style.height} / 2)`;
                this.equipmentForHoverAndSelectionDiv.style.left = `calc(${this.equipmentForHoverAndSelectionDiv.style.left} + ${this.equipmentForHoverAndSelectionDiv.style.width} / 2)`;
                this.equipmentForHoverAndSelectionSvg.style.top = `calc(${this.equipmentForHoverAndSelectionSvg.style.top} - ${this.equipmentForHoverAndSelectionDiv.style.height} / 2)`;
                this.equipmentForHoverAndSelectionSvg.style.left = `calc(${this.equipmentForHoverAndSelectionSvg.style.left} - ${this.equipmentForHoverAndSelectionDiv.style.width} / 2)`;
                this.equipmentForHoverAndSelectionDiv.style.width = `${0}px`;
                this.equipmentForHoverAndSelectionDiv.style.height = `${0}px`;
                return;
            }
            positionOfElementInViewBox = positionOfSelectedElementInViewBox;
        }
        const elementPositionInSvgX = this.svgViewBoxWidth
            ? (positionOfElementInViewBox.viewBoxX * this.svgWidthWithoutBlankSpace) /
                this.svgViewBoxWidth
            : 0;
        const elementPositionInSvgY = this.svgViewBoxHeight
            ? (positionOfElementInViewBox.viewBoxY * this.svgHeightWithoutBlankSpace) /
                this.svgViewBoxHeight
            : 0;
        // svg is centered by default
        const elementPositionInHtmlX = elementPositionInSvgX + (this.svgWidth - this.svgWidthWithoutBlankSpace) / 2;
        const elementPositionInHtmlY = elementPositionInSvgY + (this.svgHeight - this.svgHeightWithoutBlankSpace) / 2;
        const scaleFactor = 1.7;
        // todo choose the 30px with calculus
        const cicleRadius = 30 * scaleFactor;
        // We want to animate the circle hover to make it appear from the center
        // To do that we need to set is location to the center of the element (and it's width/height to 0)
        // then change it's location and width/height
        // To make the animation work we need to set the two different location in different frame
        // That's why we use requestAnimationFrame
        requestAnimationFrame(() => {
            if (!this.equipmentForHoverAndSelectionSvg || !this.equipmentForHoverAndSelectionDiv) {
                return;
            }
            this.equipmentForHoverAndSelectionSvg.style.width = `${scaleFactor * this.svgWidthWithoutBlankSpace}px`;
            this.equipmentForHoverAndSelectionSvg.style.height = `${scaleFactor * this.svgHeightWithoutBlankSpace}px`;
            if (!positionOfSelectedElementInViewBox) {
                this.equipmentForHoverAndSelectionDiv.style.transition = ``;
                this.equipmentForHoverAndSelectionDiv.style.top = `${elementPositionInHtmlY}px`;
                this.equipmentForHoverAndSelectionDiv.style.left = `${elementPositionInHtmlX}px`;
                this.equipmentForHoverAndSelectionDiv.style.width = `0px`;
                this.equipmentForHoverAndSelectionDiv.style.height = `0px`;
                this.equipmentForHoverAndSelectionSvg.style.transition = ``;
                this.equipmentForHoverAndSelectionSvg.style.left = `${-scaleFactor * elementPositionInSvgX}px`;
                this.equipmentForHoverAndSelectionSvg.style.top = `${-scaleFactor * elementPositionInSvgY}px`;
            }
            requestAnimationFrame(() => {
                if (!this.equipmentForHoverAndSelectionSvg || !this.equipmentForHoverAndSelectionDiv) {
                    return;
                }
                this.equipmentForHoverAndSelectionDiv.style.transition = `all 0.2s ease-in-out`;
                this.equipmentForHoverAndSelectionDiv.style.opacity = '1';
                this.equipmentForHoverAndSelectionDiv.style.top = `${elementPositionInHtmlY - cicleRadius}px`;
                this.equipmentForHoverAndSelectionDiv.style.left = `${elementPositionInHtmlX - cicleRadius}px`;
                this.equipmentForHoverAndSelectionDiv.style.width = `${cicleRadius * 2}px`;
                this.equipmentForHoverAndSelectionDiv.style.height = `${cicleRadius * 2}px`;
                this.equipmentForHoverAndSelectionSvg.style.transition = `all 0.2s ease-in-out`;
                this.equipmentForHoverAndSelectionSvg.style.left = `${cicleRadius - scaleFactor * elementPositionInSvgX}px`;
                this.equipmentForHoverAndSelectionSvg.style.top = `${cicleRadius - scaleFactor * elementPositionInSvgY}px`;
            });
        });
    }
};
__decorate([
    qualification.State('qualification')
], LeakageCauseEquipmentDetailQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseEquipmentDetailQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
LeakageCauseEquipmentDetailQuestion = __decorate([
    Component({
        components: {
            EquipmentWashBasin,
            EquipmentSink,
            EquipmentShower,
            EquipmentBathtube,
            EquipmentWC,
            EquipmentDishwasher,
            EquipmentRefrigerator,
            EquipmentWashingMachine,
            EquipmentRadiator,
            EquipmentBoiler,
            EquipmentHotWaterTank,
            EquipmentAquarium,
            RadioPrimaryButton,
            QuestionPanel,
        },
        props: QuestionProps,
    })
], LeakageCauseEquipmentDetailQuestion);
export default LeakageCauseEquipmentDetailQuestion;
