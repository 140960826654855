var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ElectricalDamageType } from '@shared/types/api/electricalDamage';
import { ELECTRICAL_DAMAGE_TYPES_OTHER_CHOICES } from '@shared/constants/electricalDamageConstants';
import { translateChoices } from '@shared/choice';
const claim = namespace('claim');
const electricalDamage = namespace('electricalDamage');
let ElectricalDamageDetailsSection = class ElectricalDamageDetailsSection extends Vue {
    mounted() {
        if (this.isNoChoices) {
            this.update({ type: this.damageType });
        }
    }
    get isNoChoices() {
        return this.typeChoices.length <= 1;
    }
    get maxDate() {
        return this.claim.damageDate ? new Date(this.claim.damageDate) : new Date();
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get electricalDamage() {
        return this.change.data;
    }
    get damageType() {
        var _a;
        return (_a = this.electricalDamage.type) !== null && _a !== void 0 ? _a : ElectricalDamageType.Other;
    }
    set damageType(type) {
        this.update({ type });
    }
    get designation() {
        var _a;
        return (_a = this.electricalDamage.designation) !== null && _a !== void 0 ? _a : null;
    }
    set designation(designation) {
        this.update({ designation });
    }
    get canChoseType() {
        return (this.damageType === null ||
            ELECTRICAL_DAMAGE_TYPES_OTHER_CHOICES.map(({ value }) => value).includes(this.damageType));
    }
    get typeChoices() {
        return translateChoices(ELECTRICAL_DAMAGE_TYPES_OTHER_CHOICES, this.$i18n.tc.bind(this.$i18n));
    }
    get repairable() {
        var _a;
        return (_a = this.electricalDamage.repairable) !== null && _a !== void 0 ? _a : false;
    }
    set repairable(repairable) {
        if (repairable)
            this.update({
                repairable: true,
                irreparabilityCertificate: { uploadedAppFiles: [] },
            });
        else
            this.update({
                repairable: false,
                repairAmount: null,
                repairQuote: { uploadedAppFiles: [] },
            });
    }
    get repairAmount() {
        var _a;
        return (_a = this.electricalDamage.repairAmount) !== null && _a !== void 0 ? _a : null;
    }
    set repairAmount(repairAmount) {
        this.update({ repairAmount });
    }
    get repairQuote() {
        if (typeof this.electricalDamage.repairQuote === 'object') {
            return this.electricalDamage.repairQuote;
        }
        return null;
    }
    set repairQuote(repairQuote) {
        this.update({ repairQuote });
    }
    get irreparabilityCertificate() {
        if (typeof this.electricalDamage.irreparabilityCertificate === 'object') {
            return this.electricalDamage.irreparabilityCertificate;
        }
        return null;
    }
    set irreparabilityCertificate(irreparabilityCertificate) {
        this.update({ irreparabilityCertificate });
    }
    get purchaseInfo() {
        var _a;
        return (_a = this.electricalDamage.purchaseInfo) !== null && _a !== void 0 ? _a : false;
    }
    set purchaseInfo(purchaseInfo) {
        if (!purchaseInfo) {
            this.update({ purchaseAmount: null, purchaseDate: null });
        }
        this.update({ purchaseInfo });
    }
    get purchaseCertification() {
        var _a;
        return (_a = this.electricalDamage.purchaseCertification) !== null && _a !== void 0 ? _a : false;
    }
    set purchaseCertification(purchaseCertification) {
        this.update({ purchaseCertification });
    }
    get purchaseAmount() {
        var _a;
        return (_a = this.electricalDamage.purchaseAmount) !== null && _a !== void 0 ? _a : 0;
    }
    set purchaseAmount(purchaseAmount) {
        this.update({ purchaseAmount });
    }
    get purchaseDate() {
        return this.electricalDamage.purchaseDate
            ? new Date(this.electricalDamage.purchaseDate)
            : this.maxDate;
    }
    set purchaseDate(purchaseDate) {
        var _a;
        this.update({ purchaseDate: (_a = purchaseDate === null || purchaseDate === void 0 ? void 0 : purchaseDate.toISOString()) !== null && _a !== void 0 ? _a : null });
    }
    get purchaseInvoice() {
        if (typeof this.electricalDamage.purchaseInvoice === 'object') {
            return this.electricalDamage.purchaseInvoice;
        }
        return null;
    }
    set purchaseInvoice(purchaseInvoice) {
        this.update({ purchaseInvoice });
    }
    fieldProps(field) {
        return {
            type: this.validation.fieldIsValid(field) ? '' : 'is-danger',
            message: this.validation.fieldErrors(field, this),
        };
    }
    update(data) {
        this.updateDamage({
            changeKey: this.changeKey,
            data,
        });
    }
};
__decorate([
    electricalDamage.Getter('change')
], ElectricalDamageDetailsSection.prototype, "changeGetter", void 0);
__decorate([
    electricalDamage.Getter('validation')
], ElectricalDamageDetailsSection.prototype, "validationGetter", void 0);
__decorate([
    electricalDamage.Action('updateDamage')
], ElectricalDamageDetailsSection.prototype, "updateDamage", void 0);
__decorate([
    electricalDamage.Action('validateAndRevealDamages')
], ElectricalDamageDetailsSection.prototype, "validateAndRevealDamages", void 0);
__decorate([
    claim.State('claim')
], ElectricalDamageDetailsSection.prototype, "claim", void 0);
ElectricalDamageDetailsSection = __decorate([
    Component({
        components: {
            ClaimsButton,
            SecondaryButton,
            RadioImageButton,
            AmountInput,
            DateAndTimePicker,
            FileCollectionUpload,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
            errors: { type: Array, default: () => [] },
        },
    })
], ElectricalDamageDetailsSection);
export default ElectricalDamageDetailsSection;
