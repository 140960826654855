var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Modal from '@declaration/components/UI/Modal.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import DamageType from '@declaration/components/glassDamage/DamageDescription/DamageType.vue';
import PanesDescription from '@declaration/components/glassDamage/DamageDescription/PanesDescription.vue';
import GlazingType from '@declaration/components/glassDamage/DamageDescription/GlazingType.vue';
import GlassType from '@declaration/components/glassDamage/DamageDescription/GlassType.vue';
import PicturesCollection from '@declaration/components/glassDamage/DamageDescription/PicturesCollection.vue';
import { GLASS_DAMAGE_TYPE_TRAD_KEYS } from '@shared/constants/glassDamageConstants';
import { GLASS_DAMAGE_TYPE_IMAGES, GLASS_DAMAGE_TYPE_IMAGE_CHOICES, GLASS_DAMAGE_TYPE_PLAIN_CHOICES, } from '@declaration/constants/glassDamageConstants';
const glassDamage = namespace('glassDamage');
var Sections;
(function (Sections) {
    Sections["TypeSelection"] = "TypeSelection";
    Sections["DamageDescription"] = "DamageDescription";
    Sections["GlazingType"] = "GlazingType";
    Sections["GlassType"] = "GlassType";
    Sections["Pictures"] = "Pictures";
})(Sections || (Sections = {}));
let GlassDamageModal = class GlassDamageModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.SECTIONS = Sections;
        this.selectedSection = null;
    }
    get title() {
        return this.glassDamageType && this.section !== Sections.TypeSelection
            ? this.$tc(GLASS_DAMAGE_TYPE_TRAD_KEYS[this.glassDamageType])
            : this.$tc('claim.damage.glass_damage.form.title');
    }
    get image() {
        if (this.section !== Sections.TypeSelection) {
            const type = this.glassDamageType;
            if (type && type in GLASS_DAMAGE_TYPE_IMAGES)
                return GLASS_DAMAGE_TYPE_IMAGES[type];
        }
        return null;
    }
    get buttonText() {
        return this.section === Sections.Pictures
            ? this.$tc('claim.damage.glass_damage.form.validate')
            : this.$tc('claim.damage.glass_damage.form.next');
    }
    get section() {
        if (!this.selectedSection) {
            return this.glassDamage.type && this.validation.isValid
                ? Sections.DamageDescription
                : Sections.TypeSelection;
        }
        return this.selectedSection;
    }
    nextStep() {
        this.updateGlassDamage({ changeKey: this.changeKey, data: { type: this.glassDamage.type } });
        if (this.isValidStep) {
            switch (this.section) {
                case Sections.TypeSelection:
                    this.selectedSection = Sections.DamageDescription;
                    break;
                case Sections.DamageDescription:
                    this.selectedSection = Sections.GlazingType;
                    break;
                case Sections.GlazingType:
                    this.selectedSection = Sections.GlassType;
                    break;
                case Sections.GlassType:
                    this.selectedSection = Sections.Pictures;
                    break;
                case Sections.Pictures:
                    this.save();
                    break;
            }
        }
        else
            this.validateGlassDamage(this.changeKey);
    }
    get isValidStep() {
        switch (this.section) {
            case Sections.TypeSelection:
                return typeof this.glassDamage.type === 'string' && this.validation.fieldIsValid('type');
            case Sections.DamageDescription:
                return (typeof this.glassDamage.numberOfPanes === 'number' &&
                    this.validation.fieldIsValid('numberOfPanes') &&
                    typeof this.glassDamage.widestPaneWidth === 'number' &&
                    this.validation.fieldIsValid('widestPaneWidth') &&
                    typeof this.glassDamage.widestPaneHeight === 'number' &&
                    this.validation.fieldIsValid('widestPaneHeight'));
            case Sections.GlazingType:
                return (typeof this.glassDamage.glazingType === 'string' &&
                    this.validation.fieldIsValid('glazingType'));
            case Sections.GlassType:
                return (typeof this.glassDamage.glassType === 'string' &&
                    this.validation.fieldIsValid('glassType'));
            case Sections.Pictures:
                return this.validation.isValid;
            default:
                return true;
        }
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get glassDamage() {
        return this.change.data;
    }
    get glassDamageType() {
        return this.glassDamage.type;
    }
    get glassDamageTypeImageChoices() {
        return GLASS_DAMAGE_TYPE_IMAGE_CHOICES;
    }
    get glassDamageTypeButtonChoices() {
        return GLASS_DAMAGE_TYPE_PLAIN_CHOICES;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateGlassDamage(this.changeKey);
    }
    async save() {
        this.isSaving = true;
        try {
            await this.saveChanges();
            this.closeModal();
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isSaving = false;
    }
    closeModal() {
        this.selectedSection = null;
        this.$emit('close');
    }
    onClose() {
        this.closeModal();
    }
};
__decorate([
    glassDamage.Getter('change')
], GlassDamageModal.prototype, "changeGetter", void 0);
__decorate([
    glassDamage.Action('updateDamage')
], GlassDamageModal.prototype, "updateGlassDamage", void 0);
__decorate([
    glassDamage.Action('resetState')
], GlassDamageModal.prototype, "resetState", void 0);
__decorate([
    glassDamage.Getter('validation')
], GlassDamageModal.prototype, "validationGetter", void 0);
__decorate([
    glassDamage.Action('validateAndRevealDamages')
], GlassDamageModal.prototype, "validateGlassDamage", void 0);
__decorate([
    glassDamage.Action('saveChanges')
], GlassDamageModal.prototype, "saveChanges", void 0);
GlassDamageModal = __decorate([
    Component({
        components: {
            Modal,
            DamageType,
            PanesDescription,
            GlazingType,
            GlassType,
            PicturesCollection,
            RadioPrimaryButton,
            ClaimsButton,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            changeKey: { type: [Number, String], default: null },
        },
    })
], GlassDamageModal);
export default GlassDamageModal;
