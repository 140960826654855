var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ReceiptCard from '@declaration/components/receipt/ReceiptCard.vue';
import ReceiptModal from '@declaration/components/receipt/ReceiptModal.vue';
const claim = namespace('claim');
const receipt = namespace('receipt');
let ReceiptList = class ReceiptList extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isModalOpen = false;
        this.editingChangeKey = null;
    }
    edit(changeKey) {
        this.editingChangeKey = changeKey;
        this.isModalOpen = true;
    }
    // Function below is commented instead of removed, in case we find another use for it later
    /* async onFile(event) {
      if (!this.claim.id) {
        return
      }
      try {
        if (event.target.files.length) {
          // Target.files can be a FileList not compatible with Array
          const files: File[] = Array.from(event.target.files)
  
          this.isLoading = true
          const collection = emptyUploadedFileCollection()
          collection.uploadedAppFiles = await uploadFiles(
            files,
            this.claim.id,
            ClaimFileType.Receipt,
            this.$axios,
          )
  
          this.updateReceipt({ changeKey: null, data: { receiptFile: collection } })
          this.edit(null)
  
          // if the user re-upload the same file, we want to have an event trigger
          // therefore we reset the file input value once the file is uploaded
          const fileInput = this.$refs.fileInput as HTMLInputElement | null
          if (fileInput) {
            fileInput.value = ''
          }
        }
      } catch (err) {
        await this.$store.dispatch('error/add', 'Une erreur est survenue')
      }
      this.isLoading = false
    } */
    confirmDelete(receiptIri, deleteCallback) {
        if (receiptIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.declaration.room_damage.receipt.delete.modal.text'),
                cancelText: this.$tc('claim.damage.declaration.room_damage.receipt.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.declaration.room_damage.receipt.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    onModalClose() {
        this.isModalOpen = false;
        this.resetChanges();
    }
};
__decorate([
    claim.State('claim')
], ReceiptList.prototype, "claim", void 0);
__decorate([
    receipt.State('damages')
], ReceiptList.prototype, "receipts", void 0);
__decorate([
    receipt.Action('updateDamage')
], ReceiptList.prototype, "updateReceipt", void 0);
__decorate([
    receipt.Action('resetChanges')
], ReceiptList.prototype, "resetChanges", void 0);
ReceiptList = __decorate([
    Component({
        components: {
            ReceiptCard,
            ReceiptModal,
        },
    })
], ReceiptList);
export default ReceiptList;
