var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { VERANDA_GUTTER_MATERIAL_CHOICES } from '@shared/constants/externalDamages/VerandaConstants';
import { VERANDA_GUTTER_TITLE, VERANDA_GUTTER_MATERIAL, VERANDA_GUTTER_LENGTH, } from '@declaration/constants/externalDamages/VerandaConstants';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { translateChoices } from '@shared/choice';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
let VerandaGutter = class VerandaGutter extends ExternalDamageComponent {
    get gutterTitle() {
        return this.translateExternalDamageKey(VERANDA_GUTTER_TITLE);
    }
    get gutterDamagedField() {
        return {
            type: this.fieldIsValid('gutterDamaged') ? '' : 'is-danger',
            message: this.fieldErrors('gutterDamaged')[0],
        };
    }
    get gutterDamagedChoices() {
        return translateChoices(YES_OR_NO_CHOICES, this.$i18n.tc.bind(this.$i18n));
    }
    get gutterDamaged() {
        return this.externalDamage.gutterDamaged;
    }
    set gutterDamaged(gutterDamaged) {
        if (gutterDamaged) {
            this.updateExternalDamage({
                gutterDamaged,
                gutterMaterial: null,
                gutterLength: null,
            });
        }
        else {
            this.updateExternalDamage({ gutterDamaged });
        }
    }
    get gutterMaterialLabel() {
        return this.translateExternalDamageKey(VERANDA_GUTTER_MATERIAL);
    }
    get gutterMaterialField() {
        return {
            type: this.fieldIsValid('gutterMaterial') ? '' : 'is-danger',
            message: this.fieldErrors('gutterMaterial')[0],
        };
    }
    get gutterMaterialChoices() {
        return translateChoices(VERANDA_GUTTER_MATERIAL_CHOICES, this.translateExternalDamageKey.bind(this));
    }
    get gutterMaterial() {
        return this.externalDamage.gutterMaterial;
    }
    set gutterMaterial(gutterMaterial) {
        this.updateExternalDamage({ gutterMaterial });
    }
    get gutterLengthLabel() {
        return this.translateExternalDamageKey(VERANDA_GUTTER_LENGTH);
    }
    get gutterLengthField() {
        return {
            type: this.fieldIsValid('gutterLength') ? '' : 'is-danger',
            message: this.fieldErrors('gutterLength')[0],
        };
    }
    get gutterLength() {
        return this.externalDamage.gutterLength;
    }
    set gutterLength(gutterLength) {
        this.updateExternalDamage({ gutterLength });
    }
};
VerandaGutter = __decorate([
    Component({ components: { RadioPrimaryButton, LengthInput } })
], VerandaGutter);
export default VerandaGutter;
