var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { personalPropertyTitle } from '@shared/personalProperty/personalPropertyFields';
import Modal from '@declaration/components/UI/Modal.vue';
import formatAmount from '@shared/utils/formatAmount';
import { forcedAmount, forcedAmountWithDefault } from '@shared/utils/forcedAmount';
import { uuidFromIri } from '@shared/utils/iri';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const counting = namespace('counting');
const personalProperty = namespace('personalProperty');
let PersonalPropertiesDetailModal = class PersonalPropertiesDetailModal extends Vue {
    constructor() {
        super(...arguments);
        this.personalPropertyIncludedIcon = require('@declaration/assets/images/compensation/personalPropertyIncluded.svg');
        this.personalPropertyExcludedIcon = require('@declaration/assets/images/compensation/personalPropertyExcluded.svg');
        this.formatAmount = formatAmount;
        this.forcedAmount = forcedAmount;
        this.forcedAmountWithDefault = forcedAmountWithDefault;
    }
    get totalAmount() {
        return forcedAmountWithDefault(this.personalPropertiesRefund.appliedAmounts.total, 0);
    }
    get compensationAmount() {
        return forcedAmountWithDefault(this.personalPropertiesRefund.compensation.total, 0);
    }
    get ceilingAmount() {
        var _a;
        return (_a = this.personalPropertiesRefund.parameters.ceiling.value) !== null && _a !== void 0 ? _a : null;
    }
    get deductibleAmount() {
        return forcedAmount(this.personalPropertiesRefund.parameters.deductibleAmount);
    }
    get hasCeiling() {
        return this.ceilingAmount !== null;
    }
    get hasDeductible() {
        return this.deductibleAmount !== null;
    }
    get shouldDisplayCeiling() {
        return this.ceilingAmount !== null && this.compensationAmount > this.ceilingAmount;
    }
    get shouldDisplayDeductible() {
        return this.deductibleAmount !== null && this.deductibleAmount > 0;
    }
    get shouldDisplayDetails() {
        if (!insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition)) {
            return this.shouldDisplayCeiling || this.shouldDisplayDeductible;
        }
        else {
            return false;
        }
    }
    get shouldDisplayInfo() {
        return !insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition);
    }
    get personalPropertiesWithAmounts() {
        return this.personalProperties.map((personalProperty) => {
            const warnings = this.warnings.filter(({ context: { refusedPersonalPropertyDamages } }) => {
                if (!(refusedPersonalPropertyDamages === null || refusedPersonalPropertyDamages === void 0 ? void 0 : refusedPersonalPropertyDamages.length))
                    return false;
                return refusedPersonalPropertyDamages.includes(uuidFromIri(personalProperty.id));
            });
            const title = personalPropertyTitle(personalProperty, this.categoriesByIri);
            const recoverableAmount = forcedAmountWithDefault(personalProperty.refund.appliedAmounts.total, 0);
            let exclusionMessages = [];
            if (warnings.length) {
                exclusionMessages = warnings.map(({ message, context: { tradKeyData } }) => {
                    if (this.$te(message))
                        return this.$tc(message, undefined, tradKeyData);
                    return message;
                });
            }
            else if (personalProperty.refund.parameters.exclusionReason) {
                const key = `compensation.proposition.personal_properties.exclusion_reason.${personalProperty.refund.parameters.exclusionReason}`;
                if (this.$te(key))
                    exclusionMessages = [this.$tc(key)];
                else
                    exclusionMessages = [
                        this.$tc('compensation.proposition.personal_properties.exclusion_reason'),
                    ];
            }
            return {
                ...personalProperty,
                title,
                recoverableAmount,
                exclusionMessages,
            };
        });
    }
    get personalPropertyHeaderInfoTitle() {
        if (insurerHasFeature(InsurerFeatureFlag.ShortDetailsProposition)) {
            return this.$tc('compensation.proposition.personal_properties.modal.info.short');
        }
        return this.$tc('compensation.proposition.personal_properties.modal.info');
    }
    get warnings() {
        return this.claim.warnings;
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertiesDetailModal.prototype, "claim", void 0);
__decorate([
    counting.State('counting')
], PersonalPropertiesDetailModal.prototype, "counting", void 0);
__decorate([
    counting.Getter('personalPropertiesRefund')
], PersonalPropertiesDetailModal.prototype, "personalPropertiesRefund", void 0);
__decorate([
    personalProperty.State('damages')
], PersonalPropertiesDetailModal.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertiesDetailModal.prototype, "categoriesByIri", void 0);
PersonalPropertiesDetailModal = __decorate([
    Component({
        components: { Modal },
        props: {
            isDisplayed: { type: Boolean, required: true },
        },
    })
], PersonalPropertiesDetailModal);
export default PersonalPropertiesDetailModal;
