import { render, staticRenderFns } from "./recapitulatif-dommages.vue?vue&type=template&id=45d35300&scoped=true"
import script from "./recapitulatif-dommages.vue?vue&type=script&lang=ts"
export * from "./recapitulatif-dommages.vue?vue&type=script&lang=ts"
import style0 from "./recapitulatif-dommages.vue?vue&type=style&index=0&id=45d35300&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d35300",
  null
  
)

export default component.exports