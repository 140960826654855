var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import OutlinedNumberInput from '@declaration/components/OutlinedNumberInput.vue';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const settings = namespace('settings');
const qualification = namespace('qualification');
let NumberOfPersonalPropertyDamagesQuestion = class NumberOfPersonalPropertyDamagesQuestion extends QuestionComponent {
    get maxDamages() {
        return this.settingGetter('eleMaxPersonalPropertyDamagedCount');
    }
    get validateButtonText() {
        return this.$tc('claim.qualification.number_of_personal_properties.validate', this.damagesCount === null || this.damagesCount < 1 ? 1 : 2);
    }
    get questionTitle() {
        if (this.typeTrigram === ClaimTypeTrigram.Electric) {
            return this.$tc('claim.damage.personal_property.number_of_damages.title.electric');
        }
        else if (this.typeTrigram === ClaimTypeTrigram.Robbery) {
            return this.$tc('claim.damage.personal_property.number_of_damages.title.robbery');
        }
        return this.$tc('claim.damage.personal_property.number_of_damages.title');
    }
    onValidationButtonClick() {
        this.saveChanges({ step: this.step });
    }
    get numberOfPersonalPropertiesValuesAndLabels() {
        if (this.maxDamages === null)
            return [];
        const numberOfPersonalPropertysValuesAndLabels = Array.from({ length: this.maxDamages }, (_, i) => ({
            value: i + 1,
            label: (i + 1).toString(),
        }));
        numberOfPersonalPropertysValuesAndLabels.push({
            value: this.maxDamages + 1,
            label: this.$tc('claim.damage.personal_property.number_of_damages.count_and_more', this.maxDamages + 1, { count: this.maxDamages + 1 }),
        });
        numberOfPersonalPropertysValuesAndLabels.push({
            value: 0,
            label: this.$tc('claim.damage.personal_property.number_of_damages.none'),
        });
        return numberOfPersonalPropertysValuesAndLabels;
    }
    updatePersonalPropertiesCount(initialPersonalPropertiesCount) {
        this.updateCount({ count: initialPersonalPropertiesCount });
    }
    get answer() {
        if (this.damagesCount === null || this.maxDamages === null) {
            return null;
        }
        if (this.damagesCount > this.maxDamages) {
            return this.$tc('claim.damage.personal_property.number_of_damages.sumup.maxed', this.maxDamages + 1, {
                count: this.maxDamages + 1,
            });
        }
        return this.$tc('claim.damage.personal_property.number_of_damages.sumup', this.damagesCount, {
            count: this.damagesCount,
        });
    }
};
__decorate([
    personalProperty.Action('saveChanges')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "saveChanges", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('typeTrigram')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "typeTrigram", void 0);
__decorate([
    personalProperty.Getter('damagesCount')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "damagesCount", void 0);
__decorate([
    personalProperty.Getter('damagesCountInCategory')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "damagesCountInCategory", void 0);
__decorate([
    personalProperty.Getter('areDamagesInCategory')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "areDamagesInCategory", void 0);
__decorate([
    personalProperty.Action('updateCount')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "updateCount", void 0);
__decorate([
    personalProperty.Action('updateDamagesCountInCategory')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "updateDamagesCountInCategory", void 0);
__decorate([
    settings.Getter('setting')
], NumberOfPersonalPropertyDamagesQuestion.prototype, "settingGetter", void 0);
NumberOfPersonalPropertyDamagesQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, OutlinedNumberInput },
        props: QuestionProps,
    })
], NumberOfPersonalPropertyDamagesQuestion);
export default NumberOfPersonalPropertyDamagesQuestion;
