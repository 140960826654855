var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Datetime } from 'vue-datetime';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
const qualification = namespace('qualification');
let DateAndTimePicker = class DateAndTimePicker extends Vue {
    constructor() {
        super(...arguments);
        this.date = null;
    }
    get theme() {
        return `claims-datetime${this.date ? '' : ' claims-datetime--empty'} claims-datetime--${this.typeTrigram}`;
    }
    get format() {
        switch (this.type) {
            case 'date':
                return 'dd/MM/yyyy';
            case 'datetime':
                return 'dd/MM/yyyy à HH:mm';
            case 'time':
                return 'HH:mm';
        }
    }
    get placeholder() {
        return this.$tc(`claim.datetime.placeholder.${this.type}`);
    }
    get maxDateTime() {
        return this.$route.query.disableFrontDamageMaxDate ? null : this.dateToString(this.maxDate);
    }
    dateToString(date) {
        return date ? date.toISOString() : null;
    }
    mounted() {
        this.date = this.value;
    }
    onInput(dateTime) {
        if (!dateTime) {
            return;
        }
        this.date = new Date(dateTime);
        this.$emit('input', this.date);
    }
};
__decorate([
    qualification.State('typeTrigram')
], DateAndTimePicker.prototype, "typeTrigram", void 0);
DateAndTimePicker = __decorate([
    Component({
        components: { Datetime, ClaimsButton },
        props: {
            value: {
                type: Date,
                default: null,
            },
            maxDate: {
                type: Date,
                default: null,
            },
            minDate: {
                type: Date,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'date',
            },
        },
    })
], DateAndTimePicker);
export default DateAndTimePicker;
