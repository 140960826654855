var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
const thirdParty = namespace('thirdParty');
const pages = namespace('pages');
let IsRehousingNeededQuestion = class IsRehousingNeededQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalOpen = false;
        this.leaseFileCollection = null;
    }
    created() {
        if ('tenantLeaseCollection' in this.qualification) {
            this.leaseFileCollection = this.qualification.tenantLeaseCollection;
        }
    }
    updateClaim(isLeaveBeforeSinister) {
        this.updateAndSaveQualification({
            qualificationPayload: { isLeaveBeforeSinister },
            step: this.step,
        });
    }
    fileCollectionUpdate(event) {
        this.leaseFileCollection = event;
    }
    get title() {
        return this.isTenant
            ? this.$tc('claim.qualification.leave-before-sinister.tenant.title')
            : this.$tc('claim.qualification.leave-before-sinister.owner.title');
    }
    get description() {
        return this.isTenant
            ? this.$tc('claim.qualification.leave-before-sinister.tenant.description')
            : this.$tc('claim.qualification.leave-before-sinister.owner.description');
    }
    get answer() {
        if (this.qualification.isLeaveBeforeSinister === null) {
            return null;
        }
        return this.qualification.isLeaveBeforeSinister
            ? this.isTenant
                ? this.$tc('claim.qualification.leave-before-sinister.tenant.sumup.yes')
                : this.$tc('claim.qualification.leave-before-sinister.owner.sumup.yes')
            : this.isTenant
                ? this.$tc('claim.qualification.leave-before-sinister.tenant.sumup.no')
                : this.$tc('claim.qualification.leave-before-sinister.owner.sumup.no');
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.isLeaveBeforeSinister) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
    handleButtonClick(isLeaveBeforeSinister) {
        if (isLeaveBeforeSinister === false &&
            this.isNonOccupier &&
            this.typeTrigram === ClaimTypeTrigram.Fire) {
            if (this.thirdParties.length === 0)
                this.addNewThirdPartyAndEditIt(false);
            else
                this.setThirdPartyInEditionIndex(0);
            this.isModalOpen = true;
            return this.updateQualification({
                qualificationPayload: { isLeaveBeforeSinister },
            });
        }
        this.updateClaim(isLeaveBeforeSinister);
    }
    get askingTenantInfos() {
        return this.isNonOccupier && this.typeTrigram === ClaimTypeTrigram.Fire;
    }
    get headerTitleModal() {
        return this.$tc('tenant_insurer.title');
    }
    get formTitleModal() {
        return this.$tc('claim.qualification.fire_third_party_tenant.subform_title');
    }
    get thirdPartyPayload() {
        return {
            step: this.step,
            fireDamageQualification: {
                id: this.qualification.id,
                isLeaveBeforeSinister: this.selectedValue,
                tenantLeaseCollection: this.leaseFileCollection,
            },
        };
    }
    get selectedValue() {
        return 'isLeaveBeforeSinister' in this.qualification
            ? this.qualification.isLeaveBeforeSinister
            : null;
    }
};
__decorate([
    pages.Getter('previousStep')
], IsRehousingNeededQuestion.prototype, "previousStep", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], IsRehousingNeededQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], IsRehousingNeededQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], IsRehousingNeededQuestion.prototype, "typeTrigram", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], IsRehousingNeededQuestion.prototype, "updateAndSaveQualification", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], IsRehousingNeededQuestion.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], IsRehousingNeededQuestion.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.State('thirdParties')
], IsRehousingNeededQuestion.prototype, "thirdParties", void 0);
__decorate([
    qualification.Action('updateQualification')
], IsRehousingNeededQuestion.prototype, "updateQualification", void 0);
__decorate([
    contract.Getter('isTenant')
], IsRehousingNeededQuestion.prototype, "isTenant", void 0);
__decorate([
    contract.Getter('isNonOccupier')
], IsRehousingNeededQuestion.prototype, "isNonOccupier", void 0);
IsRehousingNeededQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ThirdPartyModal },
        props: QuestionProps,
    })
], IsRehousingNeededQuestion);
export default IsRehousingNeededQuestion;
