var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { COMPENSATION_EXCLUSION_REASON_TRAD_KEYS } from '@declaration/constants/refundConstants';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { CompensationMode } from '@shared/types/api/claim';
import { apiResourceOfType } from '@shared/utils/apiResourceOfType';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { forcedAmount, forcedAmountWithDefault } from '@shared/utils/forcedAmount';
const claim = namespace('claim');
let ImmediateCompensationDetailCard = class ImmediateCompensationDetailCard extends Vue {
    constructor() {
        super(...arguments);
        this.forcedAmount = forcedAmount;
        this.forcedAmountWithDefault = forcedAmountWithDefault;
        this.entityIncludedIcon = require('@declaration/assets/images/compensation/personalPropertyIncluded.svg');
        this.entityExcludedIcon = require('@declaration/assets/images/compensation/personalPropertyExcluded.svg');
    }
    recoverable(entity) {
        if (apiResourceOfType(entity, ApiEntityType.PersonalProperty)) {
            return this.personalPropertyRecoverable(entity);
        }
        return this.breakInDamageRecoverable(entity);
    }
    close() {
        this.$emit('close');
    }
    exclusionReason(entity) {
        var _a, _b;
        const exclusionReason = (_b = (_a = entity.refund) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.exclusionReason;
        if (!exclusionReason) {
            return '';
        }
        return `${this.$tc(COMPENSATION_EXCLUSION_REASON_TRAD_KEYS[exclusionReason])}`;
    }
    get insurerHasFeatureCompensationPropositionGenerali() {
        return insurerHasFeature(InsurerFeatureFlag.CompensationPropositionGenerali);
    }
    isEntityExcluded(entity) {
        var _a, _b;
        return !!((_b = (_a = entity.refund) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.exclusionReason);
    }
    hasRen(entity) {
        return ((entity === null || entity === void 0 ? void 0 : entity.compensationMode) === CompensationMode.CompensationModeCertifiedArtisan ||
            (entity === null || entity === void 0 ? void 0 : entity.pendingCompensationMode) === CompensationMode.CompensationModeCertifiedArtisan);
    }
    breakInDamageRecoverable(entity) {
        return forcedAmountWithDefault(entity.refund.appliedAmounts.immediate, 0);
    }
    personalPropertyRecoverable(entity) {
        return forcedAmountWithDefault(entity.refund.appliedAmounts.immediate, 0);
    }
    indemnisationReasonText(entity) {
        const reason = this.indemnisationReason(entity);
        if (Array.isArray(reason)) {
            const reasons = reason.map((s) => `${this.$tc(s)}`).join(', ');
            return `${reasons}`;
        }
        return this.$tc(reason);
    }
};
__decorate([
    claim.State('claim')
], ImmediateCompensationDetailCard.prototype, "claim", void 0);
ImmediateCompensationDetailCard = __decorate([
    Component({
        components: {},
        props: {
            headerImage: { type: String, required: true },
            headerTitle: { type: String, required: true },
            refundDetail: { type: Object, required: true },
            damages: { type: Array, required: true },
            entityTitle: { type: Function, required: true },
            indemnisationReason: { type: Function, required: true },
            indemnisationExplanation: { type: Function, required: false },
            infoMessage: { type: String, required: false },
        },
    })
], ImmediateCompensationDetailCard);
export default ImmediateCompensationDetailCard;
