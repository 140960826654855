var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ROBBERY_LOCATION_CHOICES, ROBBERY_LOCATION_TRAD_KEYS, } from '@shared/constants/robberyConstants';
import { RobberyEventType } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyLocationQuestion = class RobberyLocationQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.choices = ROBBERY_LOCATION_CHOICES;
    }
    updateClaim(location) {
        this.updateAndSaveQualification({
            qualificationPayload: { location },
            step: this.step,
        });
    }
    get title() {
        if (this.qualification.eventType === RobberyEventType.Vandalism) {
            return this.$tc('claim.qualification.robbery.vandalism.location.title');
        }
        return this.$tc('claim.qualification.robbery.location.title');
    }
    get answer() {
        if (this.qualification.location === null) {
            return null;
        }
        const location = this.$tc(ROBBERY_LOCATION_TRAD_KEYS[this.qualification.location]).toLowerCase();
        if (this.qualification.eventType === RobberyEventType.Vandalism) {
            return this.$tc('claim.qualification.robbery.vandalism.location.answer', 1, { location });
        }
        return this.$tc('claim.qualification.robbery.location.answer', 1, { location });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyLocationQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyLocationQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyLocationQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyLocationQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], RobberyLocationQuestion);
export default RobberyLocationQuestion;
