var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { isFileCollectionModelField, isFileModelField, } from '@shared/modelField/modelField';
import { EMPTY } from '@shared/utils/strings';
import { booleanTransform } from '@shared/utils/transform';
let ModelField = class ModelField extends Vue {
    constructor() {
        super(...arguments);
        this.empty = EMPTY;
    }
    get fileCollection() {
        return isFileCollectionModelField(this.field) ? this.field.displayedValue : null;
    }
    get file() {
        return isFileModelField(this.field) ? this.field.displayedValue : null;
    }
    get value() {
        if (this.file)
            return booleanTransform(!!this.file.file, this.$tc.bind(this));
        if (this.fileCollection)
            return this.fileCollectionDisplayString(this.fileCollection);
        return (this.field.displayedValue ? this.field.displayedValue : this.field.value);
    }
    fileCollectionDisplayString(collectionField) {
        const count = collectionField ? fileCollectionFilesCount(collectionField.fileCollection) : 0;
        if (count === 0) {
            return booleanTransform(false, this.$tc.bind(this));
        }
        if (collectionField.displayAsCount) {
            return count.toString();
        }
        return booleanTransform(true, this.$tc.bind(this));
    }
    isList(obj) {
        return Array.isArray(obj);
    }
};
ModelField = __decorate([
    Component({
        components: {},
        props: {
            field: {
                type: Object,
                required: true,
            },
        },
    })
], ModelField);
export default ModelField;
