var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import Vue from 'vue';
import { ClaimLanguage } from '@shared/types/api/user';
import { CLAIM_LANGUAGE_FLAG_IMAGES } from '@shared/utils/language';
const user = namespace('user');
let LanguageSelector = class LanguageSelector extends Vue {
    constructor() {
        super(...arguments);
        this.languages = Object.values(ClaimLanguage);
        this.isLoading = false;
    }
    get locale() {
        return this.$i18n.locale;
    }
    set locale(locale) {
        this.setLocale(locale);
    }
    async setLocale(locale) {
        this.isLoading = true;
        try {
            await this.setLanguage(locale);
            this.$i18n.setLocale(locale);
        }
        catch (_a) {
            this.$buefy.toast.open({
                message: 'locale.error',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        finally {
            this.isLoading = false;
        }
    }
    async mounted() {
        await this.getUserInfo();
        if (this.stateLanguage !== this.$i18n.locale) {
            this.$i18n.setLocale(this.stateLanguage);
            this.$forceUpdate();
        }
    }
    get languageFlagImage() {
        return CLAIM_LANGUAGE_FLAG_IMAGES[this.$i18n.locale];
    }
};
__decorate([
    user.Action('setLanguage')
], LanguageSelector.prototype, "setLanguage", void 0);
__decorate([
    user.Action('getUserInfo')
], LanguageSelector.prototype, "getUserInfo", void 0);
__decorate([
    user.State('language')
], LanguageSelector.prototype, "stateLanguage", void 0);
LanguageSelector = __decorate([
    Component({
        props: {},
    })
], LanguageSelector);
export default LanguageSelector;
