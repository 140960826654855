var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { LeakageCauseFixed } from '@shared/types/api/claim';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { objectToTranslatableChoices } from '@shared/choice';
import { LEAKAGE_CAUSE_FIXED_TRAD_KEYS } from '@shared/constants/waterDamageConstants';
import { INSURER, INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
let WaterLeakageCauseFixedQuestion = class WaterLeakageCauseFixedQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.allowedValues = [];
        this.filteredKeys = {
            yes: '',
            no: '',
            'repair-in-progress': '',
        };
        this.yesNoQuestionValuesAndTradKeys = [];
    }
    created() {
        if (INSURER === 'bpce') {
            this.allowedValues = [LeakageCauseFixed.YES, LeakageCauseFixed.NO];
            this.filteredKeys = Object.keys(LEAKAGE_CAUSE_FIXED_TRAD_KEYS)
                .filter((key) => this.allowedValues.includes(key))
                .reduce((obj, key) => {
                obj[key] = LEAKAGE_CAUSE_FIXED_TRAD_KEYS[key];
                return obj;
            }, {});
            this.yesNoQuestionValuesAndTradKeys = objectToTranslatableChoices(this.filteredKeys);
        }
        else {
            this.yesNoQuestionValuesAndTradKeys = objectToTranslatableChoices(LEAKAGE_CAUSE_FIXED_TRAD_KEYS);
        }
    }
    updateClaim(isWaterLeakageCauseFixed) {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                isWaterLeakageCauseFixed,
            },
            step: this.step,
        });
    }
    get answer() {
        switch (this.waterDamageQualification.isWaterLeakageCauseFixed) {
            case LeakageCauseFixed.YES:
                return this.$tc('claim.qualification.repair.sumup.yes');
            case LeakageCauseFixed.NO:
                return this.$tc('claim.qualification.repair.sumup.no');
            case LeakageCauseFixed.IN_PROGRESS:
                return this.$tc('claim.qualification.repair.sumup.repair-in-progress');
            default:
                return null;
        }
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.waterLeakageCauseFixed) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], WaterLeakageCauseFixedQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], WaterLeakageCauseFixedQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], WaterLeakageCauseFixedQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
WaterLeakageCauseFixedQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], WaterLeakageCauseFixedQuestion);
export default WaterLeakageCauseFixedQuestion;
