var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TermsAndConditionsModal from '@declaration/components/TermsAndConditions/TermsAndConditionsModal.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import { LostKeysLockType } from '@shared/types/api/claim';
import ImmediateCompensationDetailModal from '@declaration/components/Compensation/ImmediateCompensationDetailModal.vue';
import PersonalPropertiesDetailModal from '@declaration/components/Compensation/PersonalPropertiesDetailModal.vue';
import CompensationOnProofDetailModal from '@declaration/components/Compensation/CompensationOnProofDetailModal.vue';
import { ROBBERY_LOST_KEYS_LOCK_TYPE_TRAD_KEYS } from '@shared/constants/robberyConstants';
const layout = namespace('layout');
const claim = namespace('claim');
const qualification = namespace('qualification');
let CompensationLostKeys = class CompensationLostKeys extends Vue {
    constructor() {
        super(...arguments);
        this.isAccepting = false;
        this.keysImage = require('@declaration/assets/images/compensation/keys.svg');
    }
    get hasQuoteOrReceipt() {
        if (this.qualification.locksReplacementQuoteFileCollection &&
            this.qualification.locksReplacementQuoteFileCollection.uploadedAppFiles) {
            return this.qualification.locksReplacementQuoteFileCollection.uploadedAppFiles.length > 0;
        }
        return false;
    }
    get locksToReplace() {
        return this.lockTypeValuesAndTradKeys.filter((lock) => lock.count > 0 && lock.value !== LostKeysLockType.Other);
    }
    get lockTypeValuesAndTradKeys() {
        return Object.entries(ROBBERY_LOST_KEYS_LOCK_TYPE_TRAD_KEYS).map(([slug, key]) => {
            var _a;
            return {
                value: slug,
                tradKey: key,
                count: this.qualification.lostKeysLocks ? (_a = this.qualification.lostKeysLocks[slug]) !== null && _a !== void 0 ? _a : 0 : 0,
            };
        });
    }
    get hasOtherLocksToReplace() {
        var _a;
        return ((this.qualification.lostKeysLocks
            ? (_a = this.qualification.lostKeysLocks[LostKeysLockType.Other]) !== null && _a !== void 0 ? _a : 0
            : 0) > 0);
    }
    get otherLocksToReplaceCount() {
        var _a;
        if (!this.hasOtherLocksToReplace) {
            return 0;
        }
        return this.qualification.lostKeysLocks
            ? (_a = this.qualification.lostKeysLocks[LostKeysLockType.Other]) !== null && _a !== void 0 ? _a : 0
            : 0;
    }
    get otherLocksToReplaceDescription() {
        if (!this.hasOtherLocksToReplace) {
            return false;
        }
        return this.qualification.lostKeysOtherLocksTypeDescription;
    }
    async acceptWithNoCompensation() {
        this.isAccepting = true;
        await this.updateAndSaveClaim({
            step: Step.CompensationLostLeys,
            claimPayload: {
                isCompensationOptionAccepted: true,
            },
        });
        this.isAccepting = false;
    }
};
__decorate([
    layout.State('isMobile')
], CompensationLostKeys.prototype, "isMobile", void 0);
__decorate([
    claim.State('claim')
], CompensationLostKeys.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationLostKeys.prototype, "updateAndSaveClaim", void 0);
__decorate([
    qualification.State('typeTrigram')
], CompensationLostKeys.prototype, "claimTypeTrigram", void 0);
__decorate([
    qualification.State('qualification')
], CompensationLostKeys.prototype, "qualification", void 0);
CompensationLostKeys = __decorate([
    Component({
        components: {
            PersonalPropertiesDetailModal,
            ImmediateCompensationDetailModal,
            ClaimsButton,
            TermsAndConditionsModal,
            Modal,
            CompensationOnProofDetailModal,
        },
        meta: {
            title: 'page.title.proposal',
            close: quitClaimDeclarationCloseCallback,
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationLostKeys);
export default CompensationLostKeys;
