var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import MoveTo from 'moveto';
import Modal from '@declaration/components/UI/Modal.vue';
import ExternalDamageLocalization from '@declaration/components/externalDamage/ExternalDamageLocalization.vue';
import ExternalDamagePictures from '@declaration/components/externalDamage/ExternalDamagePictures.vue';
import ExternalDamageDescription from '@declaration/components/externalDamage/ExternalDamageDescription.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { ANIMATION_DURATIONS } from '@declaration/animations/utils';
import { EXTERNAL_DAMAGE_SLUG_TO_DESCRIPTION_COMPONENT, } from '@declaration/constants/externalDamages';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
let ExternalDamageModal = class ExternalDamageModal extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get descriptionComponent() {
        return this.externalDamage.slug
            ? EXTERNAL_DAMAGE_SLUG_TO_DESCRIPTION_COMPONENT[this.externalDamage.slug]
            : null;
    }
    get showExternalDamageDescription() {
        return !!this.externalDamage.localizationSlug && !!this.externalDamage.slug;
    }
    created() {
        this.$watch('externalDamageSlug', (externalDamageSlug) => this.moveToRefOnWatchedValueChange(externalDamageSlug, 'description'));
    }
    moveToRefOnWatchedValueChange(watchedValue, ref) {
        if (watchedValue) {
            this.$nextTick(() => {
                // Scroll is relative to Modal content
                const container = this.$children[0].$refs.content;
                if (!container || !this.$refs[ref]) {
                    return;
                }
                const moveTo = new MoveTo({
                    tolerance: 180,
                    duration: ANIMATION_DURATIONS.Long * 1000,
                    container,
                });
                moveTo.move(this.$refs[ref]);
            });
        }
    }
    closeModal() {
        this.$emit('close');
    }
    async save() {
        try {
            this.isSaving = true;
            await this.saveExternalDamage();
            this.closeModal();
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        finally {
            this.isSaving = false;
        }
    }
    onClose() {
        this.resetChanges();
        this.closeModal();
    }
    onGoBack() {
        this.updateExternalDamageLocalization(null);
    }
};
ExternalDamageModal = __decorate([
    Component({
        components: {
            Modal,
            ExternalDamageLocalization,
            ExternalDamageDescription,
            ExternalDamagePictures,
            ClaimsButton,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
        },
    })
], ExternalDamageModal);
export default ExternalDamageModal;
