var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RoomKitchen from '@declaration/components/svg/RoomKitchen.vue';
import RoomWC from '@declaration/components/svg/RoomWC.vue';
import RoomBedroomOffice from '@declaration/components/svg/RoomBedroomOffice.vue';
import RoomCorridorEntrance from '@declaration/components/svg/RoomCorridorEntrance.vue';
import RoomBathroom from '@declaration/components/svg/RoomBathroom.vue';
import RoomLounge from '@declaration/components/svg/RoomLounge.vue';
import RoomGarage from '@declaration/components/svg/RoomGarage.vue';
import RoomOther from '@declaration/components/svg/RoomOther.vue';
import RoomBasement from '@declaration/components/svg/RoomBasement.vue';
import RoomLaundry from '@declaration/components/svg/RoomLaundry.vue';
import EquipmentWashBasin from '@declaration/components/svg/EquipmentWashBasin.vue';
import EquipmentSink from '@declaration/components/svg/EquipmentSink.vue';
import EquipmentShower from '@declaration/components/svg/EquipmentShower.vue';
import EquipmentBathtube from '@declaration/components/svg/EquipmentBathtube.vue';
import EquipmentWC from '@declaration/components/svg/EquipmentWC.vue';
import EquipmentDishwasher from '@declaration/components/svg/EquipmentDishwasher.vue';
import EquipmentRefrigerator from '@declaration/components/svg/EquipmentRefrigerator.vue';
import EquipmentWashingMachine from '@declaration/components/svg/EquipmentWashingMachine.vue';
import EquipmentRadiator from '@declaration/components/svg/EquipmentRadiator.vue';
import EquipmentBoiler from '@declaration/components/svg/EquipmentBoiler.vue';
import EquipmentHotWaterTank from '@declaration/components/svg/EquipmentHotWaterTank.vue';
import EquipmentAquarium from '@declaration/components/svg/EquipmentAquarium.vue';
import { ROOM_CONFIGURATION_BY_ROOM_TYPE, QUALIFICATION_EQUIPMENT, DEFAULT_ROOM_CONFIGURATION, EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE, } from '@shared/constants/equipmentConstants';
const layout = namespace('layout');
const qualification = namespace('qualification');
let LeakageCauseEquipmentQuestion = class LeakageCauseEquipmentQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.hoveredEquipment = null;
    }
    get roomConfiguration() {
        if (this.waterDamageQualification.leakageCauseRoomSlug === null) {
            return DEFAULT_ROOM_CONFIGURATION;
        }
        if (!ROOM_CONFIGURATION_BY_ROOM_TYPE[this.waterDamageQualification.leakageCauseRoomSlug]) {
            return DEFAULT_ROOM_CONFIGURATION;
        }
        return ROOM_CONFIGURATION_BY_ROOM_TYPE[this.waterDamageQualification.leakageCauseRoomSlug];
    }
    get equipments() {
        return this.roomConfiguration.equipments;
    }
    get otherEquipments() {
        return this.roomConfiguration.otherEquipments;
    }
    get equipmentValuesAndTradKeys() {
        return Object.values(this.equipments).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_EQUIPMENT[slug].tradKey,
            };
        });
    }
    get equipmentValuesAndTradKeysWithSvgComponent() {
        return this.equipmentValuesAndTradKeys.filter(({ value }) => {
            return !!this.componentForEquipment(value);
        });
    }
    get otherEquipmentValuesAndTradKeys() {
        return Object.values(this.otherEquipments).map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_EQUIPMENT[slug].tradKey,
            };
        });
    }
    get otherEquipmentAndEquipmentWithoutSvgComponentValuesAndTradKeys() {
        return this.equipmentValuesAndTradKeys
            .filter(({ value }) => {
            return !this.componentForEquipment(value);
        })
            .concat(this.otherEquipmentValuesAndTradKeys);
    }
    componentForEquipment(equipmentValue) {
        if (!EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE[equipmentValue]) {
            return '';
        }
        return EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE[equipmentValue].svgComponentName;
    }
    updateClaim(leakageCauseEquipmentSlug) {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                leakageCauseDetailSlug: null,
                leakageCauseEquipmentSlug,
            },
            step: this.step,
        });
    }
    updateHover(leakageCauseEquipmentSlug) {
        this.hoveredEquipment = leakageCauseEquipmentSlug;
    }
    get answer() {
        if (this.waterDamageQualification.leakageCauseEquipmentSlug === null) {
            return null;
        }
        if (!QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]) {
            return this.$tc('claim.qualification.leakage_cause_equipment.sumup.default');
        }
        return this.$tc(QUALIFICATION_EQUIPMENT[this.waterDamageQualification.leakageCauseEquipmentSlug]
            .leakageCauseEquipmentAnswerTradKey);
    }
};
__decorate([
    qualification.State('qualification')
], LeakageCauseEquipmentQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseEquipmentQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    layout.Getter('isDesktop')
], LeakageCauseEquipmentQuestion.prototype, "isDesktop", void 0);
LeakageCauseEquipmentQuestion = __decorate([
    Component({
        components: {
            EquipmentWashBasin,
            EquipmentSink,
            EquipmentShower,
            EquipmentBathtube,
            EquipmentWC,
            EquipmentDishwasher,
            EquipmentRefrigerator,
            EquipmentWashingMachine,
            EquipmentRadiator,
            EquipmentBoiler,
            EquipmentHotWaterTank,
            EquipmentAquarium,
            RoomKitchen,
            RoomWC,
            RoomBedroomOffice,
            RoomCorridorEntrance,
            RoomBathroom,
            RoomLounge,
            RoomGarage,
            RoomBasement,
            RoomLaundry,
            RoomOther,
            RadioPrimaryButton,
            QuestionPanel,
        },
        props: QuestionProps,
    })
], LeakageCauseEquipmentQuestion);
export default LeakageCauseEquipmentQuestion;
