var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { ExternalDamageComponent } from '../ExternalDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import NumberInput from '@declaration/components/NumberInput.vue';
import { CHIMNEY_CAPS_LABEL, CHIMNEY_CAPS_TITLE, CHIMNEY_STACK_LABEL, CHIMNEY_STACK_TITLE, } from '@declaration/constants/externalDamages/ChimneyConstants';
let Chimney = class Chimney extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.chimneyCaps = require('@declaration/assets/images/externalDamage/roof/cheminee_mitron.svg');
        this.chimneyStack = require('@declaration/assets/images/externalDamage/roof/cheminee_souche.svg');
    }
    get capsTitle() {
        return this.translateExternalDamageKey(CHIMNEY_CAPS_TITLE);
    }
    get capsLabel() {
        return this.translateExternalDamageKey(CHIMNEY_CAPS_LABEL);
    }
    get stackTitle() {
        return this.translateExternalDamageKey(CHIMNEY_STACK_TITLE);
    }
    get stackLabel() {
        return this.translateExternalDamageKey(CHIMNEY_STACK_LABEL);
    }
    get damagedCapsCount() {
        var _a;
        return (_a = this.externalDamage.damagedCapsCount) !== null && _a !== void 0 ? _a : 0;
    }
    set damagedCapsCount(damagedCapsCount) {
        this.updateExternalDamage({ damagedCapsCount });
    }
    get isStackDamaged() {
        var _a;
        return (_a = this.externalDamage.isStackDamaged) !== null && _a !== void 0 ? _a : null;
    }
    set isStackDamaged(isStackDamaged) {
        this.updateExternalDamage({ isStackDamaged });
    }
};
Chimney = __decorate([
    Component({
        components: { RadioPrimaryButton, NumberInput },
    })
], Chimney);
export default Chimney;
