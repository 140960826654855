var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { GLASS_BREAKAGE_ASSISTANCE_NEEDED_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
const claim = namespace('claim');
const qualification = namespace('qualification');
let GlassBreakageSecurityAssistanceNeededQuestion = class GlassBreakageSecurityAssistanceNeededQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get questionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-assistance-needed-question']);
    }
    get assistanceNeededChoices() {
        return YES_OR_NO_CHOICES;
    }
    get selectedAssistanceNeededValue() {
        return this.qualification.assistanceNeeded;
    }
    get answer() {
        return this.$tc(GLASS_BREAKAGE_ASSISTANCE_NEEDED_ANSWERS[this.selectedAssistanceNeededValue ? 'yes' : 'no']);
    }
    onAssistanceNeededUpdate(assistanceNeeded) {
        this.updateAndSaveGlassBreakageDamageQualification({
            step: this.step,
            qualificationPayload: { assistanceNeeded },
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakageSecurityAssistanceNeededQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakageSecurityAssistanceNeededQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakageSecurityAssistanceNeededQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakageSecurityAssistanceNeededQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
GlassBreakageSecurityAssistanceNeededQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], GlassBreakageSecurityAssistanceNeededQuestion);
export default GlassBreakageSecurityAssistanceNeededQuestion;
