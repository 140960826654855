var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DateWithValidation from '@declaration/components/qualification/DateWithValidation.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { formatDate, formatDateTime } from '@shared/utils/date';
const claim = namespace('claim');
const qualification = namespace('qualification');
let ClaimDateQuestion = class ClaimDateQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.maxDate = new Date();
    }
    get claimDate() {
        // We store the date as a string because date is not a primitive javascript format and therefore vuex cast it into a string
        return this.claim.damageDate ? new Date(this.claim.damageDate) : null;
    }
    get answer() {
        var _a;
        const date = (_a = this.claim) === null || _a === void 0 ? void 0 : _a.damageDate;
        if (!date) {
            return null;
        }
        if (this.hasHoursAndMinutes) {
            return this.$tc('claim.date.sumup', 0, { date: formatDateTime(date) });
        }
        return this.$tc('claim.date.sumup', 0, { date: formatDate(date) });
    }
    get hasHoursAndMinutes() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.ClaimDateHoursAndMinutes);
    }
    updateClaim(damageDate) {
        this.updateAndSaveClaim({
            claimPayload: { damageDate: damageDate.toISOString() },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('claim')
], ClaimDateQuestion.prototype, "claim", void 0);
__decorate([
    qualification.State('typeTrigram')
], ClaimDateQuestion.prototype, "typeTrigram", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], ClaimDateQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], ClaimDateQuestion.prototype, "updateAndSaveClaim", void 0);
ClaimDateQuestion = __decorate([
    Component({
        components: { QuestionPanel, DateWithValidation },
        props: QuestionProps,
    })
], ClaimDateQuestion);
export default ClaimDateQuestion;
