var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { translateChoices } from '@shared/choice';
import { PERSONAL_PROPERTY_RECEIPT_TYPES } from '@shared/constants/personalPropertyConstants';
import { PERSONAL_PROPERTY_RECEIPT_TYPES_ADDITION_TRAD_KEY } from '@declaration/constants/personalPropertyConstants';
import { PersonalPropertyReceiptType, } from '@shared/types/api/personalProperty';
import { emptyUploadedFileCollection, fileCollectionFilesCount, removeFilesFromFileCollection, } from '@shared/files/uploadedFiles';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { ClaimFileType } from '@shared/types/file';
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let PersonalPropertyInvoice = class PersonalPropertyInvoice extends Vue {
    get title() {
        return this.isAmountOnly
            ? this.$tc('claim.damage.personal_property.form.receipt.cash.title')
            : this.$tc('claim.damage.personal_property.form.receipt.title');
    }
    get message() {
        return this.isAmountOnly
            ? this.$tc('claim.damage.personal_property.form.receipt.cash.text')
            : this.$tc('claim.damage.personal_property.form.receipt.text');
    }
    get receiptTypeChoices() {
        return translateChoices(PERSONAL_PROPERTY_RECEIPT_TYPES.filter(({ value }) => value !== PersonalPropertyReceiptType.CashWithdrawal), this.$tc.bind(this));
    }
    get receiptFileCollectionLabel() {
        var _a;
        if (this.isAmountOnly) {
            return this.$tc(`claim.damage.personal_property.form.receipt.cash.upload.add`);
        }
        if (!this.isReceiptTypeModifiable) {
            return this.$tc('claim.damage.personal_property.form.receipt.upload.add.invoice');
        }
        if (!((_a = this.receipt) === null || _a === void 0 ? void 0 : _a.type)) {
            return '+';
        }
        return this.$tc(PERSONAL_PROPERTY_RECEIPT_TYPES_ADDITION_TRAD_KEY[this.receipt.type]);
    }
    get receiptFileType() {
        return ClaimFileType.PersonalPropertyReceipt;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get receipt() {
        var _a;
        return (_a = this.personalProperty.receipt) !== null && _a !== void 0 ? _a : null;
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get isReceiptTypeModifiable() {
        if (this.isAmountOnly)
            return false;
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyReceiptTypes);
    }
    get isReceiptFileCollectionModifiable() {
        var _a;
        if (this.isAmountOnly)
            return true;
        if (!this.isReceiptTypeModifiable)
            return true;
        if ((_a = this.receipt) === null || _a === void 0 ? void 0 : _a.type) {
            return ![PersonalPropertyReceiptType.None, PersonalPropertyReceiptType.Pictures].includes(this.receipt.type);
        }
        return true;
    }
    get receiptType() {
        var _a, _b;
        return (_b = (_a = this.receipt) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : null;
    }
    set receiptType(type) {
        var _a, _b;
        const receipt = this.receipt;
        const fileCollection = (type &&
            [PersonalPropertyReceiptType.None, PersonalPropertyReceiptType.Pictures].includes(type)
            ? removeFilesFromFileCollection(receipt === null || receipt === void 0 ? void 0 : receipt.fileCollection)
            : (_a = receipt === null || receipt === void 0 ? void 0 : receipt.fileCollection) !== null && _a !== void 0 ? _a : emptyUploadedFileCollection());
        this.update({
            receipt: {
                '@type': ApiEntityType.PersonalPropertyReceipt,
                id: (_b = receipt === null || receipt === void 0 ? void 0 : receipt.id) !== null && _b !== void 0 ? _b : null,
                fileCollection,
                type,
            },
        });
    }
    get receiptFileCollection() {
        var _a, _b;
        return (_b = (_a = this.receipt) === null || _a === void 0 ? void 0 : _a.fileCollection) !== null && _b !== void 0 ? _b : null;
    }
    set receiptFileCollection(fileCollection) {
        var _a, _b, _c, _d;
        let type = (_b = (_a = this.receipt) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : null;
        if (this.isAmountOnly) {
            type = fileCollectionFilesCount(fileCollection)
                ? PersonalPropertyReceiptType.CashWithdrawal
                : PersonalPropertyReceiptType.None;
        }
        else if (!this.isReceiptTypeModifiable) {
            type = fileCollectionFilesCount(fileCollection)
                ? PersonalPropertyReceiptType.TypeInvoice
                : PersonalPropertyReceiptType.None;
        }
        this.update({
            receipt: {
                '@type': ApiEntityType.PersonalPropertyReceipt,
                id: (_d = (_c = this.receipt) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null,
                type,
                fileCollection,
            },
        });
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyInvoice.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyInvoice.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyInvoice.prototype, "updatePersonalProperty", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyInvoice.prototype, "typeTrigram", void 0);
PersonalPropertyInvoice = __decorate([
    Component({
        components: { FileCollectionUpload },
        props: { changeKey: { type: [String, Number], default: null } },
    })
], PersonalPropertyInvoice);
export default PersonalPropertyInvoice;
