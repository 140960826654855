var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import ExpertAppointmentConfirmation from '@declaration/components/appointment/ExpertAppointmentConfirmation.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
let ExpertAppointmentOnSiteConfirmation = class ExpertAppointmentOnSiteConfirmation extends Vue {
    constructor() {
        super(...arguments);
        this.step = Step.ExpertAppointmentOnSiteConfirmation;
    }
};
ExpertAppointmentOnSiteConfirmation = __decorate([
    Component({
        components: {
            ExpertAppointmentConfirmation,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], ExpertAppointmentOnSiteConfirmation);
export default ExpertAppointmentOnSiteConfirmation;
