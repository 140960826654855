var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
let BeCalledQualificationQuestion = class BeCalledQualificationQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
    }
    updateClaim(isAdvisorCallWanted) {
        this.updateAndSaveClaim({
            claimPayload: { isAdvisorCallWanted },
            step: this.step,
        });
    }
    get answer() {
        if (this.claim.isAdvisorCallWanted === null) {
            return null;
        }
        return this.claim.isAdvisorCallWanted
            ? this.$tc('claim.qualification.advisor_call.sumup.yes')
            : this.$tc('claim.qualification.advisor_call.sumup.no');
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], BeCalledQualificationQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.State('claim')
], BeCalledQualificationQuestion.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], BeCalledQualificationQuestion.prototype, "updateAndSaveClaim", void 0);
BeCalledQualificationQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], BeCalledQualificationQuestion);
export default BeCalledQualificationQuestion;
