var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import NumberInput from '@declaration/components/NumberInput.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { numbersToChoices } from '@shared/choice';
import { DOOR_DAMAGE_TYPE_CHOICES, DOOR_GLASS_TYPE_CHOICES, DOOR_GLAZING_TYPE_CHOICES, DOOR_LOCK_TYPE_CHOICES, DOOR_MATERIAL_CHOICES, DOOR_TYPE_CHOICES, } from '@shared/roomDamage/goodDamage';
import { DoorDamageType, DoorMaterial, DoorType, } from '@shared/types/api/roomDamage';
let DoorDescription = class DoorDescription extends GoodDamageComponent {
    get doorTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.door_type');
    }
    get leafCountLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.leaf_count');
    }
    get materialLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.material');
    }
    get damageTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.damage_type');
    }
    get hasGlazingLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.has_glazing');
    }
    get isGlazingBrokenLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.is_glazing_broken');
    }
    get tileCountLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.tile_count');
    }
    get biggestTileSizeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_size');
    }
    get biggestTileWidthLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_width');
    }
    get biggestTileHeightLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.biggest_tile_height');
    }
    get glazingTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glazing_type');
    }
    get glassTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.glass_type');
    }
    get lockTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.door.lock_type');
    }
    get doorTypeChoices() {
        return DOOR_TYPE_CHOICES;
    }
    get leafCountChoices() {
        return numbersToChoices(1, 2);
    }
    get materialChoices() {
        return DOOR_MATERIAL_CHOICES;
    }
    get damageTypeChoices() {
        return DOOR_DAMAGE_TYPE_CHOICES;
    }
    get hasGlazingChoices() {
        return YES_OR_NO_CHOICES;
    }
    get isGlazingBrokenChoices() {
        return YES_OR_NO_CHOICES;
    }
    get glazingTypeChoices() {
        return DOOR_GLAZING_TYPE_CHOICES;
    }
    get glassTypeChoices() {
        return DOOR_GLASS_TYPE_CHOICES;
    }
    get lockTypeChoices() {
        return DOOR_LOCK_TYPE_CHOICES;
    }
    get doorType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.doorType) !== null && _b !== void 0 ? _b : null;
    }
    set doorType(doorType) {
        this.updateGoodDamage(doorType === DoorType.INDOOR
            ? {
                doorType,
                leafCount: null,
                material: null,
                damageType: null,
                hasGlazing: null,
                isGlazingBroken: null,
                tileCount: null,
                biggestTileWidth: null,
                biggestTileHeight: null,
                glazingType: null,
                glassType: null,
            }
            : { doorType });
    }
    get leafCount() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.leafCount) !== null && _b !== void 0 ? _b : null;
    }
    set leafCount(leafCount) {
        this.updateGoodDamage({ leafCount });
    }
    get material() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : null;
    }
    set material(material) {
        this.updateGoodDamage({
            material,
            damageType: material === DoorMaterial.WOOD ? this.damageType : null,
            isGlazingBroken: material === DoorMaterial.WOOD ? this.isGlazingBroken : null,
            tileCount: material === DoorMaterial.WOOD ? this.tileCount : null,
            biggestTileWidth: material === DoorMaterial.WOOD ? this.biggestTileWidth : null,
            biggestTileHeight: material === DoorMaterial.WOOD ? this.biggestTileHeight : null,
            lockType: material === DoorMaterial.PVC ||
                material === DoorMaterial.METAL ||
                this.damageType === DoorDamageType.DAMAGED
                ? this.lockType
                : null,
        });
    }
    get damageType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.damageType) !== null && _b !== void 0 ? _b : null;
    }
    set damageType(damageType) {
        const aesthetic = damageType === DoorDamageType.AESTHETIC;
        this.updateGoodDamage({
            damageType,
            isGlazingBroken: aesthetic ? this.isGlazingBroken : null,
            tileCount: aesthetic ? this.tileCount : null,
            biggestTileWidth: aesthetic ? this.biggestTileWidth : null,
            biggestTileHeight: aesthetic ? this.biggestTileHeight : null,
            lockType: aesthetic ? null : this.lockType,
        });
    }
    get hasGlazing() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.hasGlazing) !== null && _b !== void 0 ? _b : null;
    }
    set hasGlazing(hasGlazing) {
        this.updateGoodDamage({
            hasGlazing,
            isGlazingBroken: hasGlazing ? this.isGlazingBroken : null,
            tileCount: hasGlazing ? this.tileCount : null,
            biggestTileWidth: hasGlazing ? this.biggestTileWidth : null,
            biggestTileHeight: hasGlazing ? this.biggestTileHeight : null,
            glazingType: hasGlazing ? this.glazingType : null,
            glassType: hasGlazing ? this.glassType : null,
        });
    }
    get isGlazingBroken() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.isGlazingBroken) !== null && _b !== void 0 ? _b : null;
    }
    set isGlazingBroken(isGlazingBroken) {
        this.updateGoodDamage({
            isGlazingBroken,
            tileCount: isGlazingBroken ? this.tileCount : null,
            biggestTileWidth: isGlazingBroken ? this.biggestTileWidth : null,
            biggestTileHeight: isGlazingBroken ? this.biggestTileHeight : null,
            glazingType: isGlazingBroken ? this.glazingType : null,
            glassType: isGlazingBroken ? this.glassType : null,
        });
    }
    get tileCount() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.tileCount) !== null && _b !== void 0 ? _b : 0;
    }
    set tileCount(tileCount) {
        this.updateGoodDamage({ tileCount });
    }
    get biggestTileWidth() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.biggestTileWidth) !== null && _b !== void 0 ? _b : 0;
    }
    set biggestTileWidth(biggestTileWidth) {
        this.updateGoodDamage({ biggestTileWidth });
    }
    get biggestTileHeight() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.biggestTileHeight) !== null && _b !== void 0 ? _b : 0;
    }
    set biggestTileHeight(biggestTileHeight) {
        this.updateGoodDamage({ biggestTileHeight });
    }
    get glazingType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.glazingType) !== null && _b !== void 0 ? _b : null;
    }
    set glazingType(glazingType) {
        this.updateGoodDamage({ glazingType });
    }
    get glassType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.glassType) !== null && _b !== void 0 ? _b : null;
    }
    set glassType(glassType) {
        this.updateGoodDamage({ glassType });
    }
    get lockType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.lockType) !== null && _b !== void 0 ? _b : null;
    }
    set lockType(lockType) {
        this.updateGoodDamage({ lockType });
    }
    get shouldDescribeDoor() {
        return this.doorType === DoorType.MAIN_ENTRANCE || this.doorType === DoorType.SERVICE;
    }
    get shouldDescribeDamageType() {
        return this.material === DoorMaterial.WOOD;
    }
    get shouldDescribeIsGlazingBroken() {
        return this.damageType === DoorDamageType.AESTHETIC && this.hasGlazing === true;
    }
    get shouldDescribeGlassDetails() {
        return (this.isGlazingBroken === true ||
            (this.hasGlazing === true &&
                (this.damageType === DoorDamageType.DAMAGED ||
                    this.material === DoorMaterial.PVC ||
                    this.material === DoorMaterial.METAL)));
    }
    get shouldDescribeLockType() {
        return (this.damageType === DoorDamageType.DAMAGED ||
            this.material === DoorMaterial.PVC ||
            this.material === DoorMaterial.METAL);
    }
};
DoorDescription = __decorate([
    Component({ components: { RadioPrimaryButton, NumberInput, LengthInput } })
], DoorDescription);
export default DoorDescription;
