var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ColorChart from '@declaration/components/styleGuide/ColorChart.vue';
import TypoList from '@declaration/components/styleGuide/TypoList.vue';
import ClaimType from '@declaration/components/declaration/pages/type-du-sinistre.vue';
import ClaimDateQuestion from '@declaration/components/qualification/ClaimDateQuestion.vue';
import ElectricalCauseOriginQuestion from '@declaration/components/qualification/electricalDamage/ElectricalCauseEquipmentQuestion.vue';
import ElectricalDamageTypeQuestion from '@declaration/components/qualification/electricalDamage/ElectricalDamageType.vue';
import GlassDamagesQuestion from '@declaration/components/glassDamage/GlassDamagesQuestion.vue';
import PersonalPropertyQuestion from '@declaration/components/personalProperty/PersonalPropertyQuestion.vue';
import DamagesDeclarationInfos from '@declaration/components/declaration/pages/infos-etat-de-perte.vue';
import CompensationProposition from '@declaration/components/declaration/pages/indemnisation/proposition.vue';
const claim = namespace('claim');
let StyleGuide = class StyleGuide extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.COLORTHEME = [
            'color-primary',
            'color-secondary',
            'color-danger',
            'link',
            'link-invert',
            'link-focus-border',
        ];
        this.COLORSTATUT = [
            'color-status-ok',
            'color-status-warning',
            'color-status-ko',
            'color-status-ko-dark',
            'color-status-highlighted',
        ];
        this.PARAGRAPHSCONTENT = [
            { class: 'font-p1-bold', tag: 'p' },
            { class: 'font-p1-regular', tag: 'p' },
            { class: 'font-p1-link', tag: 'p' },
            { class: 'font-p2-bold', tag: 'p' },
            { class: 'font-p2-regular', tag: 'p' },
            { class: 'font-p2-link', tag: 'p' },
        ];
        this.TITLESCONTENT = [
            { class: 'font-h1-bold', tag: 'h1' },
            { class: 'font-h1-regular', tag: 'h1' },
            { class: 'font-h2-bold', tag: 'h2' },
            { class: 'font-h2-regular', tag: 'h2' },
            { class: 'font-h3-bold', tag: 'h3' },
            { class: 'font-h3-regular', tag: 'h3' },
        ];
    }
    mounted() {
        this.isLoading = false;
    }
    get isFetched() {
        var _a;
        return !!((_a = this.claim) === null || _a === void 0 ? void 0 : _a.id);
    }
    get isInfoActive() {
        return !this.isFetched;
    }
};
__decorate([
    claim.State('claim')
], StyleGuide.prototype, "claim", void 0);
StyleGuide = __decorate([
    Component({
        components: {
            ColorChart,
            TypoList,
            ClaimType,
            ClaimDateQuestion,
            ElectricalCauseOriginQuestion,
            DamagesDeclarationInfos,
            ElectricalDamageTypeQuestion,
            PersonalPropertyQuestion,
            CompensationProposition,
            GlassDamagesQuestion,
        },
        middleware: [
            'contextEntitiesFetched',
            async (ctx) => {
                await ctx.store.dispatch('claim/fetchClaim', { id: ctx.route.query.claimId });
            },
            'layout',
        ],
    })
], StyleGuide);
export default StyleGuide;
