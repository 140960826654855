var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { LIGHT_SOURCE_TYPE_CHOICES } from '@shared/roomDamage/goodDamage';
let LightSourceDescription = class LightSourceDescription extends GoodDamageComponent {
    get lightTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.electricity.light_source.light_type');
    }
    get lightTypeChoices() {
        return LIGHT_SOURCE_TYPE_CHOICES;
    }
    get lightType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.lightType) !== null && _b !== void 0 ? _b : null;
    }
    set lightType(lightType) {
        this.updateGoodDamage({ lightType });
    }
};
LightSourceDescription = __decorate([
    Component({ components: { RadioPrimaryButton } })
], LightSourceDescription);
export default LightSourceDescription;
