var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { FIRE_BEGIN_QUESTION_OPTIONS, FIRE_BEGIN_LOCATION_BY_HOUSING_TYPE, FIRE_BEGIN_LOCATIONS, } from '@declaration/constants/fireDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
let FireBeginQuestion = class FireBeginQuestion extends QuestionComponent {
    updateClaim(fireBegin) {
        this.updateAndSaveFireDamageQualification({
            qualificationPayload: {
                fireBegin,
            },
            step: this.step,
        });
    }
    get answer() {
        if (!this.fireDamageQualification.fireBegin) {
            return null;
        }
        return FIRE_BEGIN_QUESTION_OPTIONS[this.fireDamageQualification.fireBegin]
            ? this.$tc(FIRE_BEGIN_QUESTION_OPTIONS[this.fireDamageQualification.fireBegin].answerTradKey)
            : null;
    }
    get FireBeginQuestionOptions() {
        if (this.selectedContract === null) {
            return FIRE_BEGIN_LOCATIONS.map((locationOption) => FIRE_BEGIN_QUESTION_OPTIONS[locationOption]);
        }
        if (!FIRE_BEGIN_LOCATION_BY_HOUSING_TYPE[this.selectedContract.housingType.slug]) {
            return FIRE_BEGIN_LOCATIONS.map((locationOption) => FIRE_BEGIN_QUESTION_OPTIONS[locationOption]);
        }
        return FIRE_BEGIN_LOCATION_BY_HOUSING_TYPE[this.selectedContract.housingType.slug].map((location) => FIRE_BEGIN_QUESTION_OPTIONS[location]);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireBeginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireBeginQuestion.prototype, "fireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireBeginQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
__decorate([
    contract.State('selectedContract')
], FireBeginQuestion.prototype, "selectedContract", void 0);
FireBeginQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], FireBeginQuestion);
export default FireBeginQuestion;
