var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import BreakInDamagePictures from '@declaration/components/breakInDamage/BreakInDamagePictures.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { ROBBERY_BREAKABLE_ELEMENT_SVGS } from '@declaration/constants/robberySvgs';
import { Step } from '@shared/claim/steps';
import { ROBBERY_BREAKABLE_ELEMENT_SLUG_AND_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { BREAK_IN_DAMAGE_FIELDS_FOR_TYPE, BreakInDamageField, } from '@shared/types/api/breakInDamage';
import { RobberyEventType, } from '@shared/types/api/claim';
import DoorDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/Door/DoorDamageSeverity.vue';
import Repairs from '@declaration/components/breakInDamage/DamageDescription/Common/Repairs.vue';
import DefaultDescription from '@declaration/components/breakInDamage/DamageDescription/DefaultDescription.vue';
import { ROBBERY_BREAKABLE_ELEMENTS_FIRST_DESCRIPTION_COMPONENT_STEP, BreakInDamageDescriptionStep, } from '@declaration/constants/breakInDamageConstants';
import { breakInDamageHasNoDamageDeclared, getBreakInDamageTitle, initializeBreakInDamage, } from '@shared/breakInDamage/breakInDamageHelpers';
import { breakInDamageGetNextStep } from '@shared/breakInDamage/breakInDamageBRMS';
import WindowDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/Window/WindowDamageSeverity.vue';
import WindowImpacts from '@declaration/components/breakInDamage/DamageDescription/Window/WindowImpacts.vue';
import WindowOtherDamages from '@declaration/components/breakInDamage/DamageDescription/Window/WindowOtherDamages.vue';
import WindowSizeAndGlazing from '@declaration/components/breakInDamage/DamageDescription/Window/WindowSizeAndGlazing.vue';
import WindowBrokenWindow from '@declaration/components/breakInDamage/DamageDescription/Window/WindowBrokenWindow.vue';
import FenceDescription from '@declaration/components/breakInDamage/DamageDescription/Fence/FenceDescription.vue';
import FrenchWindowDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowDamageSeverity.vue';
import SlidingBayDescription from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayDescription.vue';
import SlidingBayLockDamage from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayLockDamage.vue';
import SlidingBayOtherDamages from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayOtherDamages.vue';
import SlidingBayDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayDamageSeverity.vue';
import SlidingBayBrokenTiles from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayBrokenTiles.vue';
import SlidingBaySizeAndGlazing from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBaySizeAndGlazing.vue';
import SlidingBayImpacts from '@declaration/components/breakInDamage/DamageDescription/SlidingBay/SlidingBayImpacts.vue';
import DoorDescription from '@declaration/components/breakInDamage/DamageDescription/Door/DoorDescription.vue';
import Photos from '@declaration/components/breakInDamage/DamageDescription/Common/Photos.vue';
import DoorOtherDamages from '@declaration/components/breakInDamage/DamageDescription/Door/DoorOtherDamages.vue';
import DoorLockDamage from '@declaration/components/breakInDamage/DamageDescription/Door/DoorLockDamage.vue';
import DoorBrokenWindowTiles from '@declaration/components/breakInDamage/DamageDescription/Door/DoorBrokenWindowTiles.vue';
import DoorHasWindow from '@declaration/components/breakInDamage/DamageDescription/Door/DoorHasWindow.vue';
import DoorLockType from '@declaration/components/breakInDamage/DamageDescription/Door/DoorLockType.vue';
import DoorImpacts from '@declaration/components/breakInDamage/DamageDescription/Door/DoorImpacts.vue';
import FrenchWindowImpacts from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowImpacts.vue';
import FrenchWindowSizeAndGlazing from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowSizeAndGlazing.vue';
import FrenchWindowBrokenWindow from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowBrokenWindow.vue';
import FrenchWindowLockDamage from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowLockDamage.vue';
import FrenchWindowOtherDamages from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowOtherDamages.vue';
import FrenchWindowDescription from '@declaration/components/breakInDamage/DamageDescription/FrenchWindow/FrenchWindowDescription.vue';
import WindowDescription from '@declaration/components/breakInDamage/DamageDescription/Window/WindowDescription.vue';
import ShutterType from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterType.vue';
import FlappyShutterDescription from '@declaration/components/breakInDamage/DamageDescription/Shutter/FlappyShutterDescription.vue';
import RollingShutterDescription from '@declaration/components/breakInDamage/DamageDescription/Shutter/RollingShutterDescription.vue';
import FoldingShutterDescription from '@declaration/components/breakInDamage/DamageDescription/Shutter/FoldingShutterDescription.vue';
import BarShutterDescription from '@declaration/components/breakInDamage/DamageDescription/Shutter/BarShutterDescription.vue';
import ShutterImpacts from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterImpacts.vue';
import ShutterDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterDamageSeverity.vue';
import ShutterOpening from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterOpening.vue';
import ShutterOtherDamages from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterOtherDamages.vue';
import ShutterWindowWidth from '@declaration/components/breakInDamage/DamageDescription/Shutter/ShutterWindowWidth.vue';
import GarageDoorDescription from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorDescription.vue';
import GarageDoorIsFunctional from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorIsFunctional.vue';
import GarageDoorSizeAndMotorization from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorSizeAndMotorization.vue';
import GarageDoorIsBent from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorIsBent.vue';
import GarageDoorDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorDamageSeverity.vue';
import GarageDoorOtherDamages from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorOtherDamages.vue';
import GarageDoorImpacts from '@declaration/components/breakInDamage/DamageDescription/GarageDoor/GarageDoorImpacts.vue';
import GateIsFunctional from '@declaration/components/breakInDamage/DamageDescription/Gate/GateIsFunctional.vue';
import GateIsBent from '@declaration/components/breakInDamage/DamageDescription/Gate/GateIsBent.vue';
import GateDescription from '@declaration/components/breakInDamage/DamageDescription/Gate/GateDescription.vue';
import GateImpacts from '@declaration/components/breakInDamage/DamageDescription/Gate/GateImpacts.vue';
import GateLockDamage from '@declaration/components/breakInDamage/DamageDescription/Gate/GateLockDamage.vue';
import GateDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/Gate/GateDamageSeverity.vue';
import GateOtherDamages from '@declaration/components/breakInDamage/DamageDescription/Gate/GateOtherDamages.vue';
import GateSizeAndMotorization from '@declaration/components/breakInDamage/DamageDescription/Gate/GateSizeAndMotorization.vue';
import OtherDamageDescription from '@declaration/components/breakInDamage/DamageDescription/Other/OtherDamageDescription.vue';
import OtherDamageType from '@declaration/components/breakInDamage/DamageDescription/Other/OtherDamageType.vue';
import LittleGateDamageSeverity from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateDamageSeverity.vue';
import LittleGateLockDamage from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateLockDamage.vue';
import LittleGateHeight from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateHeight.vue';
import LittleGateOtherDamages from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateOtherDamages.vue';
import LittleGateDescription from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateDescription.vue';
import LittleGateImpacts from '@declaration/components/breakInDamage/DamageDescription/LittleGate/LittleGateImpacts.vue';
import BreakInDamageNoDamageWarningModal from '@declaration/components/breakInDamage/BreakInDamageNoDamageWarningModal.vue';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
const qualification = namespace('qualification');
let BreakInDamageModal = class BreakInDamageModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.BreakInDamageField = BreakInDamageField;
        this.descriptionStepsStack = [];
        this.noDamageWarningModalIsOpen = false;
    }
    created() {
        this.$watch('changeKey', () => {
            if (this.breakInDamage.type && this.descriptionStepsStack.length === 0) {
                this.descriptionStepsStack.push(ROBBERY_BREAKABLE_ELEMENTS_FIRST_DESCRIPTION_COMPONENT_STEP[this.breakInDamage.type]);
            }
        });
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get title() {
        const defaultTitle = this.isVandalismClaim
            ? this.$tc('claim.declaration.break_in_damages.vandalism.form.title')
            : this.$tc('claim.declaration.break_in_damages.form.title');
        return getBreakInDamageTitle(this.breakInDamage, this.$tc.bind(this), defaultTitle);
    }
    get isVandalismClaim() {
        return this.qualification ? this.qualification.eventType === RobberyEventType.Vandalism : false;
    }
    get shouldDisplayCategoryChoices() {
        return !this.breakInDamage.type;
    }
    get breakables() {
        return ROBBERY_BREAKABLE_ELEMENT_SLUG_AND_TRAD_KEYS.map((element) => ({
            ...element,
            svg: ROBBERY_BREAKABLE_ELEMENT_SVGS[element.slug],
        }));
    }
    async categoryChoosed(slug) {
        await this.update(initializeBreakInDamage(slug));
        if (this.breakInDamage.type) {
            this.descriptionStepsStack.push(ROBBERY_BREAKABLE_ELEMENTS_FIRST_DESCRIPTION_COMPONENT_STEP[this.breakInDamage.type]);
        }
    }
    hasField(field) {
        return (!!this.breakInDamage.type &&
            BREAK_IN_DAMAGE_FIELDS_FOR_TYPE[this.breakInDamage.type].includes(field));
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
    }
    async save() {
        this.isSaving = true;
        try {
            await this.saveChanges({ step: Step.BreakInDamagesDetails });
            this.onClose();
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isSaving = false;
    }
    closeModal() {
        this.$emit('close');
    }
    onClose() {
        this.resetChanges();
        this.descriptionStepsStack = [];
        this.closeModal();
    }
    onGoBack() {
        if (this.descriptionStepsStack.length > 1) {
            this.descriptionStepsStack.pop();
        }
    }
    onGoToNextStep() {
        const nextStep = this.nextStep;
        if (nextStep == null) {
            this.onClose();
        }
        else if (nextStep === BreakInDamageDescriptionStep.NoNextStep) {
            if (breakInDamageHasNoDamageDeclared(this.breakInDamage) && this.breakInDamage.type) {
                this.noDamageWarningModalIsOpen = true;
            }
            else {
                this.save();
            }
        }
        else {
            this.descriptionStepsStack.push(nextStep);
        }
    }
    get isFinalStep() {
        return this.nextStep === BreakInDamageDescriptionStep.NoNextStep;
    }
    get nextStep() {
        if (this.descriptionStepsStack.length > 0) {
            return breakInDamageGetNextStep(this.breakInDamage, this.descriptionStepsStack[this.descriptionStepsStack.length - 1]);
        }
        return null;
    }
    update(data) {
        this.updateBreakInDamage({
            changeKey: this.changeKey,
            data: { ...data },
        });
    }
    async deleteBreakInDamage() {
        this.isSaving = true;
        await this.deleteDamage(this.changeKey);
        await this.saveChanges({ step: Step.BreakInDamagesDetails });
        this.isSaving = false;
    }
    get displayGoBackButton() {
        return this.descriptionStepsStack.length > 1;
    }
    get currentDescriptionComponent() {
        if (this.descriptionStepsStack.length > 0) {
            return this.descriptionStepsStack[this.descriptionStepsStack.length - 1];
        }
        return null;
    }
    openWarningModal() {
        this.noDamageWarningModalIsOpen = true;
    }
    closeWarningModal() {
        this.noDamageWarningModalIsOpen = false;
    }
    resetSteps() {
        // this.closeWarningModal()
        // while (this.descriptionStepsStack.length > 0) {
        //   this.descriptionStepsStack.pop()
        // }
        this.resetChanges();
        this.descriptionStepsStack = [];
    }
    deleteDamageAndClose() {
        this.closeWarningModal();
        this.onClose();
    }
};
__decorate([
    layout.State('color')
], BreakInDamageModal.prototype, "color", void 0);
__decorate([
    breakInDamage.State('damages')
], BreakInDamageModal.prototype, "breakInDamages", void 0);
__decorate([
    breakInDamage.Getter('change')
], BreakInDamageModal.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], BreakInDamageModal.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], BreakInDamageModal.prototype, "saveChanges", void 0);
__decorate([
    breakInDamage.Action('deleteDamage')
], BreakInDamageModal.prototype, "deleteDamage", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], BreakInDamageModal.prototype, "validateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], BreakInDamageModal.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('resetChanges')
], BreakInDamageModal.prototype, "resetChanges", void 0);
__decorate([
    qualification.State('qualification')
], BreakInDamageModal.prototype, "qualification", void 0);
BreakInDamageModal = __decorate([
    Component({
        components: {
            BreakInDamageNoDamageWarningModal,
            Modal,
            BreakInDamagePictures,
            ClaimsButton,
            DoorDescription,
            DoorDamageSeverity,
            DoorImpacts,
            DoorHasWindow,
            DoorBrokenWindowTiles,
            DoorLockDamage,
            DoorOtherDamages,
            DoorLockType,
            FrenchWindowDescription,
            FrenchWindowDamageSeverity,
            FrenchWindowImpacts,
            FrenchWindowSizeAndGlazing,
            FrenchWindowBrokenWindow,
            FrenchWindowLockDamage,
            FrenchWindowOtherDamages,
            Repairs,
            Photos,
            WindowDescription,
            WindowDamageSeverity,
            WindowImpacts,
            WindowOtherDamages,
            WindowSizeAndGlazing,
            WindowBrokenWindow,
            FenceDescription,
            SlidingBayDescription,
            SlidingBayDamageSeverity,
            SlidingBayImpacts,
            SlidingBayBrokenTiles,
            SlidingBayLockDamage,
            SlidingBayOtherDamages,
            SlidingBaySizeAndGlazing,
            ShutterType,
            FlappyShutterDescription,
            RollingShutterDescription,
            FoldingShutterDescription,
            BarShutterDescription,
            ShutterDamageSeverity,
            ShutterImpacts,
            ShutterOpening,
            ShutterOtherDamages,
            ShutterWindowWidth,
            GarageDoorDescription,
            GarageDoorDamageSeverity,
            GarageDoorImpacts,
            GarageDoorIsFunctional,
            GarageDoorIsBent,
            GarageDoorSizeAndMotorization,
            GarageDoorOtherDamages,
            GateDescription,
            GateDamageSeverity,
            GateImpacts,
            GateIsFunctional,
            GateIsBent,
            GateLockDamage,
            GateOtherDamages,
            GateSizeAndMotorization,
            OtherDamageType,
            OtherDamageDescription,
            LittleGateDescription,
            LittleGateHeight,
            LittleGateDamageSeverity,
            LittleGateImpacts,
            LittleGateLockDamage,
            LittleGateOtherDamages,
            DefaultDescription,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            changeKey: { type: [Number, String], default: null },
        },
    })
], BreakInDamageModal);
export default BreakInDamageModal;
