var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { uuidFromIri } from '@shared/utils/iri';
import { claimDeclarationMiddlewares } from '@declaration/middleware/middlewares';
const claim = namespace('claim');
let RulesMonitoring = class RulesMonitoring extends Vue {
    constructor() {
        super(...arguments);
        this.brmsRulesLogs = [];
        this.parsedBrmsRulesLogs = [];
        this.isLoading = false;
    }
    async created() {
        if (!this.claim.id) {
            return;
        }
        this.isLoading = true;
        const response = await this.$axios.get(`claim_monitoring/${uuidFromIri(this.claim.id)}`);
        this.isLoading = false;
        this.brmsRulesLogs = response.data;
        this.parsedBrmsRulesLogs = this.brmsRulesLogs.map(this.parseRule);
    }
    parseRule(brmsRuleLog, index) {
        const timestampStart = brmsRuleLog.indexOf('[');
        const timestampEnd = brmsRuleLog.indexOf(']');
        const timestamp = brmsRuleLog.substring(timestampStart + 1, timestampEnd).split(' ')[1];
        const rule = brmsRuleLog.split('Invoke Rule: ')[1];
        const ruleDividedByContext = rule.split('CONTEXT');
        const ruleTitle = ruleDividedByContext[0];
        const appliedRule = 'SI' + ruleDividedByContext[1].split('SI')[1];
        const ruleCondition = appliedRule.split('SI')[1].split('ALORS')[0];
        const ruleApplication = appliedRule.split('SI')[1].split('ALORS')[1].split('FIN')[0];
        return {
            index,
            timestamp,
            ruleTitle,
            appliedRule,
            ruleCondition,
            ruleApplication,
        };
    }
};
__decorate([
    claim.State('claim')
], RulesMonitoring.prototype, "claim", void 0);
RulesMonitoring = __decorate([
    Component({
        middleware: claimDeclarationMiddlewares(),
        meta: {},
    })
], RulesMonitoring);
export default RulesMonitoring;
