var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { parseAge } from '@declaration/helpers/typeFormatters';
var Unit;
(function (Unit) {
    Unit["Years"] = "years";
    Unit["Months"] = "months";
})(Unit || (Unit = {}));
let AgeInput = class AgeInput extends Vue {
    constructor() {
        super(...arguments);
        this.unit = Unit.Years;
    }
    get agePlaceholder() {
        if (this.unit === Unit.Years) {
            return this.$tc('claim.damage.personal_property.form.question.age_placeholder.years', this.inputValue === '' || this.inputValue >= 2 ? 2 : 1);
        }
        return this.$tc('claim.damage.personal_property.form.question.age_placeholder.months');
    }
    get yearOption() {
        return this.$tc('claim.damage.personal_property.form.question.age_placeholder.years', this.inputValue === '' || this.inputValue >= 2 ? 2 : 1);
    }
    get monthOption() {
        return this.$tc('claim.damage.personal_property.form.question.age_placeholder.months');
    }
    created() {
        this.$watch('value', (value) => {
            this.setInputValueFromValue(value);
        }, { immediate: true });
    }
    setInputValueFromValue(value) {
        if (value === null) {
            this.inputValue = '';
            return;
        }
        if (this.unit === this.valueUnit) {
            this.inputValue = value;
            return;
        }
        if (this.unit === Unit.Years) {
            this.inputValue = Math.round(value / 12);
            return;
        }
        if (this.unit === Unit.Months) {
            this.inputValue = Math.round(value * 12);
        }
    }
    onInput(age) {
        if (age === '') {
            this.$emit('input', null);
            return;
        }
        const ageValue = parseAge(age);
        this.inputValue = ageValue;
        if (this.unit === this.valueUnit) {
            return this.$emit('input', ageValue);
        }
        if (this.unit === Unit.Years) {
            // Unit is years and consumer in months
            return this.$emit('input', ageValue * 12);
        }
        if (this.unit === Unit.Months) {
            // Unit is months and consumer in years
            return this.$emit('input', ageValue / 12);
        }
    }
    onUnitChange(unit) {
        if (this.unit === unit || this.inputValue === '') {
            return;
        }
        this.unit = unit;
        if (this.unit === this.valueUnit) {
            return this.$emit('input', this.inputValue);
        }
        if (this.valueUnit === Unit.Years) {
            return this.$emit('input', this.inputValue / 12);
        }
        if (this.valueUnit === Unit.Months) {
            return this.$emit('input', this.inputValue * 12);
        }
    }
};
AgeInput = __decorate([
    Component({
        props: {
            canChoseTimeUnit: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Number,
            },
            valueUnit: {
                type: String,
                default: Unit.Months,
            },
        },
    })
], AgeInput);
export default AgeInput;
