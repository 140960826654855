var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamagesComponent } from './GoodDamageComponent';
import GoodDamageDetailsInput from '@declaration/components/roomDamage/goodDamage/GoodDamageDetailsInput.vue';
import { GoodDamageCategory, GoodDamageOther, } from '@shared/types/api/roomDamage';
import { getGoodDamageDetails, getGoodDamageTitle } from '@shared/roomDamage/goodDamageFields';
let GoodDamageDetailsForm = class GoodDamageDetailsForm extends GoodDamagesComponent {
    get sortedGoodDamages() {
        return this.goodDamages
            .slice()
            .map((goodDamage, originalIndex) => ({ goodDamage, originalIndex }))
            .filter(({ goodDamage }) => goodDamage.category &&
            goodDamage.type &&
            goodDamage.category !== GoodDamageCategory.Other &&
            !Object.values(GoodDamageOther).includes(goodDamage.type))
            .sort((a, b) => (a.goodDamage.type || '').localeCompare(b.goodDamage.type || ''))
            .sort((a, b) => (a.goodDamage.category || '').localeCompare(b.goodDamage.category || ''))
            .map((d) => d.originalIndex);
    }
    getGoodDamagesOfType(type) {
        return this.goodDamages.filter((damage) => damage.type === type);
    }
    getIndexOfType(type, goodDamage) {
        return this.getGoodDamagesOfType(type).indexOf(goodDamage);
    }
    getTitleForIndex(index) {
        const goodDamage = this.goodDamages[index];
        if (!(goodDamage === null || goodDamage === void 0 ? void 0 : goodDamage.type))
            return null;
        const translate = this.$tc.bind(this);
        let label = getGoodDamageTitle(goodDamage, translate);
        const matchingGoodDamages = this.goodDamages.filter((damage) => getGoodDamageTitle(damage, translate) === label);
        if (matchingGoodDamages.length > 1)
            label += ` ${matchingGoodDamages.indexOf(goodDamage) + 1}`;
        return label;
    }
    getDetailsForIndex(index) {
        const goodDamage = this.goodDamages[index];
        return getGoodDamageDetails(goodDamage, this.$tc.bind(this));
    }
    onRemove(index) {
        this.removeGoodDamage(index);
        if (!this.sortedGoodDamages.length)
            this.$emit('previous-step');
    }
};
GoodDamageDetailsForm = __decorate([
    Component({
        components: { GoodDamageDetailsInput },
    })
], GoodDamageDetailsForm);
export default GoodDamageDetailsForm;
