var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { WINDOW_PANE_DIMENSIONS_TITLE } from '@shared/constants/externalDamages';
import { WindowComponent } from '@declaration/components/externalDamage/DamageDescription/Window/WindowComponent';
import LengthInput from '@declaration/components/LengthInput.vue';
let WindowPaneDimensions = class WindowPaneDimensions extends WindowComponent {
    get windowPaneDimensionsTitle() {
        if (!this.windowType)
            return '';
        return this.translateExternalDamageKey(WINDOW_PANE_DIMENSIONS_TITLE[this.windowType]);
    }
};
WindowPaneDimensions = __decorate([
    Component({ components: { LengthInput } })
], WindowPaneDimensions);
export default WindowPaneDimensions;
