var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
const claim = namespace('claim');
const counting = namespace('counting');
const contract = namespace('contract');
const qualification = namespace('qualification');
let HasPersonalPropertyDamagesQuestion = class HasPersonalPropertyDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
    }
    updateClaim(hasPersonalPropertyDamages) {
        this.updateAndSaveCounting({
            countingPayload: { hasPersonalPropertyDamages },
            step: this.step,
        });
    }
    get title() {
        return this.trigram === ClaimTypeTrigram.WaterDamage && this.isTenant
            ? this.$tc('claim.damage.personal_property.has_personal_property_damages.tenant.title')
            : this.$tc('claim.damage.personal_property.has_personal_property_damages.owner.title');
    }
    get subTitle() {
        return this.isTenant
            ? this.$tc('claim.damage.personal_property.has_personal_property_damages.tenant.description')
            : null;
    }
    get answer() {
        if (this.counting.hasPersonalPropertyDamages === null) {
            return null;
        }
        return this.counting.hasPersonalPropertyDamages
            ? this.$tc('claim.damage.personal_property.has_personal_property_damages.sumup.yes')
            : this.$tc('claim.damage.personal_property.has_personal_property_damages.sumup.no');
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], HasPersonalPropertyDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    counting.State('counting')
], HasPersonalPropertyDamagesQuestion.prototype, "counting", void 0);
__decorate([
    counting.Action('updateAndSaveCounting')
], HasPersonalPropertyDamagesQuestion.prototype, "updateAndSaveCounting", void 0);
__decorate([
    contract.Getter('isTenant')
], HasPersonalPropertyDamagesQuestion.prototype, "isTenant", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], HasPersonalPropertyDamagesQuestion.prototype, "trigram", void 0);
HasPersonalPropertyDamagesQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], HasPersonalPropertyDamagesQuestion);
export default HasPersonalPropertyDamagesQuestion;
