var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { DOOR_IMPACT_CHOICES } from '@shared/constants/externalDamages/DoorConstants';
import { DOOR_IMPACTS_TITLE, DOOR_IMPACTS_SUBTITLE, } from '@declaration/constants/externalDamages/DoorConstants';
let EntranceDoorImpacts = class EntranceDoorImpacts extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.doorImpactsChoices = DOOR_IMPACT_CHOICES;
    }
    get impactAmount() {
        return this.externalDamage.doorImpactsNumber;
    }
    set impactAmount(doorImpactsNumber) {
        this.updateExternalDamage({ doorImpactsNumber });
    }
    get title() {
        return this.translateExternalDamageKey(DOOR_IMPACTS_TITLE);
    }
    get subtitle() {
        return this.translateExternalDamageKey(DOOR_IMPACTS_SUBTITLE);
    }
};
EntranceDoorImpacts = __decorate([
    Component({ components: { RadioPrimaryButton } })
], EntranceDoorImpacts);
export default EntranceDoorImpacts;
