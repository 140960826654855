var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import SecondaryButton from '../UI/SecondaryButton.vue';
import ClaimsButton from '../UI/ClaimsButton.vue';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import ThirdPartyCard from '@declaration/components/thirdParty/ThirdPartyCard.vue';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
const thirdParty = namespace('thirdParty');
const qualification = namespace('qualification');
let ThirdPartyList = class ThirdPartyList extends Vue {
    constructor() {
        super(...arguments);
        this.isThirdPartyModalOpen = false;
        this.isFetchingInsuranceCompany = false;
    }
    async created() {
        this.isFetchingInsuranceCompany = true;
        await this.fetchInsuranceCompanyChoicesIfNotSet();
        this.isFetchingInsuranceCompany = false;
    }
    selectThirdPartyAndOpenModal(index) {
        this.setThirdPartyInEditionIndex(index);
        this.isThirdPartyModalOpen = true;
    }
    get shouldBePrefilled() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PreFillThirdPartyAddress);
    }
    addNewThirdParty() {
        this.addNewThirdPartyAndEditIt(this.shouldBePrefilled);
        this.isThirdPartyModalOpen = true;
    }
    closeModal() {
        this.isThirdPartyModalOpen = false;
    }
    confirmDelete(thirdPartyIri, deleteCallback) {
        if (thirdPartyIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.third_party.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.third_party.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.third_party.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    get showNoInfoButton() {
        return this.typeTrigram === ClaimTypeTrigram.WaterDamage && this.thirdParties.length === 0;
    }
};
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], ThirdPartyList.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], ThirdPartyList.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.State('thirdParties')
], ThirdPartyList.prototype, "thirdParties", void 0);
__decorate([
    thirdParty.Action('fetchInsuranceCompanyChoicesIfNotSet')
], ThirdPartyList.prototype, "fetchInsuranceCompanyChoicesIfNotSet", void 0);
__decorate([
    qualification.State('typeTrigram')
], ThirdPartyList.prototype, "typeTrigram", void 0);
ThirdPartyList = __decorate([
    Component({
        components: { ThirdPartyModal, ThirdPartyCard, ClaimsButton, SecondaryButton },
    })
], ThirdPartyList);
export default ThirdPartyList;
