var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Checkout from '@declaration/components/Payout/Checkout.vue';
import { uuidFromIri } from '@shared/utils/iri';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { Step } from '@shared/claim/steps';
import { ClaimStatus } from '@shared/types/api/claim';
const claim = namespace('claim');
let PayoutFailure = class PayoutFailure extends Vue {
    constructor() {
        super(...arguments);
        this.isResettingPayout = false;
        this.isRetrying = false;
    }
    async resetAndGoHome() {
        if (!this.claim.id) {
            return;
        }
        this.isResettingPayout = true;
        try {
            await this.$axios.$put(`/checkout/reset-payout/${uuidFromIri(this.claim.id)}`);
            this.$goBackHome();
        }
        catch (_a) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isResettingPayout = false;
    }
    async retry() {
        if (!this.claim.id) {
            return;
        }
        this.isRetrying = true;
        try {
            await this.$axios.$put(`/checkout/reset-payout/${uuidFromIri(this.claim.id)}`);
            await this.updateAndSaveClaimStep(this.claim.status === ClaimStatus.ImmediatePayoutPending
                ? Step.ImmediatePayoutFailure
                : Step.DelayedPayoutFailure);
        }
        catch (_a) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isRetrying = false;
    }
};
__decorate([
    claim.State('claim')
], PayoutFailure.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], PayoutFailure.prototype, "updateAndSaveClaimStep", void 0);
PayoutFailure = __decorate([
    Component({
        components: {
            Checkout,
            ClaimsButton,
        },
        meta: {
            title: 'page.title.payout.index',
            goBack: null,
        },
    })
], PayoutFailure);
export default PayoutFailure;
