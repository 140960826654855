var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RoomDamagesList from '@declaration/components/roomDamage/RoomDamagesList.vue';
import ReceiptList from '@declaration/components/receipt/ReceiptList.vue';
const qualification = namespace('qualification');
const roomDamage = namespace('roomDamage');
let RoomDamageQuestion = class RoomDamageQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get answer() {
        const roomNames = this.roomDamages
            .map(({ roomNameSlug }) => roomNameSlug)
            .filter((roomNameSlug) => !!roomNameSlug)
            .map((roomNameSlug) => this.$tc(QUALIFICATION_ROOM[roomNameSlug].tradKey).toLowerCase());
        if (roomNames.length === 0) {
            return this.$tc('claim.declaration.room_damages.answer.none');
        }
        return `${this.$tc('claim.declaration.room_damages.answer.damaged', roomNames.length)} <strong>${roomNames.join(', ')}</strong>`;
    }
    get validationButtonText() {
        return this.roomDamages.length === 0
            ? this.$tc('claim.declaration.room_damages.validate.no_damage')
            : this.$tc('claim.declaration.room_damages.validate.with_damages');
    }
    get isOneRoomDamageComplete() {
        return this.roomDamages.some((damage) => this.isDamageValid(damage));
    }
    get isEveryRoomDamageComplete() {
        return this.roomDamages.every((damage) => this.isDamageValid(damage));
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.declaration.room_damages.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidationClick() {
        this.isSaving = true;
        await this.validateRoomDamagesStep();
        this.isSaving = false;
    }
    confirmDelete(roomDamageIri, deleteCallback) {
        const onConfirm = async () => {
            this.isSaving = true;
            await deleteCallback();
            this.isSaving = false;
        };
        if (roomDamageIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.real_estate.room_damage.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.real_estate.room_damage.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.real_estate.room_damage.form.delete.modal.confirm'),
                onConfirm,
            });
        }
        else {
            onConfirm();
        }
    }
};
__decorate([
    qualification.Getter('typeTrigram')
], RoomDamageQuestion.prototype, "typeTrigram", void 0);
__decorate([
    roomDamage.Getter('changedDamages')
], RoomDamageQuestion.prototype, "roomDamages", void 0);
__decorate([
    roomDamage.Getter('isDamageValid')
], RoomDamageQuestion.prototype, "isDamageValid", void 0);
__decorate([
    roomDamage.Action('validateRoomDamagesStep')
], RoomDamageQuestion.prototype, "validateRoomDamagesStep", void 0);
RoomDamageQuestion = __decorate([
    Component({
        components: { QuestionPanel, RoomDamagesList, ClaimsButton, ReceiptList },
        props: QuestionProps,
    })
], RoomDamageQuestion);
export default RoomDamageQuestion;
