var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_DEGRADATIONS_CHOICES, GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_INSTALLATION_CHOICES, } from '@shared/constants/glassBreakageDamageConstants';
import { GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_DEGRADATIONS_ANSWERS, GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_INSTALLATION_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
import { GlassBreakageRoomDamagesCause, GlassBreakageRoomDamagesOrigin, } from '@shared/types/api/glassBreakageDamage';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
const thirdParty = namespace('thirdParty');
let GlassBreakagePersonalPropertyDamagesOriginQuestion = class GlassBreakagePersonalPropertyDamagesOriginQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isThirdPartyModalOpen = false;
    }
    get roomDamagesCause() {
        return this.qualification.roomDamagesCause;
    }
    get selectedValue() {
        return this.qualification.roomDamagesOrigin;
    }
    get title() {
        switch (this.roomDamagesCause) {
            case GlassBreakageRoomDamagesCause.Degradations:
                return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damages-origin-degradations-question']);
            case GlassBreakageRoomDamagesCause.Installation:
                return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damages-origin-installation-question']);
            default:
                return '';
        }
    }
    get choices() {
        switch (this.roomDamagesCause) {
            case GlassBreakageRoomDamagesCause.Degradations:
                return GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_DEGRADATIONS_CHOICES;
            case GlassBreakageRoomDamagesCause.Installation:
                return GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_INSTALLATION_CHOICES;
            default:
                return [];
        }
    }
    get thirdPartyPayload() {
        return {
            step: this.step,
            glassBreakageDamageQualification: {
                id: this.qualification.id,
                roomDamagesOrigin: this.selectedValue,
            },
        };
    }
    updateClaim(roomDamagesOrigin) {
        if (roomDamagesOrigin === GlassBreakageRoomDamagesOrigin.Professionnal) {
            if (this.thirdParties.length === 0)
                this.addNewThirdPartyAndEditIt(false);
            else
                this.setThirdPartyInEditionIndex(0);
            this.updateQualification({
                qualificationPayload: { roomDamagesOrigin },
            });
            this.isThirdPartyModalOpen = true;
        }
        else {
            this.updateAndSaveQualification({
                qualificationPayload: { roomDamagesOrigin },
                step: this.step,
            });
        }
    }
    get answer() {
        if (this.selectedValue === null)
            return null;
        switch (this.roomDamagesCause) {
            case GlassBreakageRoomDamagesCause.Degradations:
                return this.$tc(GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_DEGRADATIONS_ANSWERS[this.selectedValue]);
            case GlassBreakageRoomDamagesCause.Installation:
                return this.$tc(GLASS_BREAKAGE_ROOM_DAMAGES_ORIGIN_INSTALLATION_ANSWERS[this.selectedValue]);
            default:
                return null;
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "updateAndSaveQualification", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.State('thirdParties')
], GlassBreakagePersonalPropertyDamagesOriginQuestion.prototype, "thirdParties", void 0);
GlassBreakagePersonalPropertyDamagesOriginQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ThirdPartyModal },
        props: QuestionProps,
    })
], GlassBreakagePersonalPropertyDamagesOriginQuestion);
export default GlassBreakagePersonalPropertyDamagesOriginQuestion;
