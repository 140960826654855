var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { PERGOLA_SPECIFICATIONS_MATERIAL_CHOICES } from '@shared/constants/externalDamages/PergolaConstants';
let PergolaSpecifications = class PergolaSpecifications extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.pergolaMaterialsChoices = PERGOLA_SPECIFICATIONS_MATERIAL_CHOICES;
        this.isSaving = false;
    }
    get pergolaMaterial() {
        var _a;
        return (_a = this.externalDamage.material) !== null && _a !== void 0 ? _a : null;
    }
    set pergolaMaterial(material) {
        this.updateExternalDamage({ material });
    }
    get isBioClimaticPergola() {
        var _a;
        return (_a = this.externalDamage.isBioclimatic) !== null && _a !== void 0 ? _a : null;
    }
    set isBioClimaticPergola(isBioclimatic) {
        this.updateExternalDamage({ isBioclimatic });
    }
};
PergolaSpecifications = __decorate([
    Component({
        components: { RadioPrimaryButton },
        props: {},
    })
], PergolaSpecifications);
export default PergolaSpecifications;
