var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
const thirdParty = namespace('thirdParty');
let FireElectricalCauseCommonPartsQuestion = class FireElectricalCauseCommonPartsQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isModalOpen = false;
        this.YES_OR_NO_CHOICES = YES_OR_NO_CHOICES;
    }
    get thirdPartyPayload() {
        return {
            step: this.step,
            fireDamageQualification: {
                id: this.fireDamageQualification.id,
                fireElectricalCauseCommonParts: this.selectedValue,
            },
        };
    }
    get headerTitleModal() {
        return this.$tc('claim.qualification.fire_third_party_crim_act.form_title');
    }
    get selectedValue() {
        return this.fireDamageQualification.fireElectricalCauseCommonParts;
    }
    get answer() {
        if (this.fireDamageQualification.fireElectricalCauseCommonParts === null) {
            return null;
        }
        if (this.fireDamageQualification.fireElectricalCauseCommonParts)
            return this.$tc('claim.qualification.fire.has_third_party_info.answer');
        return this.$tc('claim.qualification.fire.has_not_third_party_info.answer');
    }
    handleButtonClick(fireElectricalCauseCommonParts) {
        if (fireElectricalCauseCommonParts === false) {
            this.updateAndSaveFireDamageQualification({
                qualificationPayload: { fireElectricalCauseCommonParts },
                step: this.step,
            });
        }
        else {
            if (this.thirdParties.length === 0)
                this.addNewThirdPartyAndEditIt(false);
            else
                this.setThirdPartyInEditionIndex(0);
            this.updateFireDamageQualification({
                qualificationPayload: { fireElectricalCauseCommonParts },
            });
            this.isModalOpen = true;
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireElectricalCauseCommonPartsQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireElectricalCauseCommonPartsQuestion.prototype, "fireDamageQualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireElectricalCauseCommonPartsQuestion.prototype, "updateFireDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireElectricalCauseCommonPartsQuestion.prototype, "updateAndSaveFireDamageQualification", void 0);
__decorate([
    contract.State('selectedContract')
], FireElectricalCauseCommonPartsQuestion.prototype, "selectedContract", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], FireElectricalCauseCommonPartsQuestion.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], FireElectricalCauseCommonPartsQuestion.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.State('thirdParties')
], FireElectricalCauseCommonPartsQuestion.prototype, "thirdParties", void 0);
FireElectricalCauseCommonPartsQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, ThirdPartyModal },
        props: QuestionProps,
    })
], FireElectricalCauseCommonPartsQuestion);
export default FireElectricalCauseCommonPartsQuestion;
