var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { WATER_RADIATOR_DAMAGE_TYPE_CHOICES, WATER_RADIATOR_MATERIAL_CHOICES, } from '@shared/roomDamage/goodDamage';
import { WaterRadiatorDamageType, } from '@shared/types/api/roomDamage';
let WaterRadiatorDescription = class WaterRadiatorDescription extends GoodDamageComponent {
    get damageTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.damage_type');
    }
    get materialLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.heating.water_radiator.material');
    }
    get damageTypeChoices() {
        return WATER_RADIATOR_DAMAGE_TYPE_CHOICES;
    }
    get materialChoices() {
        return WATER_RADIATOR_MATERIAL_CHOICES;
    }
    get damageType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.damageType) !== null && _b !== void 0 ? _b : null;
    }
    set damageType(damageType) {
        this.updateGoodDamage({
            damageType,
            material: this.shouldProvideMaterial(damageType) ? this.material : null,
        });
    }
    get material() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : null;
    }
    set material(material) {
        this.updateGoodDamage({ material });
    }
    shouldProvideMaterial(damageType) {
        return damageType === WaterRadiatorDamageType.DAMAGED;
    }
};
WaterRadiatorDescription = __decorate([
    Component({ components: { RadioPrimaryButton } })
], WaterRadiatorDescription);
export default WaterRadiatorDescription;
