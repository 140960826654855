var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { GLASS_BREAKAGE_DAMAGE_TYPE_ANSWERS, GLASS_BREAKAGE_DAMAGE_TYPE_IMAGE_CHOICES, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
import { GlassBreakageDamageType } from '@shared/types/api/glassBreakageDamage';
const claim = namespace('claim');
const qualification = namespace('qualification');
let GlassBreakageDamageTypeQuestion = class GlassBreakageDamageTypeQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.typeOfDamages = '';
    }
    get questionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['damage-type-question-title']);
    }
    get questionDescription() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['damage-type-question-description']);
    }
    get typeOfDamagesChoices() {
        return GLASS_BREAKAGE_DAMAGE_TYPE_IMAGE_CHOICES;
    }
    get selectedValue() {
        if (this.qualification.hasRoomDamages && this.qualification.hasPersonalPropertyDamages) {
            return null;
        }
        if (this.qualification.hasRoomDamages) {
            return GlassBreakageDamageType.RoomDamages;
        }
        if (this.qualification.hasPersonalPropertyDamages) {
            return GlassBreakageDamageType.PersonalProperties;
        }
        return null;
    }
    get answer() {
        if (this.qualification.hasRoomDamages && this.qualification.hasPersonalPropertyDamages) {
            return this.$tc(GLASS_BREAKAGE_DAMAGE_TYPE_ANSWERS.both);
        }
        if (this.qualification.hasRoomDamages) {
            return this.$tc(GLASS_BREAKAGE_DAMAGE_TYPE_ANSWERS[GlassBreakageDamageType.RoomDamages]);
        }
        if (this.qualification.hasPersonalPropertyDamages) {
            return this.$tc(GLASS_BREAKAGE_DAMAGE_TYPE_ANSWERS[GlassBreakageDamageType.PersonalProperties]);
        }
        return null;
    }
    onTypeOfDamagesClick(typeOfDamages) {
        this.updateGlassBreakageDamageQualification({
            qualificationPayload: {
                hasRoomDamages: typeOfDamages === GlassBreakageDamageType.RoomDamages
                    ? !this.qualification.hasRoomDamages || this.qualification.hasPersonalPropertyDamages
                    : false,
                hasPersonalPropertyDamages: typeOfDamages === GlassBreakageDamageType.PersonalProperties
                    ? !this.qualification.hasPersonalPropertyDamages || this.qualification.hasRoomDamages
                    : false,
            },
        });
    }
    onBothButtonClick() {
        this.updateGlassBreakageDamageQualification({
            qualificationPayload: {
                hasRoomDamages: true,
                hasPersonalPropertyDamages: true,
            },
        });
        this.validate();
    }
    async validate() {
        await this.updateAndSaveGlassBreakageDamageQualification({
            qualificationPayload: {
                hasRoomDamages: this.qualification.hasRoomDamages,
                hasPersonalPropertyDamages: this.qualification.hasPersonalPropertyDamages,
            },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakageDamageTypeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakageDamageTypeQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakageDamageTypeQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakageDamageTypeQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
GlassBreakageDamageTypeQuestion = __decorate([
    Component({
        components: { RadioImageButton, QuestionPanel, ClaimsButton },
        props: QuestionProps,
    })
], GlassBreakageDamageTypeQuestion);
export default GlassBreakageDamageTypeQuestion;
