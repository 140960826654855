var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let PersonalPropertyPictures = class PersonalPropertyPictures extends Vue {
    get title() {
        return this.$tc('claim.damage.personal_property.form.photo.title');
    }
    get message() {
        return this.$tc('claim.damage.personal_property.form.photo.text');
    }
    get picturesFileType() {
        return ClaimFileType.PersonalPropertyPicture;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get pictureCollection() {
        var _a;
        return (_a = this.personalProperty.pictureCollection) !== null && _a !== void 0 ? _a : null;
    }
    set pictureCollection(pictureCollection) {
        this.update({ pictureCollection });
    }
    update(data) {
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
    }
};
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyPictures.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyPictures.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyPictures.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyPictures.prototype, "updatePersonalProperty", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyPictures.prototype, "typeTrigram", void 0);
PersonalPropertyPictures = __decorate([
    Component({
        components: { PictureCollectionUpload },
        props: { changeKey: { type: [String, Number], default: null } },
    })
], PersonalPropertyPictures);
export default PersonalPropertyPictures;
