var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import WindowType from './WindowType.vue';
import WindowCount from './WindowCount.vue';
import WindowStructureDamaged from './WindowStructureDamaged.vue';
import WindowPaneCount from './WindowPaneCount.vue';
import WindowPaneDimensions from './WindowPaneDimensions.vue';
import WindowPaneGlassType from './WindowPaneGlassType.vue';
import WindowPaneGlazingType from './WindowPaneGlazingType.vue';
import { WindowComponent } from './WindowComponent';
import { ExternalDamageOrigin, WindowType as WindowTypeSlug, } from '@shared/types/api/externalDamage';
let Window = class Window extends WindowComponent {
    get hasWindowCount() {
        return this.damageOrigin !== ExternalDamageOrigin.Hail;
    }
    get hasWindowStructureDamaged() {
        return this.damageOrigin === ExternalDamageOrigin.Hail && this.windowType !== null;
    }
    get hasWindowPaneCount() {
        return (this.damageOrigin === ExternalDamageOrigin.Hail &&
            this.windowStructureDamaged === false &&
            this.windowType !== WindowTypeSlug.RoofWindow);
    }
    get hasWindowPaneDimensions() {
        return this.damageOrigin === ExternalDamageOrigin.Hail && this.windowStructureDamaged === false;
    }
    get hasWindowPaneGlazingType() {
        return this.damageOrigin === ExternalDamageOrigin.Hail && this.windowStructureDamaged === false;
    }
    get hasWindowPaneGlassType() {
        return this.damageOrigin === ExternalDamageOrigin.Hail && this.windowStructureDamaged === false;
    }
};
Window = __decorate([
    Component({
        components: {
            WindowType,
            WindowCount,
            WindowStructureDamaged,
            WindowPaneCount,
            WindowPaneDimensions,
            WindowPaneGlassType,
            WindowPaneGlazingType,
        },
    })
], Window);
export default Window;
