import { render, staticRenderFns } from "./HouseCrossSection.vue?vue&type=template&id=6cab6881&scoped=true"
import script from "./HouseCrossSection.vue?vue&type=script&lang=ts"
export * from "./HouseCrossSection.vue?vue&type=script&lang=ts"
import style0 from "./HouseCrossSection.vue?vue&type=style&index=0&id=6cab6881&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cab6881",
  null
  
)

export default component.exports