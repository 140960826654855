var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { FireHasRealEstateDamages } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { FireQuantityOfExternalDamages, FIRE_NUMBER_OF_EXTERNAL_DAMAGES, } from '@declaration/constants/fireDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
const settings = namespace('settings');
let NumberOfExternalDamagesQuestion = class NumberOfExternalDamagesQuestion extends QuestionComponent {
    get maxNumberOfExternalDamages() {
        const value = this.settingGetter('incExternalDamagesCountThresholdBeforeExpertise');
        return value ? parseInt(value) : null;
    }
    get numberOfExternalDamagesChoices() {
        const count = this.maxNumberOfExternalDamages;
        return FIRE_NUMBER_OF_EXTERNAL_DAMAGES.map(({ value, tradKey }) => ({
            value,
            label: this.$tc(tradKey, undefined, { count }),
        }));
    }
    async onUpdate(value) {
        const hasExternalDamages = value === null ? null : value !== FireQuantityOfExternalDamages.None;
        await this.updateAndSaveQualification({
            step: this.step,
            qualificationPayload: {
                externalDamagesCountAboveThreshold: !hasExternalDamages
                    ? null
                    : value === FireQuantityOfExternalDamages.MoreThanThreshold,
            },
        });
    }
    get selectedValue() {
        if ([FireHasRealEstateDamages.Inside, FireHasRealEstateDamages.None, null].includes(this.qualification.hasRealEstateDamages)) {
            return null;
        }
        switch (this.qualification.externalDamagesCountAboveThreshold) {
            case false:
                return FireQuantityOfExternalDamages.ThresholdOrLess;
            case true:
                return FireQuantityOfExternalDamages.MoreThanThreshold;
            default:
                return FireQuantityOfExternalDamages.None;
        }
    }
    get answer() {
        const count = this.maxNumberOfExternalDamages;
        switch (this.selectedValue) {
            case FireQuantityOfExternalDamages.ThresholdOrLess:
                return this.$tc('claim.qualification.fire_number_of_external_damages.sumup.threshold_or_less', undefined, { count });
            case FireQuantityOfExternalDamages.MoreThanThreshold:
                return this.$tc('claim.qualification.fire_number_of_external_damages.sumup.more_than_threshold', undefined, { count });
            default:
                return null;
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], NumberOfExternalDamagesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    settings.Getter('setting')
], NumberOfExternalDamagesQuestion.prototype, "settingGetter", void 0);
__decorate([
    qualification.State('qualification')
], NumberOfExternalDamagesQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], NumberOfExternalDamagesQuestion.prototype, "updateAndSaveQualification", void 0);
NumberOfExternalDamagesQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], NumberOfExternalDamagesQuestion);
export default NumberOfExternalDamagesQuestion;
