var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE } from '@declaration/constants/electricalDamageConstants';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
let ElectricalCauseEquipmentQuestion = class ElectricalCauseEquipmentQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.causeEquipment = null;
    }
    created() {
        this.causeEquipment = this.electricalDamageQualification.causeEquipment;
    }
    get causeEquipmentConfigurationWithImage() {
        return Object.keys(CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE)
            .filter((causeEquipmentSlug) => {
            return CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[causeEquipmentSlug].image;
        })
            .map((causeEquipmentSlug) => {
            return {
                value: causeEquipmentSlug,
                tradKey: CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[causeEquipmentSlug].tradKey,
                image: CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[causeEquipmentSlug].image,
            };
        });
    }
    get causeEquipmentConfigurationWithoutImageValuesAndTradKeys() {
        return Object.keys(CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE)
            .filter((causeEquipmentSlug) => {
            return !CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[causeEquipmentSlug].image;
        })
            .map((causeEquipmentSlug) => {
            return {
                value: causeEquipmentSlug,
                tradKey: CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[causeEquipmentSlug].tradKey,
            };
        });
    }
    updateCauseEquipment(causeEquipment) {
        this.causeEquipment = causeEquipment;
    }
    updateClaim() {
        if (this.isUpdatingClaim) {
            return;
        }
        this.updateAndSaveQualification({
            qualificationPayload: { causeEquipment: this.causeEquipment },
            step: this.step,
        });
    }
    get answer() {
        if (!this.electricalDamageQualification.causeEquipment ||
            !CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[this.electricalDamageQualification.causeEquipment]) {
            return null;
        }
        return this.$tc(CAUSE_EQUIPMENT_CONFIGURATION_BY_TYPE[this.electricalDamageQualification.causeEquipment]
            .answerTradKey);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ElectricalCauseEquipmentQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], ElectricalCauseEquipmentQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], ElectricalCauseEquipmentQuestion.prototype, "updateAndSaveQualification", void 0);
ElectricalCauseEquipmentQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, ClaimsButton, QuestionPanel },
        props: QuestionProps,
    })
], ElectricalCauseEquipmentQuestion);
export default ElectricalCauseEquipmentQuestion;
