var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ContractCard from '@declaration/components/Contract/ContractCard.vue';
import { Step } from '@shared/claim/steps';
const contract = namespace('contract');
const error = namespace('error');
let Contracts = class Contracts extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
    }
    async created() {
        await this.fetchContracts().catch();
        this.isLoading = false;
    }
    async selectAndNavigate(index) {
        this.isLoading = true;
        try {
            await this.selectAndSaveContract({ selectedContractIndex: index, step: Step.ContractChoice });
        }
        catch (_a) {
            this.addError('Une erreur est survenue');
        }
        this.isLoading = false;
    }
    noContractSelection() {
        this.saveWithNoContract(Step.ContractChoice);
    }
};
__decorate([
    contract.State('contracts')
], Contracts.prototype, "contracts", void 0);
__decorate([
    contract.Action('fetchContracts')
], Contracts.prototype, "fetchContracts", void 0);
__decorate([
    contract.Action('selectAndSaveContract')
], Contracts.prototype, "selectAndSaveContract", void 0);
__decorate([
    contract.Action('saveWithNoContract')
], Contracts.prototype, "saveWithNoContract", void 0);
__decorate([
    error.Action('add')
], Contracts.prototype, "addError", void 0);
Contracts = __decorate([
    Component({
        components: {
            ContractCard,
        },
    })
], Contracts);
export default Contracts;
