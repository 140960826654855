var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { GoodDamageComponent } from '../GoodDamageComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { numbersToChoices } from '@shared/choice';
import { SLIDING_BAY_GLASS_TYPE_CHOICES, SLIDING_BAY_GLAZING_TYPE_CHOICES, SLIDING_BAY_LEAF_WIDTH_CHOICES, } from '@shared/roomDamage/goodDamage';
let SlidingBayDescription = class SlidingBayDescription extends GoodDamageComponent {
    get leafCountLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_count');
    }
    get isGlassBrokenLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.is_glass_broken');
    }
    get glazingTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glazing_type');
    }
    get glassTypeLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.glass_type');
    }
    get paneWidthLabel() {
        return this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.opening.sliding_bay.leaf_width');
    }
    get leafCountChoices() {
        return numbersToChoices(2, 3);
    }
    get isGlassBrokenChoices() {
        return YES_OR_NO_CHOICES;
    }
    get glazingTypeChoices() {
        return SLIDING_BAY_GLAZING_TYPE_CHOICES;
    }
    get glassTypeChoices() {
        return SLIDING_BAY_GLASS_TYPE_CHOICES;
    }
    get leafWidthChoices() {
        return SLIDING_BAY_LEAF_WIDTH_CHOICES;
    }
    get leafCount() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.leafCount) !== null && _b !== void 0 ? _b : null;
    }
    set leafCount(leafCount) {
        this.updateGoodDamage({ leafCount });
    }
    get isGlassBroken() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.isGlassBroken) !== null && _b !== void 0 ? _b : null;
    }
    set isGlassBroken(isGlassBroken) {
        this.updateGoodDamage({
            isGlassBroken,
            glazingType: isGlassBroken ? this.glazingType : null,
            glassType: isGlassBroken ? this.glassType : null,
            leafWidth: isGlassBroken ? this.leafWidth : null,
        });
    }
    get glazingType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.glazingType) !== null && _b !== void 0 ? _b : null;
    }
    set glazingType(glazingType) {
        this.updateGoodDamage({ glazingType });
    }
    get glassType() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.glassType) !== null && _b !== void 0 ? _b : null;
    }
    set glassType(glassType) {
        this.updateGoodDamage({ glassType });
    }
    get leafWidth() {
        var _a, _b;
        return (_b = (_a = this.goodDamage) === null || _a === void 0 ? void 0 : _a.leafWidth) !== null && _b !== void 0 ? _b : null;
    }
    set leafWidth(leafWidth) {
        this.updateGoodDamage({ leafWidth });
    }
    get shouldProvideDetails() {
        return this.isGlassBroken === true;
    }
};
SlidingBayDescription = __decorate([
    Component({ components: { RadioPrimaryButton } })
], SlidingBayDescription);
export default SlidingBayDescription;
