var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import DoorMaterials from './DoorMaterials.vue';
import DoorImpacts from './DoorImpacts.vue';
import DoorLeaves from './DoorLeaves.vue';
import DoorHasWindows from './DoorHasWindows.vue';
import DoorHasBrokenWindows from './DoorHasBrokenWindows.vue';
import DoorNumberOfBrokenTiles from './DoorNumberOfBrokenTiles.vue';
import DoorBrokenTileSize from './DoorBrokenTileSize.vue';
import DoorGlazing from './DoorGlazing.vue';
import DoorGlass from './DoorGlass.vue';
import { DoorMaterial } from '@shared/types/api/externalDamage';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
let Door = class Door extends ExternalDamageComponent {
    get doorMaterial() {
        return this.externalDamage.doorMaterial;
    }
    get materialWood() {
        return DoorMaterial.Wood;
    }
    get selectedDoorLeaves() {
        return this.externalDamage.doorLeavesType;
    }
    set selectedDoorLeaves(leavesType) {
        this.updateExternalDamage({ doorLeavesType: leavesType });
    }
    get doorHasWindows() {
        return this.externalDamage.doorHasWindows;
    }
    set doorHasWindows(doorHasWindows) {
        this.updateExternalDamage({ doorHasWindows });
    }
    get doorHasBrokenTiles() {
        return this.externalDamage.doorHasBrokenTiles;
    }
    set doorHasBrokenTiles(doorHasBrokenTiles) {
        this.updateExternalDamage({ doorHasBrokenTiles });
    }
    get selectedDoorGlazingType() {
        return this.externalDamage.doorGlazingType;
    }
    set selectedDoorGlazingType(glazingType) {
        this.updateExternalDamage({ doorGlazingType: glazingType });
    }
    get selectedDoorGlassType() {
        return this.externalDamage.doorGlassType;
    }
    set selectedDoorGlassType(glassType) {
        this.updateExternalDamage({ doorGlassType: glassType });
    }
    getDoorNumberOfBrokenTiles() {
        return this.externalDamage.doorNumberOfBrokenTiles || null;
    }
    setDoorNumberOfBrokenTiles(count) {
        this.updateExternalDamage({ doorNumberOfBrokenTiles: count });
    }
    getBrokenTileHeight() {
        return this.externalDamage.doorBiggestBrokenWindowTileHeight || null;
    }
    setBrokenTileHeight(length) {
        this.updateExternalDamage({ doorBiggestBrokenWindowTileHeight: length });
    }
    getBrokenTileWidth() {
        return this.externalDamage.doorBiggestBrokenWindowTileWidth || null;
    }
    setBrokenTileWidth(length) {
        this.updateExternalDamage({ doorBiggestBrokenWindowTileWidth: length });
    }
};
Door = __decorate([
    Component({
        components: {
            DoorMaterials,
            DoorImpacts,
            DoorLeaves,
            DoorHasWindows,
            DoorHasBrokenWindows,
            DoorNumberOfBrokenTiles,
            DoorBrokenTileSize,
            DoorGlazing,
            DoorGlass,
        },
        props: {
            changeKey: { type: [Number, String], default: null },
        },
    })
], Door);
export default Door;
