var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import GlassDamagesList from '@declaration/components/glassDamage/GlassDamagesList.vue';
import { GLASS_DAMAGE_TYPE_TRAD_KEYS } from '@shared/constants/glassDamageConstants';
const glassDamage = namespace('glassDamage');
let GlassDamagesQuestion = class GlassDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get title() {
        return this.$tc('claim.damage.glass_damage.question.title', this.damagesCount);
    }
    get answer() {
        return this.$tc('claim.damage.glass_damage.answer', this.damagesCount, {
            count: this.damagesCount,
            items: this.glassDamagesList
                .map((damage) => this.$tc(GLASS_DAMAGE_TYPE_TRAD_KEYS[damage.type]).toLowerCase())
                .join(', '),
        });
    }
    get damagesCount() {
        return this.glassDamagesList.length;
    }
    get validationButtonText() {
        return this.$tc('claim.damage.glass_damage.question.validate');
    }
    get canValidate() {
        return this.damagesCount > 0;
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.damage.glass_damage.question.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidation() {
        try {
            this.isSaving = true;
            await this.validateGlassDamagesStep();
        }
        catch (_a) {
        }
        finally {
            this.isSaving = false;
        }
    }
};
__decorate([
    glassDamage.State('damages')
], GlassDamagesQuestion.prototype, "glassDamagesList", void 0);
__decorate([
    glassDamage.Action('validateGlassDamagesStep')
], GlassDamagesQuestion.prototype, "validateGlassDamagesStep", void 0);
GlassDamagesQuestion = __decorate([
    Component({
        components: { QuestionPanel, ClaimsButton, GlassDamagesList },
        props: QuestionProps,
    })
], GlassDamagesQuestion);
export default GlassDamagesQuestion;
