var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RadioSecondaryButton from '@declaration/components/UI/RadioSecondaryButton.vue';
import RoomKitchen from '@declaration/components/svg/RoomKitchen.vue';
let Stylesheet = class Stylesheet extends Vue {
    constructor() {
        super(...arguments);
        this.numberOfRoomDamages = null;
        this.date = null;
        this.checked = false;
    }
    get numberOfRoomDamagesValuesAndLabels() {
        const numberOfRoomDamagesValuesAndLabels = Array.from({ length: 4 }, (_, i) => ({
            value: i + 1,
            label: this.$tc('claim.damage.real_estate.number_of_room.room_number', i + 1, {
                number: i + 1,
            }),
        }));
        numberOfRoomDamagesValuesAndLabels.push({
            value: 4 + 1,
            label: this.$tc('claim.damage.real_estate.number_of_room.room_number_and_more', 4 + 1, {
                number: 4 + 1,
            }),
        });
        return numberOfRoomDamagesValuesAndLabels;
    }
};
Stylesheet = __decorate([
    Component({
        components: {
            ClaimsButton,
            SecondaryButton,
            RadioSecondaryButton,
            RadioPrimaryButton,
            RoomKitchen,
        },
        meta: {
            close: 'index',
        },
    })
], Stylesheet);
export default Stylesheet;
