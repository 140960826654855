var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ExpertAppointmentDateSelection from '@declaration/components/appointment/ExpertAppointmentDateSelection.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { PageComponent, PageProps } from '@declaration/page';
import { CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@shared/layout/layout';
const claim = namespace('claim');
let ExpertAppointmentByVisioDateSelection = class ExpertAppointmentByVisioDateSelection extends PageComponent {
    async onConfirm() {
        try {
            await this.updateAndSaveClaim({
                step: this.step,
                claimPayload: {},
            });
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
};
__decorate([
    claim.Action('updateAndSaveClaim')
], ExpertAppointmentByVisioDateSelection.prototype, "updateAndSaveClaim", void 0);
ExpertAppointmentByVisioDateSelection = __decorate([
    Component({
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'appointment.expert.date_selection.breadcrumb_title',
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
        },
        components: { ExpertAppointmentDateSelection },
        props: PageProps,
    })
], ExpertAppointmentByVisioDateSelection);
export default ExpertAppointmentByVisioDateSelection;
