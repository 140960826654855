var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import { GLASS_BREAKAGE_TRAD_KEYS } from '@declaration/constants/glassBreakageDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
const thirdParty = namespace('thirdParty');
let GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion = class GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalOpen = false;
    }
    get title() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damages-origin-third-party-question']);
    }
    get thirdPartyPayload() {
        return {
            step: this.step,
            glassBreakageDamageQualification: {
                id: this.qualification.id,
                roomDamagesOriginThirdPartyKnown: this.selectedValue,
            },
        };
    }
    get selectedValue() {
        return this.qualification.roomDamagesOriginThirdPartyKnown;
    }
    handleButtonClick(roomDamagesOriginThirdPartyKnown) {
        if (roomDamagesOriginThirdPartyKnown === false) {
            this.updateAndSaveQualification({
                qualificationPayload: { roomDamagesOriginThirdPartyKnown },
                step: this.step,
            });
        }
        else {
            if (this.thirdParties.length === 0)
                this.addNewThirdPartyAndEditIt(false);
            else
                this.setThirdPartyInEditionIndex(0);
            this.updateQualification({
                qualificationPayload: { roomDamagesOriginThirdPartyKnown },
            });
            this.isModalOpen = true;
        }
    }
    get answer() {
        var _a;
        if (this.qualification.roomDamagesOriginThirdPartyKnown === null)
            return null;
        if (this.qualification.roomDamagesOriginThirdPartyKnown === false ||
            !this.thirdParties.length) {
            return this.$tc('claim.damage.glass_breakage.answer.room_damages_origin_third_party_known.no');
        }
        const [thirdParty] = this.thirdParties;
        const name = (_a = thirdParty.contact.companyName) !== null && _a !== void 0 ? _a : [thirdParty.contact.firstName, thirdParty.contact.lastName].filter(Boolean).join(' ');
        const address = [
            thirdParty.contact.address,
            [thirdParty.contact.zipcode, thirdParty.contact.city].filter(Boolean).join(' '),
            thirdParty.contact.addressAffix,
        ]
            .filter(Boolean)
            .join('<br/>');
        return this.$t('claim.damage.glass_breakage.answer.room_damages_origin_third_party_known.yes', {
            name,
            address,
        }).toString();
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "updateAndSaveQualification", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.State('thirdParties')
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion.prototype, "thirdParties", void 0);
GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ThirdPartyModal },
        props: QuestionProps,
    })
], GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion);
export default GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion;
