var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import RoomOutbuilding from '@declaration/components/svg/RoomOutbuilding.vue';
import RoomKitchen from '@declaration/components/svg/RoomKitchen.vue';
import RoomWC from '@declaration/components/svg/RoomWC.vue';
import RoomBedroomOffice from '@declaration/components/svg/RoomBedroomOffice.vue';
import RoomCorridorEntrance from '@declaration/components/svg/RoomCorridorEntrance.vue';
import RoomBathroom from '@declaration/components/svg/RoomBathroom.vue';
import RoomLounge from '@declaration/components/svg/RoomLounge.vue';
import RoomGarage from '@declaration/components/svg/RoomGarage.vue';
import RoomBasement from '@declaration/components/svg/RoomBasement.vue';
import RoomLaundry from '@declaration/components/svg/RoomLaundry.vue';
import RoomOther from '@declaration/components/svg/RoomOther.vue';
let RoomDamageCard = class RoomDamageCard extends Vue {
};
RoomDamageCard = __decorate([
    Component({
        props: {
            roomComponentName: {
                type: String,
                required: true,
            },
            isStatic: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            RoomKitchen,
            RoomWC,
            RoomBedroomOffice,
            RoomCorridorEntrance,
            RoomBathroom,
            RoomLounge,
            RoomGarage,
            RoomBasement,
            RoomLaundry,
            RoomOther,
            RoomOutbuilding,
        },
    })
], RoomDamageCard);
export default RoomDamageCard;
