import { render, staticRenderFns } from "./FireHasRealEstateDamagesQuestion.vue?vue&type=template&id=295a7ee0&scoped=true"
import script from "./FireHasRealEstateDamagesQuestion.vue?vue&type=script&lang=ts"
export * from "./FireHasRealEstateDamagesQuestion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295a7ee0",
  null
  
)

export default component.exports