var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, PropSync } from 'vue-property-decorator';
import NumberInput from '@declaration/components/NumberInput.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { VERANDA_GLASS_PANEL_GLAZING_CHOICES } from '@shared/constants/externalDamages/VerandaConstants';
import { VERANDA_GLASS_PANEL_TITLE, VERANDA_GLASS_PANEL_MESSAGE, VERANDA_GLASS_PANEL_DIMENSIONS, VERANDA_GLASS_PANEL_GLAZING, VERANDA_POLYCARBONATE_PANEL_TITLE, VERANDA_POLYCARBONATE_PANEL_MESSAGE, VERANDA_POLYCARBONATE_PANEL_DIMENSIONS, VERANDA_SANDWICH_PANEL_TITLE, VERANDA_SANDWICH_PANEL_MESSAGE, VERANDA_SANDWICH_PANEL_DIMENSIONS, VERANDA_OTHER_PANEL_TITLE, VERANDA_OTHER_PANEL_MESSAGE, } from '@declaration/constants/externalDamages/VerandaConstants';
import { VerandaPanelMaterial } from '@shared/types/api/externalDamage';
let VerandaPanels = class VerandaPanels extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.glazingChoices = VERANDA_GLASS_PANEL_GLAZING_CHOICES;
    }
    get allPanels() {
        var _a;
        return ((_a = this.externalDamage.panels) !== null && _a !== void 0 ? _a : []);
    }
    get panels() {
        return this.allPanels.filter((panel) => (panel === null || panel === void 0 ? void 0 : panel.material) === this.material);
    }
    get title() {
        switch (this.material) {
            case VerandaPanelMaterial.Glass:
                return this.translateExternalDamageKey(VERANDA_GLASS_PANEL_TITLE);
            case VerandaPanelMaterial.Polycarbonate:
                return this.translateExternalDamageKey(VERANDA_POLYCARBONATE_PANEL_TITLE);
            case VerandaPanelMaterial.SandwichPanel:
                return this.translateExternalDamageKey(VERANDA_SANDWICH_PANEL_TITLE);
            case VerandaPanelMaterial.Other:
                return this.translateExternalDamageKey(VERANDA_OTHER_PANEL_TITLE);
        }
    }
    get message() {
        switch (this.material) {
            case VerandaPanelMaterial.Glass:
                return this.translateExternalDamageKey(VERANDA_GLASS_PANEL_MESSAGE);
            case VerandaPanelMaterial.Polycarbonate:
                return this.translateExternalDamageKey(VERANDA_POLYCARBONATE_PANEL_MESSAGE);
            case VerandaPanelMaterial.SandwichPanel:
                return this.translateExternalDamageKey(VERANDA_SANDWICH_PANEL_MESSAGE);
            case VerandaPanelMaterial.Other:
                return this.translateExternalDamageKey(VERANDA_OTHER_PANEL_MESSAGE);
        }
    }
    get hasDimensions() {
        return this.material !== VerandaPanelMaterial.Other;
    }
    panelDimensionsLabel(index) {
        switch (this.material) {
            case VerandaPanelMaterial.Glass:
                return this.translateExternalDamageKey(VERANDA_GLASS_PANEL_DIMENSIONS, index + 1);
            case VerandaPanelMaterial.Polycarbonate:
                return this.translateExternalDamageKey(VERANDA_POLYCARBONATE_PANEL_DIMENSIONS, index + 1);
            case VerandaPanelMaterial.SandwichPanel:
                return this.translateExternalDamageKey(VERANDA_SANDWICH_PANEL_DIMENSIONS, index + 1);
            case VerandaPanelMaterial.Other:
                return null;
        }
    }
    panelDimensionField(index, dimension) {
        const panel = this.panels[index];
        if (!panel)
            return;
        const realIndex = this.allPanels.indexOf(panel);
        if (realIndex === -1)
            return;
        return {
            type: this.fieldIsValid(`panels.${realIndex}.${dimension}`) ? '' : 'is-danger',
            message: this.fieldErrors(`panels.${realIndex}.${dimension}`)[0],
        };
    }
    getPanelHeight(index) {
        var _a;
        return (_a = this.panels[index].height) !== null && _a !== void 0 ? _a : null;
    }
    setPanelHeight(index, height) {
        const panel = this.panels[index];
        if (!panel)
            return;
        const realIndex = this.allPanels.indexOf(panel);
        if (realIndex === -1)
            return;
        if (height === '')
            height = null;
        if (typeof height === 'string')
            height = parseInt(height);
        const panels = this.allPanels.slice();
        panels.splice(realIndex, 1, { ...panel, height });
        this.updateExternalDamage({ panels });
    }
    getPanelWidth(index) {
        var _a;
        return (_a = this.panels[index].width) !== null && _a !== void 0 ? _a : null;
    }
    setPanelWidth(index, width) {
        const panel = this.panels[index];
        if (!panel)
            return;
        const realIndex = this.allPanels.indexOf(panel);
        if (realIndex === -1)
            return;
        if (width === '')
            width = null;
        if (typeof width === 'string')
            width = parseInt(width);
        const panels = this.allPanels.slice();
        panels.splice(realIndex, 1, { ...panel, width });
        this.updateExternalDamage({ panels });
    }
    get hasGlazing() {
        return this.material === VerandaPanelMaterial.Glass;
    }
    get glazingTitle() {
        return this.translateExternalDamageKey(VERANDA_GLASS_PANEL_GLAZING);
    }
    get glazingField() {
        const type = this.panels.some((panel) => {
            const realIndex = this.allPanels.indexOf(panel);
            if (realIndex === -1)
                return false;
            return !this.fieldIsValid(`panels.${realIndex}.glazingType`);
        })
            ? 'is-danger'
            : '';
        const message = this.panels.reduce((messages, panel) => {
            const realIndex = this.allPanels.indexOf(panel);
            if (realIndex === -1)
                return messages;
            const errors = this.fieldErrors(`panels.${realIndex}.glazingType`);
            return messages.concat(errors);
        }, [])[0];
        return { type, message };
    }
    get glazingType() {
        var _a, _b;
        if (this.material !== VerandaPanelMaterial.Glass)
            return null;
        return (_b = (_a = this.panels.find((panel) => panel.glazingType)) === null || _a === void 0 ? void 0 : _a.glazingType) !== null && _b !== void 0 ? _b : null;
    }
    set glazingType(glazingType) {
        const panels = this.allPanels.map((panel) => {
            if ((panel === null || panel === void 0 ? void 0 : panel.material) !== VerandaPanelMaterial.Glass)
                return panel;
            return { ...panel, glazingType };
        });
        this.updateExternalDamage({ panels });
    }
};
__decorate([
    Prop({ type: String, required: true })
], VerandaPanels.prototype, "material", void 0);
__decorate([
    PropSync('count', { type: Number, required: true })
], VerandaPanels.prototype, "panelCount", void 0);
VerandaPanels = __decorate([
    Component({ components: { RadioImageButton, NumberInput, LengthInput } })
], VerandaPanels);
export default VerandaPanels;
