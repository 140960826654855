var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
let CollapsibleHelp = class CollapsibleHelp extends Vue {
    get titleId() {
        return `collapsible-help-${this._uid}-title`;
    }
};
__decorate([
    Prop({ type: String, required: true })
], CollapsibleHelp.prototype, "title", void 0);
__decorate([
    PropSync('open', { type: Boolean, default: false })
], CollapsibleHelp.prototype, "isOpen", void 0);
CollapsibleHelp = __decorate([
    Component
], CollapsibleHelp);
export default CollapsibleHelp;
