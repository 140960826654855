var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import SvgWithZoomAndTransparentHighlight from '@declaration/components/UI/SvgWithZoomAndTransparentHighlight.vue';
import { isExternalDamageEnabled, getExternalDamageSlugChoices, getExternalDamageLocalizationChoices, EXTERNAL_DAMAGE_LOCALIZATIONS, EXTERNAL_DAMAGE_SLUGS, } from '@shared/constants/externalDamages';
import { ExternalDamageImageComponentName, EXTERNAL_DAMAGE_SLUG_TO_IMAGE_COMPONENT, EXTERNAL_DAMAGE_APARTMENT_SLUGS, getExternalDamageApartmentSlugChoices, ExternalDamageLocalizationSlugAppartmentExcluded, isLocalisationEnabled, getOtherExternalDamageLocalizationChoices, } from '@declaration/constants/externalDamages';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
let ExternalDamageLocalization = class ExternalDamageLocalization extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.internalHoveredElement = null;
        this.internalHoveredDetail = null;
        this.ExternalView = ExternalDamageImageComponentName.ExternalView;
    }
    mounted() {
        const qualification = this.qualification;
        if (this.isStormSnowHailDamage(qualification)) {
            if (!qualification.causeType) {
                throw new Error('No cause type for external damage localization');
            }
        }
    }
    get showDetailImage() {
        if (!this.externalDamageLocalizationSlug)
            return false;
        if (this.damageOrigin) {
            const fn = this.isHouse ? getExternalDamageSlugChoices : getExternalDamageApartmentSlugChoices;
            return fn(this.damageOrigin, this.externalDamageLocalizationSlug).length > 0;
        }
        return false;
    }
    get showImageHouse() {
        return this.isHouse;
    }
    get elementChoices() {
        return this.damageOrigin ? EXTERNAL_DAMAGE_LOCALIZATIONS[this.damageOrigin] : null;
    }
    get detailChoices() {
        if (!this.damageOrigin || !this.externalDamageLocalizationSlug)
            return null;
        const map = this.isHouse ? EXTERNAL_DAMAGE_SLUGS : EXTERNAL_DAMAGE_APARTMENT_SLUGS;
        return map[this.damageOrigin][this.externalDamageLocalizationSlug];
    }
    get localizationChoices() {
        const tradKeys = this.damageOrigin
            ? getExternalDamageLocalizationChoices(this.damageOrigin)
            : [];
        return tradKeys.filter(({ value }) => {
            return this.isHouse || !ExternalDamageLocalizationSlugAppartmentExcluded.includes(value);
        });
    }
    get otherLocalizationChoices() {
        const tradKeys = this.damageOrigin
            ? getOtherExternalDamageLocalizationChoices(this.damageOrigin)
            : [];
        return tradKeys.filter(({ value }) => {
            return this.isHouse || !ExternalDamageLocalizationSlugAppartmentExcluded.includes(value);
        });
    }
    get localizationDetailComponent() {
        var _a;
        if (!this.externalDamage.localizationSlug || !this.damageOrigin)
            return null;
        return ((_a = EXTERNAL_DAMAGE_SLUG_TO_IMAGE_COMPONENT[this.damageOrigin][this.externalDamage.localizationSlug]) !== null && _a !== void 0 ? _a : null);
    }
    get detailsValuesAndTradKeys() {
        if (!this.externalDamageLocalizationSlug)
            return [];
        if (this.damageOrigin) {
            const fn = this.isHouse ? getExternalDamageSlugChoices : getExternalDamageApartmentSlugChoices;
            return fn(this.damageOrigin, this.externalDamageLocalizationSlug);
        }
        return [];
    }
    get selectedElement() {
        return this.externalDamageLocalizationSlug;
    }
    set selectedElement(element) {
        if (element === null || isLocalisationEnabled(element, this.qualification)) {
            this.updateExternalDamageLocalization(element);
        }
    }
    get hoveredElement() {
        return this.internalHoveredElement;
    }
    set hoveredElement(hoveredElement) {
        if (hoveredElement === null || isLocalisationEnabled(hoveredElement, this.qualification)) {
            this.internalHoveredElement = hoveredElement;
        }
    }
    get selectedDetail() {
        return this.externalDamageSlug;
    }
    set selectedDetail(detail) {
        if (!this.selectedElement)
            return;
        if (detail === null ||
            isExternalDamageEnabled(detail, this.selectedElement, this.qualification)) {
            this.updateExternalDamageSlug(detail);
        }
    }
    get hoveredDetail() {
        return this.internalHoveredDetail;
    }
    set hoveredDetail(hoveredDetail) {
        if (!this.selectedElement)
            return;
        if (hoveredDetail === null ||
            isExternalDamageEnabled(hoveredDetail, this.selectedElement, this.qualification)) {
            this.internalHoveredDetail = hoveredDetail;
        }
    }
    closeModal() {
        this.$emit('close');
    }
    onBackButtonClick() {
        this.selectedElement = null;
    }
};
ExternalDamageLocalization = __decorate([
    Component({ components: { SvgWithZoomAndTransparentHighlight, RadioPrimaryButton } })
], ExternalDamageLocalization);
export default ExternalDamageLocalization;
