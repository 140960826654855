var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ReceiptUpload from '@declaration/components/UI/ReceiptUpload.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import AgeInput from '@declaration/components/AgeInput.vue';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
const equipment = namespace('equipment');
let EquipmentForm = class EquipmentForm extends Vue {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isRepairQuoteMandatory = null;
        this.ClaimFileType = ClaimFileType;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get equipment() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    update(data) {
        this.updateEquipment({ changeKey: this.changeKey, data });
    }
};
__decorate([
    claim.State('claim')
], EquipmentForm.prototype, "claim", void 0);
__decorate([
    equipment.Getter('change')
], EquipmentForm.prototype, "changeGetter", void 0);
__decorate([
    equipment.Getter('validation')
], EquipmentForm.prototype, "validationGetter", void 0);
__decorate([
    equipment.Action('updateDamage')
], EquipmentForm.prototype, "updateEquipment", void 0);
EquipmentForm = __decorate([
    Component({
        components: {
            FileCollectionUpload,
            PictureCollectionUpload,
            AmountInput,
            AgeInput,
            ReceiptUpload,
            RadioPrimaryButton,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], EquipmentForm);
export default EquipmentForm;
