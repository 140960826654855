var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RobberyComplaintFiledModal from '@declaration/components/qualification/robbery/RobberyComplaintFiledModal.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyComplaintFiledIntroQuestion = class RobberyComplaintFiledIntroQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalDisplayed = false;
        this.isFiled = false;
        this.step = Step.RobberyComplaintFiledIntro;
        this.claimHasComplaint = false;
        this.answerSummary = Step.QualificationCertification;
    }
    get helpLabel() {
        if (this.$te('help.complaint_filed.question'))
            return this.$tc('help.complaint_filed.question');
    }
    update(isComplaintFiled) {
        this.updateQualification({ qualificationPayload: { isComplaintFiled } });
        this.isModalDisplayed = true;
    }
    get answer() {
        if (this.qualification.isComplaintFiled === null) {
            return null;
        }
        if (this.claim.nextStep === this.answerSummary) {
            if (this.qualification.isComplaintFiled) {
                let answer = this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.yes');
                if (this.qualification.isComplaintFiled &&
                    this.qualification.complaintReceiptFileCollection.uploadedAppFiles.length === 0) {
                    answer += this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.summary');
                }
                if (this.qualification.isComplaintFiled &&
                    this.qualification.complaintReceiptFileCollection.uploadedAppFiles.length > 0)
                    answer +=
                        ' - ' + this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.complaint');
                return answer;
            }
            else {
                return (this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.no') +
                    this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.summary'));
            }
        }
        else {
            let answer = this.qualification.isComplaintFiled
                ? this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.yes')
                : this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.no');
            if (this.qualification.isComplaintFiled &&
                this.qualification.complaintReceiptFileCollection.uploadedAppFiles.length > 0)
                answer +=
                    ' - ' + this.$tc('claim.qualification.robbery.complaint_filed.intro.answer.complaint');
            return answer;
        }
    }
    get smsSuffix() {
        return insurerHasFeature(InsurerFeatureFlag.SmsSending)
            ? this.$tc('claim.qualification.robbery.complaint.sms_suffix')
            : '';
    }
    onModalClose() {
        this.isModalDisplayed = false;
    }
};
__decorate([
    claim.State('claim')
], RobberyComplaintFiledIntroQuestion.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], RobberyComplaintFiledIntroQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyComplaintFiledIntroQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyComplaintFiledIntroQuestion.prototype, "updateQualification", void 0);
RobberyComplaintFiledIntroQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, RobberyComplaintFiledModal },
        props: QuestionProps,
    })
], RobberyComplaintFiledIntroQuestion);
export default RobberyComplaintFiledIntroQuestion;
