var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
const claim = namespace('claim');
const qualification = namespace('qualification');
let IsHelpForProtectionMeasureNeededQuestion = class IsHelpForProtectionMeasureNeededQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
    }
    updateClaim(isHelpForProtectionMeasureNeeded) {
        this.updateAndSaveQualification({
            qualificationPayload: { isHelpForProtectionMeasureNeeded },
            step: this.step,
        });
    }
    get answer() {
        if (this.qualification.isHelpForProtectionMeasureNeeded === null) {
            return null;
        }
        return this.qualification.isHelpForProtectionMeasureNeeded
            ? this.$tc('claim.qualification.storm.protection_measure.is_help_needed.answer.yes')
            : this.$tc('claim.qualification.storm.protection_measure.is_help_needed.answer.no');
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], IsHelpForProtectionMeasureNeededQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], IsHelpForProtectionMeasureNeededQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], IsHelpForProtectionMeasureNeededQuestion.prototype, "updateAndSaveQualification", void 0);
IsHelpForProtectionMeasureNeededQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], IsHelpForProtectionMeasureNeededQuestion);
export default IsHelpForProtectionMeasureNeededQuestion;
