var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { emptyUploadedFileCollection, fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { RepairableStatus } from '@shared/types/api/personalProperty';
const claim = namespace('claim');
let BreakInDamageRepairQuoteUpload = class BreakInDamageRepairQuoteUpload extends Vue {
    get isIrreparable() {
        return this.repairableStatus === RepairableStatus.Irreparable;
    }
    get repairableUploadLabel() {
        return this.repairableStatus === RepairableStatus.Irreparable
            ? this.$tc('claim.damage.personal_property.form.repair.upload.certificate.add')
            : this.uploadLabel;
    }
    get fileType() {
        return this.isIrreparable && this.irreparabilityFileType
            ? this.irreparabilityFileType
            : this.repairFileType;
    }
    get shouldDisplayRepairQuoteAmount() {
        return !!this.repairFileCollection && fileCollectionFilesCount(this.repairFileCollection) > 0;
    }
    onQuoteInfoUpdate(repairQuoteInfo) {
        var _a;
        this.$emit('update', {
            repairableStatus: this.repairableStatus,
            repairQuoteInfo,
            repairFileCollection: (_a = this.repairFileCollection) !== null && _a !== void 0 ? _a : emptyUploadedFileCollection(),
        });
    }
    onFileCollectionUpdate(repairFileCollection) {
        var _a;
        this.$emit('update', {
            repairableStatus: (_a = this.repairableStatus) !== null && _a !== void 0 ? _a : RepairableStatus.Repairable,
            repairQuoteInfo: repairFileCollection.uploadedAppFiles.length > 0 ? this.repairQuoteInfo : null,
            repairFileCollection,
        });
    }
};
__decorate([
    claim.State('claim')
], BreakInDamageRepairQuoteUpload.prototype, "claim", void 0);
BreakInDamageRepairQuoteUpload = __decorate([
    Component({
        components: {
            AmountInput,
            ClaimsButton,
            FileCollectionUpload,
            RadioPrimaryButton,
        },
        props: {
            showIsRepairable: {
                type: Boolean,
                default: false,
            },
            repairableStatus: {
                type: String,
                required: false,
            },
            repairFileCollection: {
                type: Object,
                required: false,
            },
            repairQuoteInfo: {
                type: Object,
                required: false,
            },
            repairQuoteInfoValidationKey: {
                type: String,
                default: 'repairQuoteInfo',
            },
            repairFileType: {
                type: String,
                required: true,
            },
            irreparabilityFileType: {
                type: String,
                default: null,
            },
            validation: {
                type: Object,
                required: true,
            },
            uploadLabel: {
                type: String,
                required: false,
            },
        },
    })
], BreakInDamageRepairQuoteUpload);
export default BreakInDamageRepairQuoteUpload;
