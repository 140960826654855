var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ThirdPartyList from '@declaration/components/thirdParty/ThirdPartyList.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { IrsiConventionTier } from '@shared/types/api/claim';
import { QuestionComponent, QuestionProps } from '@declaration/question';
const thirdParty = namespace('thirdParty');
const settings = namespace('settings');
const qualification = namespace('qualification');
const claim = namespace('claim');
let ThirdPartyQuestion = class ThirdPartyQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get answer() {
        if (!this.thirdParties.length) {
            return this.$tc('claim.declaration.third_party.sumup.no');
        }
        return this.thirdParties.length === 1
            ? this.$tc('claim.declaration.third_party.sumup.yes', 1)
            : this.$tc('claim.declaration.third_party.sumup.yes', 2, { count: this.thirdParties.length });
    }
    get areThirdPartyComplete() {
        return this.thirdParties.every((thirdParty) => {
            return thirdParty.iri;
        });
    }
    get validationText() {
        return this.$tc('claim.declaration.third_party.validate', this.thirdParties.length + 1);
    }
    get canValidate() {
        if (!this.areThirdPartyComplete) {
            return false;
        }
        if (this.thirdParties.length > 0) {
            return true;
        }
        return !this.isThirdPartyRequired;
    }
    get isThirdPartyRequired() {
        // Third parties are mandatory if the setting thirdPartyRequired is true
        // or we are in irsi2 with irsi2ThirdPartyRequired setting true
        if (this.claim.irsi === IrsiConventionTier.Irsi2) {
            return this.setting('irsi2ThirdPartyRequired');
        }
        return this.setting('thirdPartyRequired');
    }
    get isIncidentDescriptionFieldDisplayed() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.ThirdPartyWithIncidentDescription);
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.areThirdPartyComplete
                ? this.$tc('claim.declaration.third_party.validate.error.third_party_required')
                : this.$tc('claim.declaration.third_party.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async validateQuestion() {
        this.isSaving = true;
        await this.validateThirdPartyStep(this.step);
        this.isSaving = false;
    }
};
__decorate([
    thirdParty.State('thirdParties')
], ThirdPartyQuestion.prototype, "thirdParties", void 0);
__decorate([
    thirdParty.Action('validateThirdPartyStep')
], ThirdPartyQuestion.prototype, "validateThirdPartyStep", void 0);
__decorate([
    settings.Getter('setting')
], ThirdPartyQuestion.prototype, "setting", void 0);
__decorate([
    qualification.State('typeTrigram')
], ThirdPartyQuestion.prototype, "typeTrigram", void 0);
__decorate([
    claim.State('claim')
], ThirdPartyQuestion.prototype, "claim", void 0);
__decorate([
    claim.Action('updateThirdPartyIncidentDescription')
], ThirdPartyQuestion.prototype, "updateThirdPartyIncidentDescription", void 0);
ThirdPartyQuestion = __decorate([
    Component({
        components: { QuestionPanel, ThirdPartyList, ClaimsButton },
        props: QuestionProps,
    })
], ThirdPartyQuestion);
export default ThirdPartyQuestion;
