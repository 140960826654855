var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { translateChoices } from '@shared/choice';
import { PERSONAL_PROPERTY_RECEIPT_TYPES } from '@shared/constants/personalPropertyConstants';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { PersonalPropertyReceiptType } from '@shared/types/api/personalProperty';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
// TODO : Complete with other field types when available
let DocumentSynthesisField = class DocumentSynthesisField extends Vue {
    get value() {
        return this.field.value;
    }
    set value(value) {
        this.$emit('update', { ...this.field, value });
    }
    get isPersonalPropertyReceiptType() {
        return (this.field.entity['@type'] === ApiEntityType.PersonalPropertyReceipt &&
            this.field.parameter === 'type');
    }
    get fieldType() {
        if (this.isPersonalPropertyReceiptType) {
            return 'select';
        }
        if (this.field.parameter.includes('amount')) {
            return 'amount';
        }
        return 'text';
    }
    get fieldChoices() {
        if (this.isPersonalPropertyReceiptType) {
            const translate = this.$i18n.tc.bind(this.$i18n);
            const choices = PERSONAL_PROPERTY_RECEIPT_TYPES.filter(({ value }) => value !== PersonalPropertyReceiptType.None);
            return translateChoices(choices, translate);
        }
        return [];
    }
    get label() {
        if (this.isPersonalPropertyReceiptType) {
            return this.$tc('model.personal_property.receipt.type');
        }
        if (this.fieldType === 'amount') {
            return this.$tc('generic.amount');
        }
    }
};
DocumentSynthesisField = __decorate([
    Component({
        components: { AmountInput },
        props: {
            field: {
                type: Object,
                required: true,
            },
        },
    })
], DocumentSynthesisField);
export default DocumentSynthesisField;
