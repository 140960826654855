var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const breakInDamage = namespace('breakInDamage');
let BreakInDamageCheckBox = class BreakInDamageCheckBox extends Vue {
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
};
__decorate([
    breakInDamage.Action('updateDamage')
], BreakInDamageCheckBox.prototype, "updateBreakInDamage", void 0);
BreakInDamageCheckBox = __decorate([
    Component({
        props: {
            changeKey: {
                type: [String, Number],
                default: null,
            },
            title: {
                type: String,
                default: null,
            },
            value: {
                type: Boolean,
                default: null,
            },
            valueName: {
                type: String,
                default: null,
            },
        },
    })
], BreakInDamageCheckBox);
export default BreakInDamageCheckBox;
