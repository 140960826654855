var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import { GARAGE_DOOR_MOTORIZATION_CHOICES, GARAGE_DOOR_MOTORIZATION_LABEL, GARAGE_DOOR_TITLE, GARAGE_DOOR_TYPE_CHOICES, GARAGE_DOOR_TYPE_LABEL, GARAGE_DOOR_WIDTH_CHOICES, GARAGE_DOOR_WIDTH_LABEL, } from '@declaration/constants/externalDamages';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
let GarageDoor = class GarageDoor extends ExternalDamageComponent {
    get title() {
        return this.translateExternalDamageKey(GARAGE_DOOR_TITLE);
    }
    get subTitle() {
        return this.translateExternalDamageKey(GARAGE_DOOR_TYPE_LABEL);
    }
    get widthLabel() {
        return this.translateExternalDamageKey(GARAGE_DOOR_WIDTH_LABEL);
    }
    get motorizationLabel() {
        return this.translateExternalDamageKey(GARAGE_DOOR_MOTORIZATION_LABEL);
    }
    get doorTypeChoices() {
        return GARAGE_DOOR_TYPE_CHOICES;
    }
    get widthChoices() {
        return GARAGE_DOOR_WIDTH_CHOICES;
    }
    get motorizationChoices() {
        return GARAGE_DOOR_MOTORIZATION_CHOICES;
    }
    get doorType() {
        var _a;
        return (_a = this.externalDamage.doorType) !== null && _a !== void 0 ? _a : null;
    }
    set doorType(doorType) {
        this.updateExternalDamage({ doorType });
    }
    get isWidthGreaterThanStandard() {
        var _a;
        return (_a = this.externalDamage.isWidthGreaterThanStandard) !== null && _a !== void 0 ? _a : null;
    }
    set isWidthGreaterThanStandard(isWidthGreaterThanStandard) {
        this.updateExternalDamage({ isWidthGreaterThanStandard });
    }
    get isOpeningMotorized() {
        var _a;
        return (_a = this.externalDamage.isOpeningMotorized) !== null && _a !== void 0 ? _a : null;
    }
    set isOpeningMotorized(isOpeningMotorized) {
        this.updateExternalDamage({ isOpeningMotorized });
    }
};
GarageDoor = __decorate([
    Component({ components: { RadioPrimaryButton, RadioImageButton } })
], GarageDoor);
export default GarageDoor;
