var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import ThirdPartyModal from '@declaration/components/thirdParty/ThirdPartyModal.vue';
import { FIRE_VEHICLE_THIRD_PARTY_KNOWN_ANSWERS, FIRE_VEHICLE_THIRD_PARTY_KNOWN_CHOICES, } from '@declaration/constants/fireDamageConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
const thirdParty = namespace('thirdParty');
let FireCauseVehicleThirdPartyContactQuestion = class FireCauseVehicleThirdPartyContactQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isModalOpen = false;
        this.choices = FIRE_VEHICLE_THIRD_PARTY_KNOWN_CHOICES;
    }
    get title() {
        return this.$tc('claim.qualification.fire_cause_vehicle_origin.title');
    }
    get thirdPartyPayload() {
        return {
            step: this.step,
            fireDamageQualification: {
                id: this.qualification.id,
                hasThirdPartyInfo: this.selectedValue,
            },
        };
    }
    get selectedValue() {
        return this.qualification.hasThirdPartyInfo;
    }
    handleButtonClick(hasThirdPartyInfo) {
        if (!hasThirdPartyInfo) {
            this.updateAndSaveQualification({
                qualificationPayload: { hasThirdPartyInfo },
                step: this.step,
            });
        }
        else {
            if (this.thirdParties.length === 0)
                this.addNewThirdPartyAndEditIt(false);
            else
                this.setThirdPartyInEditionIndex(0);
            this.updateQualification({
                qualificationPayload: { hasThirdPartyInfo },
            });
            this.isModalOpen = true;
        }
    }
    get answer() {
        switch (this.qualification.hasThirdPartyInfo) {
            case null:
                return null;
            case true:
                return this.$tc(FIRE_VEHICLE_THIRD_PARTY_KNOWN_ANSWERS.yes);
            case false:
                return this.$tc(FIRE_VEHICLE_THIRD_PARTY_KNOWN_ANSWERS.no);
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "updateAndSaveQualification", void 0);
__decorate([
    thirdParty.Action('addNewThirdPartyAndEditIt')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "addNewThirdPartyAndEditIt", void 0);
__decorate([
    thirdParty.Action('setThirdPartyInEditionIndex')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "setThirdPartyInEditionIndex", void 0);
__decorate([
    thirdParty.State('thirdParties')
], FireCauseVehicleThirdPartyContactQuestion.prototype, "thirdParties", void 0);
FireCauseVehicleThirdPartyContactQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, ThirdPartyModal },
        props: QuestionProps,
    })
], FireCauseVehicleThirdPartyContactQuestion);
export default FireCauseVehicleThirdPartyContactQuestion;
