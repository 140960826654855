var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import ExternalView from '@declaration/components/svg/StormSnowHail/ExternalView.vue';
import RoofView from '@declaration/components/svg/StormSnowHail/RoofView.vue';
import GardenView from '@declaration/components/svg/StormSnowHail/GardenView.vue';
import OutbuildingView from '@declaration/components/svg/StormSnowHail/OutbuildingView.vue';
let SvgWithZoomAndTransparentHighlight = class SvgWithZoomAndTransparentHighlight extends Vue {
    get selectedElementModel() {
        return this.selectedElement;
    }
    set selectedElementModel(element) {
        this.$emit('update:selected-element', element);
    }
    get hoveredElementModel() {
        return this.hoveredElement;
    }
    set hoveredElementModel(element) {
        this.$emit('update:hovered-element', element);
    }
    get selectedDetailModel() {
        return this.selectedDetail;
    }
    set selectedDetailModel(detail) {
        this.$emit('update:selected-detail', detail);
    }
    get hoveredDetailModel() {
        return this.hoveredDetail;
    }
    set hoveredDetailModel(detail) {
        this.$emit('update:hovered-detail', detail);
    }
    get faded() {
        return (this.selectedElement !== null ||
            this.hoveredElement !== null ||
            this.selectedDetail !== null ||
            this.hoveredDetail !== null);
    }
};
SvgWithZoomAndTransparentHighlight = __decorate([
    Component({
        props: {
            selectedElement: {
                type: String,
                default: null,
            },
            hoveredElement: {
                type: String,
                default: null,
            },
            selectedDetail: {
                type: String,
                default: null,
            },
            hoveredDetail: {
                type: String,
                default: null,
            },
            view: {
                type: String,
                required: true,
            },
            zoomAndPan: {
                type: Object,
                default: null,
            },
            elementChoices: {
                type: Array,
                default: null,
            },
            detailChoices: {
                type: Array,
                default: null,
            },
        },
        components: { ExternalView, RoofView, GardenView, OutbuildingView },
    })
], SvgWithZoomAndTransparentHighlight);
export default SvgWithZoomAndTransparentHighlight;
