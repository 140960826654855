var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let ColorChart = class ColorChart extends Vue {
    constructor() {
        super(...arguments);
        this.rgba2hex = (rgba) => `#${rgba
            .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
            .slice(1)
            .map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
            .toString(16)
            .padStart(2, '0')
            .replace('NaN', ''))
            .join('')}`;
    }
    mounted() {
        for (const elt in this.$refs.colors) {
            const backgroundColor = window
                .getComputedStyle(this.$refs.colors[elt].querySelector('.color__block'), null)
                .getPropertyValue('background-color');
            const label = this.$refs.colors[elt].querySelector('[data-color-label]');
            label.innerHTML = this.rgba2hex(backgroundColor) + ' ' + backgroundColor;
        }
    }
};
ColorChart = __decorate([
    Component({
        props: {
            colorsData: {
                type: [],
                default: [],
            },
            title: {
                type: String,
                default: 'couleur',
            },
        },
    })
], ColorChart);
export default ColorChart;
