var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { Step } from '@shared/claim/steps';
import { uuidFromIri } from '@shared/utils/iri';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RobberyComplaintFiledModal from '@declaration/components/qualification/robbery/RobberyComplaintFiledModal.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
const personalProperty = namespace('personalProperty');
let RobberyComplaintNotFiledQuestion = class RobberyComplaintNotFiledQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.isModalDisplayed = false;
        this.isSendingEmail = false;
        this.isEmailSent = false;
        this.step = Step.RobberyComplaintNotFiled;
        this.illustration = require('@declaration/assets/images/receipts/receipt.svg');
    }
    onValidation() {
        this.updateQualification({ qualificationPayload: { isComplaintFiled: true } });
        this.isModalDisplayed = true;
    }
    get insurerHasFeatureMailButtonForPersonalProperties() {
        return insurerHasFeature(InsurerFeatureFlag.MailButtonForPersonalProperties);
    }
    get answer() {
        if (this.qualification.isComplaintFiled === null ||
            this.qualification.complaintReceiptFileCollection.uploadedAppFiles.length === 0) {
            return null;
        }
        return this.$tc('claim.qualification.robbery.complaint_not_filed.answer');
    }
    get canSendEmail() {
        return this.personalProperties.length > 0;
    }
    onModalClose() {
        this.isModalDisplayed = false;
    }
    async sendEmail() {
        if (!this.claim.id) {
            return;
        }
        this.isSendingEmail = true;
        await this.$axios.$post(`/send-email/stolen-items-list/${uuidFromIri(this.claim.id)}`);
        this.isSendingEmail = false;
        this.isEmailSent = true;
    }
    get smsSuffix() {
        return insurerHasFeature(InsurerFeatureFlag.SmsSending)
            ? this.$tc('claim.qualification.robbery.complaint.sms_suffix')
            : '';
    }
};
__decorate([
    claim.State('claim')
], RobberyComplaintNotFiledQuestion.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], RobberyComplaintNotFiledQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    personalProperty.State('damages')
], RobberyComplaintNotFiledQuestion.prototype, "personalProperties", void 0);
__decorate([
    qualification.State('qualification')
], RobberyComplaintNotFiledQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyComplaintNotFiledQuestion.prototype, "updateQualification", void 0);
RobberyComplaintNotFiledQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, RobberyComplaintFiledModal, ClaimsButton },
        props: QuestionProps,
    })
], RobberyComplaintNotFiledQuestion);
export default RobberyComplaintNotFiledQuestion;
