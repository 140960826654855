var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { translateChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const qualification = namespace('qualification');
let WaterLeakageCauseLocalizedPlumberIntervenedQuestion = class WaterLeakageCauseLocalizedPlumberIntervenedQuestion extends QuestionComponent {
    get title() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu.question-title');
    }
    get choices() {
        return translateChoices(YES_OR_NO_CHOICES, this.$i18n.tc.bind(this.$i18n));
    }
    get answer() {
        if (this.waterDamageQualification.hasPlumberIntervened === null) {
            return null;
        }
        const key = this.waterDamageQualification.hasPlumberIntervened ? 'yes' : 'no';
        return this.$tc(`step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu.answers.${key}`);
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.waterLeakageCauseLocalizedPlumberIntervened) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
    updateClaim(hasPlumberIntervened) {
        this.updateAndSaveWaterDamageQualification({
            step: this.step,
            qualificationPayload: { hasPlumberIntervened },
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
WaterLeakageCauseLocalizedPlumberIntervenedQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel },
        props: QuestionProps,
    })
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion);
export default WaterLeakageCauseLocalizedPlumberIntervenedQuestion;
