var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import BreakInDamageChoices from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageChoices.vue';
import { FenceType } from '@shared/types/api/externalDamage';
import { parseLength } from '@declaration/helpers/typeFormatters';
import { DEFAULT_FENCE_TYPE_TRAD_KEYS } from '@shared/constants/externalDamages/FenceConstants';
import { FENCE_TYPE_FULL_HEIGHT_IMAGES, FENCE_TYPE_HALF_HEIGHT_IMAGES, } from '@declaration/constants/externalDamages/FenceConstants';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let FenceDescription = class FenceDescription extends Vue {
    constructor() {
        super(...arguments);
        this.parseLength = parseLength;
        this.isSaving = false;
        this.showValidation = false;
    }
    get fenceTypes() {
        return Object.values(FenceType).map((value) => ({
            value,
            tradKey: DEFAULT_FENCE_TYPE_TRAD_KEYS[value],
            halfHeightImage: FENCE_TYPE_HALF_HEIGHT_IMAGES[value],
            fullHeightImage: FENCE_TYPE_FULL_HEIGHT_IMAGES[value],
        }));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return (!this.validation.fieldHasViolations('fenceType') &&
            !this.validation.fieldHasViolations('fenceLength'));
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    onImageClick(fenceType, isFenceHalfHeight) {
        if (fenceType === this.breakInDamage.fenceType &&
            isFenceHalfHeight === this.breakInDamage.isFenceHalfHeight) {
            this.update({ fenceType: null, isFenceHalfHeight: null });
        }
        this.update({ fenceType, isFenceHalfHeight });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
};
__decorate([
    layout.State('color')
], FenceDescription.prototype, "color", void 0);
__decorate([
    breakInDamage.Getter('change')
], FenceDescription.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], FenceDescription.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], FenceDescription.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], FenceDescription.prototype, "validateBreakInDamage", void 0);
FenceDescription = __decorate([
    Component({
        components: {
            BreakInDamageChoices,
            RadioPrimaryButton,
            ClaimsButton,
            BreakInDamageDescriptionSectionCard,
            BreakInDamageDescriptionStep,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
        },
    })
], FenceDescription);
export default FenceDescription;
