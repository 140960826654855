var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import ExternalDamageModal from '@declaration/components/externalDamage/ExternalDamageModal.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import ExternalDamagesList from '@declaration/components/externalDamage/ExternalDamagesList.vue';
import { EXTERNAL_DAMAGE_SLUG_TRAD_KEYS } from '@shared/constants/externalDamages';
import { InsuranceContractXmlLabel } from '@shared/types/api/contract';
import { ExclusionReason } from '@shared/types/api/refundDetail';
const externalDamage = namespace('externalDamage');
const contract = namespace('contract');
const qualification = namespace('qualification');
let ExternalDamagesQuestion = class ExternalDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.externalDamage = null;
        this.selectedChangeKey = null;
        this.isExternalDamageModalOpen = false;
    }
    addNewDamage() {
        this.selectedChangeKey = 0;
        this.isExternalDamageModalOpen = true;
    }
    deleteDamageHandler(externalDamage) {
        this.externalDamage = externalDamage;
        this.confirmDelete(externalDamage.id, this.deleteExternalDamage);
    }
    confirmDelete(externalDamageIri, deleteCallback) {
        if (externalDamageIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.external_damages.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.external_damages.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.external_damages.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    selectExternalDamageAndOpenModal(externalDamage) {
        this.selectedChangeKey = externalDamage.id;
        this.isExternalDamageModalOpen = true;
    }
    closeModal() {
        this.isExternalDamageModalOpen = false;
    }
    async deleteExternalDamage() {
        var _a, _b;
        this.isSaving = true;
        if ((_a = this.externalDamage) === null || _a === void 0 ? void 0 : _a.id) {
            await this.deleteDamage((_b = this.externalDamage) === null || _b === void 0 ? void 0 : _b.id);
            await this.saveDamage();
        }
        else {
            await this.deleteDamage(this.selectedChangeKey);
        }
        this.isSaving = false;
    }
    get areOutbuildingsExcluded() {
        if (this.applicableContractDetail === null) {
            return false;
        }
        for (const contractCategoriesCondition of this.applicableContractDetail
            .contractCategoriesConditions) {
            if (contractCategoriesCondition.insuranceContractXmlLabel ===
                InsuranceContractXmlLabel.Outbuilding) {
                return contractCategoriesCondition.isExcluded;
            }
        }
        return false;
    }
    get answer() {
        if (!this.externalDamagesList.length)
            return this.$tc('claim.damage.external_damages.question.answer.no_damages');
        const qualification = this.qualification;
        const externalDamagesDetail = this.externalDamagesList
            .filter((damage) => !!damage.slug)
            .map((damage) => {
            const key = 'causeType' in qualification ? qualification.causeType : ClaimTypeTrigram.Fire;
            if (!key)
                return '';
            const tradKey = EXTERNAL_DAMAGE_SLUG_TRAD_KEYS[key][damage.slug];
            return this.$tc(typeof tradKey === 'string' ? tradKey : tradKey.sumupTradKey).concat(damage.refund.parameters.exclusionReason === ExclusionReason.ContractGuarantees
                ? ` (${this.$tc('claim.declaration.external_damages.question.info.not_covered')})`
                : '');
        })
            .join(', ');
        return this.$tc('claim.damage.external_damages.question.answer.with_damages', 0, {
            detail: externalDamagesDetail,
        });
    }
    get areExternalDamagesComplete() {
        return (this.areDamagesValid &&
            this.externalDamagesList.every((externalDamage) => {
                return externalDamage.id;
            }));
    }
    get validationButtonText() {
        return this.externalDamagesList.length === 0
            ? this.$tc('claim.declaration.external_damages.validate.no_damages')
            : this.$tc('claim.declaration.external_damages.validate.with_damages');
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.declaration.external_damages.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidationClick() {
        this.isSaving = true;
        await this.validateExternalDamagesStep();
        this.isSaving = false;
    }
};
__decorate([
    qualification.State('qualification')
], ExternalDamagesQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], ExternalDamagesQuestion.prototype, "typeTrigram", void 0);
__decorate([
    externalDamage.State('damages')
], ExternalDamagesQuestion.prototype, "externalDamagesList", void 0);
__decorate([
    externalDamage.Getter('areDamagesValid')
], ExternalDamagesQuestion.prototype, "areDamagesValid", void 0);
__decorate([
    externalDamage.Action('validateExternalDamagesStep')
], ExternalDamagesQuestion.prototype, "validateExternalDamagesStep", void 0);
__decorate([
    externalDamage.Action('deleteDamage')
], ExternalDamagesQuestion.prototype, "deleteDamage", void 0);
__decorate([
    externalDamage.Action('saveChanges')
], ExternalDamagesQuestion.prototype, "saveDamage", void 0);
__decorate([
    contract.State('applicableContractDetail')
], ExternalDamagesQuestion.prototype, "applicableContractDetail", void 0);
ExternalDamagesQuestion = __decorate([
    Component({
        components: { QuestionPanel, ExternalDamagesList, ClaimsButton, ExternalDamageModal },
        props: QuestionProps,
    })
], ExternalDamagesQuestion);
export default ExternalDamagesQuestion;
