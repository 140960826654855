var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { WINDOW_STRUCTURE_DAMAGED_TITLE, WINDOW_STRUCTURE_DAMAGED_SUBTITLE, } from '@declaration/constants/externalDamages';
import { translateChoices } from '@shared/choice';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { WindowComponent } from '@declaration/components/externalDamage/DamageDescription/Window/WindowComponent';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
let WindowStructureDamaged = class WindowStructureDamaged extends WindowComponent {
    get windowStructureDamagedTitle() {
        if (!this.windowType)
            return '';
        return this.translateExternalDamageKey(WINDOW_STRUCTURE_DAMAGED_TITLE[this.windowType]);
    }
    get windowStructureDamagedSubtitle() {
        return this.translateExternalDamageKey(WINDOW_STRUCTURE_DAMAGED_SUBTITLE);
    }
    get windowStructureDamagedChoices() {
        return translateChoices(YES_OR_NO_CHOICES, this.translateExternalDamageKey.bind(this));
    }
};
WindowStructureDamaged = __decorate([
    Component({ components: { RadioPrimaryButton } })
], WindowStructureDamaged);
export default WindowStructureDamaged;
